import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createOpponentTeam,
  deleteOpponentTeam,
  editOpponentTeam,
} from 'entities/opponentTeams/api';
import { opponentTeamsQueryKeys } from 'entities/opponentTeams/consts';

export const createOpponentTeamMutation = () =>
  useMutation({
    mutationFn: createOpponentTeam,
  });

export const editOpponentTeamMutation = () =>
  useMutation({
    mutationFn: editOpponentTeam,
  });

export const useDeleteOpponentTeamMutation = () => {
  return useMutation({
    mutationFn: deleteOpponentTeam,
    onSuccess: () => {
      return queryClient.resetQueries([opponentTeamsQueryKeys.opponentTeams]);
    },
  });
};
