import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { opponentTeamsQueryKeys } from 'entities/opponentTeams/consts';
import { createOpponentTeamMutation } from 'entities/opponentTeams/mutations';
import { mapOpponentTeamModalFieldsToDTO } from 'feature/opponentTeamModal/mapper/mapOpponentTeamModalFieldsToDTO';
import { TOpponentTeamModalFields } from 'feature/opponentTeamModal/OpponentTeamModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useCreateOpponentTeam = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { mutateAsync, isLoading } = createOpponentTeamMutation();
  const { locales } = useLocales();

  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  const createTeam = async (
    createOpponentTeamModalFields: TOpponentTeamModalFields,
  ) => {
    const dto = await mapOpponentTeamModalFieldsToDTO(
      createOpponentTeamModalFields,
      locales,
    );

    await mutateAsync(dto, {
      onSuccess: () => {
        queryClient.resetQueries([opponentTeamsQueryKeys.opponentTeams]);
      },
      onError: () => {
        notify(t('content.validation.notifyCreateTeamOpponent'), {
          type: 'error',
        });
      },
      onSettled: closeModal,
    });
  };

  return { isLoading, createTeam, openModal, closeModal, isOpenModal };
};
