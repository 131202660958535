import { TTableSubComponents, TTableWithRef } from 'shared/components/SimpleTable';
import { TSortableCellProps } from 'shared/components/SimpleTable/components/THeadCell/THeadCell';
import { Text } from 'shared/components/Text/Text';
import { TSortOptions } from 'shared/types/table';
import { TColumnConfig, TDataItem } from 'widgets/elementsList/types';
type TProps<T extends TDataItem, SortBy extends string> = Omit<
  TColumnConfig<T, SortBy>,
  'headCellProps'
> & {
  sortOptions: TSortOptions<SortBy>;
  onSort: (sortOptions: TSortOptions<SortBy>) => void;
  headCellProps: TSortableCellProps<SortBy>;
  tableComponent: TTableWithRef & TTableSubComponents<SortBy>;
};
export const CommonListTableHeadCellSortable = <
  T extends TDataItem,
  SortBy extends string,
>({
  title,
  tableComponent: Table,
  sortOptions,
  onSort,
  headCellProps = {},
}: TProps<T, SortBy>) => {
  const { sort, ...restHeadCellProps } = headCellProps;
  return (
    <Table.HeadCell
      {...restHeadCellProps}
      sort={{
        ...sort,
        sortOptions,
        onSort,
      }}
    >
      <Text>{title}</Text>
    </Table.HeadCell>
  );
};
