import { useQueryClient } from '@tanstack/react-query';
import { managerTeamAchievementsQueryKeys } from 'entities/managerTeamAchievements/consts';
import { useDeleteManagerTeamAchievementMutation } from 'entities/managerTeamAchievements/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeleteManagerTeamAchievement = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: deleteManagerTeamAchievementMutation, isLoading } =
    useDeleteManagerTeamAchievementMutation();

  const deleteManagerTeamAchievement = (teamAchievementId: TEntityId) => {
    deleteManagerTeamAchievementMutation(teamAchievementId, {
      onError: () =>
        notify(t('content.manager.notifyNotDeleteTeamAchievements'), {
          type: 'error',
        }),
      onSuccess: () =>
        queryClient.resetQueries([
          managerTeamAchievementsQueryKeys.managerTeamAchievements,
        ]),
    });
  };

  return {
    isDeletingManagerTeamAchievement: isLoading,
    deleteManagerTeamAchievement,
  };
};
