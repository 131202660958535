import { TSessionCredentials } from 'entities/session/model/types';
import { useAuthorize } from 'feature/auth/model/useAuthorize';
import { AuthSelectSandbox } from 'feature/auth/ui/AuthSelectSandbox/AuthSelectSandbox';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Form, FormInput } from 'shared/components/Form';
import {
  getDefaultLoginRules,
  getDefaultRequiredRules,
} from 'shared/components/Form/validations';
import { Text } from 'shared/components/Text/Text';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from './AuthForm.module.css';

export const AuthForm = () => {
  const { t } = useTranslation();
  const { authorize, isLoading } = useAuthorize();

  const { submit, control } = useForm<TSessionCredentials>({
    reValidateMode: 'onBlur',
    actionSubmit: authorize,
  });

  return (
    <div className={styles.root}>
      <Text className={styles.title} size={24} tag="h1" weight="SemiBold">
        {t('content.welcome', { mark: '!' })}
      </Text>
      <Text className={styles.subtitle} size={18}>
        {t('content.loginTitle')}
      </Text>
      <Form action={submit} className={styles.form}>
        <FormInput
          control={control}
          fieldName="login"
          placeholder={t('content.login')}
          rules={getDefaultLoginRules()}
        />
        <FormInput
          control={control}
          fieldName="password"
          placeholder={t('content.password')}
          rules={getDefaultRequiredRules()}
          type="password"
        />
        <AuthSelectSandbox />
        <Button isDisabled={isLoading} onClick={submit}>
          {t('content.loginButton')}
        </Button>
      </Form>
    </div>
  );
};
