import { TContentLang, TTranslations } from 'shared/types/common';

export const reduceTranslationObject = <T>(
  locales: TContentLang[],
  resolver: (locale: TContentLang) => T,
) =>
  locales.reduce(
    (obj, locale) => ({
      ...obj,
      [locale]: resolver(locale),
    }),
    {} as TTranslations<T>,
  );
