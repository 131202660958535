import { OpponentTeamModal } from 'feature/opponentTeamModal/OpponentTeamModal';
import { useCreateOpponentTeam } from 'pages/opponentTeam/OpponentTeamsPage/hooks/useCreateOpponentTeam';
import { useTranslation } from 'react-i18next';
import { CardButton } from 'shared/components/CardButton/CardButton';
export const OpponentTeamsPageCreateButton = () => {
  const { t } = useTranslation();
  const {
    createTeam,
    isLoading: isLoadingCreateTeam,
    openModal,
    isOpenModal,
    closeModal,
  } = useCreateOpponentTeam();
  return (
    <>
      <CardButton title={t('shared.create')} onClick={openModal} />
      <OpponentTeamModal
        actionSubmit={createTeam}
        closeModal={closeModal}
        isLoading={isLoadingCreateTeam}
        isOpened={isOpenModal}
        title={t('content.teams.createTeamsEnding')}
      />
    </>
  );
};
