import { useEditPhotoGalleryImageMutation } from 'entities/photoGalleryImages/mutations';
import { TPhotoGalleryImage } from 'entities/photoGalleryImages/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

export const useEditPhotoGalleryImage = ({
  id,
  photoGalleryId,
  externalId,
}: TPhotoGalleryImage) => {
  const { t } = useTranslation();
  const { openImageEditModal, closeImageEditModal, openedImageEditModal } =
    useModal('ImageEdit');
  const { mutate: editPhotoGalleryImage, isLoading: isLoadingEditing } =
    useEditPhotoGalleryImageMutation();

  const onConfirmEdit = async (files: File[]) => {
    closeImageEditModal();
    const data = clearMetaDataFromBase64Url(await getBase64FromFile(files[0]));

    editPhotoGalleryImage(
      {
        id,
        photoGalleryId,
        externalId: externalId || undefined,
        picture: {
          filename: files[0].name,
          data,
        },
      },
      {
        onSuccess: () => {
          notify(t('content.photoGallery.successLoadImage'), { type: 'success' });
          closeImageEditModal();
        },
        onError: () => {
          notify(t('content.photoGallery.errorLoadImage'), {
            type: 'error',
          });
          closeImageEditModal();
        },
      },
    );
  };

  return {
    isLoadingEditing,
    openImageEditModal,
    openedImageEditModal,
    onConfirmEdit,
    closeImageEditModal,
  };
};
