import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { Form, FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { getImageSettings } from 'shared/constants';
import { getLangNameMap } from 'shared/consts';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TVideoCategoriesFormFields } from 'widgets/videoCategoriesForm/types';
import { addVideoCategoriesPostValidationSchema } from 'widgets/videoCategoriesForm/validationSchema';

import styles from './VideoCategoriesFormModal.module.css';

type TProps = {
  title: string;
  subtitle: string;
  closeModal: () => void;
  actionSubmit: (data: TVideoCategoriesFormFields) => void;
  isOpened: boolean;
  initialValues: TVideoCategoriesFormFields;
};

export const VideoCategoriesFormModal = ({
  title,
  subtitle,
  closeModal,
  actionSubmit,
  isOpened,
  initialValues,
}: TProps) => {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const {
    control,
    submit,
    formState: { isValid },
    reset,
  } = useForm<TVideoCategoriesFormFields>({
    values: initialValues,
    mode: 'onChange',
    resolver: zodResolver(addVideoCategoriesPostValidationSchema(locales)),
    actionSubmit,
    needResetAfterSubmit: true,
  });

  const onCloseModal = () => {
    closeModal();
    reset();
  };

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
      }}
      onClose={onCloseModal}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        {subtitle}
      </Text>
      <Form action={submit} className={styles.form}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`translations.${locale}.name`}
              inputClassName={styles.inputForm}
              label={getLangNameMap()[locale]}
              maxLength={255}
              placeholder={t('content.inputPlaceholder')}
              required
              size="s"
            />
          );
        })}
        <Text className={styles.text} size={14} tag="h3" weight="SemiBold">
          {t('content.importance')}
        </Text>
        <FormInput
          control={control}
          fieldName="importance"
          inputClassName={styles.inputForm}
          placeholder={t('content.video.importance')}
          size="s"
          type="number"
        />
        <Text className={styles.text} size={14} tag="h3" weight="SemiBold">
          {t('content.video.coverCategory')}
        </Text>
        <UploadImage
          aspectRatio={getImageSettings(t).video.importance.aspectRatio}
          control={control}
          fieldName="picture"
          label={t('content.cover')}
          modalTitle={t('content.video.coverCategory')}
          required
          showIcon={false}
        />
      </Form>
    </ActionModal>
  );
};
