import { TNewsCategory } from 'entities/newsCategories/types';
import {
  TContentLang,
  TEntityId,
  TImageContent,
  TImageUpload,
  TTranslationsObject,
} from 'shared/types/common';

export enum ParagraphTypeEnum {
  Text = 'text',
  Image = 'image',
  MainImage = 'main_image',
  Slider = 'slider',
  Title = 'title',
  Video = 'video',
}

export type TTitleParagraph = {
  id: TEntityId;
  type: ParagraphTypeEnum.Title;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  title: string;
}>;

export type TMainImageParagraph = {
  id: TEntityId;
  type: ParagraphTypeEnum.MainImage;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  file: TImageContent;
}>;

export type TSliderParagraph = {
  id: TEntityId;
  type: ParagraphTypeEnum.Slider;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  files: TImageContent[];
}>;

export type TTextParagraph = {
  id: TEntityId;
  type: ParagraphTypeEnum.Text;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  content: string;
}>;

export type TImageParagraph = {
  id: TEntityId;
  type: ParagraphTypeEnum.Image;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  file: TImageContent;
}>;

export type TVideoParagraph = {
  id: TEntityId;
  type: ParagraphTypeEnum.Video;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  videoLink: string;
}>;

export type TParagraph =
  | TTitleParagraph
  | TMainImageParagraph
  | TSliderParagraph
  | TTextParagraph
  | TImageParagraph
  | TCreateVideoParagraphParams;

export enum ENewsPostStatus {
  Published = 'published',
  Draft = 'draft',
  Postponed = 'postponed',
}

type TEntitiesArray = { id: TEntityId }[];

export type TAPINewsPost = {
  id: TEntityId;
  pictures: {
    base: TImageContent;
    slider?: TImageContent;
  };
  availableLocales: TContentLang[];
  paragraphs: TParagraph[];
  importance: number;
  published?: string;
  createdAt?: string;
  categories: TNewsCategory[];
  seasons: TEntitiesArray;
  matches: TEntitiesArray;
  labels: TEntitiesArray;
  managers: TEntitiesArray;
  teams: TEntitiesArray;
  players: TEntitiesArray;
  tournaments: TEntitiesArray;
};

export type TNewsPost = {
  title: string;
  status: ENewsPostStatus;
} & TAPINewsPost;

export type TDeleteNewsPostParams = {
  newsPostId: TEntityId;
};

export type TCreateTitleParagraphParams = {
  id?: TEntityId;
  type: ParagraphTypeEnum.Title;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  title: string;
}>;

export type TCreateImageParagraphParams = {
  id?: TEntityId;
  type: ParagraphTypeEnum.Image;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  file: TImageUpload;
}>;

export type TCreateSliderParagraphParams = {
  id?: TEntityId;
  type: ParagraphTypeEnum.Slider;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  files: TImageUpload[];
}>;

export type TCreateMainImageParagraphParams = {
  id?: TEntityId;
  type: ParagraphTypeEnum.MainImage;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  file: TImageUpload;
}>;

export type TCreateTextParagraphParams = {
  id?: TEntityId;
  type: ParagraphTypeEnum.Text;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  content: string;
}>;

export type TCreateVideoParagraphParams = {
  id?: TEntityId;
  type: ParagraphTypeEnum.Video;
  externalId?: TEntityId;
  position: number;
} & TTranslationsObject<{
  videoLink: string;
}>;

export type TCreateNewsPostParagraphDTO =
  | TCreateTitleParagraphParams
  | TCreateTextParagraphParams
  | TCreateVideoParagraphParams
  | TCreateImageParagraphParams
  | TCreateSliderParagraphParams
  | TCreateMainImageParagraphParams;

export type TEditNewsPostParagraphDTO = TCreateNewsPostParagraphDTO & {
  deleted?: boolean;
};

export type TCreateNewsPostDTO = {
  importance: number;
  relationships: {
    categories: TEntityId[] | null;
    labels: TEntityId[] | null;
    tournaments: TEntityId[] | null;
    seasons: TEntityId[] | null;
    matches: TEntityId[] | null;
    players: TEntityId[] | null;
    managers: TEntityId[] | null;
    teams: TEntityId[] | null;
  };
  availableLocales: TContentLang[];
  published?: string | null;
  pictures: {
    baseImage: TImageUpload;
    sliderImage: TImageUpload | null;
  };
  paragraphs: TCreateNewsPostParagraphDTO[];
};

export type TEditNewsPostDTO = Omit<TCreateNewsPostDTO, 'paragraphs'> & {
  id: TEntityId;
  paragraphs: TEditNewsPostParagraphDTO[];
};
