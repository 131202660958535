import { TEntityId } from 'shared/types/common';

const videoCategoriesBaseUrl = 'video_categories';

export const videoCategoriesQueryKeys = {
  videoCategories: 'videoCategories',
};

export const videoCategoriesApiKeys = {
  createVideoCategory: videoCategoriesBaseUrl,
  getVideoCategories: videoCategoriesBaseUrl,
  editVideoCategory: (categoryId: TEntityId) =>
    `${videoCategoriesBaseUrl}/${categoryId}`,
  deleteVideoCategory: (categoryId: TEntityId) =>
    `${videoCategoriesBaseUrl}/${categoryId}`,
};
