import cn from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './THead.module.css';

export type TTableHeadProps = HTMLAttributes<HTMLDivElement>;

export const THead = ({ children, className, ...other }: TTableHeadProps) => (
  <div {...other} className={cn(styles.tableHeader, className)}>
    {children}
  </div>
);
