import { useDeletePlayerTeamAchievementMutation } from 'entities/playerTeamAchievements/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerTeamAchievement = () => {
  const { t } = useTranslation();
  const { mutate: deletePlayerTeamAchievementMutation, isLoading } =
    useDeletePlayerTeamAchievementMutation();

  const deletePlayerTeamAchievement = (teamAchievementId: TEntityId) => {
    deletePlayerTeamAchievementMutation(teamAchievementId, {
      onError: () =>
        notify(t('content.player.notifyNotDeleteTeamAchievements'), {
          type: 'error',
        }),
    });
  };

  return {
    isDeletingPlayerTeamAchievement: isLoading,
    deletePlayerTeamAchievement,
  };
};
