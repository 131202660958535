import { useLocales } from 'entities/config/lib/useLocales';
import { TManagerSocialNetworkItem } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/types';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './ManagerSocialNetworksPage.module.css';

export const useManagerSocialNetworksColumnsConfig: TConfigCreator<
  TManagerSocialNetworkItem
> = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  return [
    {
      title: t('content.title'),
      cellClassName: styles.nameColumn,
      render: (item) => (
        <>
          <div className={styles.icon}>
            <img
              alt={`social network ${item.socialNetwork.translations[defaultLocale].name}`}
              src={item.socialNetwork.picture.x3}
            />
          </div>
          <Text className={styles.text} color="var(--gray-500)">
            {item.socialNetwork.translations[defaultLocale].name}
          </Text>
        </>
      ),
    },
  ];
};
