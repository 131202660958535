import { useConfig } from 'entities/config/lib/useConfig';
import { useUser } from 'entities/user/lib/useUser';
import { useToken } from 'shared/api/token';
import { toBoolean } from 'shared/lib/toBoolean';

export const useSession = () => {
  const token = useToken();

  const { config, isLoading: isLoadingConfig } = useConfig();
  const { user, isLoading: isLoadingUser } = useUser();

  const isLoading = token && (isLoadingConfig || isLoadingUser);
  const isAuthorized = toBoolean(user && config && user.roles);

  return { isAuthorized, isLoading };
};
