import { ManagerLayout } from 'feature/managerLayout/ui/ManagerLayout/ManagerLayout';
import { useEditManager } from 'pages/manager/edit/EditManagerPage/useEditManager';
import { useLoadManagerForEdit } from 'pages/manager/edit/EditManagerPage/useLoadManagerForEdit';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { ManagerForm } from 'widgets/managerForm/ui/ManagerForm/ManagerForm';
import { useManagerForm } from 'widgets/managerForm/ui/ManagerForm/useManagerForm';
export const EditManagerPage = () => {
  const { isLoading: isLoadingManager, managerFormFields } = useLoadManagerForEdit();
  const { editManager, isLoading: isLoadingEditManager } = useEditManager();
  const managerForm = useManagerForm({
    initialValues: managerFormFields,
  });
  const editManagerHandler = () => {
    editManager(managerForm.getValues());
  };
  return (
    <ManagerLayout
      confirmButtonProps={{
        isDisabled: !managerForm.formState.isValid,
        onClick: editManagerHandler,
        isLoading: isLoadingEditManager,
      }}
    >
      {isLoadingManager ? (
        <Centered>
          <Loader />
        </Centered>
      ) : (
        <ManagerForm control={managerForm.control} />
      )}
    </ManagerLayout>
  );
};
