import { isAfter, parseISO } from 'date-fns';
import { ETableElementStatus } from 'entities/common/types';

export const getStatusByPublicationDate = (
  date: string | null,
): ETableElementStatus => {
  const now = new Date();
  if (!date) {
    return ETableElementStatus.Draft;
  }
  if (isAfter(now, parseISO(date))) {
    return ETableElementStatus.Published;
  }

  return ETableElementStatus.Postponed;
};
