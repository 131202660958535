import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createPhotoGalleryCategory,
  deletePhotoGalleryCategory,
  editPhotoGalleryCategory,
} from 'entities/photoGalleryCategories/api';
import { photoGalleryCategoriesQueryKeys } from 'entities/photoGalleryCategories/consts';

export const useCreatePhotoGalleryCategoryMutation = () => {
  return useMutation({
    mutationFn: createPhotoGalleryCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        photoGalleryCategoriesQueryKeys.photoGalleryCategories,
      ]);
    },
  });
};

export const useDeletePhotoGalleryCategoryMutation = () => {
  return useMutation({
    mutationFn: deletePhotoGalleryCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        photoGalleryCategoriesQueryKeys.photoGalleryCategories,
      ]);
    },
  });
};

export const useEditPhotoGalleryCategoryMutation = () => {
  return useMutation({
    mutationFn: editPhotoGalleryCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        photoGalleryCategoriesQueryKeys.photoGalleryCategories,
      ]);
    },
  });
};
