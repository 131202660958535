import { setDefaultOptions } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { TAppLang } from 'shared/types/common';

export const setDateFnsLocale = (appLocale: TAppLang) => {
  const locale = locales[appLocale];

  setDefaultOptions({
    locale,
  });
};

const locales: Record<TAppLang, Locale> = {
  en: enUS,
  ru: ru,
};
