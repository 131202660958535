import { ManagerLayout } from 'feature/managerLayout/ui/ManagerLayout/ManagerLayout';
import { SocialNetworkModal } from 'feature/socialNetworkModal/ui/SocialNetworkModal/SocialNetworkModal';
import { useManagerSocialNetworksColumnsConfig } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/columnsConfig';
import { useCreateManagerSocialNetwork } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/hooks/useCreateManagerSocialNetwork';
import { useManagerSocialNetworks } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/hooks/useManagerSocialNetworks';
import { ManagerSocialNetworksActions } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/ManagerSocialNetworksActions/ManagerSocialNetworksActions';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './ManagerSocialNetworksPage.module.css';
import tableStyles from './ManagerSocialNetworksTable.module.css';

export const ManagerSocialNetworksPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    socialLinks,
    page,
    total,
    handleConfirmButtonClick,
  } = useManagerSocialNetworks();
  const { openCreateModal, createSocialNetworkModalProps } =
    useCreateManagerSocialNetwork();

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonList
        className={styles.wrapper}
        configCreator={useManagerSocialNetworksColumnsConfig}
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.socialLinks')}
        createButtonProps={{
          content: t('content.addLinkOtherEnding'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(socialLinks, page),
          totalItems: calculateTotalItems(socialLinks, total),
          onPageChange: changePage,
        }}
        tableProps={{
          wrapperClassName: tableStyles.root,
          data: socialLinks,
          headRowClassName: tableStyles.headRow,
          rowClassName: tableStyles.rowGridTemplate,
          ActionComponent: ManagerSocialNetworksActions,
        }}
      />
      {createSocialNetworkModalProps.isOpened && (
        <SocialNetworkModal {...createSocialNetworkModalProps} />
      )}
    </ManagerLayout>
  );
};
