import { useLocales } from 'entities/config/lib/useLocales';
import { Story } from 'pages/stories/edit/EditStory/components/Story/Story';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { MouseEventHandler, useEffect } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Dropzone } from 'shared/components/Dropzone/Dropzone';
import { DNDItemTypes } from 'shared/constants';
import { useSortableDND } from 'shared/hooks/useSortableDND';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

import styles from './StoryWrapper.module.css';

type TProps = {
  id: TEntityId;
  index: number;
  selected: boolean;
  onClick: MouseEventHandler;
  image: string;
  moveStory: (dragIndex: number, hoverIndex: number) => void;
};

export const StoryWrapper = ({
  id,
  index,
  moveStory,
  onClick,
  image,
  selected,
}: TProps) => {
  const { selectedStory, selectedLocale, elementsWithErrors } =
    useStoryEditorContext();
  const hasErrors =
    toBoolean(elementsWithErrors && elementsWithErrors?.length > 0) &&
    toString(id) !== toString(selectedStory?.id);
  const { defaultLocale } = useLocales();
  const canDrag = selectedLocale === defaultLocale && !hasErrors;
  const { handlerId, drop, ref, preview, drag, isDragging } = useSortableDND({
    index,
    move: moveStory,
    dragItemType: DNDItemTypes.STORY_GROUP_IMAGE,
    canDrag,
    item: {
      id,
      image,
      selected,
    },
  });
  const handleClick: MouseEventHandler = (event) => {
    if (hasErrors) {
      return;
    }
    onClick(event);
  };

  useEffect(() => {
    drag(drop(ref));
    preview(getEmptyImage());
  }, []);

  return (
    <>
      <Story
        disabled={!canDrag}
        handlerId={handlerId}
        hasErrors={hasErrors}
        image={image}
        index={index}
        isDragging={isDragging}
        isSelected={selected}
        moveStory={moveStory}
        ref={ref}
        onClick={handleClick}
      />
      {isDragging && (
        <Dropzone
          className={styles.dropZone}
          dragItemType={DNDItemTypes.STORY_GROUP_IMAGE}
        />
      )}
    </>
  );
};
