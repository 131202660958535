import i18n from 'i18next';
import { Alert } from 'shared/components/Alert/Alert';

import styles from './LayersPanel.module.css';

const getEmptyMessage = () => i18n.t('content.notCreated');

export const EmptyLayers = () => {
  return (
    <>
      <Alert
        className={styles.alert}
        message={getEmptyMessage()}
        textSize={12}
        variant="dark"
      />
    </>
  );
};
