import { fetchPlayerAchievements } from 'entities/playerAchievements/api';
import { playerAchievementsQueryKeys } from 'entities/playerAchievements/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchPlayerAchievementsParams = {
  filter?: Record<string, string>;
};

export const useFetchPlayerAchievementsQuery = ({
  filter,
}: TFetchPlayerAchievementsParams) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [playerAchievementsQueryKeys.playerAchievements, pagination, filter],
    queryFn: () => fetchPlayerAchievements({ params: { ...filter, ...pagination } }),
    cacheTime: 0,
  }));
};
