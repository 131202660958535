import { createContext, useContext } from 'react';

type TEyeDropContext = {
  isEyeDropActive: boolean;
  setIsEyeDropActive: (active: boolean) => void;
};

export const EyeDropContext = createContext<TEyeDropContext | undefined>(undefined);

export const useEyeDropContext = () => {
  const context = useContext(EyeDropContext);

  if (context === undefined) {
    throw new Error(
      'context is undefined. check it is being used within its provider',
    );
  }
  return context;
};
