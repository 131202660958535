import { useLocales } from 'entities/config/lib/useLocales';
import { StadiumsSelect } from 'entities/stadiums/StadiumSelect/StadiumsSelect';
import { TeamSelect } from 'entities/teamSelect/TeamSelect';
import { CountrySelect } from 'feature/countrySelect/CountrySelect';
import { LocalesInput } from 'feature/localesInput/LocalesInput';
import { PlayerPositionSelect } from 'feature/playerPositionSelect/PlayerPositionSelect';
import { AchievementModalExample } from 'pages/uikit/UIKit/examples/AchievementModalExample';
import { AlertExample } from 'pages/uikit/UIKit/examples/Alert';
import { BadgeExample } from 'pages/uikit/UIKit/examples/BadgeExample';
import { CardButtonExample } from 'pages/uikit/UIKit/examples/CardButtonExample';
import { CardsExample } from 'pages/uikit/UIKit/examples/CardsExample';
import { ColorInputExample } from 'pages/uikit/UIKit/examples/ColorInput';
import { LoaderExample } from 'pages/uikit/UIKit/examples/LoaderExample';
import { ManagerCareerModalExample } from 'pages/uikit/UIKit/examples/ManagerCareerModalExample';
import { ManagerPositionSelectExample } from 'pages/uikit/UIKit/examples/ManagerPositionSelectExample';
import { ModeSelectExample } from 'pages/uikit/UIKit/examples/ModeSelect';
import { NewSelectExample } from 'pages/uikit/UIKit/examples/NewSelectExample';
import { OpponentPlayerModalExample } from 'pages/uikit/UIKit/examples/OpponentPlayerModalExample';
import { PlayerCareerModalExample } from 'pages/uikit/UIKit/examples/PlayerCareerModalExample';
import { SocialNetworkModalExample } from 'pages/uikit/UIKit/examples/SocialNetworkModalExample';
import { TeamModalExample } from 'pages/uikit/UIKit/examples/TeamModalExample';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from 'shared/components/Divider';
import { Text } from 'shared/components/Text/Text';
import { EyeDropContext } from 'shared/contexts/eyedropContext';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';

import { ButtonExample } from './examples/ButtonExample';
import { DatePickerExample } from './examples/DatePickerExample';
import { IconButtonExample } from './examples/IconButtonExample';
import { SkeletonExample } from './examples/Skeleton';
import { SwitchExample } from './examples/Switch';
import styles from './UIKit.module.css';

export const UIKit = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const { control } = useForm({
    values: {
      countryId: '',
      teamId: '',
      stadiumId: '',
      playerPositionId: '',
      name: reduceTranslationObject(locales, () => ''),
      biography: reduceTranslationObject(locales, () => ''),
    },
  });

  const [isEyeDropActive, setIsEyeDropActive] = useState(false);
  return (
    <EyeDropContext.Provider value={{ isEyeDropActive, setIsEyeDropActive }}>
      <div className={styles.container}>
        <Text size={36} tag="h1">
          UI Kit Examples
        </Text>
        <ManagerPositionSelectExample />
        <Controller
          control={control}
          name={'name'}
          render={({ field }) => (
            <LocalesInput
              label={t('content.nameTitle')}
              maxLength={255}
              placeholder={t('content.notInputPreview')}
              required
              size="s"
              title={t('content.inputName')}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name={'biography'}
          render={({ field }) => (
            <LocalesInput
              label={t('content.bio')}
              maxLength={8000}
              placeholder={t('content.notInputPreview')}
              required
              size="s"
              title={t('content.inputBio')}
              type="textarea"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Divider />
        <CountrySelect control={control} fieldName="countryId" />
        <Divider />
        <TeamSelect control={control} fieldName={'teamId'} />
        <Divider />
        <PlayerPositionSelect control={control} fieldName="playerPositionId" />
        <Divider />
        <ColorInputExample />
        <Divider />
        <SwitchExample />
        <Divider />
        <SkeletonExample />
        <Divider />
        <ModeSelectExample />
        <Divider />
        <LoaderExample />
        <Divider />
        <AlertExample />
        <Divider />
        <BadgeExample />
        <Divider />
        <IconButtonExample />
        <Divider />
        <ButtonExample />
        <Divider />
        <DatePickerExample />
        <Divider />
        <NewSelectExample />
        <Divider />
        <PlayerCareerModalExample />
        <Divider />
        <SocialNetworkModalExample />
        <Divider />
        <TeamModalExample />
        <Divider />
        <CardButtonExample />
        <Divider />
        <CardsExample />
        <Divider />
        <AchievementModalExample />
        <Divider />
        <ManagerCareerModalExample />
        <Divider />
        <OpponentPlayerModalExample />
        <Divider />
        <StadiumsSelect control={control} fieldName="stadiumId" />
      </div>
    </EyeDropContext.Provider>
  );
};
