import { useEditPhotoGalleryMutation } from 'entities/photoGallery/mutations';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { routes } from 'shared/routes';
import { mapPhotoGalleryFormToDTO } from 'widgets/photoGalleryForm/mapper/mapPhotoGalleryFormToDTO';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

export const usePhotoGalleryFormPublish = () => {
  const navigate = useNavigate();
  const { mutateAsync } = useEditPhotoGalleryMutation();
  const { openedPublishModal, closePublishModal, openPublishModal } =
    useModal('Publish');
  const { openChooseDateModal, closeChooseDateModal, openedChooseDateModal } =
    useModal('ChooseDate');

  const { trigger, control, getValues, handleSubmit } =
    useFormContext<TPhotoGalleryFields>();
  const { field: publishedField } = useController({
    control,
    name: 'published',
  });

  const onClickPublishButton = useCallback(async () => {
    const isValid = await trigger();
    const haveImages = await trigger('totalImages');

    if (!haveImages) {
      notify('Загрузите хотя бы одно изображение в фотогалерею', { type: 'error' });
      return;
    }

    if (!isValid) {
      return;
    }

    openPublishModal();
  }, [trigger]);

  const editPhotoGallery = useCallback(async () => {
    const formFields = getValues();
    const dto = mapPhotoGalleryFormToDTO(formFields);

    await mutateAsync(
      { ...dto, id: formFields.id },
      {
        onSuccess: () => {
          navigate(routes.photoGallery);
        },
        onError: () => {
          notify('Не удалось отредактировать фотогалерею', { type: 'error' });
        },
      },
    );
  }, [getValues, mutateAsync, navigate]);

  const onClickConfirmPublishModal = useCallback(() => {
    if (!publishedField.value) {
      publishedField.onChange(new Date());
    }

    closePublishModal();
    handleSubmit(editPhotoGallery)();
  }, [publishedField, handleSubmit, editPhotoGallery]);

  const onChooseDate = useCallback(
    (date: Date) => {
      closeChooseDateModal();
      publishedField.onChange(date);
      handleSubmit(editPhotoGallery)();
    },
    [publishedField, handleSubmit, editPhotoGallery],
  );

  const onClickChooseDate = () => {
    openChooseDateModal();
    closePublishModal();
  };

  return {
    publishModalProps: {
      isOpened: openedPublishModal,
      onClickChooseDate,
      onClose: closePublishModal,
      onPublish: onClickConfirmPublishModal,
    },
    datePickerModalProps: {
      date: publishedField.value,
      isOpened: openedChooseDateModal,
      onClose: closeChooseDateModal,
      onDateChange: onChooseDate,
    },
    onClickPublishButton,
  };
};
