import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchManagerPositionsQuery } from 'entities/managerPositions/query';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';

import styles from './ManagerPositionSelect.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
};

export const ManagerPositionSelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
}: TProps<T>) => {
  const { t } = useTranslation();

  const {
    field: { value: selectedPosition },
  } = useController({
    control,
    name: fieldName,
  });

  const { defaultLocale } = useLocales();
  const { data: managerPositions } = useFetchManagerPositionsQuery();

  const managerPositionOptions = useMemo(
    () =>
      managerPositions?.map<TSelectOption>((position) => ({
        value: toString(position.id),
        label: position.translations[defaultLocale].jobTitle,
        size: 's',
      })) ?? [],
    [managerPositions, defaultLocale],
  );

  const managerPositionPlaceholder = useMemo(() => {
    if (selectedPosition && managerPositionOptions.length) {
      const selectedManagerPosition = managerPositionOptions.find(
        (position) => position.value === selectedPosition,
      );

      if (selectedManagerPosition) {
        return selectedManagerPosition.label;
      }
    }

    return t('content.choosePosition');
  }, [selectedPosition, managerPositionOptions]);

  return (
    <FormSelect
      className={className}
      control={control}
      fieldName={fieldName}
      label={t('content.position')}
      multiple={false}
      options={managerPositionOptions}
      optionsContainerClassName={styles.optionsContainer}
      placeholder={managerPositionPlaceholder}
      required
      search={true}
    />
  );
};
