import { findLastIndex } from 'lodash';
import { ActionItem } from 'shared/components/Actions/ActionItem';
import {
  ActionsWrapper,
  TActionsWrapperProps,
} from 'shared/components/Actions/ActionsWrapper';
import { TActionByStatus, TEntityId } from 'shared/types/common';

export type TActionsProps = Omit<TActionsWrapperProps, 'children'> & {
  actions: TActionByStatus[];
  itemId: TEntityId;
};

export const Actions = ({
  isOpen,
  isLoading,
  onIconClick,
  actions,
  itemId,
}: TActionsProps) => {
  return (
    <ActionsWrapper isLoading={isLoading} isOpen={isOpen} onIconClick={onIconClick}>
      {actions.map(({ title, icon, action }, index) => (
        <ActionItem
          key={title}
          isLast={index === findLastIndex(actions)}
          title={title}
          buttonProps={{
            icon,
            onClick: () => action(itemId),
          }}
        />
      ))}
    </ActionsWrapper>
  );
};
