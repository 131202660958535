import { useLocales } from 'entities/config/lib/useLocales';
import { useCallback, useState } from 'react';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TEntityId, TTranslations, TTranslationsObject } from 'shared/types/common';

type TBaseCategory = { id: TEntityId } & TTranslationsObject<{
  name: string;
}>;

type TUseCategoriesPageTable = {
  category: TBaseCategory;
  deleteCategory: (id: TEntityId) => void;
  editCategory: (params: TBaseCategory) => void;
};

export const useCategoriesPageTable = ({
  category,
  deleteCategory,
  editCategory,
}: TUseCategoriesPageTable) => {
  const { locales } = useLocales();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [activeItemId, setActiveItemId] = useState<TEntityId | null>(null);
  const [currentCategory, setCurrentCategory] = useState<TBaseCategory | null>(null);

  const deleteCategoryHandler = useCallback((id: TEntityId | null) => {
    setActiveItemId(id);
    setShowConfirmModal(true);
  }, []);

  const openEditModalHandler = useCallback(
    (id: TEntityId | null) => {
      setActiveItemId(id);
      setCurrentCategory(category || null);
      setShowEditModal(true);
    },
    [category],
  );

  const closeEditModalHandler = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const closeConfirmModalHandler = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const onConfirm = useCallback(() => {
    if (activeItemId) {
      deleteCategory(activeItemId);
      setShowConfirmModal(false);
    }
  }, [deleteCategory, activeItemId]);

  const onEdit = useCallback(
    (category: TTranslations<string>) => {
      if (activeItemId) {
        editCategory({
          id: activeItemId,
          translations: reduceTranslationObject(locales, (locale) => ({
            name: category[locale],
          })),
        });
        setShowEditModal(false);
      }
    },
    [editCategory, activeItemId],
  );

  return {
    showConfirmModal,
    setShowConfirmModal,
    showEditModal,
    setShowEditModal,
    deleteCategoryHandler,
    openEditModalHandler,
    closeEditModalHandler,
    closeConfirmModalHandler,
    onConfirm,
    onEdit,
    currentCategory,
  };
};
