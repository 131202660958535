import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';
import { useModal } from 'shared/hooks/useModal';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

type TProps = {
  isBlock: boolean;
  cancelUploading: () => void;
};

export const useHandleRouterChange = ({ isBlock, cancelUploading }: TProps) => {
  const { openedConfirmLeaveModal, closeConfirmLeaveModal, openConfirmLeaveModal } =
    useModal('ConfirmLeave');
  const {
    formState: { isDirty, isSubmitted, isSubmitting },
  } = useFormContext<TPhotoGalleryFields>();

  const isSendingForm = isSubmitted || isSubmitting;

  const isBlocked = isSendingForm ? false : isBlock || isDirty;
  const blocker = useBlocker(isBlocked);

  const confirmLeave = () => {
    if (blocker.proceed) {
      cancelUploading();
      blocker.proceed();
    }
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openConfirmLeaveModal();
    }
  }, [blocker]);

  return {
    confirmLeave,
    openedConfirmLeaveModal,
    closeConfirmLeaveModal,
  };
};
