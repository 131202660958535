import { TCropper } from 'shared/components/cropper/types';

export const cropperActions = (cropper: TCropper, defaultAspectRatio: number) => {
  return {
    zoomIn: () => {
      cropper.zoom(0.1);
    },
    zoomOut: () => {
      cropper.zoom(-0.1);
    },
    rotateRight: () => {
      cropper.rotate(90);
    },
    rotateLeft: () => {
      cropper.rotate(-90);
    },
    reset: () => {
      cropper.reset();
      cropper.setAspectRatio(defaultAspectRatio);
    },
    deleteCropping: () => {
      cropper.clear();
    },
    enableCropping: () => {
      cropper.crop();
    },
    setAspectRatio: (ratio: number) => {
      cropper.setAspectRatio(ratio);
    },
  };
};
