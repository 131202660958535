import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchOpponentTeamQuery } from 'entities/opponentTeams/queries';
import { OpponentTeamPlayers } from 'pages/opponentTeam/teamPage/OpponentTeamPlayers';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { Centered } from 'shared/components/Centered/Centered';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Loader } from 'shared/components/Loader/Loader';
import { Text } from 'shared/components/Text/Text';
import { routes } from 'shared/routes';

import styles from './OpponentTeamPage.module.css';
export const OpponentTeamPage = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const navigate = useNavigate();
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const {
    data: team,
    isLoading,
    error,
  } = useFetchOpponentTeamQuery({
    id: teamId ?? '',
  });
  if (error) {
    throw error;
  }
  const teamName = team?.translations[defaultLocale].name ?? '';
  return (
    <div className={styles.root}>
      {isLoading ? (
        <Centered>
          <Loader showLabel />
        </Centered>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <IconButton
                className={styles.backButton}
                icon="chevron-left"
                onClick={() => navigate(routes.opponentTeams)}
              />
              <div className={styles.imageWrapper}>
                {team?.picture?.x1 ? (
                  <img
                    alt={`team ${teamName}`}
                    className={styles.image}
                    loading="lazy"
                    src={team?.picture?.x1}
                  />
                ) : (
                  <Icon className={styles.image} kind="image-03" />
                )}
              </div>
              <Text
                className={styles.title}
                color="var(--dark-blue-400)"
                size={24}
                tag="h1"
                weight="Bold"
              >
                {teamName}
              </Text>
            </div>
            <Button
              className={styles.button}
              onClick={() => navigate(routes.opponentTeams)}
            >
              {t('shared.confirm')}
            </Button>
          </div>
          <OpponentTeamPlayers />
        </>
      )}
    </div>
  );
};
