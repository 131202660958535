import { TOpponentPlayerModalFields } from 'feature/opponentPlayerModal/types';
import { TFunction } from 'i18next';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { TODAY } from 'shared/constants';

import styles from './OpponnentPlayerModal.module.css';
type TProps = {
  control: Control<TOpponentPlayerModalFields>;
};
const FROM_DATE = new Date(1970, 0);

const discardedStatusLabels = (t: TFunction): Record<string, string> => ({
  false: t('content.inTeam'),
  true: t('content.leaveFromTeam'),
});

export const OpponentPlayerModalDiscarded = ({ control }: TProps) => {
  const { t } = useTranslation();
  const { field } = useController({
    control,
    name: 'discarded',
  });

  const discardedLabels = discardedStatusLabels(t);

  return (
    <>
      <Field label={t('content.fromTeam')}>
        <div className={styles.status}>
          {Object.keys(discardedLabels).map((discardedStatus) => (
            <RadioButton
              key={discardedStatus}
              checked={field.value === discardedStatus}
              label={discardedLabels[discardedStatus]}
              name={field.name}
              size="s"
              value={discardedStatus}
              onChange={() => {
                field.onChange(discardedStatus);
              }}
            />
          ))}
        </div>
      </Field>
      <InputDatePicker
        control={control}
        disabled={field.value === 'false'}
        fieldName="discardedAt"
        fromDate={FROM_DATE}
        label={t('content.player.quitTeam')}
        placeholder={t('content.choseDate')}
        required
        toDate={TODAY}
      />
    </>
  );
};
