import { opponentTeamsApiKeys } from 'entities/opponentTeams/consts';
import {
  TCreateOpponentTeamDTO,
  TEditOpponentTeamDTO,
  TGetOpponentTeam,
  TOpponentTeam,
} from 'entities/opponentTeams/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchOpponentTeam = ({ id }: TGetOpponentTeam) =>
  get<TOpponentTeam>(opponentTeamsApiKeys.getOpponentTeam(id)).then(
    (response) => response.data.data,
  );

export const fetchOpponentTeams = ({ params }: TFetchParams) =>
  get<TOpponentTeam[], TPaginatedMeta>(opponentTeamsApiKeys.getTeams, {
    params,
  }).then((response) => response.data);

export const createOpponentTeam = (teamDTO: TCreateOpponentTeamDTO) =>
  post(opponentTeamsApiKeys.createTeam, teamDTO);

export const editOpponentTeam = ({ teamId, ...teamDTO }: TEditOpponentTeamDTO) =>
  patch(opponentTeamsApiKeys.editTeam(teamId), teamDTO);

export const deleteOpponentTeam = (teamId: TEntityId) =>
  del(opponentTeamsApiKeys.deleteTeam(teamId));
