import { ETeamTabType, TTeamTab } from 'pages/team/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useTeamPageTabs = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<ETeamTabType>(ETeamTabType.Player);

  const tabs: TTeamTab[] = [
    {
      text: t('content.teams.mainCast'),
      onClick: () => setSelectedTab(ETeamTabType.Player),
      tabName: ETeamTabType.Player,
    },
    {
      text: t('content.teams.coachingStaff'),
      onClick: () => setSelectedTab(ETeamTabType.Manager),
      tabName: ETeamTabType.Manager,
    },
  ];

  return {
    tabs,
    selectedTab,
  };
};
