import { useState } from 'react';
import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { TVideoPostFields } from 'widgets/videoForm/types';

type TUseVideoFormUnpublishButtonArgs = Partial<UseFormReturn<TVideoPostFields>> & {
  editVideoPost: (data: TVideoPostFields) => Promise<void>;
};

export const useVideoFormUnpublishButton = ({
  editVideoPost,
  control,
  handleSubmit,
}: TUseVideoFormUnpublishButtonArgs) => {
  const [isOpenConfirmUnpublishModal, setIsOpenConfirmUnpublishModal] =
    useState(false);
  const { field } = useController({
    control,
    name: 'publishDate',
  });

  const onClickUnpublishBtn = () => {
    setIsOpenConfirmUnpublishModal(true);
  };

  const onCloseUnpublish = () => {
    setIsOpenConfirmUnpublishModal(false);
  };

  const onConfirmUnpublish = () => {
    setIsOpenConfirmUnpublishModal(false);
    field.onChange(null);
    handleSubmit?.(editVideoPost)();
  };

  return {
    isOpenConfirmUnpublishModal,
    onClickUnpublishBtn,
    onCloseUnpublish,
    onConfirmUnpublish,
  };
};
