import i18n from 'i18next';
import z from 'zod';

export const addSocialNetworkValidationSchema = () =>
  z.object({
    url: z
      .string()
      .url({ message: i18n.t('content.notAvailableLink') })
      .startsWith('https://', { message: i18n.t('shared.Workspace.urlSafeWarning') })
      .max(4096),
    socialNetworkId: z.string().min(1, i18n.t('content.player.chooseNetwork')),
  });
