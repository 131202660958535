import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import { getRenderActions } from 'feature/workspacePanel/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { usePhotoGalleryFormBackButton } from 'widgets/photoGalleryForm/hooks/usePhotoGalleryFormBackButton';
import { usePhotoGalleryFormLocalesControl } from 'widgets/photoGalleryForm/hooks/usePhotoGalleryFormLocalesControl';
import { usePhotoGalleryFormPublish } from 'widgets/photoGalleryForm/hooks/usePhotoGalleryFormPublish';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';
import { PhotoGalleryFormPublishModal } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormPublishModal/PhotoGalleryFormPublishModal';

export const PhotoGalleryFormHeader = () => {
  const { t } = useTranslation();
  const { formState } = useFormContext<TPhotoGalleryFields>();
  const localesControlProps = usePhotoGalleryFormLocalesControl();
  const { onClickBackButton } = usePhotoGalleryFormBackButton();
  const { onClickPublishButton, publishModalProps, datePickerModalProps } =
    usePhotoGalleryFormPublish();

  const renderActions = getRenderActions({
    publishButtonProps: {
      onClick: onClickPublishButton,
      isValid: formState.isValid,
      isLoading: formState.isSubmitting,
      children: t('shared.confirm'),
    },
  });

  return (
    <>
      <WorkspacePanel
        backButtonProps={{ onClick: onClickBackButton }}
        localesControlProps={localesControlProps}
        renderActions={renderActions}
      />
      <PhotoGalleryFormPublishModal {...publishModalProps} />
      <DatePicker {...datePickerModalProps} />
    </>
  );
};
