import cn from 'classnames';

import styles from './Brick.module.css';

type TProps = {
  width?: string | number;
  height?: string | number;
  round?: boolean;
};

export const Brick = ({ width, height, round }: TProps) => {
  return (
    <div
      className={cn(styles.brick, { [styles.round]: round })}
      style={{ width, height }}
    />
  );
};
