import { w3 } from 'shared/constants';

export const linkInWhitelist = (link: string, whitelist: string[]) => {
  try {
    const url = new URL(link);
    const hostname = url.hostname.replace(w3, '');

    return whitelist.includes(hostname);
  } catch (error) {
    return false;
  }
};
