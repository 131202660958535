import { useDeletePlayerSocialNetworkMutation } from 'entities/playerSocialNetworks/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerSocialNetwork = () => {
  const { t } = useTranslation();
  const { mutate: deletePlayerSocialNetworkMutation, isLoading } =
    useDeletePlayerSocialNetworkMutation();

  const deletePlayerSocialNetwork = (playerSocialNetworkId: TEntityId) => {
    deletePlayerSocialNetworkMutation(playerSocialNetworkId, {
      onError: () =>
        notify(t('content.player.notifyNotDeleteSocial'), { type: 'error' }),
    });
  };

  return {
    isDeletingPlayerSocialNetwork: isLoading,
    deletePlayerSocialNetwork,
  };
};
