import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createPlayerCareer,
  deletePlayerCareer,
  editPlayerCareer,
  fetchPlayerCareers,
} from 'entities/playerCareers/api';
import { playerCareersQueryKeys } from 'entities/playerCareers/consts';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchPlayerCareersParams = {
  filter?: Record<string, string>;
};

export const useFetchPlayerCareersQuery = ({
  filter,
}: TFetchPlayerCareersParams) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [playerCareersQueryKeys.playerCareers, pagination, filter],
    queryFn: () => fetchPlayerCareers({ params: { ...pagination, ...filter } }),
    cacheTime: 0,
  }));
};

export const useDeletePlayerCareerMutation = () => {
  return useMutation({
    mutationFn: deletePlayerCareer,
    onSuccess: () => {
      return queryClient.invalidateQueries([playerCareersQueryKeys.playerCareers]);
    },
  });
};

export const useCreatePlayerCareerMutation = () => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: createPlayerCareer,
    onSuccess: () => {
      return queryClient.invalidateQueries([playerCareersQueryKeys.playerCareers]);
    },
    onError: () => {
      notify(t('content.validation.notifyCreateCareer'), { type: 'error' });
    },
  });
};

export const useEditPlayerCareerMutation = () => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: editPlayerCareer,
    onSuccess: () => {
      return queryClient.invalidateQueries([playerCareersQueryKeys.playerCareers]);
    },
    onError: () => {
      notify(t('content.validation.notifyEditCareer'), { type: 'error' });
    },
  });
};
