import { useController, useFormContext } from 'react-hook-form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

export const useIsPublishNewsPost = () => {
  const { control } = useFormContext<TEditNewsPostFields>();

  const { field } = useController({
    control,
    name: 'publishDate',
  });

  if (!field.value) {
    return false;
  }

  return field.value.valueOf() < Date.now();
};
