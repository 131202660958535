import { indexToLetterMap } from 'pages/stories/edit/EditStory/components/elements/constants';
import commonElementFormStyles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import {
  TQuizAnswersItem,
  TQuizControl,
} from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { TInputSwitchField } from 'shared/components/Form/types';

type TProps = {
  index: number;
  field: TQuizAnswersItem;
  control: TQuizControl;
  switchField?: TInputSwitchField;
};

export const AnswerInput = memo(({ field, index, control, switchField }: TProps) => {
  const { t } = useTranslation();
  return (
    <FormInput
      control={control}
      disabled={field.disabled}
      fieldName={`answers.${index}.title`}
      inputClassName={commonElementFormStyles.input}
      label={`${t('content.story.option')} - ${indexToLetterMap[index]}`}
      maxLength={20}
      placeholder={t('content.story.inputAnswer')}
      switchField={switchField}
    />
  );
});
