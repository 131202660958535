import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';

export type TUseRedirectByNoPermissions = {
  needRedirect: boolean;
  route?: string;
};

/**
 * Хук для перенаправления пользователя при отсутствии разрешений.
 * По умолчанию редиректит на routes.root
 *
 * @param {Object} TUseRedirectByNoPermissions - Параметры перенаправления.
 * @param {boolean} TUseRedirectByNoPermissions.needRedirect - Флаг, указывающий, нужно ли перенаправление.
 * @param {string} [TUseRedirectByNoPermissions.route] - Маршрут, на который следует перенаправить. По умолчанию - routes.root.
 */
export const useRedirectByNoPermissions = ({
  needRedirect,
  route = routes.root,
}: TUseRedirectByNoPermissions) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (needRedirect) {
      navigate(route, { replace: true });
    }
  }, [needRedirect]);
};
