import { getTextMinLengthMessage, TEXT_MIN_LENGTH } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { getMaxLengthTextGenerate } from 'shared/utils/getMaxLengthTextGenerate';
import z from 'zod';

const MAX_PLAYER_FIRST_NAME_LENGTH = 255;

export const teamModalValidationSchema = (locales: TContentLang[]) =>
  z
    .object({
      name: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
            .max(
              MAX_PLAYER_FIRST_NAME_LENGTH,
              getMaxLengthTextGenerate(MAX_PLAYER_FIRST_NAME_LENGTH),
            ),
        ),
      }),
      clubName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
            .max(
              MAX_PLAYER_FIRST_NAME_LENGTH,
              getMaxLengthTextGenerate(MAX_PLAYER_FIRST_NAME_LENGTH),
            ),
        ),
      }),
      defaultTeam: z.boolean(),
      countryId: z.string().min(TEXT_MIN_LENGTH, getTextMinLengthMessage()),
    })
    .passthrough();
