import { JSONAPIDocument } from 'json-api-serializer';
import { JSONApiSerializer } from 'shared/api/jsonApi/JSONApiSerializer';
import { registerJsonApiDocument } from 'shared/api/jsonApi/registerJsonApiDocument';

export const deserializeApiData = (document: JSONAPIDocument) => {
  if (!document.data) {
    return {};
  }

  if (Array.isArray(document.data)) {
    let type;
    document.data.forEach((doc) => {
      type = doc.type;
      registerJsonApiDocument(doc);
    });

    return type ? JSONApiSerializer.deserialize(type, document) : [];
  } else {
    registerJsonApiDocument(document.data);

    return JSONApiSerializer.deserialize(document.data.type, document);
  }
};
