import { EditRoute } from 'pages/video/edit';
import { CreateRoute } from 'pages/video/new';
import { VideoPage } from 'pages/video/VideoPage/VideoPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const VideoRoutes: Record<string, TProtectedRoute> = {
  List: {
    path: routes.video,
    element: <VideoPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Add: CreateRoute,
  Edit: EditRoute,
};
