import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { setApiBaseURL } from 'shared/api/config';
import { DEFAULT_SANDBOX } from 'shared/api/constants';
import { TAPIName } from 'shared/api/types';
import { isProduction, LocalStorageKeys } from 'shared/constants';

export const useApiSelection = () => {
  const [selectedApi, setSelectedApi] = useLocalStorage<TAPIName>(
    LocalStorageKeys.backendSandbox,
    DEFAULT_SANDBOX,
    { raw: true },
  );

  useEffect(() => {
    // После разавторизации необходимо менять песок
    if (selectedApi && !isProduction) {
      setApiBaseURL(selectedApi);
    }
  }, [selectedApi]);

  return {
    selectedApi: selectedApi ?? DEFAULT_SANDBOX,
    onChangeApi: (api: string) => {
      setSelectedApi(api as TAPIName);
    },
  };
};
