import { useLocales } from 'entities/config/lib/useLocales';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TContentLang } from 'shared/types/common';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

export const usePhotoGalleryFormLocalesControl = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { setValue, trigger, getValues } = useFormContext<TPhotoGalleryFields>();

  const {
    field: { value: selectedLocale, onChange: changeSelectedLocale },
  } = useController({ name: 'locale.selected' });

  const {
    field: { value: createdLocales, onChange: changeCreatedLocales },
  } = useController({ name: 'locale.created' });

  const isValidContent = useCallback(
    async (locale: TContentLang) => {
      return trigger([`translations.${locale as TContentLang}.title`]);
    },
    [selectedLocale, trigger],
  );

  const onLocaleAdd = async (locale: TContentLang) => {
    const isValid = await isValidContent(selectedLocale);

    if (!isValid) {
      return;
    }

    const { translations } = getValues();
    const newTranslations = translations![defaultLocale]!;

    changeCreatedLocales([...createdLocales, locale]);
    changeSelectedLocale(locale);
    setValue(`translations.${locale}.title`, newTranslations.title);
    notify(t('content.notifyTranslation'), { type: 'warning' });
  };

  const onLocaleSelect = useCallback(
    async (locale: TContentLang) => {
      const isValid = await isValidContent(selectedLocale);
      if (isValid) {
        changeSelectedLocale(locale);
      }
    },
    [changeSelectedLocale, isValidContent],
  );

  const onLocaleDelete = useCallback(
    (locale: TContentLang) => {
      changeSelectedLocale(defaultLocale);
      changeCreatedLocales(
        createdLocales.filter(
          (createdLocale: TContentLang) => createdLocale !== locale,
        ),
      );
    },
    [changeCreatedLocales, changeSelectedLocale, createdLocales, defaultLocale],
  );

  return {
    selectedLocale,
    createdLocales,
    onLocaleSelect,
    onLocaleAdd,
    onLocaleDelete,
  };
};
