import { TEntityId } from 'shared/types/common';

export const playerSocialNetworksQueryKeys = {
  playerSocialNetworks: 'playerSocialNetworks',
};

const playerSocialNetworksBaseUrl = 'player/social_networks';

export const playerSocialNetworksApiKeys = {
  getPlayerSocialNetworks: playerSocialNetworksBaseUrl,
  createPlayerSocialNetwork: playerSocialNetworksBaseUrl,
  deletePlayerSocialNetwork: (playerId: TEntityId) =>
    `${playerSocialNetworksBaseUrl}/${playerId}`,
  editPlayerSocialNetwork: (playerSocialNetworkId: TEntityId) =>
    `${playerSocialNetworksBaseUrl}/${playerSocialNetworkId}`,
};
