const BYTES_IN_KB = 1024;
const BYTES_IN_MB = BYTES_IN_KB * 1024;
const BYTES_IN_GB = BYTES_IN_MB * 1024;

export function formatSizeUnits(bytes: number) {
  let result = '';

  if (bytes < 0) {
    result = '-' + formatSizeUnits(-bytes);
  } else if (bytes >= BYTES_IN_GB) {
    result = (bytes / BYTES_IN_GB).toFixed(2) + ' GB';
  } else if (bytes >= BYTES_IN_MB) {
    result = (bytes / BYTES_IN_MB).toFixed(2) + ' MB';
  } else if (bytes >= BYTES_IN_KB) {
    result = (bytes / BYTES_IN_KB).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    result = bytes + ' bytes';
  } else if (bytes === 1) {
    result = bytes + ' byte';
  } else {
    result = '0 bytes';
  }

  return result;
}
