import cn from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { BaseButton, TBaseButtonProps } from 'shared/components/BaseButton';
import { Icon } from 'shared/components/Icon/Icon';
import { TIconSize, TIconType } from 'shared/components/Icon/types';
import { Text } from 'shared/components/Text/Text';

import styles from './Button.module.css';

type TButtonSize = 's' | 'm' | 'l';
type TButtonKind =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'light'
  | 'ghost'
  | 'basic';
type TButtonColor = 'gray' | 'green' | 'blue';

type TIconPosition = 'before' | 'after';

export type TButtonProps = {
  size?: TButtonSize;
  kind?: TButtonKind;
  color?: TButtonColor;
  iconPosition?: TIconPosition;
  iconSize?: TIconSize;
  icon?: TIconType;
  className?: string;
  children?: ReactNode;
} & Omit<TBaseButtonProps, 'children'>;

const buttonTextBySize = {
  s: 12,
  m: 14,
  l: 16,
} as const;

export const Button = forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      size = 'm',
      kind = 'primary',
      color = 'green',
      iconPosition = 'after',
      iconSize = 's',
      icon,
      children,
      className,
      ...restProps
    },
    ref,
  ) => {
    return (
      <BaseButton
        ref={ref}
        className={cn(
          styles[size],
          styles[kind],
          styles[color],
          { [styles.withIcon]: !!icon },
          className,
        )}
        {...restProps}
      >
        {icon && iconPosition === 'before' && (
          <Icon className={styles.icon} kind={icon} size={iconSize} />
        )}
        {children && typeof children === 'string' ? (
          <Text size={buttonTextBySize[size]}>{children}</Text>
        ) : (
          children
        )}
        {icon && iconPosition === 'after' && <Icon kind={icon} size={iconSize} />}
      </BaseButton>
    );
  },
);

Button.displayName = 'Button';
