import { useUpdateStoryElementMutation } from 'entities/stories/mutations';
import { TStoryElementData } from 'entities/stories/types';
import { set } from 'lodash';
import round from 'lodash/round';
import { TStoryElementUpdater } from 'pages/stories/edit/EditStory/types';
import { useStoryElements } from 'pages/stories/hooks/useStoryElements';
import { useCallback, useRef } from 'react';
import { TContentLang } from 'shared/types/common';
import { isRectsIntersect } from 'shared/utils/common';
import { calcPercent } from 'shared/utils/numbers';

const ROUND_PRECISION = 2;
export const useStoryElementsRND = (selectedLocale: TContentLang) => {
  const storyOutlineRef = useRef<HTMLDivElement>(null);
  const storyElementsToShow = useStoryElements();

  const { mutate: updateMutation } = useUpdateStoryElementMutation();

  const updateStoryElement = useCallback<TStoryElementUpdater>(
    (storyElement: TStoryElementData, box: HTMLElement, { x, y }) => {
      let elementPosition;
      if (storyOutlineRef.current) {
        const boxRectangle = box.getBoundingClientRect();
        const storyOutlineRectangle =
          storyOutlineRef.current.getBoundingClientRect();
        if (isRectsIntersect(boxRectangle, storyOutlineRectangle)) {
          const topOffsetPercent = calcPercent(
            boxRectangle.top - storyOutlineRectangle.top,
            storyOutlineRectangle.height,
          );
          const rightOffsetPercent = calcPercent(
            storyOutlineRectangle.right - boxRectangle.right,
            storyOutlineRectangle.width,
          );
          const bottomOffsetPercent = calcPercent(
            storyOutlineRectangle.bottom - boxRectangle.bottom,
            storyOutlineRectangle.height,
          );
          const leftOffsetPercent = calcPercent(
            boxRectangle.left - storyOutlineRectangle.left,
            storyOutlineRectangle.width,
          );
          elementPosition = {
            top: round(topOffsetPercent, ROUND_PRECISION),
            right: round(rightOffsetPercent, ROUND_PRECISION),
            bottom: round(bottomOffsetPercent, ROUND_PRECISION),
            left: round(leftOffsetPercent, ROUND_PRECISION),
          };
        }

        const elementObj = {
          ...storyElement,
          translations: {
            ...storyElement.translations,
            [selectedLocale]: {
              ...storyElement.translations[selectedLocale],
              editorPosition: { x, y },
              width: box.offsetWidth,
              height: box.offsetHeight,
              elementPosition,
            },
          },
        };

        if (storyElement.kind === 'text') {
          set(
            elementObj,
            `translations.${selectedLocale}.settings.width`,
            box.offsetWidth,
          );
        }
        updateMutation(elementObj);
      }
    },
    [selectedLocale],
  );

  return {
    storyElementsToShow,
    updateStoryElement,
    storyOutlineRef,
  };
};
