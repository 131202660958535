import cn from 'classnames';
import { FormEvent } from 'react';
import { ModeSelectField } from 'shared/components/Form/ModeSelect';
import { SimpleSelect } from 'shared/components/Form/SimpleSelect/SimpleSelect';
import { SwitchField } from 'shared/components/Form/Switch';
import { TWithChildren } from 'shared/types/common';

import { Checkbox } from './Checkbox/Checkbox';
import { Field } from './Field/Field';
import formCommonStyles from './FormCommonStyles.module.css';
import { HiddenInput } from './HiddenInput';
import { FormInput } from './Input/FormInput';

export {
  Checkbox,
  Field,
  FormInput,
  HiddenInput,
  ModeSelectField,
  SimpleSelect,
  SwitchField,
};

type TProps = {
  className?: string;
  action(event: FormEvent<HTMLFormElement>): void;
} & TWithChildren;

export const Form = ({ className, children, action }: TProps) => {
  return (
    <form className={cn(formCommonStyles.form, className)} onSubmit={action}>
      {children}
    </form>
  );
};
