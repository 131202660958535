import { useFetchVideosQuery } from 'entities/video/queries';
import { useVideoColumnsConfig } from 'pages/video/VideoPage/columnsConfig';
import { TVideoFilter, TVideoTableSortParams } from 'pages/video/VideoPage/types';
import {
  calculateSelectedPage,
  calculateTotalItems,
  createFilterQuery,
  createSortQuery,
} from 'pages/video/VideoPage/utils';
import { VideoPostActions } from 'pages/video/VideoPage/videoActions/ui/VideoPostActions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useCommonListTableSort } from 'widgets/elementsList/hooks/useCommonListTableSort';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './VideoPage.module.css';
import tableStyles from './VideoTable.module.css';

const DEFAULT_VIDEO_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'created_at',
} as const;

export const VideoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sortOptions, handleSort } = useCommonListTableSort<TVideoTableSortParams>(
    DEFAULT_VIDEO_SORT_OPTIONS,
  );
  const [filter, setFilter] = useState<TVideoFilter | null>(null);

  const {
    data: { data: videoPosts = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchVideosQuery({
    sort: createSortQuery(sortOptions),
    filter: createFilterQuery(filter),
  });

  const handleFilter = (filter: TVideoFilter) => {
    setFilter(filter);
    changePage(1);
  };

  return (
    <div className={styles.root}>
      <CommonList
        configCreator={useVideoColumnsConfig}
        emptyComponentDescription={t('content.video.createFirstVideo')}
        error={error}
        isEmpty={!filter && (!videoPosts || (total === 0 && !isLoading))}
        isLoading={isLoading}
        title={t('content.video')}
        createButtonProps={{
          content: t('shared.create'),
          onClick: () => navigate(routes.createVideo),
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(videoPosts, page),
          totalItems: calculateTotalItems(videoPosts, total),
          onPageChange: changePage,
        }}
        tableProps={{
          data: videoPosts,
          filter,
          rowClassName: tableStyles.rowGridTemplate,
          sortOptions,
          onFilter: handleFilter,
          onSort: handleSort,
          ActionComponent: VideoPostActions,
          actionsCellClassName: tableStyles.actionsColumn,
        }}
      />
    </div>
  );
};
