import { parseISO } from 'date-fns';
import {
  ParagraphTypeEnum,
  TImageParagraph,
  TMainImageParagraph,
  TNewsPost,
  TParagraph,
  TSliderParagraph,
  TTextParagraph,
  TTitleParagraph,
} from 'entities/news/types';
import { TCreateVideoParagraphParams } from 'entities/news/types';
import { TEditorVideoElement } from 'shared/components/Editor/types';
import {
  EditorElementTypeEnum,
  TEditorImage,
  TEditorImageElement,
  TEditorParagraphElement,
  TEditorSliderElement,
} from 'shared/components/Editor/types';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { getFilenameFromUrl } from 'shared/lib/getFilenameFromUrl';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TContentLang, TImageContent, TTranslations } from 'shared/types/common';
import { mapImageToEdit } from 'shared/utils/image';

export const mapNewsPostToEdit = async (
  data: TNewsPost,
  defaultLocale: TContentLang,
): Promise<TEditNewsPostFields> => {
  const titleParagraph = data.paragraphs.find(
    (p) => p.type === ParagraphTypeEnum.Title,
  ) as TTitleParagraph;

  const contentPreviewParagraph = data.paragraphs.find(
    (p) => p.type === ParagraphTypeEnum.MainImage,
  ) as TMainImageParagraph;

  const locale = {
    created: data.availableLocales,
    selected: defaultLocale,
  };

  return {
    id: data.id,
    content: {
      preview: {
        id: contentPreviewParagraph.id,
        translations: await mapPreviewContent(contentPreviewParagraph.translations),
      },
      title: {
        id: titleParagraph.id,
        translations: titleParagraph.translations,
      },
      body: await mapBodyContent(data.paragraphs, locale),
    },
    locale,
    preview: await mapImageToEdit(data.pictures.base.x3),
    labels: data.labels.map((l) => toString(l.id)),
    categories: data.categories.map((c) => toString(c.id)),
    tournament: data.tournaments[0] ? toString(data.tournaments[0].id) : undefined,
    season: data.seasons[0] ? toString(data.seasons[0].id) : undefined,
    match: data.matches[0] ? toString(data.matches[0].id) : undefined,
    players: data.players.map((p) => toString(p.id)),
    managers: data.managers.map((m) => toString(m.id)),
    teams: data.teams?.map((m) => toString(m.id)),
    important: toBoolean(data.importance),
    slider: data.pictures.slider?.x3
      ? await mapImageToEdit(data.pictures.slider?.x3)
      : {
          file: undefined,
          data: '',
        },
    publishDate: data.published ? parseISO(data.published) : undefined,
  };
};

const mapBodyContent = async (
  paragraphs: TParagraph[],
  locale: {
    created: TContentLang[];
    selected: TContentLang;
  },
) => {
  const bodyParagraphs = paragraphs.filter(
    (p) =>
      p.type === ParagraphTypeEnum.Text ||
      p.type === ParagraphTypeEnum.Image ||
      p.type === ParagraphTypeEnum.Slider ||
      p.type === ParagraphTypeEnum.Video,
  ) as (
    | TTextParagraph
    | TImageParagraph
    | TSliderParagraph
    | TCreateVideoParagraphParams
  )[];

  const promiseBodyParagraphs = bodyParagraphs.map(async (p) => {
    if (p.type === ParagraphTypeEnum.Slider) {
      return mapSliderParagraph(p, locale.created, locale.selected);
    }

    if (p.type === ParagraphTypeEnum.Image) {
      return mapImageParagraph(p, locale.created, locale.selected);
    }

    if (p.type === ParagraphTypeEnum.Video) {
      return mapVideoParagraph(p, locale.created, locale.selected);
    }

    return mapTextParagraph(p, locale.created, locale.selected);
  });

  return Promise.all(promiseBodyParagraphs);
};

const mapSliderParagraph = async (
  p: TSliderParagraph,
  created: TContentLang[],
  selected: TContentLang,
): Promise<TEditorSliderElement> => {
  const translations = {} as TTranslations<TEditorImage[]>;

  await Promise.all(
    created.map(async (locale) => {
      const initialFiles = p.translations[locale as TContentLang].files;
      const files = [] as TEditorImage[];
      await Promise.all(
        initialFiles.map(async (url, index) => {
          const file = await getFileFromUrl(url.x3);
          const base64 = await getBase64FromFile(file);

          files[index] = {
            filename: getFilenameFromUrl(url.x3),
            url: base64,
          };
        }),
      );

      translations[locale] = files;
    }),
  );

  return {
    id: p.id,
    type: EditorElementTypeEnum.Slider,
    translations,
    files: translations[selected],
    children: [{ text: '' }],
  };
};

const mapImageParagraph = async (
  p: TImageParagraph,
  created: TContentLang[],
  selected: TContentLang,
): Promise<TEditorImageElement> => {
  const translations = {} as TTranslations<TEditorImage>;

  await Promise.all(
    created.map(async (locale) => {
      const url = p.translations[locale as TContentLang].file.x3;
      const file = await getFileFromUrl(url);
      const base64 = await getBase64FromFile(file);

      translations[locale] = {
        filename: getFilenameFromUrl(url),
        url: base64,
      } satisfies TEditorImage;
    }),
  );

  return {
    id: p.id,
    type: EditorElementTypeEnum.Image,
    translations,
    file: translations[selected],
    children: [{ text: '' }],
  };
};

const mapTextParagraph = (
  p: TTextParagraph,
  created: TContentLang[],
  selected: TContentLang,
): TEditorParagraphElement => {
  const translations = reduceTranslationObject(created, (locale) => ({
    text: p.translations[locale].content,
  }));

  return {
    id: p.id,
    type: EditorElementTypeEnum.Paragraph,
    translations,
    children: [{ text: translations[selected]?.text ?? '' }],
  };
};

const mapVideoParagraph = async (
  p: TCreateVideoParagraphParams,
  created: TContentLang[],
  selected: TContentLang,
) => {
  const hostingRegEx = /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\\/\n]+)/im;
  const translations = reduceTranslationObject(created, (locale) => ({
    src: p.translations[locale].videoLink,
    hosting: p.translations[locale].videoLink.split(hostingRegEx)[1],
  }));

  return {
    id: p.id,
    type: EditorElementTypeEnum.Video,
    translations,
    file: {
      src: translations[selected].src,
      hosting: translations[selected].hosting,
    },
    children: [{ text: '' }],
  } satisfies TEditorVideoElement;
};

const mapPreviewContent = async (
  translations: Record<string, { file: TImageContent }>,
) => {
  const entries = Object.entries(translations);
  const modifiedEntries = await Promise.all(
    entries.map(async ([lang, value]) => [
      lang,
      await mapImageToEdit(value.file.x3),
    ]),
  );

  return Object.fromEntries(modifiedEntries);
};
