import { Icon } from 'shared/components/Icon/Icon';
import { TPostFormFile } from 'shared/types/common';

import styles from './VideoFormPreview.module.css';

type TProps = {
  preview: TPostFormFile;
};

export const VideoFormPreviewImage = ({ preview }: TProps) => {
  return (
    <div className={styles.videoWrapper}>
      {preview.file ? (
        <img
          alt="video preview image"
          className={styles.videoPreview}
          src={preview.data}
        />
      ) : (
        <div className={styles.dummyPreview}>
          <Icon className={styles.dummyPreviewIcon} kind="image-03" size="xl" />
        </div>
      )}
    </div>
  );
};
