import { Navigate } from 'react-router-dom';
import { useToken } from 'shared/api/token';
import { routes } from 'shared/routes';

export const AuthRedirect = () => {
  const token = useToken();

  if (!token) {
    return <Navigate replace to={routes.auth} />;
  }
  return null;
};
