import { format } from 'date-fns';
import { useLocales } from 'entities/config/lib/useLocales';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { CopyButton } from 'shared/components/CopyButton/CopyButton';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { getLangNameMap } from 'shared/consts';
import { toString } from 'shared/lib/toString';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

import styles from './PhotoGalleryFormPublishModal.module.css';

export const PhotoGalleryFormPublishModal = ({
  onClose,
  onPublish,
  onClickChooseDate,
  isOpened,
}: TProps) => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const { control, formState } = useFormContext<TPhotoGalleryFields>();
  const createdLocales = useWatch({ control, name: 'locale.created' });
  const id = useWatch({
    control,
    name: 'id',
  });

  const publishDate = useWatch({
    control,
    name: 'published',
  });

  const langNames = useMemo(() => getLangNameMap(), [t]);

  const filledLocales = createdLocales.map((locale) => langNames[locale]).join(', ');

  const notFilledLocales = locales
    .reduce<string[]>((str, lang) => {
      if (!createdLocales.includes(lang)) {
        str.push(langNames[lang]);
      }

      return str;
    }, [])
    .join(', ');

  return (
    <ActionModal
      cancelText={publishDate ? t('content.edit') : t('content.schedule')}
      confirmText={publishDate ? t('shared.confirm') : t('shared.publish')}
      contentClassName={styles.mainContent}
      opened={isOpened}
      title={publishDate ? t('shared.editContent') : t('shared.publishContent')}
      width={549}
      cancelButtonProps={{
        icon: 'calendar',
        iconPosition: 'before',
        isDisabled: formState.isSubmitting,
        onClick: onClickChooseDate,
      }}
      confirmButtonProps={{
        isLoading: formState.isSubmitting,
        kind: 'primary',
        onClick: onPublish,
      }}
      onClose={onClose}
    >
      {id && (
        <div className={styles.infoId}>
          <Text>{t('content.photoGallery.IDLabel')}</Text>
          <div className={styles.copyId}>
            <CopyButton iconSize="s" textToCopy={toString(id)} />
            <Text className={styles.textMuted} size={14}>
              ID: {id}
            </Text>
          </div>
        </div>
      )}
      <div className={styles.info}>
        <Text>{t('shared.filledLocales')}</Text>
        <Text className={styles.textMuted} size={16}>
          {filledLocales}
        </Text>
      </div>
      {notFilledLocales.length > 0 && (
        <div className={styles.info}>
          <Text>{t('shared.notFilledLocales')}</Text>
          <Text className={styles.textMuted} size={16}>
            {notFilledLocales}
          </Text>
          <div className={styles.alertMessage}>
            <Icon kind="alert-circle" size="s" />
            <Text className={styles.textMuted}>
              {t('content.publishModal.warningUnpublishLocalization')}
            </Text>
          </div>
        </div>
      )}
      {publishDate && (
        <div className={styles.info}>
          <Text>{t('content.photoGallery.publishModal.scheduleDateLabel')}</Text>

          <Text color="var(--gray-500)" size={16}>
            {format(publishDate, 'dd/MM/yyyy')}&nbsp;—&nbsp;
            <Text color="var(--gray-400)" size={16}>
              {format(publishDate, 'HH:mm')}
            </Text>
          </Text>
        </div>
      )}
    </ActionModal>
  );
};

type TProps = {
  onClose: () => void;
  onPublish: () => void;
  onClickChooseDate: () => void;
  isOpened: boolean;
};
