import { playerTeamAchievementsApiKeys } from 'entities/playerTeamAchievements/consts';
import {
  TCreatePlayerTeamAchievementDTO,
  TPlayerTeamAchievement,
} from 'entities/playerTeamAchievements/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchPlayerTeamAchievements = ({ params }: TFetchParams) =>
  get<TPlayerTeamAchievement[], TPaginatedMeta>(
    playerTeamAchievementsApiKeys.getPlayerTeamAchievements,
    {
      params,
    },
  ).then((response) => response.data);

export const createPlayerTeamAchievement = (
  teamAchievement: TCreatePlayerTeamAchievementDTO,
) =>
  post(playerTeamAchievementsApiKeys.createPlayerTeamAchievement, {
    teamAchievement,
  }).then((response) => response.data.data);

export const deletePlayerTeamAchievement = (teamAchievementId: TEntityId) =>
  del(playerTeamAchievementsApiKeys.deletePlayerTeamAchievement(teamAchievementId));

export const editPlayerTeamAchievement = ({
  id,
  ...playerTeamAchievement
}: TPlayerTeamAchievement) =>
  patch(
    playerTeamAchievementsApiKeys.editPlayerTeamAchievement(id),
    playerTeamAchievement,
  ).then((response) => response.data.data);
