import { EditorElementTypeEnum, TEditor } from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { BaseOperation } from 'slate';

export const isStructureChange = (editor: TEditor, op: BaseOperation) => {
  const isChangedStructureOperation =
    op.type === 'remove_node' ||
    op.type === 'move_node' ||
    op.type === 'insert_node' ||
    op.type === 'split_node' ||
    op.type === 'merge_node';

  if (isChangedStructureOperation && op.path.length === 1) {
    return true;
  }

  // В недефолтных локалях необходимо запрещать перемещение параграфов.
  // Первая проверка не применяется в этом случае, так как длина path равна двум.
  if (
    op.type === 'move_node' &&
    editorController.getElement(editor, EditorElementTypeEnum.ListItem, op.path)
  ) {
    return true;
  }

  return false;
};
