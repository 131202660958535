import { TSettingsNavigationLink } from 'feature/settingsNavigation/types';
import i18n from 'i18next';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';

export const getTextMaxLengthMessage = (): TSettingsNavigationLink[] => [
  {
    name: i18n.t('content.categories'),
    subLinks: [
      {
        name: i18n.t('content.settings.categoryVideos'),
        route: routes.categoryVideo,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: i18n.t('content.settings.categoryNews'),
        route: routes.categoryNews,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: i18n.t('content.settings.categories'),
        route: routes.categoryPhotogallery,
        roles: DEFAULT_ROUTER_ROLES,
      },
    ],
  },
  {
    name: i18n.t('content.labelsWithOtherEnding'),
    subLinks: [
      {
        name: i18n.t('content.settings.labelList'),
        route: routes.labels,
        roles: DEFAULT_ROUTER_ROLES,
      },
    ],
  },
];
