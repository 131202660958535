import { zodResolver } from '@hookform/resolvers/zod';
import type {
  TQuizElementSettings,
  TStoryElementData,
} from 'entities/stories/types';
import { debounce, isEmpty } from 'lodash';
import { FORM_DEBOUNCE_DELAY } from 'pages/stories/edit/EditStory/components/forms/constants';
import {
  TGetSwitchFieldArguments,
  TGetSwitchFieldFunction,
  TQuizAnswersItem,
} from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/types';
import { quizElementValidationSchema } from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/validationSchema';
import type { TQuizElementFormValues } from 'pages/stories/edit/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import type { TChangeRadioButtonHandler } from 'shared/components/Form/RadioButton/types';
import { useForm } from 'shared/hooks/useReactHookForm';
import { toNumber } from 'shared/lib/toNumber';

const ANSWERS_MAX_LENGTH = 4;

const transformAnswers = (
  answers: TQuizElementSettings['answers'],
  maxLength = ANSWERS_MAX_LENGTH,
) =>
  new Array(maxLength).fill(null).map((_, index) => ({
    title: answers[index] || '',
    hasSwitch: index > 1,
    disabled: typeof answers[index] === 'undefined',
  }));

const createSwitchField = (field: TQuizAnswersItem, onSwitch: () => void) =>
  field.hasSwitch
    ? {
        checked: !field.disabled,
        onSwitch,
      }
    : undefined;

export const useQuizElementForm = (selectedElement: TStoryElementData<'quiz'>) => {
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();

  const actionSubmit = debounce((data: TQuizElementFormValues) => {
    dispatch({
      type: 'updateSelectedStoryElementSettings',
      payload: {
        ...data,
        answers,
      },
    });
  }, FORM_DEBOUNCE_DELAY);

  const {
    submit,
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useForm<TQuizElementFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      ...selectedElement?.translations[selectedLocale].settings,
      answers: transformAnswers(
        selectedElement?.translations[selectedLocale].settings.answers,
      ),
    },
    resolver: zodResolver(quizElementValidationSchema()),
    shouldFocusError: false,
    actionSubmit,
    submitOnChange: true,
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'answers',
  });

  const answers = getValues('answers')
    .filter((answer) => !answer.disabled)
    .map((answer) => answer.title);

  const handleChangeCorrectAnswer = useCallback<TChangeRadioButtonHandler>(
    (_, value) => setValue('correctAnswerIndex', toNumber(value)),
    [setValue],
  );

  const handleChangeSwitch = useCallback(
    ({ index, field }: TGetSwitchFieldArguments) =>
      () => {
        const newDisabled = !field.disabled;
        update(index, {
          ...field,
          title: newDisabled ? '' : field.title,
          disabled: newDisabled,
        });
        if (getValues('correctAnswerIndex') === index && newDisabled) {
          setValue('correctAnswerIndex', 0);
        }
      },
    [getValues, setValue, update],
  );

  const getSwitchField = useCallback<TGetSwitchFieldFunction>(
    ({ index, field }) => {
      return createSwitchField(field, handleChangeSwitch({ index, field }));
    },
    [handleChangeSwitch],
  );

  useEffect(() => {
    if (!isEmpty(errors)) {
      dispatch({
        type: 'updateSelectedStoryElementSettings',
        payload: {
          ...getValues(),
          answers,
        },
      });
    }
  }, [errors]);

  return {
    control,
    submit,
    setValue,
    fields,
    titleDisabled: getValues('titleDisabled'),
    getSwitchField,
    handleChangeCorrectAnswer,
  };
};
