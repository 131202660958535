import { queryClient } from 'app/Providers';
import { managerCareersQueryKeys } from 'entities/managerCareers/api/consts';
import { useDeleteManagerCareerMutation } from 'entities/managerCareers/api/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeleteManagerCareer = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteManagerCareerMutation();

  const deleteManagerCareer = (activeCareerId: TEntityId) => {
    mutate(activeCareerId, {
      onError: () =>
        notify(t('content.manager.notifyNotDeleteCareer'), { type: 'error' }),
      onSuccess: () => {
        return queryClient.resetQueries([managerCareersQueryKeys.managerCareers]);
      },
    });
  };

  return {
    isDeletingManagerCareer: isLoading,
    deleteManagerCareer,
  };
};
