import cn from 'classnames';
import {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
  PropsWithoutRef,
  ReactElement,
  RefAttributes,
} from 'react';
import {
  TBody,
  TTableBodyProps,
} from 'shared/components/SimpleTable/components/TBody';
import {
  TCell,
  TTableCellProps,
} from 'shared/components/SimpleTable/components/TCell';
import {
  THead,
  TTableHeadProps,
} from 'shared/components/SimpleTable/components/THead';
import {
  THeadCell,
  TTableHeadCellProps,
} from 'shared/components/SimpleTable/components/THeadCell/THeadCell';
import {
  TRow,
  TTableRowProps,
} from 'shared/components/SimpleTable/components/TRow/TRow';
import {
  TSkeleton,
  TTableSkeletonProps,
} from 'shared/components/SimpleTable/components/TSkeleton';

import styles from './SimpleTable.module.css';

type TProps = HTMLAttributes<HTMLDivElement>;

export type TTableSubComponents<SortBy extends string> = {
  Head: (props: TTableHeadProps) => ReactElement;
  Row: (props: TTableRowProps) => ReactElement;
  Body: (props: TTableBodyProps) => ReactElement;
  Cell: (props: TTableCellProps) => ReactElement;
  HeadCell: (props: TTableHeadCellProps<SortBy>) => ReactElement;
  Skeleton: (props: TTableSkeletonProps) => ReactElement;
};

export type TTableWithRef = ForwardRefExoticComponent<
  PropsWithoutRef<TProps> & RefAttributes<HTMLDivElement>
>;
const TableContent: TTableWithRef = forwardRef(({ children, className }, ref) => {
  return (
    <div className={cn(styles.container, className)} ref={ref}>
      <div className={styles.table} role="table">
        {children}
      </div>
    </div>
  );
});

TableContent.displayName = 'Table';

export const createTable = <SortBy extends string>(): TTableWithRef &
  TTableSubComponents<SortBy> =>
  Object.assign(TableContent, {
    Head: THead,
    Body: TBody,
    Row: TRow,
    Cell: TCell,
    HeadCell: THeadCell,
    Skeleton: TSkeleton,
  });

type TSortParams = 'screenCount' | 'createdAt' | 'published' | 'viewsCount';

export const Table = createTable<TSortParams>();
