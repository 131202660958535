import { AddVideoPage } from 'pages/video/new/AddVideoPage/AddVideoPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const CreateRoute: TProtectedRoute = {
  path: routes.createVideo,
  element: <AddVideoPage />,
  roles: DEFAULT_ROUTER_ROLES,
};
