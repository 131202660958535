import { userApiKeys } from 'entities/user/model/consts';
import { TUserData, TUserRolesData } from 'entities/user/model/types';
import { get } from 'shared/api/methods';
import { TUserRole } from 'shared/types/user';

const normalizeRoles = (roles: TUserRolesData[]): Set<TUserRole> =>
  new Set<TUserRole>(roles.map<TUserRole>(({ name }) => name));

const mapUserData = (user: TUserData) => {
  return {
    ...user,
    roles: normalizeRoles(user.roles),
  };
};

export const fetchUser = () =>
  get<TUserData>(userApiKeys.getUser).then((response) =>
    mapUserData(response.data.data),
  );
