import i18n from 'i18next';
import { MAX_FILE_SIZE } from 'shared/consts';
import z from 'zod';

export const fileZodValidation = (
  errorMessage: string,
  maxFileSize = MAX_FILE_SIZE,
  maxSizeErrorMessage = i18n.t('shared.fileZodValidation.maxSizeErrorMessage', {
    mark: '.',
  }),
) =>
  z
    .any()
    .refine((file?: File) => file, errorMessage)
    .refine((file?: File) => {
      return file && file.size < maxFileSize;
    }, maxSizeErrorMessage);
