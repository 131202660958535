import { EditVideo } from 'pages/video/edit/EditVideo/EditVideo';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditRoute: TProtectedRoute = {
  path: routes.editVideo.url,
  element: <EditVideo />,
  roles: DEFAULT_ROUTER_ROLES,
};
