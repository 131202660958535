import { ManagerCard } from 'entities/managers/ui/ManagerCard/ManagerCard';
import { useManagers } from 'pages/team/hooks/useManagers';
import { TEAM_PAGE_GAP } from 'pages/team/teamPage/TeamPage';
import styles from 'pages/team/teamPage/TeamPage.module.css';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CardButton } from 'shared/components/CardButton/CardButton';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { routes } from 'shared/routes';

export const TeamPageManagerTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { teamId } = useParams<{
    teamId: string;
  }>();

  const {
    managersList,
    pageSize,
    isShowPagination,
    isLoading,
    total,
    page,
    changePage,
  } = useManagers(wrapperRef, teamId);

  return (
    <>
      <div ref={wrapperRef}>
        {isLoading ? (
          <Centered>
            <Loader showLabel />
          </Centered>
        ) : (
          <div className={styles.listWrapper} style={{ gap: `${TEAM_PAGE_GAP}px` }}>
            <CardButton
              title={t('shared.create')}
              onClick={() => teamId && navigate(routes.createManager.as(teamId))}
            />
            {managersList.map((item) => {
              return <ManagerCard key={item.id} manager={item} />;
            })}
          </div>
        )}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={pageSize}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </>
  );
};
