import cn from 'classnames';
import { MouseEventHandler } from 'react';
import {
  IconButton,
  TIconButtonProps,
} from 'shared/components/IconButton/IconButton';

import styles from './WorkspacePanelBackButton.module.css';

export type TWorkspacePanelBackButtonProps = Partial<TIconButtonProps> & {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const WorkspacePanelBackButton = ({
  onClick,
  icon = 'chevron-left',
  className,
  ...restProps
}: TWorkspacePanelBackButtonProps) => (
  <IconButton
    className={cn(styles.backButton, className)}
    icon={icon}
    onClick={onClick}
    {...restProps}
  />
);
