import { useDeleteVideoPostMutation } from 'entities/video/mutations';
import { TVideoPost } from 'entities/video/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

type TProps = {
  videoPost: TVideoPost;
  closeConfirmationModal: () => void;
};

export const useDeleteVideo = ({ videoPost, closeConfirmationModal }: TProps) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteVideoPostMutation, isLoading: isVideoDeleting } =
    useDeleteVideoPostMutation();

  const deleteVideo = () => {
    deleteVideoPostMutation(videoPost.id).catch(() =>
      notify(t('content.validation.notifyDeleteVideo')),
    );
    closeConfirmationModal();
  };

  return {
    deleteVideo,
    isVideoDeleting,
  };
};
