import { useDrop } from 'react-dnd';
import { TDragItemType } from 'shared/types/common';

export const useDropzone = (accept: TDragItemType) => {
  const [{ isActive }, drop] = useDrop(() => ({
    accept,
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  }));

  return [isActive, drop] as const;
};
