import { useLocales } from 'entities/config/lib/useLocales';
import { mapDefaultPhotoGalleryToDTO } from 'entities/photoGallery/mapper/mapDefaultPhotoGalleryToDTO';
import { useCreatePhotoGalleryMutation } from 'entities/photoGallery/mutations';
import { useNavigate } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';

export const useCreatePhotoGalleryPage = () => {
  const navigate = useNavigate();
  const { defaultLocale } = useLocales();
  const { mutate, isLoading } = useCreatePhotoGalleryMutation();

  const createDefaultPhotoGallery = () => {
    const availableLocales = [defaultLocale];

    const dto = mapDefaultPhotoGalleryToDTO(availableLocales);

    mutate(dto, {
      onSuccess: (photoGallery) => {
        navigate(routes.editPhotoGallery.as(photoGallery.id));
      },
      onError: () => {
        notify('Не удалось создать фотогалерею', { type: 'error' });
      },
    });
  };

  return {
    isLoading,
    createDefaultPhotoGallery,
  };
};
