import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { matchesQueryKeys } from 'entities/matches/consts';
import { TMatch } from 'entities/matches/types';
import { TFetchMatchParams } from 'entities/matches/utils';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchMatch, fetchMatches } from './api';

export type TFetchMatchesParams = {
  filter?: Record<string, string>;
} & TPaginatedParameters;

export const useFetchMatchesQuery = <
  WithIncludes extends boolean,
  T extends TFetchMatchParams = object,
>({
  fetchParams = {},
  options = {},
  params,
}: {
  fetchParams?: TFetchMatchesParams;
  options?: UseQueryOptions<TResponseData<TMatch<WithIncludes>[], TPaginatedMeta>>;
  params?: T;
}) => {
  const { filter, ...defaultPagination } = fetchParams;
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [matchesQueryKeys.matches, pagination, filter],
      queryFn: () =>
        fetchMatches<WithIncludes>({
          params: { ...pagination, ...filter, ...params },
        }),
      ...options,
    }),
    defaultPagination,
  );
};

export const useFetchMatchQuery = (
  id: TEntityId = '',
  options: UseQueryOptions<TMatch, unknown, TMatch> = {},
) =>
  useQuery({
    queryKey: [matchesQueryKeys.match, id],
    queryFn: () => fetchMatch(id),
    enabled: toBoolean(id),
    ...options,
  });
