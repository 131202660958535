import cn from 'classnames';
import { fontStyleMap } from 'pages/stories/edit/EditStory/components/forms/constants';
import { TElementProps } from 'pages/stories/edit/EditStory/types';

import styles from './ButtonElement.module.css';

export const ButtonElement = ({ settings }: TElementProps<'button'>) => {
  const { title, fontStyle, fontFamily, fill, background, size } = settings;

  const fontStyles = fontStyleMap[fontStyle];

  return (
    <div
      className={cn(styles.root, styles[size.toLowerCase()])}
      style={{
        fontFamily,
        color: fill,
        backgroundColor: background,
        ...fontStyles,
      }}
    >
      <span>{title}</span>
    </div>
  );
};
