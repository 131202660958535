import { TEditor } from 'shared/components/Editor/types';

export const editorWithSplitParagraphs = (editor: TEditor) => {
  const { apply } = editor;

  editor.apply = (op) => {
    // Удаление translations и id при разделении параграфа
    if (op.type === 'split_node' && 'type' in op.properties) {
      const newOperation = { ...op, properties: { ...op.properties } };
      if ('translations' in newOperation.properties) {
        delete newOperation.properties.translations;
      }
      if ('id' in newOperation.properties) {
        delete newOperation.properties.id;
      }

      return apply(newOperation);
    }

    return apply(op);
  };

  return editor;
};
