import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteOpponentTeamMutation } from 'entities/opponentTeams/mutations';
import { TOpponentTeam } from 'entities/opponentTeams/types';
import { OpponentTeamModal } from 'feature/opponentTeamModal/OpponentTeamModal';
import { useEditOpponentTeam } from 'pages/opponentTeam/OpponentTeamsPage/hooks/useEditOpponentTeam';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { notify } from 'shared/components/Notification';
type TProps = {
  team: TOpponentTeam;
  onClick: () => void;
};
export const OpponentTeamCard = ({ team, onClick }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { mutateAsync: deleteTeam, isLoading } = useDeleteOpponentTeamMutation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const onDeleteHandler = () => {
    deleteTeam(team.id, {
      onError: () => notify(t('content.validation.notifyDeleteTeam')),
    });
  };
  const {
    editTeam,
    isLoading: isLoadingEditTeam,
    openModal,
    isOpenModal,
    closeModal,
    initialValues,
  } = useEditOpponentTeam({
    team,
  });
  return (
    <>
      <Card
        key={team.id}
        type={ECardType.Team}
        entity={{
          name: team.translations[defaultLocale].name,
          picture: team.picture.x3,
        }}
        onClick={onClick}
        onDelete={() => setOpenConfirmModal(true)}
        onEdit={openModal}
      />
      <OpponentTeamModal
        actionSubmit={editTeam}
        closeModal={closeModal}
        initialValues={initialValues}
        isLoading={isLoadingEditTeam}
        isOpened={isOpenModal}
        title={t('content.team.edit')}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpened={openConfirmModal}
        description={t('content.team.warningDelete', {
          mark: '.',
        })}
        title={t('content.team.notifyDelete', {
          mark: '?',
        })}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onDeleteHandler}
      />
    </>
  );
};
