import { ReactElement } from 'react';

import { formComponentsMap } from './constants';
import { TCommonElementFormProps } from './types';

export const ElementForm = ({ selectedElement }: TCommonElementFormProps) => {
  if (!selectedElement || !selectedElement.kind) {
    return null;
  }

  const FormComponent: (props: TCommonElementFormProps) => ReactElement | null =
    formComponentsMap[selectedElement.kind];

  return <FormComponent selectedElement={selectedElement} />;
};
