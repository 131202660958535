import { useFetchPlayerSocialNetworksQuery } from 'entities/playerSocialNetworks/queries';
import { useFetchSocialNetworksQuery } from 'entities/socialNetworks/queries';
import { combinePlayerSocialNetworks } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/helpers';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getApiFilters } from 'shared/lib/apiFilters';
import { routes } from 'shared/routes';

export const usePlayerSocialNetworks = () => {
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();

  const {
    data: {
      data: playerSocialNetworks = [],
      meta: { total = 0, page = 1 } = {},
    } = {},
    isLoading: isPlayerSocialNetworksLoading,
    error: playerSocialNetworksError,
    changePage,
  } = useFetchPlayerSocialNetworksQuery({
    filter: getApiFilters({
      name: 'player_id',
      type: 'eq',
      value: playerId,
    }),
  });
  const {
    data: { data: socialNetworksData } = {},
    isLoading: isSocialNetworksLoading,
    error: socialNetworksError,
  } = useFetchSocialNetworksQuery();

  const combinedSocialNetworks = useMemo(() => {
    if (!playerSocialNetworks || !socialNetworksData) {
      return [];
    }

    return combinePlayerSocialNetworks(playerSocialNetworks, socialNetworksData);
  }, [playerSocialNetworks, socialNetworksData]);

  const handleConfirmButtonClick = () => {
    navigate(routes.playerCareers.as(teamId, playerId), {
      replace: true,
    });
  };

  return {
    socialLinks: combinedSocialNetworks,
    isLoading: isPlayerSocialNetworksLoading || isSocialNetworksLoading,
    error: playerSocialNetworksError || socialNetworksError,
    page,
    total,
    handleConfirmButtonClick,
    changePage,
  };
};
