import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createVideoCategory,
  deleteVideoCategory,
  editVideoCategory,
} from 'entities/videoCategories/api';
import { videoCategoriesQueryKeys } from 'entities/videoCategories/consts';

export const useCreateVideoCategoryMutation = () => {
  return useMutation({
    mutationFn: createVideoCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        videoCategoriesQueryKeys.videoCategories,
      ]);
    },
  });
};

export const useDeleteVideoCategoryMutation = () => {
  return useMutation({
    mutationFn: deleteVideoCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        videoCategoriesQueryKeys.videoCategories,
      ]);
    },
  });
};

export const useEditVideoCategoryMutation = () => {
  return useMutation({
    mutationFn: editVideoCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        videoCategoriesQueryKeys.videoCategories,
      ]);
    },
  });
};
