import { EditorMarksEnum, TEditor } from 'shared/components/Editor/types';
import { Editor } from 'slate';

export const editorMarkController = {
  isMarkActive(editor: TEditor, type: EditorMarksEnum) {
    const marks = Editor.marks(editor);
    return marks ? marks[type] === true : false;
  },

  toggleMark(editor: TEditor, type: EditorMarksEnum) {
    const active = this.isMarkActive(editor, type);
    if (active) {
      Editor.removeMark(editor, type);
    } else {
      Editor.addMark(editor, type, true);
    }
  },
};
