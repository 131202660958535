import { useRef, WheelEvent } from 'react';
import { componentsMap } from 'shared/components/Icon/constants';

import styles from './TimeControl.module.css';

type TProps = {
  className?: string;
  add: () => void;
  sub: () => void;
};

const UpIcon = componentsMap['chevron-up'];
const DownIcon = componentsMap['chevron-down'];

export const TimeControl = ({ add, sub }: TProps) => {
  const addRef = useRef<HTMLButtonElement | null>(null);
  const subRef = useRef<HTMLButtonElement | null>(null);

  const handleAddClick = () => {
    if (addRef.current) {
      addRef.current?.blur();
    }
    add();
  };

  const handleSubClick = () => {
    if (subRef.current) {
      subRef.current?.blur();
    }
    sub();
  };

  const handleScroll = (event: WheelEvent) => {
    if (event.deltaY > 0) {
      sub();
    }
    if (event.deltaY < 0) {
      add();
    }
  };

  return (
    <div className={styles.container} onWheel={handleScroll}>
      <button
        className={styles.button}
        ref={addRef}
        type="button"
        onClick={handleAddClick}
      >
        <i>
          <UpIcon />
        </i>
      </button>
      <button className={styles.button} ref={subRef} onClick={handleSubClick}>
        <i>
          <DownIcon />
        </i>
      </button>
    </div>
  );
};
