import { useGetPhotoGalleryValues } from 'pages/photogallery/edit/hooks/useGetPhotoGalleryValues';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { PhotoGalleryForm } from 'widgets/photoGalleryForm/ui/PhotoGalleryForm';

export const EditPhotoGallery = () => {
  const { isError, error, isLoading, data } = useGetPhotoGalleryValues();

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  return <PhotoGalleryForm values={data} />;
};
