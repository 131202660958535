import { socialNetworksApiKeys } from 'entities/socialNetworks/consts';
import { TSocialNetwork } from 'entities/socialNetworks/types';
import { get } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';

export const fetchSocialNetworks = ({ params }: TFetchParams) => {
  return get<TSocialNetwork[], TPaginatedMeta>(
    socialNetworksApiKeys.getSocialNetworks,
    {
      params,
    },
  ).then((response) => response.data);
};
