import {
  useDeleteNewsCategoryMutation,
  useEditNewsCategoryMutation,
} from 'entities/newsCategories/mutations';
import { TNewsCategory } from 'entities/newsCategories/types';
import { useCategoriesPageTable } from 'feature/categories/hooks/useCategoriesPageTable';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import styles from 'shared/components/Actions/Actions.module.css';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const NewsCategoriesActions = ({
  isActive,
  onIconClick,
  closeActiveItemId,
  item: newsCategory,
}: TTableActions<TNewsCategory>) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteCategory, isLoading: isLoadingDelete } =
    useDeleteNewsCategoryMutation();
  const { mutateAsync: editCategory, isLoading: isLoadingEdit } =
    useEditNewsCategoryMutation();
  const {
    showConfirmModal,
    setShowConfirmModal,
    showEditModal,
    deleteCategoryHandler,
    openEditModalHandler,
    closeEditModalHandler,
    onConfirm,
    onEdit,
    currentCategory,
  } = useCategoriesPageTable({
    category: newsCategory,
    deleteCategory: async (data) => {
      await deleteCategory(data);
      closeActiveItemId();
    },
    editCategory: async (data) => {
      await editCategory(data);
      closeActiveItemId();
    },
  });
  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModalHandler,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: deleteCategoryHandler,
    },
  ];
  const isLoading = isLoadingDelete || isLoadingEdit;
  const closeDeleteModalHandler = useCallback(() => {
    closeActiveItemId();
    setShowConfirmModal(false);
  }, []);
  const closeEditModal = useCallback(() => {
    closeActiveItemId();
    closeEditModalHandler();
  }, []);
  const confirmDeleteHandler = () => {
    onConfirm();
    closeActiveItemId();
  };
  return (
    <>
      <Actions
        actions={actions}
        className={styles.dropdown}
        isLoading={isLoading}
        isOpen={isActive}
        itemId={newsCategory.id}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        isOpened={showConfirmModal}
        title={t('content.settings.deleteCategory', {
          mark: '?',
        })}
        onClose={closeDeleteModalHandler}
        onConfirm={confirmDeleteHandler}
      />
      <LocalizationModal
        actionSubmit={onEdit}
        closeModal={closeEditModal}
        isOpened={showEditModal}
        subtitle={t('content.settings.nameCategory')}
        title={t('content.settings.editCategory')}
        createInitialValue={(locale) =>
          currentCategory?.translations[locale].name ?? ''
        }
        inputProps={{
          maxLength: 255,
          placeholder: t('content.inputPlaceholder'),
          required: true,
          size: 's',
        }}
      />
    </>
  );
};
