import cn from 'classnames';
import { TPhotoGalleryImage } from 'entities/photoGalleryImages/types';
import { PhotoGalleryFormCount } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormCount/PhotoGalleryFormCount';
import { PhotoGalleryFormImageCard } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormImageCard/PhotoGalleryFormImageCard';
import { PhotoGalleryFormUploadButton } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormUploadButton/PhotoGalleryFormUploadButton';

import styles from './PhotoGalleryFormList.module.css';

type TProps = {
  images: TPhotoGalleryImage[];
  total: number;
  openedUploadModal: boolean;
  imagesWrapperClassName: string;
  onClickUpload: VoidFunction;
};

export const PhotoGalleryFormList = ({
  images,
  openedUploadModal,
  total,
  imagesWrapperClassName,
  onClickUpload,
}: TProps) => {
  return (
    <div className={styles.root}>
      <div className={cn(styles.imagesWrapper, imagesWrapperClassName)}>
        {images.map((image, index) => (
          <PhotoGalleryFormImageCard
            key={image.id}
            image={image}
            isLast={index === images.length - 1}
          />
        ))}
      </div>
      <PhotoGalleryFormCount count={total} />
      <PhotoGalleryFormUploadButton
        count={total}
        openedUploadModal={openedUploadModal}
        onClick={onClickUpload}
      />
    </div>
  );
};
