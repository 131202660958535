import { noop } from 'lodash';
import { EyeDropper } from 'shared/components/ColorPicker/components/EyeDropper';
import { DefaultIconButton } from 'shared/components/IconButton/components/DefaultIconButton';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';
import { UIKitSizes } from 'shared/types/common';

import styles from '../UIKit.module.css';

export const IconButtonExample = () => {
  return (
    <>
      <Text size={24} tag="h2">
        Icon Button
      </Text>
      <div className={styles.row}>
        <IconButton icon="dots-vertical" iconSize="l" onClick={noop} />
        <IconButton icon="dots-vertical" iconSize="m" onClick={noop} />
        <IconButton icon="dots-vertical" iconSize="s" onClick={noop} />
        <DefaultIconButton
          icon="copy"
          size={UIKitSizes.l}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="feedback"
          size={UIKitSizes.m}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="button"
          size={UIKitSizes.s}
          variant="circle"
          onClick={noop}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.badgerGroup}>
          <DefaultIconButton
            icon="copy"
            kind="primary"
            size={UIKitSizes.l}
            variant="circle"
            onClick={noop}
          />
          <DefaultIconButton
            icon="feedback"
            kind="primary"
            size={UIKitSizes.m}
            variant="circle"
            onClick={noop}
          />
          <DefaultIconButton
            icon="button"
            kind="primary"
            size={UIKitSizes.s}
            variant="circle"
            onClick={noop}
          />
        </div>
        <DefaultIconButton
          icon="copy"
          kind="secondary"
          size={UIKitSizes.l}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="feedback"
          kind="secondary"
          size={UIKitSizes.m}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="button"
          kind="secondary"
          size={UIKitSizes.s}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="copy"
          kind="outlined"
          size={UIKitSizes.l}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="feedback"
          kind="outlined"
          size={UIKitSizes.m}
          variant="circle"
          onClick={noop}
        />
        <DefaultIconButton
          icon="button"
          kind="outlined"
          size={UIKitSizes.s}
          variant="circle"
          onClick={noop}
        />
      </div>
      <div className={styles.row}>
        <DefaultIconButton icon="copy" size={UIKitSizes.l} onClick={noop} />
        <DefaultIconButton icon="feedback" size={UIKitSizes.m} onClick={noop} />
        <DefaultIconButton icon="button" size={UIKitSizes.s} onClick={noop} />
      </div>
      <div className={styles.row}>
        <Text>I am not a global component</Text>
        <EyeDropper setIsEyeDropActive={() => {}} onColorPick={noop} />
      </div>
    </>
  );
};
