import { format, parseISO } from 'date-fns';
import { Text } from 'shared/components/Text/Text';

import styles from './DateTimeCell.module.css';

type TProps = {
  dateTimeString: Date | string | null;
};

const datePlaceholder = '--/--/--';
const timePlaceholder = '--:--';

export const DateTimeCell = ({ dateTimeString }: TProps) => {
  const parsedDate = dateTimeString ? parseISO(dateTimeString as string) : null;
  const date = parsedDate ? format(parsedDate, 'dd/MM/yyyy') : datePlaceholder;
  const time = parsedDate ? format(parsedDate, 'HH:mm') : timePlaceholder;
  return (
    <>
      <Text className={styles.root}>
        {`${date} - `}
        <Text className={styles.time}>{`${time}`}</Text>
      </Text>
    </>
  );
};
