import cn from 'classnames';
import { TeamSelect } from 'entities/teamSelect/TeamSelect';
import { CountrySelect } from 'feature/countrySelect/CountrySelect';
import { FormLocalesInput } from 'feature/localesInput/FormLocalesInput';
import { ManagerPositionSelect } from 'feature/managerPositionSelect/ManagerPositionSelect';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { Text } from 'shared/components/Text/Text';
import { getImageSettings, TODAY } from 'shared/constants';
import { TManagerFormFields } from 'widgets/managerForm/types';
import {
  MAX_MANAGER_BIOGRAPHY_LENGTH,
  MAX_MANAGER_FIRST_NAME_LENGTH,
  MAX_MANAGER_LAST_NAME_LENGTH,
} from 'widgets/managerForm/ui/ManagerForm/validationSchema';
import { ManagerFormDiscarded } from 'widgets/managerForm/ui/ManagerFormDiscarded/ManagerFormDiscarded';
import { ManagerFormPreview } from 'widgets/managerForm/ui/ManagerFormPreview/ManagerFormPreview';
import { ManagerFormStatus } from 'widgets/managerForm/ui/ManagerFormStatus/ManagerFormStatus';

import styles from './ManagerForm.module.css';
type TProps = {
  control: Control<TManagerFormFields>;
};
export const MANAGER_FORM_FROM_DATE = new Date(1970, 0);
export const ManagerForm = ({ control }: TProps) => {
  const { t } = useTranslation();
  const { aspectRatio, hint } = getImageSettings(t).manager.picture;
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <Text color="var(--gray-500)" size={20} weight="SemiBold">
          {t('content.baseData')}
        </Text>
        <ManagerFormPreview control={control} />
        <FormLocalesInput
          control={control}
          fieldName="firstName"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.nameTitle')}
          maxLength={MAX_MANAGER_FIRST_NAME_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          required
          size="s"
          title={t('content.inputName')}
        />
        <FormLocalesInput
          control={control}
          fieldName="lastName"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.secondNameTitle')}
          maxLength={MAX_MANAGER_LAST_NAME_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          required
          size="s"
          title={t('content.inputSecondName')}
        />
        <InputDatePicker
          control={control}
          fieldName="birthDate"
          fromDate={MANAGER_FORM_FROM_DATE}
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.birthDay')}
          placeholder={t('content.choseDate')}
          toDate={TODAY}
        />
        <FormLocalesInput
          control={control}
          fieldName="biography"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.bio')}
          maxLength={MAX_MANAGER_BIOGRAPHY_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          size="s"
          title={t('content.inputBio')}
          type="textarea"
        />
        <CountrySelect
          className={cn(styles.input, styles.baseWidth)}
          control={control}
          fieldName="countryId"
        />
        <ManagerFormStatus control={control} />
        <TeamSelect
          className={styles.baseWidth}
          control={control}
          fieldName="teamId"
        />
        <ManagerPositionSelect
          className={styles.baseWidth}
          control={control}
          fieldName="managerPositionId"
        />
        <InputDatePicker
          control={control}
          fieldName="debutDate"
          fromDate={MANAGER_FORM_FROM_DATE}
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.debutDate')}
          placeholder={t('content.choseDate')}
          toDate={TODAY}
        />
        <UploadImage
          aspectRatio={aspectRatio}
          control={control}
          fieldName="picture"
          hint={hint}
          imageRequirementText={hint}
          label={t('content.manager.imageList')}
          modalTitle={t('shared.imageSetting')}
          showIcon={false}
          wrapperClassName={styles.baseWidth}
        />
        <ManagerFormDiscarded control={control} />
      </div>
      <div className={styles.links}>
        <Text color="var(--gray-500)" size={20} weight="SemiBold">
          {t('content.story.linkWithEndingBigLetter')}
        </Text>
        <FormInput
          control={control}
          fieldName="shopLink"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label={t('content.linkTShirt')}
          placeholder={t('shared.addLink')}
          size="s"
        />
        <FormInput
          control={control}
          fieldName="sharingLink"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label={t('content.linkPage')}
          placeholder={t('shared.addLink')}
          size="s"
        />
      </div>
    </div>
  );
};
