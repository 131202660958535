import { fetchPlayerTeamAchievements } from 'entities/playerTeamAchievements/api';
import { playerTeamAchievementsQueryKeys } from 'entities/playerTeamAchievements/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchPlayerTeamAchievementsParams = {
  filter?: Record<string, string>;
};

export const useFetchPlayerTeamAchievementsQuery = ({
  filter,
}: TFetchPlayerTeamAchievementsParams) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [
      playerTeamAchievementsQueryKeys.playerTeamAchievements,
      pagination,
      filter,
    ],
    queryFn: () =>
      fetchPlayerTeamAchievements({ params: { ...filter, ...pagination } }),
    cacheTime: 0,
  }));
};
