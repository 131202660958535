import { UseQueryOptions } from '@tanstack/react-query';
import { fetchOpponentPlayers } from 'entities/opponentPlayers/api';
import { opponentPlayersQueryKeys } from 'entities/opponentPlayers/consts';
import { TOpponentPlayer } from 'entities/opponentPlayers/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

type TFetchOpponentPlayersParams = {
  filter: Record<string, string>;
} & TPaginatedParameters;

export const useFetchOpponentPlayersQuery = (
  { filter, ...defaultPagination }: TFetchOpponentPlayersParams,
  options: UseQueryOptions<TResponseData<TOpponentPlayer[], TPaginatedMeta>> = {},
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [opponentPlayersQueryKeys.opponentPlayers, pagination, filter],
      queryFn: () => fetchOpponentPlayers({ params: { ...filter, ...pagination } }),
      ...options,
    }),
    defaultPagination,
  );
