import { useFetchConfig } from 'entities/config/model/queries';
import { useConfigStore } from 'entities/config/model/store';
import { useEffect } from 'react';

export const useConfig = () => {
  const { data: configData, isLoading } = useFetchConfig();

  const { config, setConfig } = useConfigStore((state) => state);

  useEffect(() => {
    setConfig(configData);
  }, [configData]);

  return { config, isLoading };
};
