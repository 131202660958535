import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { createPlayer, deletePlayer, editPlayer } from 'entities/players/api/api';
import { playersQueryKeys } from 'entities/players/api/consts';
import {
  TCreatePlayerDTO,
  TEditPlayerDTO,
  TPlayer,
} from 'entities/players/model/types';

export const useCreatePlayerMutation = (
  options?: UseMutationOptions<TPlayer, unknown, TCreatePlayerDTO>,
) =>
  useMutation({
    mutationFn: createPlayer,
    ...options,
  });

export const useEditPlayerMutation = (
  options?: UseMutationOptions<TPlayer, unknown, TEditPlayerDTO>,
) =>
  useMutation({
    mutationFn: editPlayer,
    ...options,
  });

export const useDeletePlayerMutation = () => {
  return useMutation({
    mutationFn: deletePlayer,
    onSuccess: () => {
      return queryClient.resetQueries([playersQueryKeys.players]);
    },
  });
};
