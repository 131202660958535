import { createContext } from 'react';
import { TWorkspaceVideoContext } from 'shared/components/Workspace/WorkspaceContext/useWorkspaceVideoContext';
import { TWorkspaceImageContext } from 'shared/components/Workspace/WorkspaceContext/workspaceImageContext';
import { TWorkspaceSliderContext } from 'shared/components/Workspace/WorkspaceContext/workspaceSliderContext';

export type TWorkspaceContext = {
  image: TWorkspaceImageContext;
  slider: TWorkspaceSliderContext;
  video: TWorkspaceVideoContext;
};

export const WorkspaceContext = createContext({} as TWorkspaceContext);
