import cn from 'classnames';
import i18n from 'i18next';
import { Text } from 'shared/components/Text/Text';
import { EListStatus, TContentStatus } from 'shared/types/common';

import styles from './Status.module.css';

type TProps = {
  status: TContentStatus;
  containerClassName?: string;
  size?: 12 | 14;
};

const getTextByStatus = (): { [key in TProps['status']]: string } => ({
  [EListStatus.Published]: i18n.t('shared.Status.published'),
  [EListStatus.Postponed]: i18n.t('shared.Status.postponed'),
  [EListStatus.Draft]: i18n.t('shared.Status.draft'),
  completed: i18n.t('shared.Status.completed'),
  error: i18n.t('shared.error'),
});

export const Status = ({ status, containerClassName, size = 12 }: TProps) => (
  <div className={cn(styles.container, styles[status], containerClassName)}>
    <div />
    <Text size={size}>{getTextByStatus()[status]}</Text>
  </div>
);
