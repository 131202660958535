import { photoGalleryApiKeys } from 'entities/photoGallery/consts';
import {
  TEditPhotoGalleryDTO,
  TPhotoGallery,
  TPhotoGalleryDTO,
} from 'entities/photoGallery/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchPhotoGalleries = ({ params }: TFetchParams) =>
  get<TPhotoGallery[], TPaginatedMeta>(photoGalleryApiKeys.getPhotoGalleries, {
    params,
  }).then((response) => response.data);

export const fetchPhotoGallery = (photoGalleryId: TEntityId) =>
  get<TPhotoGallery>(photoGalleryApiKeys.getPhotoGallery(photoGalleryId)).then(
    (response) => response.data.data,
  );

export const createPhotoGallery = (dto: TPhotoGalleryDTO) =>
  post<TPhotoGallery>(photoGalleryApiKeys.createPhotoGallery, dto).then(
    (response) => response.data.data,
  );

export const editPhotoGallery = ({ id, ...dto }: TEditPhotoGalleryDTO) =>
  patch<TPhotoGallery>(photoGalleryApiKeys.editPhotoGallery(id), dto).then(
    (response) => response.data.data,
  );

export const deletePhotoGallery = (photoGalleryId: TEntityId) =>
  del(photoGalleryApiKeys.deletePhotoGallery(photoGalleryId)).then(
    (response) => response.data.data,
  );
