import { ChangeEvent, useRef } from 'react';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';

import styles from './OpacityInput.module.css';

type TProps = {
  value: number;
  onChange: (value: number) => void;
};

const min = 0;
const max = 100;

export const OpacityInput = ({ value, onChange }: TProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(min, Math.min(max, toNumber(e.target.value)));
    onChange(value);
  };

  const handleFocus = () => {
    if (ref.current) {
      ref.current?.select();
    }
  };

  return (
    <div className={styles.root}>
      <input
        maxLength={3}
        ref={ref}
        type="number"
        value={toString(toNumber(value))}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <div className={styles.inputFakeValueWrapper}>
        <span className={styles.inputFakeValue}>{value}</span>
        <span className={styles.suffix}>%</span>
      </div>
    </div>
  );
};
