import { photoGalleryImagesApiKeys } from 'entities/photoGalleryImages/consts';
import {
  TCreatePhotoGalleryImageDTO,
  TEditPhotoGalleryImageDTO,
  TPhotoGalleryImage,
} from 'entities/photoGalleryImages/types';
import { omit } from 'lodash';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchPhotoGalleryImages = ({ params }: TFetchParams) =>
  get<TPhotoGalleryImage[], TPaginatedMeta>(
    photoGalleryImagesApiKeys.getPhotoGalleryImages,
    {
      params,
    },
  ).then((response) => response.data);

export const uploadPhotoGalleryImage = ({
  signal,
  ...dto
}: TCreatePhotoGalleryImageDTO) =>
  post<TPhotoGalleryImage>(photoGalleryImagesApiKeys.uploadPhotoGalleryImage, dto, {
    signal,
  }).then((response) => response.data.data);

export const deletePhotoGalleryImage = (photoId: TEntityId) =>
  del(photoGalleryImagesApiKeys.deletePhotoGalleryImage(photoId)).then(
    (response) => response.data.data,
  );

export const editPhotoGalleryImage = (payload: TEditPhotoGalleryImageDTO) =>
  patch(photoGalleryImagesApiKeys.editPhotoGalleryImage(payload.id), {
    photoGalleryImage: omit(payload, 'id'),
  }).then((response) => response.data.data);
