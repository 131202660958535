import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { LocalStorageKeys } from 'shared/constants';
import { setDateFnsLocale } from 'shared/hooks/setDateFnsLocale';
import { TAppLang } from 'shared/types/common';

export const useChangeAppLocale = () => {
  const { i18n } = useTranslation();
  const [_, setLocale] = useLocalStorage(LocalStorageKeys.locale);

  return async (locale: TAppLang) => {
    setLocale(locale);
    i18n.changeLanguage(locale);
    setDateFnsLocale(locale);
  };
};
