import { labelsApiKeys } from 'entities/labels/consts';
import { TLabel } from 'entities/labels/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId, TTranslationsObject } from 'shared/types/common';

export const fetchLabels = ({ params }: TFetchParams) =>
  get<TLabel[], TPaginatedMeta>(labelsApiKeys.label, {
    params,
  }).then((response) => response.data);

export const createLabel = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TLabel>(labelsApiKeys.label, { label: data }).then(
    (response) => response.data.data,
  );

export const editLabel = (data: TLabel) =>
  patch<TLabel>(`${labelsApiKeys.labelWithId(data.id)}`, { label: data }).then(
    (response) => response.data.data,
  );

export const deleteLabel = (labelId: TEntityId) =>
  del(`${labelsApiKeys.labelWithId(labelId)}`).then(
    (response) => response.data.data,
  );
