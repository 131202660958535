import 'cropperjs/dist/cropper.css';
import './initialCropperStyle.css';

import { useState } from 'react';
import ReactCropper from 'react-cropper';
import { DEFAULT_CROPPER_ASPECT_RATIO } from 'shared/components/cropper/consts';
import { CropperPanel } from 'shared/components/cropper/CropperPanel/CropperPanel';
import { TCropper, TCropperMode } from 'shared/components/cropper/types';

import styles from './Cropper.module.css';

export type TCropperProps = {
  imageBase64Url: string;
  defaultAspectRatio: number;
  mode?: TCropperMode;
  initCropper: (cropper: TCropper) => void;
};

const MAX_ZOOM_IN_RATIO = 2;
const VIEW_MODE = 1;

export const Cropper = ({
  imageBase64Url,
  defaultAspectRatio,
  mode = 'strict',
  initCropper,
}: TCropperProps) => {
  const [cropper, changeCropper] = useState<TCropper | null>(null);

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        <ReactCropper
          alt="cropped image"
          aspectRatio={defaultAspectRatio ?? DEFAULT_CROPPER_ASPECT_RATIO}
          center={false}
          className={styles.img}
          dragMode="move"
          guides={false}
          src={imageBase64Url}
          toggleDragModeOnDblclick={false}
          viewMode={VIEW_MODE}
          zoom={(event) => {
            if (event.detail.ratio >= MAX_ZOOM_IN_RATIO) {
              event.preventDefault();
            }
          }}
          onInitialized={(cropperInstance) => {
            changeCropper(cropperInstance);
            initCropper(cropperInstance);
          }}
        />
      </div>
      {cropper && (
        <CropperPanel
          cropper={cropper}
          defaultAspectRatio={defaultAspectRatio}
          mode={mode}
        />
      )}
    </div>
  );
};
