import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createVideoPost, deleteVideoPost, editVideoPost } from 'entities/video/api';
import { videoQueryKeys } from 'entities/video/consts';
import { editVideoPostDTO } from 'entities/video/mapper/editVideoPostDTO';
import { prepareVideoPostDTO } from 'entities/video/mapper/prepareVideoPostDTO';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const useCreateVideoPostMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: TVideoPostFields) =>
      createVideoPost(prepareVideoPostDTO(data)),
    onSuccess: () => {
      navigate(routes.video);
      queryClient.resetQueries([videoQueryKeys.videoPosts]);
    },
  });
};

export const useEditVideoPostMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: TVideoPostFields) =>
      editVideoPost(
        editVideoPostDTO({
          data,
        }),
      ),
    onSuccess: () => {
      queryClient.resetQueries([videoQueryKeys.videoPosts]);
      navigate(routes.video);
    },
  });
};

export const useDeleteVideoPostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteVideoPost,
    onSuccess: () => queryClient.resetQueries([videoQueryKeys.videoPosts]),
  });
};
