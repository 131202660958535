import cn from 'classnames';

import styles from './BlurredImage.module.css';

type TProps = {
  imageUrl: string;
  className?: string;
  alt?: string;
};

export const BlurredImage = ({ imageUrl, className, alt }: TProps) => (
  <div className={cn(styles.root, className)}>
    <div
      className={styles.background}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    />
    <img alt={alt} className={styles.image} src={imageUrl} />
  </div>
);
