import { managerSocialNetworksApiKeys } from 'entities/managerSocialNetworks/consts';
import {
  TCreateManagerSocialNetworkDTO,
  TEditManagerSocialNetworkDTO,
  TManagerSocialNetwork,
} from 'entities/managerSocialNetworks/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { toNumber } from 'shared/lib/toNumber';
import { TEntityId } from 'shared/types/common';

export const fetchManagerSocialNetworks = ({ params }: TFetchParams) =>
  get<TManagerSocialNetwork[], TPaginatedMeta>(
    managerSocialNetworksApiKeys.getManagerSocialNetworks,
    {
      params,
    },
  ).then((response) => response.data);

export const createManagerSocialNetwork = ({
  socialNetworkId,
  ...managerSocialNetwork
}: TCreateManagerSocialNetworkDTO) =>
  post(managerSocialNetworksApiKeys.createManagerSocialNetworks, {
    socialNetwork: {
      ...managerSocialNetwork,
      socialNetworkId: toNumber(socialNetworkId),
    },
  });

export const deleteManagerSocialNetwork = (managerId: TEntityId) =>
  del(managerSocialNetworksApiKeys.deleteManagerSocialNetwork(managerId));

export const editManagerSocialNetwork = ({
  id,
  ...managerSocialNetwork
}: TEditManagerSocialNetworkDTO) =>
  patch(
    managerSocialNetworksApiKeys.editManagerSocialNetwork(id),
    managerSocialNetwork,
  );
