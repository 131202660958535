import { fetchManagerTeamAchievements } from 'entities/managerTeamAchievements/api';
import { managerTeamAchievementsQueryKeys } from 'entities/managerTeamAchievements/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchManagerTeamAchievementsParams = {
  filter?: Record<string, string>;
};

export const useFetchManagerTeamAchievementsQuery = ({
  filter,
}: TFetchManagerTeamAchievementsParams) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [
      managerTeamAchievementsQueryKeys.managerTeamAchievements,
      pagination,
      filter,
    ],
    queryFn: () =>
      fetchManagerTeamAchievements({ params: { ...pagination, ...filter } }),
    cacheTime: 0,
  }));
};
