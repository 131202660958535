import { useContext } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Editor } from 'shared/components/Editor/Editor';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { WorkspaceContext } from 'shared/components/Workspace/WorkspaceContext/WorkspaceContext';

import styles from './WorkspaceEditor.module.css';

type TProps = {
  control: Control<TEditNewsPostFields>;
  disabledStructureChange: boolean;
};

export const WorkspaceEditor = ({ disabledStructureChange }: TProps) => {
  const {
    image: { openChangeImageModal, openCropImageModal },
    slider: { openChangeSliderImagesModal, openCropSliderImageModal },
    video: { openChangeVideoLinkModal },
  } = useContext(WorkspaceContext);

  const { t } = useTranslation();

  return (
    <Editor
      changeImage={openChangeImageModal}
      changeSlider={openChangeSliderImagesModal}
      changeVideo={openChangeVideoLinkModal}
      className={styles.editor}
      cropImage={openCropImageModal}
      cropSliderImage={openCropSliderImageModal}
      disabledStructureChange={disabledStructureChange}
      placeholder={t('shared.Workspace.placeholder')}
    />
  );
};
