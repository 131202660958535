import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

import {
  TBatchUpdateStoriesDTO,
  TMutateStoryDTO,
  TMutateStoryGroupDTO,
  TStoryData,
  TStoryElementData,
  TStoryGroupData,
} from './types';

// ============== Story group endpoints ==============

export const fetchStoryGroups = ({ params }: TFetchParams) =>
  get<TStoryGroupData[], TPaginatedMeta>('story_groups', { params }).then(
    (response) => response.data,
  );

export const fetchStoryGroup = (id: TEntityId) =>
  get<TStoryGroupData>(`story_groups/${id}`).then((response) => response.data.data);

export const createStoryGroup = ({
  availableLocales,
  translations,
}: Pick<TStoryGroupData, 'translations' | 'availableLocales'>) =>
  post<TStoryGroupData>('story_groups', {
    available_locales: availableLocales,
    translations,
  }).then((response) => response.data.data);

export const updateStoryGroup = (
  storyGroupParams: Partial<TMutateStoryGroupDTO>,
) => {
  const { id, ...restParams } = storyGroupParams;
  return patch<TStoryGroupData>(`story_groups/${id}`, {
    ...restParams,
  }).then((response) => response.data.data);
};

export const deleteStoryGroup = (id: TEntityId) => del(`story_groups/${id}`);

// ============== Story endpoints ==============

export const fetchStory = (id: TEntityId) =>
  get<TStoryData>(`stories/${id}`).then((response) => response.data.data);

export const createStory = (storyData: TMutateStoryDTO) =>
  post<TStoryData>('stories', storyData).then((response) => response.data.data);

export const batchUpdateStories = (storiesData: TBatchUpdateStoriesDTO) =>
  patch<TStoryData[]>('story_batches', {
    data: storiesData,
  }).then((response) => response.data.data);

export const deleteStory = (storyData: TStoryData) => del(`stories/${storyData.id}`);

// ============== Story element endpoints ==============

export const createStoryElement = (
  storyElementData: Omit<TStoryElementData, 'id'>,
) => {
  const { story, ...restData } = storyElementData;
  return post<TStoryElementData>('story_elements', {
    ...restData,
    storyId: story,
  }).then((response) => response.data.data);
};

export const fetchStoryElement = (id: TEntityId) =>
  get<TStoryElementData>(`story_elements/${id}`).then(
    (response) => response.data.data,
  );

export const updateStoryElement = (storyElementData: TStoryElementData) => {
  const { story, ...restData } = storyElementData;
  return patch<TStoryElementData>(`story_elements/${storyElementData.id}`, {
    ...restData,
    storyId: story,
  }).then((response) => response.data.data);
};

export const deleteStoryElement = (id: TEntityId) => del(`story_elements/${id}`);
