export const isNumber = (entity: unknown): entity is number =>
  typeof entity === 'number';

export function calcPercent(numerator: number, denominator: number): number {
  if (denominator === 0) {
    console.error('Division by zero happened!');
    return NaN;
  }

  return (numerator / denominator) * 100;
}
