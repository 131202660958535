import { useQuery } from '@tanstack/react-query';
import { seasonsQueryKeys } from 'entities/seasons/consts';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchSeason, fetchSeasons } from './api';

export const useFetchSeasonsQuery = () =>
  useQuery({
    queryKey: [seasonsQueryKeys.seasons],
    queryFn: fetchSeasons,
  });

export const useFetchSeasonQuery = (id: TEntityId) =>
  useQuery({
    queryKey: [seasonsQueryKeys.season(id)],
    queryFn: () => fetchSeason(id),
    enabled: toBoolean(id),
  });
