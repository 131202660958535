import { useEditPlayerAchievementMutation } from 'entities/playerAchievements/mutations';
import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useEditPlayerAchievement = () => {
  const { t } = useTranslation();
  const { mutate: editPlayerAchievementMutation, isLoading } =
    useEditPlayerAchievementMutation();

  const editPlayerAchievement = (
    playerAchievement: TPlayerAchievement,
    onSuccess: () => void,
  ) => {
    editPlayerAchievementMutation(playerAchievement, {
      onSuccess,
      onError: () =>
        notify(t('content.player.notifyNotUpdateAchievement'), { type: 'error' }),
    });
  };

  return { isEditingPlayerAchievement: isLoading, editPlayerAchievement };
};
