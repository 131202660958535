import axios from 'axios';
import { useEditVideoPostMutation } from 'entities/video/mutations';
import { notify } from 'shared/components/Notification';
import { getDefaultErrorMessage, getDefaultErrorTitle } from 'shared/validation';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const useEditVideoPost = () => {
  const { mutateAsync } = useEditVideoPostMutation();

  return async (data: TVideoPostFields) => {
    try {
      await mutateAsync(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notify(getDefaultErrorMessage(), {
          type: 'error',
          title: getDefaultErrorTitle(),
        });
      }
    }
  };
};
