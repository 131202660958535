import {
  EditorElementTypeEnum,
  TEditor,
  TEditorElement,
} from 'shared/components/Editor/types';
import { editorImageController } from 'shared/components/Editor/utils/image/editorImageController';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { TContentLang } from 'shared/types/common';

export const editorWithImages = (editor: TEditor, defaultLocale: TContentLang) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element: TEditorElement) => {
    return element.type === EditorElementTypeEnum.Image ||
      element.type === EditorElementTypeEnum.Slider ||
      element.type === EditorElementTypeEnum.Video
      ? true
      : isVoid(element);
  };

  editor.insertData = (data: DataTransfer) => {
    const { files } = data;

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) {
          continue;
        }
        const [mime] = file.type.split('/');
        if (mime === 'image') {
          getBase64FromFile(file).then((url) => {
            editorImageController.insertImage(
              editor,
              {
                url: url as string,
                filename: file.name,
              },
              defaultLocale,
            );
          });
        }
      }
    } else {
      insertData(data);
    }
  };

  return editor;
};
