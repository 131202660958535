import cn from 'classnames';
import { MenuListProps } from 'react-select';
import { TOption } from 'shared/components/Form/NewSelect/types';

import styles from '../NewSelect.module.css';

type TProps = MenuListProps<TOption>;

export const MenuList = (props: TProps) => {
  const { children, getClassNames, innerProps } = props;

  return (
    <div
      {...innerProps}
      className={cn(styles.optionsContainer, getClassNames('menuList', props))}
    >
      {children}
    </div>
  );
};
