import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { CountrySelect } from 'feature/countrySelect/CountrySelect';
import { OpponentPlayerModalDiscarded } from 'feature/opponentPlayerModal/OpponentPlayerModalDiscarded';
import { TOpponentPlayerModalFields } from 'feature/opponentPlayerModal/types';
import {
  MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH,
  MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH,
  MAX_OPPONENT_PLAYER_NICKNAME_LENGTH,
  opponnentPlayerFormValidationSchema,
} from 'feature/opponentPlayerModal/validationSchema';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { getImageSettings } from 'shared/constants';
import { getLangNameMap } from 'shared/consts';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TContentLang } from 'shared/types/common';

import styles from './OpponnentPlayerModal.module.css';
type TProps = {
  title: string;
  isOpened: boolean;
  closeModal: () => void;
  isLoading?: boolean;
  initialValues?: TOpponentPlayerModalFields;
  actionSubmit: (fields: TOpponentPlayerModalFields) => void;
};
const getDefaultValues = (locale: TContentLang): TOpponentPlayerModalFields => ({
  number: '',
  countryId: '',
  nickname: '',
  discarded: 'false',
  translations: {
    [locale]: {
      firstName: '',
      lastName: '',
    },
  },
});
export const OpponentPlayerModal = ({
  title,
  isOpened,
  closeModal,
  actionSubmit,
  initialValues,
  isLoading,
}: TProps) => {
  const { t } = useTranslation();
  const { locales, defaultLocale } = useLocales();
  const { aspectRatio, hint } = getImageSettings(t).opponentPlayer.picture;
  const {
    submit,
    control,
    formState: { isValid },
  } = useForm<TOpponentPlayerModalFields>({
    values: initialValues ?? getDefaultValues(defaultLocale),
    resolver: zodResolver(opponnentPlayerFormValidationSchema(locales)),
    mode: 'onBlur',
    actionSubmit,
  });
  const langNames = getLangNameMap();
  return (
    <ActionModal
      contentClassName={styles.root}
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isLoading,
        onClick: submit,
        isDisabled: !isValid,
      }}
      onClose={closeModal}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        {t('content.nameTitle')}
      </Text>
      <div className={styles.localesInput}>
        {locales?.map((locale) => (
          <FormInput
            key={locale}
            control={control}
            fieldName={`translations.${locale}.firstName`}
            label={langNames[locale]}
            maxLength={MAX_OPPONENT_PLAYER_FIRST_NAME_LENGTH}
            placeholder={t('content.inputPlaceholder')}
            required
            size="s"
          />
        ))}
      </div>
      <Text size={14} tag="h3" weight="SemiBold">
        {t('content.secondNameTitle')}
      </Text>
      <div className={styles.localesInput}>
        {locales?.map((locale) => (
          <FormInput
            key={locale}
            control={control}
            fieldName={`translations.${locale}.lastName`}
            label={langNames[locale]}
            maxLength={MAX_OPPONENT_PLAYER_LAST_NAME_LENGTH}
            placeholder={t('content.inputPlaceholder')}
            required
            size="s"
          />
        ))}
      </div>
      <Text size={14} tag="h3" weight="SemiBold">
        {t('content.player.nickName')}
      </Text>
      <FormInput
        control={control}
        fieldName="nickname"
        maxLength={MAX_OPPONENT_PLAYER_NICKNAME_LENGTH}
        placeholder={t('content.inputPlaceholder')}
        size="s"
      />
      <CountrySelect
        className={styles.field}
        control={control}
        fieldName="countryId"
        labelClassName={styles.label}
      />
      <FormInput
        className={styles.field}
        control={control}
        fieldName="number"
        label={t('content.opponentTeams.number')}
        labelClassName={styles.label}
        placeholder={t('content.player.inputNumber')}
        required
        size="s"
        type="number"
      />
      <OpponentPlayerModalDiscarded control={control} />
      <UploadImage
        aspectRatio={aspectRatio}
        control={control}
        fieldName="picture"
        hint={hint}
        imageRequirementText={hint}
        label={t('content.opponentTeams.imagePlayer')}
        labelClassName={styles.label}
        modalTitle={t('shared.imageSetting')}
        showIcon={false}
      />
    </ActionModal>
  );
};
