import { TEntityId } from 'shared/types/common';

export const labelsQueryKeys = {
  labels: 'labels',
};

const labelsBaseUrl = 'labels';

export const labelsApiKeys = {
  label: labelsBaseUrl,
  labelWithId: (labelId: TEntityId) => `${labelsBaseUrl}/${labelId}`,
};
