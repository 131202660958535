import { IconButton } from 'shared/components/IconButton/IconButton';

import styles from './WorkspacePanelPreviewButton.module.css';

type TProps = {
  onClick: () => void;
};

export const WorkspacePanelPreviewButton = ({ onClick }: TProps) => (
  <IconButton className={styles.button} icon="play" iconSize="m" onClick={onClick} />
);
