import { parseISO } from 'date-fns';
import { TManager } from 'entities/managers/model/types';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toString } from 'shared/lib/toString';
import { TContentLang } from 'shared/types/common';
import { TManagerFormFields } from 'widgets/managerForm/types';
export const mapManagerToManagerFormFields = async (
  manager: TManager,
  locales: TContentLang[],
  defaultLocale: TContentLang,
): Promise<TManagerFormFields> => ({
  id: toString(manager.id),
  photo: manager.photo?.x3 ? await getFileFromUrl(manager.photo.x3) : undefined,
  firstName: reduceTranslationObject(
    locales,
    (locale) => manager.translations[locale].firstName,
  ),
  lastName: reduceTranslationObject(
    locales,
    (locale) => manager.translations[locale].lastName,
  ),
  birthDate: manager.birthDate ? parseISO(manager.birthDate) : null,
  biography: reduceTranslationObject(
    locales,
    (locale) => manager.translations[locale].biography ?? '',
  ),
  countryId: manager.country,
  status: manager.status,
  teamId: manager.team,
  managerPositionId: manager.managerPosition,
  debutDate: manager.debutDate ? parseISO(manager.debutDate) : null,
  picture: manager.picture?.x3
    ? await getFileFromUrl(manager.picture.x3)
    : undefined,
  discardedAt: manager.discardedAt ? parseISO(manager.discardedAt) : undefined,
  discarded: manager.discardedAt ? 'true' : 'false',
  shopLink: manager.translations[defaultLocale]?.shopLink ?? undefined,
  sharingLink: manager.translations[defaultLocale]?.sharingLink ?? undefined,
});
