import { useMutation } from '@tanstack/react-query';
import {
  createManagerSocialNetwork,
  deleteManagerSocialNetwork,
  editManagerSocialNetwork,
} from 'entities/managerSocialNetworks/api';

export const useDeleteManagerSocialNetworkMutation = () => {
  return useMutation({
    mutationFn: deleteManagerSocialNetwork,
  });
};

export const useCreateManagerSocialNetworkMutation = () =>
  useMutation({
    mutationFn: createManagerSocialNetwork,
  });

export const useEditManagerSocialNetworkMutation = () =>
  useMutation({
    mutationFn: editManagerSocialNetwork,
  });
