import { useEffect } from 'react';
import { TEditor } from 'shared/components/Editor/types';
import { isStructureChange } from 'shared/components/Editor/utils/structureChange/isStructureChange';

export const useDisableStructureChange = (
  disabledStructureChange: boolean,
  editor?: TEditor,
) => {
  useEffect(() => {
    if (!editor) {
      return;
    }

    const { apply } = editor;

    editor.apply = (op) => {
      if (isStructureChange(editor, op) && disabledStructureChange) {
        return;
      }

      return apply(op);
    };

    return () => {
      editor.apply = apply;
    };
  }, [disabledStructureChange]);
};
