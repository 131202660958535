import { ControlProps } from 'react-select';
import { TOption } from 'shared/components/Form/NewSelect/types';

type TProps = ControlProps<TOption>;

export const Control = (props: TProps) => {
  const { innerProps, getClassNames, children } = props;
  return (
    <div className={getClassNames('control', props)} {...innerProps}>
      {children}
    </div>
  );
};
