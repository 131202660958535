import { useState } from 'react';
import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

type TUseNewsWorkspacePanelUnPublish = Partial<
  UseFormReturn<TEditNewsPostFields>
> & {
  isEdit: boolean;
  isPublish: boolean;
  editNewsPost: (data: TEditNewsPostFields) => Promise<void>;
};

export const useNewsWorkspacePanelUnPublish = ({
  control,
  handleSubmit,
  editNewsPost,
  isEdit,
  isPublish,
}: TUseNewsWorkspacePanelUnPublish) => {
  const [isOpenConfirmUnpublishModal, setIsOpenConfirmUnpublishModal] =
    useState(false);
  const { field } = useController({
    control,
    name: 'publishDate',
  });

  if (!isEdit || !isPublish) {
    return null;
  }

  const onClickUnpublishBtn = () => {
    setIsOpenConfirmUnpublishModal(true);
  };

  const onCloseUnpublish = () => {
    setIsOpenConfirmUnpublishModal(false);
  };

  const onConfirmUnpublish = () => {
    setIsOpenConfirmUnpublishModal(false);
    field.onChange(undefined);
    handleSubmit?.(editNewsPost)();
  };

  return {
    isOpenConfirmUnpublishModal,
    onClickUnpublishBtn,
    onCloseUnpublish,
    onConfirmUnpublish,
  };
};
