import { PlayerCareerModal } from 'feature/playerCareerModal/PlayerCareerModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { notify } from 'shared/components/Notification';

import styles from '../UIKit.module.css';

export const PlayerCareerModalExample = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.root}>
      <Button onClick={() => setOpen(true)}>
        {t('content.player.openPlayerModal')}
      </Button>
      <PlayerCareerModal
        actionSubmit={(values) => notify(JSON.stringify(values, undefined, 2))}
        closeModal={() => setOpen(false)}
        isLoading={false}
        isOpened={open}
        title={t('content.createCareer')}
      />
    </div>
  );
};
