import { ReactElement, useState } from 'react';

type TProps = {
  alt: string;
  src: string;
  className?: string;
  DefaultComponent: ReactElement;
};

export const Image = ({ src, DefaultComponent, alt, className }: TProps) => {
  const [isError, setIsError] = useState(false);

  if (isError) {
    return DefaultComponent;
  }

  return (
    <img
      alt={alt}
      className={className}
      src={src}
      onError={() => setIsError(true)}
    />
  );
};
