import { TEntityId } from 'shared/types/common';

const photoGalleryCategoriesBaseUrl = 'photo_gallery_categories';

export const photoGalleryCategoriesQueryKeys = {
  photoGalleryCategories: 'photoGalleryCategories',
};

export const photoGalleryCategoriesApiKeys = {
  createPhotoGalleryCategory: photoGalleryCategoriesBaseUrl,
  getPhotoGalleryCategories: photoGalleryCategoriesBaseUrl,
  editPhotoGalleryCategory: (categoryId: TEntityId) =>
    `${photoGalleryCategoriesBaseUrl}/${categoryId}`,
  deletePhotoGalleryCategory: (categoryId: TEntityId) =>
    `${photoGalleryCategoriesBaseUrl}/${categoryId}`,
};
