import { useController, useFormContext } from 'react-hook-form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { toBoolean } from 'shared/lib/toBoolean';

export const useIsEditNewsPost = () => {
  const { control } = useFormContext<TEditNewsPostFields>();

  const { field } = useController({
    control,
    name: 'id',
  });

  return toBoolean(field.value);
};
