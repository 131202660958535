import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { DEFAULT_CROPPER_ASPECT_RATIO } from 'shared/components/cropper/consts';
import { cropperActions } from 'shared/components/cropper/CropperPanel/cropperActions';
import { TCropper, TCropperMode } from 'shared/components/cropper/types';
import { Divider } from 'shared/components/Divider';
import { ToolbarButton } from 'shared/components/ToolbarButton/ToolbarButton';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';

import styles from './CropperPanel.module.css';

type TProps = {
  cropper: TCropper;
  mode: TCropperMode;
  defaultAspectRatio: number;
};

export const CropperPanel = ({ cropper, mode, defaultAspectRatio }: TProps) => {
  const { t } = useTranslation();

  const [isActiveCrop, toggleIsActiveCrop] = useToggle(true);
  const {
    reset,
    zoomIn,
    zoomOut,
    setAspectRatio,
    deleteCropping,
    enableCropping,
    rotateRight,
    rotateLeft,
  } = cropperActions(cropper, defaultAspectRatio);
  const isOptionalMode = mode === 'optional';

  const onCropHandler = () => {
    if (isActiveCrop) {
      deleteCropping();
    } else {
      enableCropping();
    }

    toggleIsActiveCrop();
  };

  return (
    <div className={styles.root}>
      <Tooltip tip={t('shared.cropper.CroppedPanel.turnLeft')}>
        <ToolbarButton icon="refresh-ccw-01" type="icon" onClick={rotateRight} />
      </Tooltip>
      <Tooltip tip={t('shared.cropper.CroppedPanel.turnRight')}>
        <ToolbarButton icon="refresh-cw-01" onClick={rotateLeft} />
      </Tooltip>
      <Divider className={styles.divider} variant="vertical" />
      <Tooltip tip={t('shared.cropper.CroppedPanel.zoomIn')}>
        <ToolbarButton icon="zoom-in" onClick={zoomIn} />
      </Tooltip>
      <Tooltip tip={t('shared.cropper.CroppedPanel.zoomOut')}>
        <ToolbarButton icon="zoom-out" onClick={zoomOut} />
      </Tooltip>
      {isOptionalMode && (
        <>
          <Divider className={styles.divider} variant="vertical" />
          <Tooltip tip={`${t('shared.cropper.CroppedPanel.ratio')} 16/9`}>
            <ToolbarButton
              text="16/9"
              type="text"
              onClick={() => setAspectRatio(DEFAULT_CROPPER_ASPECT_RATIO)}
            />
          </Tooltip>
          <Tooltip tip={`${t('shared.cropper.CroppedPanel.ratio')} 4/3`}>
            <ToolbarButton
              text="4/3"
              type="text"
              onClick={() => setAspectRatio(4 / 3)}
            />
          </Tooltip>
          <Tooltip tip={`${t('shared.cropper.CroppedPanel.ratio')} 1/1`}>
            <ToolbarButton
              text="1/1"
              type="text"
              onClick={() => setAspectRatio(1 / 1)}
            />
          </Tooltip>
          <Tooltip tip={`${t('shared.cropper.CroppedPanel.ratio')} 2/3`}>
            <ToolbarButton
              text="2/3"
              type="text"
              onClick={() => setAspectRatio(2 / 3)}
            />
          </Tooltip>
          <Tooltip tip={`${t('shared.cropper.CroppedPanel.ratio')} 9/16`}>
            <ToolbarButton
              text="9/16"
              type="text"
              onClick={() => setAspectRatio(9 / 16)}
            />
          </Tooltip>
        </>
      )}
      <Divider className={styles.divider} variant="vertical" />
      {isOptionalMode && (
        <Tooltip tip={t('shared.cropper.CroppedPanel.crop')}>
          <ToolbarButton
            active={isActiveCrop}
            icon="crop-01"
            onClick={onCropHandler}
          />
        </Tooltip>
      )}
      <Tooltip tip={t('shared.reset')}>
        <ToolbarButton icon="refresh-ccw-02" onClick={reset} />
      </Tooltip>
    </div>
  );
};
