import cn from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

import styles from './Dropdown.module.css';

type TProps = {
  width?: number;
  minWidth?: number;
};

export const Dropdown = forwardRef<
  HTMLDivElement,
  TProps & HTMLAttributes<HTMLDivElement>
>(({ children, className, width, minWidth, style, ...props }, ref) => {
  return (
    <div
      className={cn(styles.root, className)}
      ref={ref}
      style={{ ...style, width, minWidth }}
      {...props}
    >
      {children}
    </div>
  );
});
