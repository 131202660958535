import i18n from 'i18next';
import { getTextMinLengthMessage, TEXT_MIN_LENGTH } from 'shared/consts';
import { declination } from 'shared/lib/declination';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import z from 'zod';

const NAME_LENGTH = 255;
const maxLengthTextGenerate = (length: number) =>
  `${i18n.t('content.maximumLength')} ${length} ${declination(length, [i18n.t('content.symbol'), i18n.t('content.symbolDifferentEnding'), i18n.t('content.symbols')])}`;

export const opponentTeamModalValidationSchema = (locales: TContentLang[]) =>
  z
    .object({
      name: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
            .max(NAME_LENGTH, maxLengthTextGenerate(NAME_LENGTH)),
        ),
      }),
      clubName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, getTextMinLengthMessage())
            .max(NAME_LENGTH, maxLengthTextGenerate(NAME_LENGTH)),
        ),
      }),
      countryId: z.string().min(TEXT_MIN_LENGTH, getTextMinLengthMessage()),
    })
    .passthrough();
