import { useRightPanelTabs } from 'feature/rightPanel/hooks/useRightPanelTabs';
import { ComponentType, useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';

import { tabNames } from '../constants';
import { TDefaultRightPanelProps, TTabName } from '../types';

type TRightPanelElement<T extends FieldValues> = {
  Component: ComponentType<TDefaultRightPanelProps>;
  fieldName?: Path<T>;
};

export type TUseRightPanel<T extends FieldValues> = {
  hasPreview: boolean;
  preview?: TRightPanelElement<T>;
  label?: TRightPanelElement<T>;
  connections?: TRightPanelElement<T>;
  review?: TRightPanelElement<T>;
  importance?: TRightPanelElement<T>;
  sharingLink?: TRightPanelElement<T>;
};

export const useRightPanel = <T extends FieldValues>(props: TUseRightPanel<T>) => {
  const { toggleTab, onClickNext, isActiveTab, isLastTab } =
    useRightPanelTabs<T>(props);

  const {
    hasPreview,
    preview,
    label,
    connections,
    review,
    importance,
    sharingLink,
  } = props;

  const getComponent = (
    tabName: TTabName,
    element?: TRightPanelElement<T>,
    toggleFn: () => void = () => toggleTab(tabName),
  ) =>
    element ? (
      <element.Component
        key={tabName}
        disabled={tabName === 'preview' ? false : !hasPreview}
        isOpen={isActiveTab(tabName)}
        onClick={toggleFn}
        onClickNext={!isLastTab(tabName) ? () => onClickNext(tabName) : undefined}
      />
    ) : null;

  const Preview = useMemo(
    () => getComponent(tabNames.preview, preview),
    [isActiveTab(tabNames.preview)],
  );

  const Label = useMemo(
    () => getComponent(tabNames.label, label),
    [isActiveTab(tabNames.label), hasPreview],
  );

  const Connections = useMemo(
    () => getComponent(tabNames.connected, connections),
    [isActiveTab(tabNames.connected), hasPreview],
  );

  const Review = useMemo(
    () => getComponent(tabNames.review, review),
    [isActiveTab(tabNames.review), hasPreview],
  );

  const Importance = useMemo(
    () => getComponent(tabNames.importance, importance),
    [isActiveTab(tabNames.importance), hasPreview],
  );

  const SharingLink = useMemo(
    () => getComponent(tabNames.sharingLink, sharingLink),
    [isActiveTab(tabNames.sharingLink), hasPreview],
  );

  return {
    Preview,
    Label,
    Connections,
    Review,
    Importance,
    SharingLink,
  };
};
