import { EditStory } from 'pages/stories/edit/EditStory/EditStory';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditRoute: TProtectedRoute = {
  path: routes.editStory,
  element: <EditStory />,
  roles: DEFAULT_ROUTER_ROLES,
};
