import { useLocales } from 'entities/config/lib/useLocales';
import { useRef, useState } from 'react';
import { TEditor, TEditorVideo } from 'shared/components/Editor/types';
import { editorVideoController } from 'shared/components/Editor/utils/video/editorVideoController';

export type TWorkspaceVideoContext = {
  isOpenAttachVideoLinkModal: boolean;
  openAttachVideoLinkModal: () => void;
  openChangeVideoLinkModal: (path: number[]) => void;
  closeAttachVideoLinkModal: () => void;
  attachLink: (link: TEditorVideo) => void;
};

export const useWorkspaceVideoContext = (editor: TEditor) => {
  const [isOpenAttachVideoLinkModal, setIsOpenAttachVideoLinkModal] =
    useState(false);
  const { defaultLocale } = useLocales();
  const changeVideoPath = useRef([] as number[]);

  const openAttachVideoLinkModal = () => {
    setIsOpenAttachVideoLinkModal(true);
  };

  const closeAttachVideoLinkModal = () => {
    setIsOpenAttachVideoLinkModal(false);
  };

  const insertVideo = (file: TEditorVideo) => {
    editorVideoController.insertVideo(editor, file, defaultLocale);
  };

  const changeVideo = (file: TEditorVideo) => {
    editorVideoController.changeVideo(editor, changeVideoPath.current, file);
    changeVideoPath.current = [];
  };

  const attachLink = (props: TEditorVideo) => {
    if (changeVideoPath.current.length) {
      changeVideo(props);
    } else {
      insertVideo(props);
    }
  };

  const openChangeVideoLinkModal = (path: number[]) => {
    changeVideoPath.current = path;
    openAttachVideoLinkModal();
  };

  return {
    isOpenAttachVideoLinkModal,
    openAttachVideoLinkModal,
    closeAttachVideoLinkModal,
    openChangeVideoLinkModal,
    attachLink,
  };
};
