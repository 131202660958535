import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { Text } from 'shared/components/Text/Text';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from '../UIKit.module.css';

export const ColorInputExample = () => {
  const { control } = useForm({
    defaultValues: {
      color: '#ffffff',
    },
  });

  return (
    <div>
      <Text size={24} tag="h2">
        Color Input
      </Text>
      <div className={styles.row}>
        <ColorInput control={control} fieldName="color" placeholder="Hello" />
      </div>
    </div>
  );
};
