import type { Identifier, XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DNDItemTypes } from 'shared/constants';
import { TEntityId } from 'shared/types/common';

type TDragItem = {
  index: number;
  id: string;
  type: string;
};

type THookProps = {
  index: number;
  move: (dragIndex: number, hoverIndex: number) => void;
  dragItemType: (typeof DNDItemTypes)[keyof typeof DNDItemTypes];
  canDrag?: boolean;
  item: {
    id: TEntityId;
    image?: string;
    selected?: boolean;
  };
};

export const useSortableDND = ({
  index,
  move,
  dragItemType,
  canDrag = true,
  item,
}: THookProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    TDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: dragItemType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item: TDragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      move(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: dragItemType,
    item: () => ({ ...item, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: canDrag,
  });

  return { ref, handlerId, drop, isDragging, drag, preview };
};
