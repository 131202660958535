import cn from 'classnames';
import { Controller, FieldValues } from 'react-hook-form';
import { Field } from 'shared/components/Form/Field/Field';
import { TFormCheckboxProps, TRenderInputProps } from 'shared/components/Form/types';
import { Icon } from 'shared/components/Icon/Icon';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';

import formCommonStyles from '../FormCommonStyles.module.css';
import styles from './Checkbox.module.css';

export const Checkbox = <T extends FieldValues>(props: TFormCheckboxProps<T>) => {
  const {
    control,
    fieldName,
    className,
    containerClassName,
    labelClassName,
    rules,
    label,
    size = 's',
    checkboxLabel,
  } = props;

  const renderInput = (renderProps: TRenderInputProps<T>) => {
    const {
      field,
      fieldState: { invalid, error },
    } = renderProps;
    const inputClass = cn(formCommonStyles.formControl, styles.checkbox, {
      [formCommonStyles.invalid]: invalid,
    });

    return (
      <Field
        className={cn(className, size === 's' ? styles.small : styles.medium)}
        error={error}
        label={label}
      >
        <label className={cn(styles.container, containerClassName)}>
          <input
            {...field}
            checked={toBoolean(field.value)}
            className={styles.input}
            type="checkbox"
            value={toString(toBoolean(field.value))}
          />
          <div className={inputClass}>
            {toBoolean(field.value) && <Icon kind="checkmark" size="s" />}
          </div>
          <span className={cn(styles.label, labelClassName)}>{checkboxLabel}</span>
        </label>
      </Field>
    );
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={renderInput}
      rules={rules}
    />
  );
};
