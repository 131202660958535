import { fetchCountries } from 'entities/countries/api';
import { countriesQueryKeys } from 'entities/countries/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export const useFetchCountriesQuery = (defaultPagination: TPaginatedParameters) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [countriesQueryKeys.countries, pagination],
      queryFn: () => fetchCountries({ params: pagination }),
    }),
    defaultPagination,
  );
