import { TPlayerCareer } from 'entities/playerCareers/types';
import { PlayerCareerModal } from 'feature/playerCareerModal/PlayerCareerModal';
import { usePlayerCareersActions } from 'pages/player/careers/PlayerCareersPage/hooks/usePlayerCareersActions';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';

import styles from './PlayerCareersActions.module.css';

export const PlayerCareersActions = (props: TTableActions<TPlayerCareer>) => {
  const { t } = useTranslation();
  const {
    actions,
    isDeleteModalOpen,
    isDeletingPlayerCareer,
    isActive,
    onIconClick,
    handleConfirmDeletion,
    handleCloseDeleteModal,
    editCareerModalProps,
    itemId,
  } = usePlayerCareersActions(props);
  return (
    <>
      <Actions
        actions={actions}
        className={styles.dropdown}
        isLoading={isDeletingPlayerCareer}
        isOpen={isActive}
        itemId={itemId}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        description={t('content.deleteWarning')}
        isOpened={isDeleteModalOpen}
        title={t('content.deleteCareer')}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeletion}
      />
      <PlayerCareerModal {...editCareerModalProps} />
    </>
  );
};
