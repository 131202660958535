import { useEffect } from 'react';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

type TUseRightPanelImportance = {
  sliderImage?: File;
  onChange: (base64: string) => void;
};

export const useRightPanelImportance = ({
  sliderImage,
  onChange,
}: TUseRightPanelImportance) => {
  useEffect(() => {
    getBase64FromFile(sliderImage).then((data) => {
      onChange(data);
    });
  }, [sliderImage]);
};
