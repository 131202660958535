import { random, range } from 'lodash';
import { useEffect, useState } from 'react';
import { Loader } from 'shared/components/Loader/Loader';
import { Text } from 'shared/components/Text/Text';

import styles from '../UIKit.module.css';

const loadingRange = range(0, 100, random(5, 20));

export const LoaderExample = () => {
  const [loadingStateIndex, setLoadingStateIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingStateIndex((prevIndex) => {
        let nextIndex = prevIndex + 1;
        if (nextIndex === loadingRange.length) {
          nextIndex = 0;
        }
        return nextIndex;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Text size={24} tag="h2">
        Loading State
      </Text>
      <div className={styles.row}>
        <Loader showLabel size="xs" />
        <Loader size="xs" />
        <Loader showLabel size="s" />
        <Loader size="s" />
        <Loader showLabel size="m" />
        <Loader size="m" />
        <Loader showLabel size="l" />
        <Loader size="l" />
        <Loader percent={loadingRange[loadingStateIndex]} showLabel size="l" />
        <Loader percent={loadingRange[loadingStateIndex]} size="l" />
      </div>
    </div>
  );
};
