import { TTokenStore } from 'shared/api/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useTokenStore = create<TTokenStore>()(
  persist(
    (set) => ({
      token: undefined,
      setToken: (token) => set({ token }),
      resetToken: () => set({ token: undefined }),
    }),
    { name: 'token' },
  ),
);

export const useToken = () => useTokenStore((state) => state.token);

export const getTokenState = useTokenStore.getState;
