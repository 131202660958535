import { createDefaultElement } from 'shared/components/Editor/helpers';
import {
  EditorElementTypeEnum,
  TEditorImage,
  TEditorImageElement,
} from 'shared/components/Editor/types';

export const createImageElement = (file: TEditorImage) =>
  ({
    type: EditorElementTypeEnum.Image,
    file,
    children: createDefaultElement(''),
  }) as TEditorImageElement;
