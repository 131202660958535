import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createNewsCategory,
  deleteNewsCategory,
  editNewsCategory,
} from 'entities/newsCategories/api';
import { newsCategoriesQueryKeys } from 'entities/newsCategories/consts';

export const useCreateNewsCategoryMutation = () => {
  return useMutation({
    mutationFn: createNewsCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([newsCategoriesQueryKeys.newsCategories]);
    },
  });
};

export const useDeleteNewsCategoryMutation = () => {
  return useMutation({
    mutationFn: deleteNewsCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([newsCategoriesQueryKeys.newsCategories]);
    },
  });
};

export const useEditNewsCategoryMutation = () => {
  return useMutation({
    mutationFn: editNewsCategory,
    onSuccess: () => {
      return queryClient.invalidateQueries([newsCategoriesQueryKeys.newsCategories]);
    },
  });
};
