import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { UploadAvatar } from 'shared/components/UploadAvatar/UploadAvatar';
import { getImageSettings } from 'shared/constants';
import { TPlayerFormFields } from 'widgets/playerForm/types/types';
import styles from 'widgets/playerForm/ui/PlayerForm/PlayerForm.module.css';
type TProps = {
  control: Control<TPlayerFormFields>;
};
export const PlayerFormPreview = ({ control }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const firstName = useWatch({
    control,
    name: 'firstName',
  });
  const lastName = useWatch({
    control,
    name: 'lastName',
  });
  const id = useWatch({
    control,
    name: 'id',
  });
  return (
    <div className={cn(styles.preview, styles.baseWidth)}>
      <Controller
        control={control}
        name="photo"
        render={({ field }) => (
          <UploadAvatar
            aspectRatio={getImageSettings(t).player.avatar.aspectRatio}
            avatar={field.value}
            onLoadAvatar={field.onChange}
          />
        )}
      />
      <div className={styles.info}>
        <div className={styles.name}>
          <Text color="var(--gray-550)" size={16} weight="SemiBold">
            {firstName[defaultLocale] || t('content.nameTitle')}
          </Text>
          <Text color="var(--gray-550)" size={16} weight="SemiBold">
            {lastName[defaultLocale] || t('content.secondNameTitle')}
          </Text>
        </div>
        {id ? <Text color="var(--gray-450)">ID: {id}</Text> : null}
      </div>
    </div>
  );
};
