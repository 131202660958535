import { PromisePool } from '@supercharge/promise-pool';

type TProps<T> = {
  items: T[];
  func: (i: T) => Promise<unknown>;
  limit: number;
  onEachLimit?: () => void;
};

export const promisePool = async <T>({
  onEachLimit,
  limit,
  func,
  items,
}: TProps<T>) =>
  await PromisePool.withConcurrency(limit)
    .for(items)
    .onTaskFinished((task, pool) => {
      if (pool.processedCount() % limit === 0) {
        onEachLimit?.();
      }
    })
    .process(func);
