import { OpponentPlayerModal } from 'feature/opponentPlayerModal/OpponentPlayerModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

import styles from '../UIKit.module.css';

export const OpponentPlayerModalExample = () => {
  const { t } = useTranslation();
  const { openedModal, closeModal, openModal } = useModal('');

  return (
    <div className={styles.root}>
      <Button onClick={openModal}>{t('content.opponentTeams.openModal')}</Button>
      <OpponentPlayerModal
        actionSubmit={(values) => notify(JSON.stringify(values, undefined, 2))}
        closeModal={closeModal}
        isLoading={false}
        isOpened={openedModal}
        title={t('content.opponentTeams.createPlayer')}
      />
    </div>
  );
};
