import { parseISO } from 'date-fns';
import { TSeason } from 'entities/seasons/types';

export const getStartSeasonYear = (season: TSeason) =>
  parseISO(season.startAt).getFullYear();

export const getEndSeasonYear = (season: TSeason) =>
  parseISO(season.endAt).getFullYear();

export const getOldestSeasonYear = (seasons: TSeason[]) => {
  let oldestSeasonYear = Number.MAX_SAFE_INTEGER;

  seasons.forEach((season) => {
    const year = getStartSeasonYear(season);

    if (year < oldestSeasonYear) {
      oldestSeasonYear = year;
    }
  });

  return oldestSeasonYear;
};

export const getNewestSeasonYear = (seasons: TSeason[]) => {
  let newestSeasonYear = 0;

  seasons.forEach((season) => {
    const year = getEndSeasonYear(season);

    if (year > newestSeasonYear) {
      newestSeasonYear = year;
    }
  });

  return newestSeasonYear;
};
