import { StoryEditorDispatchContext } from 'pages/stories/edit/EditStory/context';
import { useContext } from 'react';

export const useStoryEditorDispatchContext = () => {
  const context = useContext(StoryEditorDispatchContext);
  if (context === undefined) {
    throw new Error(
      'context is undefined. check it is being used within its provider',
    );
  }
  return context;
};
