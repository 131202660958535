import { CreateManagerPage } from 'pages/manager/create/CreateManagerPage/CreateManagerPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const CreateRoute: TProtectedRoute = {
  path: routes.createManager.url,
  element: <CreateManagerPage />,
  roles: DEFAULT_ROUTER_ROLES,
};
