export const declination = (
  number: number,
  txt: string[],
  cases = [2, 0, 1, 1, 1, 2],
) => {
  const positiveNumber = Math.abs(number);
  return txt[
    positiveNumber % 100 > 4 && positiveNumber % 100 < 20
      ? 2
      : cases[positiveNumber % 10 < 5 ? positiveNumber % 10 : 5]
  ];
};
