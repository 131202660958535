import axios from 'axios';
import { useCreateNewsPostMutation } from 'entities/news/mutations';
import { TAddNewsPostFields } from 'shared/components/NewsPostForm/types';
import { notify } from 'shared/components/Notification';
import { getDefaultErrorMessage, getDefaultErrorTitle } from 'shared/validation';

export const useCreateNewsPost = () => {
  const { mutateAsync } = useCreateNewsPostMutation();

  return async (data: TAddNewsPostFields) => {
    try {
      await mutateAsync(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notify(getDefaultErrorMessage(), {
          type: 'error',
          title: getDefaultErrorTitle(),
        });
      }
    }
  };
};
