import { PlaceholderProps } from 'react-select';
import { TOption } from 'shared/components/Form/NewSelect/types';

type TProps = PlaceholderProps<TOption>;

export const Placeholder = (props: TProps) => {
  const { getClassNames, innerProps, children, hasValue, selectProps } = props;

  if (hasValue || selectProps.menuIsOpen) {
    return null;
  }

  return (
    <div className={getClassNames('placeholder', props)} {...innerProps}>
      {children}
    </div>
  );
};
