type TCardBase = {
  onDelete: () => void;
  onEdit: () => void;
  onClick?: () => void;
};

export enum ECardType {
  Player = 'player',
  Opponent = 'opponent',
  Manager = 'manager',
  Team = 'team',
}

type TCardTeamProps = {
  picture: string;
  name: string;
};

type TCardManagerProps = TCardTeamProps & {
  lastName: string;
  position: string;
};
type TCardOpponentProps = TCardTeamProps & {
  lastName: string;
  number: string;
};

type TCardPlayerProps = TCardManagerProps & {
  number: number;
};

type TCardPlayerInformation = {
  type: ECardType.Player;
  entity: TCardPlayerProps;
};

type TCardManagerInformation = {
  type: ECardType.Manager;
  entity: TCardManagerProps;
};

type TCardTeamInformation = {
  type: ECardType.Team;
  entity: TCardTeamProps;
};

type TCardOpponentInformation = {
  type: ECardType.Opponent;
  entity: TCardOpponentProps;
};

export type TCardInformation =
  | TCardPlayerInformation
  | TCardManagerInformation
  | TCardTeamInformation
  | TCardOpponentInformation;

export type TCardProps = TCardBase & TCardInformation;
