import cn from 'classnames';

import styles from './Divider.module.css';

type TProps = {
  className?: string;
  variant?: 'horizontal' | 'vertical';
};

export const Divider = ({ variant = 'horizontal', className }: TProps) => (
  <div className={cn(styles[variant], className)} />
);
