import { TPhotoGalleryFilter } from 'pages/photogallery/PhotoGalleryPage/types';

export const createPhotoGalleryFilterQuery = (
  filter: TPhotoGalleryFilter | null,
) => {
  if (!filter) {
    return {};
  }

  const filterQuery: Record<string, string> = {};

  Object.entries(filter).forEach(([key, filterValue]) => {
    filterQuery[`filter[${key}][overlap]`] = filterValue.join(',');
  });

  return filterQuery;
};
