import { TFunction } from 'i18next';

export const DNDItemTypes = {
  PARAGRAPH: 'paragraph',
  STORY_ELEMENT: 'storyElement',
  STORY_GROUP_IMAGE: 'storyGroupImage',
  LAYER_ITEM: 'layerItem',
} as const;

export const TODAY = new Date();

export const availableExtensions = ['png', 'jpg', 'jpeg', 'webp'];

export const allowedVideoFormats = ['.mp4'];

export const getDefaultImageRequirementText = (t: TFunction) =>
  t('shared.defaultImageRequirementText', { mark: '.' });

export const MODAL_CLASS_NAME = 'modal-container';

export const isProduction = process.env.APP_ENV === 'production';

export enum LocalStorageKeys {
  swatches = 'swatches',
  backendSandbox = 'backendSandbox',
  token = 'token',
  locale = 'locale',
}

export const secureProtocol = 'https://';

export const w3 = 'www.';

export const videoFormatRegex = /\.\S{1,3}$/;

export const getImageSettings = (t: TFunction) => ({
  stories: {
    preview: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload', {
        formats: 'JPEG, JPG, PNG, WebP',
        size: '1:1',
        mark: '.',
      }),
    },
    background: {
      aspectRatio: 9 / 16,
      hint: t('shared.imageRequirementText.warningUpload', {
        formats: 'JPEG, JPG, PNG, WebP',
        size: '9:16',
        mark: '.',
      }),
    },
  },
  playerCareer: {
    teamPicture: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload', {
        formats: 'JPEG, JPG, PNG, WebP',
        size: '1:1',
        mark: '.',
      }),
    },
  },
  managerCareer: {
    teamPicture: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload', {
        formats: 'JPEG, JPG, PNG, WebP',
        size: '1:1',
        mark: '.',
      }),
    },
  },
  news: {
    preview: {
      aspectRatio: 3 / 4,
    },
    cover: {
      aspectRatio: 5 / 7,
    },
    slider: {
      aspectRatio: 9 / 16,
    },
    paragraph: {
      image: {
        aspectRatio: 6 / 7,
      },
      slider: {
        aspectRatio: 6 / 7,
      },
    },
  },
  video: {
    preview: {
      aspectRatio: 16 / 9,
    },
    importance: {
      aspectRatio: 3 / 4,
    },
  },
  opponentPlayer: {
    picture: {
      aspectRatio: 1 / 1,
      hint: t(
        'shared.imageRequirementText.warningUpload.transparentBackgroundWithoutRatio',
        {
          aspectRatio: '1:1',
          mark: '.',
        },
      ),
    },
  },
  player: {
    avatar: {
      aspectRatio: 9 / 16,
    },
    picture: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload.transparentBackground', {
        aspectRatio: '1:1',
        mark: '.',
      }),
    },
  },
  team: {
    picture: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload.transparentBackground', {
        aspectRatio: '1:1',
        mark: '.',
      }),
    },
  },
  opponentTeam: {
    picture: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload.transparentBackground', {
        aspectRatio: '1:1',
        mark: '.',
      }),
    },
  },
  manager: {
    avatar: {
      aspectRatio: 9 / 16,
    },
    picture: {
      aspectRatio: 1 / 1,
      hint: t('shared.imageRequirementText.warningUpload.transparentBackground', {
        aspectRatio: '1:1',
        mark: '.',
      }),
    },
  },
  photoGallery: {
    preview: {
      aspectRatio: 16 / 9,
    },
  },
});
