import { TPhotoGallery } from 'entities/photoGallery/types';
import { usePhotoGalleryActions } from 'pages/photogallery/PhotoGalleryPage/hooks/usePhotoGalleryActions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Actions } from 'shared/components/Actions/Actions';
import styles from 'shared/components/Actions/Actions.module.css';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { routes } from 'shared/routes';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const PhotoGalleryActions = ({
  isActive,
  onIconClick,
  closeActiveItemId,
  item: photoGallery,
}: TTableActions<TPhotoGallery>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    showConfirmModal,
    setShowConfirmModal,
    deletePhotoGalleryHandler,
    onConfirm,
    isLoading,
  } = usePhotoGalleryActions({
    closeActiveItemId,
  });
  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: (id) => {
        navigate?.(routes.editPhotoGallery.as(id));
      },
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: deletePhotoGalleryHandler,
    },
  ];
  const closeDeleteModalHandler = useCallback(() => {
    closeActiveItemId();
    setShowConfirmModal(false);
  }, []);
  const confirmDeleteHandler = async () => {
    await onConfirm(photoGallery.id);
  };
  return (
    <>
      <Actions
        actions={actions}
        className={styles.dropdown}
        isLoading={isLoading}
        isOpen={isActive}
        itemId={photoGallery.id}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        description={t('content.photoGallery.confirmDelete.description')}
        isLoading={isLoading}
        isOpened={showConfirmModal}
        title={t('content.photoGallery.confirmDelete.title')}
        onClose={closeDeleteModalHandler}
        onConfirm={confirmDeleteHandler}
      />
    </>
  );
};
