import { tabNames } from 'feature/rightPanel/constants';
import { TUseRightPanel } from 'feature/rightPanel/hooks/useRightPanel';
import { TTabName } from 'feature/rightPanel/types';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

const initialActiveTabs = [tabNames.preview];

export const useRightPanelTabs = <T extends FieldValues>({
  preview,
  label,
  connections,
  review,
  importance,
  sharingLink,
  hasPreview,
}: TUseRightPanel<T>) => {
  const [activeTab, setActiveTab] = useState<TTabName[]>(initialActiveTabs);

  const orderedTabs = useMemo(() => {
    return [
      preview && tabNames.preview,
      label && tabNames.label,
      connections && tabNames.connected,
      review && tabNames.review,
      importance && tabNames.importance,
      sharingLink && tabNames.sharingLink,
    ].filter(Boolean) as TTabName[];
  }, [preview, label, connections, review, importance, sharingLink]);

  const toggleTab = (tab: TTabName) => {
    setActiveTab((tabs) =>
      tabs.includes(tab) ? tabs.filter((item) => item !== tab) : [...tabs, tab],
    );
  };

  const isActiveTab = (tab: TTabName) => activeTab.includes(tab);
  const isLastTab = (tab: TTabName) =>
    orderedTabs.indexOf(tab) === orderedTabs.length - 1;

  const onClickNext = (currentTab: TTabName) => {
    const currentIndex = orderedTabs.indexOf(currentTab);
    if (currentIndex !== -1) {
      const nextTab = orderedTabs[currentIndex + 1];
      setActiveTab((tabs) => [...tabs.filter((tab) => tab !== currentTab), nextTab]);
    }
  };

  useEffect(() => {
    if (!hasPreview && activeTab.length > 1) {
      setActiveTab(initialActiveTabs);
    }
  }, [hasPreview]);

  return {
    toggleTab,
    isActiveTab,
    isLastTab,
    onClickNext,
  };
};
