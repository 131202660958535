import { queryClient } from 'app/Providers';
import { photoGalleryImagesQueryKeys } from 'entities/photoGalleryImages/consts';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { getUploadImageModalErrorMessage } from 'shared/components/ImageUploadModal/helpers';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { generateUUID } from 'shared/lib/generateUUID';
import { promisePool } from 'shared/lib/promisePool';
import { useCreatePhotoGalleryImage } from 'widgets/photoGalleryForm/ui/hooks/useCreatePhotoGalleryImage';
import {
  TImagesToUpload,
  TImageToUpload,
} from 'widgets/photoGalleryForm/ui/PhotoGalleryFormDropzone/types';

const initImagesToUpload: TImagesToUpload = new Map();

type TDropHandler = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent,
) => void;

export const usePhotoGalleryImageUploading = (maxFiles: number) => {
  const { openedUploadModal, openUploadModal, closeUploadModal } =
    useModal('Upload');

  const [imagesToUpload, setImagesToUpload] =
    useState<TImagesToUpload>(initImagesToUpload);
  const createImage = useCreatePhotoGalleryImage();

  const addImageToUpload = useCallback(
    (id: string, file: File, abortController: AbortController) => {
      setImagesToUpload((prev) => {
        const newMap = new Map(prev);
        newMap.set(id, {
          abortController,
          status: 'uploading',
          file,
        });
        return newMap;
      });
    },
    [],
  );

  const updateImageStatus = useCallback(
    (id: string, status: 'uploading' | 'success' | 'error' | 'cancelled') => {
      setImagesToUpload((prev) => {
        const newMap = new Map(prev);
        const item = newMap.get(id);
        if (item) {
          newMap.set(id, { ...item, status });
        }
        return newMap;
      });
    },
    [],
  );

  const removeImageToUpload = useCallback((id: string) => {
    setImagesToUpload((prev) => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
  }, []);

  const removeIdFromSet = useCallback(
    (setState: Dispatch<SetStateAction<Set<string>>>, id: string) => {
      setState((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    },
    [],
  );

  const uploadSingleFile = useCallback(
    async ({
      file,
      id,
      abortController,
    }: {
      file: File;
      id: string;
      abortController: AbortController;
    }) => {
      try {
        await createImage({
          image: file,
          signal: abortController.signal,
        });
        updateImageStatus(id, 'success');
      } catch (error) {
        if (error instanceof Error) {
          const isAborted =
            error.name === 'AbortError' || error.message === 'canceled';
          updateImageStatus(id, isAborted ? 'cancelled' : 'error');
        } else {
          updateImageStatus(id, 'error');
        }
      }
    },
    [createImage, removeIdFromSet, updateImageStatus],
  );

  const onDrop: TDropHandler = useCallback(
    async (files, fileRejections) => {
      if (fileRejections.length) {
        const errorMessages = getUploadImageModalErrorMessage(
          fileRejections,
          maxFiles,
        );

        notify(errorMessages, { type: 'error' });
        return;
      }

      openUploadModal();

      const mappedFiles = files.map((file) => {
        const abortController = new AbortController();
        const id = generateUUID();

        addImageToUpload(id, file, abortController);

        return { file, id, abortController };
      });

      await promisePool({
        func: uploadSingleFile,
        limit: 5,
        items: mappedFiles,
        onEachLimit: debouncedUpdateImages,
      });
      debouncedUpdateImages();
    },
    [
      imagesToUpload.size,
      addImageToUpload,
      maxFiles,
      openUploadModal,
      uploadSingleFile,
    ],
  );

  const onDeleteUploading = useCallback(
    (id: string) => {
      removeImageToUpload(id);
    },
    [removeImageToUpload, removeIdFromSet],
  );

  const uploadingImages = useMemo(
    () => Array.from(imagesToUpload.entries()),
    [imagesToUpload],
  );

  const totalUploading = useMemo(
    () => calculateLoadingImages(uploadingImages),
    [uploadingImages],
  );

  const confirmCloseUploadModal = useCallback(() => {
    setImagesToUpload(initImagesToUpload);
    closeUploadModal();
  }, [closeUploadModal, setImagesToUpload]);

  const cancelAllUploading = useCallback(() => {
    uploadingImages.forEach(([_, image]) => {
      if (image.status === 'uploading') {
        image.abortController.abort();
      }
    });
  }, [uploadingImages]);

  return {
    actions: {
      onDeleteUploading,
      confirmCloseUploadModal,
      cancelAllUploading,
    },
    images: {
      totalUploading,
      uploadingImages,
    },
    openedUploadModal,
    onDrop,
  };
};

// Так как на бекенде загруженное изображение конвертируется в другие форматы асинхронно, необходимо добавлять небольшую задержку на запрос списка изображений
const debouncedUpdateImages = debounce(updateImages, 1000);

function updateImages() {
  queryClient.invalidateQueries([photoGalleryImagesQueryKeys.photoGalleryImages]);
}

function calculateLoadingImages(images: [string, TImageToUpload][]) {
  return images.reduce((acc, [_, image]) => {
    if (image.status === 'uploading') {
      acc++;
    }

    return acc;
  }, 0);
}
