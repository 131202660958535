import { AuthForm } from 'feature/auth/ui/AuthForm/AuthForm';
import crespoPresentation from 'shared/assets/images/crespoPresentation.jpg';

import styles from './AuthPage.module.css';

export const AuthPage = () => (
  <div className={styles.root}>
    <AuthForm />
    <img
      alt="Crespo Presentation"
      className={styles.image}
      src={crespoPresentation}
    />
  </div>
);
