import { useDeletePlayerCareerMutation } from 'entities/playerCareers/queries';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerCareer = () => {
  const { t } = useTranslation();
  const { mutate: deletePlayerCareerMutation, isLoading } =
    useDeletePlayerCareerMutation();

  const deletePlayerCareer = (activeCareerId: TEntityId) => {
    deletePlayerCareerMutation(activeCareerId, {
      onError: () =>
        notify(t('content.player.notifyNotDeleteCareer'), {
          type: 'error',
        }),
    });
  };

  return {
    isDeletingPlayerCareer: isLoading,
    deletePlayerCareer,
  };
};
