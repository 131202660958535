import { EPlayerStatus } from 'entities/players/model/types';
import i18n from 'i18next';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { TPlayerFormFields } from 'widgets/playerForm/types/types';
import styles from 'widgets/playerForm/ui/PlayerForm/PlayerForm.module.css';
type TProps = {
  control: Control<TPlayerFormFields>;
};
const getPlayerStatusLabels = () => ({
  [EPlayerStatus.PartOfTeam]: i18n.t('content.player.playerTeam'),
  [EPlayerStatus.Outstaff]: i18n.t('content.player.invitedPlayer'),
});
export const PlayerFormStatus = ({ control }: TProps) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="status"
      render={({ field }) => (
        <Field label={t('content.status')} required>
          <div className={styles.status}>
            {Object.values(EPlayerStatus).map((status) => (
              <RadioButton
                key={status}
                checked={field.value === status}
                label={getPlayerStatusLabels()[status]}
                name={field.name}
                size="s"
                value={status}
                onChange={(n, value) => {
                  field.onChange(value);
                }}
              />
            ))}
          </div>
        </Field>
      )}
    />
  );
};
