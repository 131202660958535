import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchManagerQuery } from 'entities/managers/api/queries';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mapManagerToManagerFormFields } from 'widgets/managerForm/mapper/mapManagerToManagerFormFields';
import { TManagerFormFields } from 'widgets/managerForm/types';

export const useLoadManagerForEdit = () => {
  const [isLoadingPrepare, setIsLoadingPrepare] = useState(true);
  const [managerFormFields, setManagerFormFields] = useState<
    TManagerFormFields | undefined
  >(undefined);

  const { locales, defaultLocale } = useLocales();

  const { managerId } = useParams<{
    managerId: string;
  }>();

  const {
    isLoading,
    data: manager,
    status,
  } = useFetchManagerQuery(managerId, { cacheTime: 0 });

  useLayoutEffect(() => {
    if (manager && status === 'success') {
      mapManagerToManagerFormFields(manager, locales, defaultLocale)
        .then((managerFormFields) => {
          setManagerFormFields(managerFormFields);
        })
        .finally(() => setIsLoadingPrepare(false));
    }

    if (status === 'error') {
      setIsLoadingPrepare(false);
    }
  }, [status]);

  return {
    managerFormFields,
    isLoading: isLoading || isLoadingPrepare,
  };
};
