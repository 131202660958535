import { photoGalleryCategoriesQueryKeys } from 'entities/photoGalleryCategories/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

import { fetchPhotoGalleryCategories } from './api';

export const useFetchPhotoGalleryCategoriesQuery = (
  defaultPagination?: TPaginatedParameters,
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [photoGalleryCategoriesQueryKeys.photoGalleryCategories, pagination],
      queryFn: () =>
        fetchPhotoGalleryCategories({ params: { ...pagination, sort: 'name' } }),
      cacheTime: 0,
    }),
    defaultPagination,
  );
