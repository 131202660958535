import cn from 'classnames';
import { forwardRef } from 'react';
import { BaseButton } from 'shared/components/BaseButton';
import { TIconType } from 'shared/components/Icon/types';
import {
  IconButton,
  TIconButtonProps,
} from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';

import styles from './ToolbarButton.module.css';

type TBaseProps = {
  active?: boolean;
  type?: 'text' | 'icon';
  icon?: TIconType;
  text?: string;
} & Pick<TIconButtonProps, 'onClick' | 'disabled'>;

export const ToolbarButton = forwardRef<HTMLButtonElement, TBaseProps>(
  ({ active, type = 'icon', icon, text, ...props }, ref) => {
    if (icon && type === 'icon') {
      return (
        <IconButton
          icon={icon}
          iconSize="m"
          ref={ref}
          className={cn(styles.action, {
            [styles.actionActive]: active,
          })}
          {...props}
        />
      );
    } else {
      return (
        <BaseButton
          ref={ref}
          className={cn(styles.action, styles.textAction, {
            [styles.actionActive]: active,
          })}
          {...props}
        >
          <Text size={14} weight="SemiBold">
            {text}
          </Text>
        </BaseButton>
      );
    }
  },
);
