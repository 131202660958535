import { queryClient } from 'app/Providers';
import { useLocales } from 'entities/config/lib/useLocales';
import { managerCareersQueryKeys } from 'entities/managerCareers/api/consts';
import { useEditManagerCareerMutation } from 'entities/managerCareers/api/mutations';
import { mapCreateManagerCareerDTO } from 'entities/managerCareers/mapper/mapCreateManagerCareerDTO';
import { TManagerCareer } from 'entities/managerCareers/model/types';
import { TManagerCareerModalFields } from 'feature/managerCareerModal/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { notify } from 'shared/components/Notification';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';

export const useEditManagerCareer = (career: TManagerCareer) => {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { managerId = '' } = useParams<{
    teamId: string;
    managerId: string;
  }>();
  const [careerModal, setCareerModal] = useState<TManagerCareerModalFields>();

  const openEditModal = async () => {
    const teamPicture = await getFileFromUrl(career.teamPicture.x3);
    setCareerModal({
      season: career.season,
      teamPicture,
      translations: career.translations,
    });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => setIsEditModalOpen(false);

  const { mutateAsync: editCareerMutation, isLoading } =
    useEditManagerCareerMutation();

  const editCareerHandler = async (data: TManagerCareerModalFields) => {
    const editCareerDTO = await mapCreateManagerCareerDTO({
      data,
      locales,
      managerId,
    });

    await editCareerMutation(
      {
        id: career.id,
        ...editCareerDTO,
      },
      {
        onSuccess: () => {
          return queryClient.resetQueries([managerCareersQueryKeys.managerCareers]);
        },
        onError: () => {
          notify(t('content.manager.notifyNotEditCareer'), { type: 'error' });
        },
      },
    );
    setIsEditModalOpen(false);
  };

  return {
    openEditModal,
    editCareerModalProps: {
      title: t('content.editCareer'),
      closeModal: closeEditModal,
      actionSubmit: editCareerHandler,
      isOpened: isEditModalOpen,
      isLoading,
      initialValues: careerModal,
    },
  };
};
