import { useCanUser } from 'entities/user/lib/useCanUser';
import { TProtectedLink } from 'shared/types/protectedLinks';

export type TUseProtectedLinksReturn = <T extends TProtectedLink>(
  links: T[],
  isEveryPermissionProtected?: boolean,
) => T[];

/**
 * Хук, который возвращает callback для фильтрации ссылок на основе permissions.
 * @returns {TUseProtectedLinksReturn} - Функция, которая фильтрует массив ссылок на основе пользовательских разрешений.
 *
 * Аргументы функции:
 * * links - Массив ссылок для фильтрации.
 */
export const useProtectedLinks = (): TUseProtectedLinksReturn => {
  const canUser = useCanUser();

  return (links) => links.filter(({ roles }) => !roles || canUser(roles));
};
