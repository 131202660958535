import { playerCareersApiKeys } from 'entities/playerCareers/consts';
import {
  TPlayerCareer,
  TPlayerCreateCareerDTO,
  TPlayerEditCareerDTO,
} from 'entities/playerCareers/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchPlayerCareers = ({ params }: TFetchParams) =>
  get<TPlayerCareer[], TPaginatedMeta>(playerCareersApiKeys.getPlayerCareers, {
    params,
  }).then((response) => response.data);

export const deletePlayerCareer = (careerId: TEntityId) =>
  del(playerCareersApiKeys.deleteCareer(careerId)).then(
    (response) => response.data.data,
  );

export const createPlayerCareer = (data: TPlayerCreateCareerDTO) =>
  post<TPlayerCareer>(playerCareersApiKeys.createPlayerCareer, data).then(
    (response) => response.data.data,
  );

export const editPlayerCareer = ({ careerId, ...data }: TPlayerEditCareerDTO) =>
  patch<TPlayerCareer>(playerCareersApiKeys.editCareer(careerId), data).then(
    (response) => response.data.data,
  );
