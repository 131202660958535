import { noop } from 'lodash';
import {
  TModalContext,
  TStoryEditorActionType,
} from 'pages/stories/edit/EditStory/types';
import { TStoryEditorContext } from 'pages/stories/edit/EditStory/types';
import { createContext, Dispatch } from 'react';

export const StoryEditorDispatchContext = createContext<
  Dispatch<TStoryEditorActionType> | undefined
>(undefined);

export const StoryEditorContext = createContext<TStoryEditorContext | undefined>(
  undefined,
);

export const ModalContext = createContext<TModalContext>({
  openedModal: null,
  openModal: noop,
});
