import { useMutation } from '@tanstack/react-query';
import {
  createManagerCareer,
  deleteManagerCareer,
  editManagerCareer,
} from 'entities/managerCareers/api/api';

export const useDeleteManagerCareerMutation = () => {
  return useMutation({
    mutationFn: deleteManagerCareer,
  });
};

export const useCreateManagerCareerMutation = () => {
  return useMutation({
    mutationFn: createManagerCareer,
  });
};

export const useEditManagerCareerMutation = () =>
  useMutation({
    mutationFn: editManagerCareer,
  });
