import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import cn from 'classnames';
import { forwardRef, HTMLProps } from 'react';
import styles from 'shared/components/Tooltip/Tooltip.module.css';
import { useTooltipContext } from 'shared/components/Tooltip/TooltipContext';

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ style, className, ...props }, propRef) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) {
      return null;
    }

    return (
      <FloatingPortal>
        <div
          className={cn(styles.content, className)}
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...style,
          }}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  },
);
