import JSONAPISerializer from 'json-api-serializer';
import { JSONApiSerializer } from 'shared/api/jsonApi/JSONApiSerializer';

export function registerJsonApiDocument({
  relationships,
  type,
}: JSONAPISerializer.ResourceObject<unknown>) {
  registerJsonApiType(type);
  registerJsonApiRelationshipTypes(relationships);
}

function registerJsonApiRelationshipTypes(
  relationships: { [p: string]: JSONAPISerializer.Relationship } | undefined,
) {
  for (const key in relationships) {
    const value = relationships[key];

    if (value.data) {
      const type = Array.isArray(value.data) ? value.data[0]?.type : value.data.type;

      registerJsonApiType(type);
    }
  }
}

const entitiesTypes = new Set();
function registerJsonApiType(type: string | undefined) {
  if (type && !entitiesTypes.has(type)) {
    JSONApiSerializer.register(type);
    entitiesTypes.add(type);
  }
}
