import { useLocales } from 'entities/config/lib/useLocales';
import { useRef, useState } from 'react';
import { TEditor, TEditorImage } from 'shared/components/Editor/types';
import { editorSliderController } from 'shared/components/Editor/utils/slider/editorSliderController';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { toBoolean } from 'shared/lib/toBoolean';
import { Editor } from 'slate';

export const useWorkspaceSliderContext = (editor: TEditor) => {
  const { defaultLocale } = useLocales();
  const changeSliderImagesPath = useRef([] as number[]);
  const [isOpenSliderImagesModal, setIsOpenSliderImagesModal] = useState(false);
  const [isOpenCropSliderImageModal, setIsOpenCropSliderImageModal] =
    useState(false);

  const croppedSliderImagePath = useRef<{
    path: number[];
    index: number;
  }>({
    path: [],
    index: 0,
  });

  const insertSlider = (urls: TEditorImage[]) => {
    editorSliderController.insertSlider(editor, urls, defaultLocale);
  };

  const changeSlider = (urls: TEditorImage[], path: number[]) => {
    editorSliderController.changeSlider(editor, path, urls);
  };

  const openSliderImagesModal = () => {
    setIsOpenSliderImagesModal(true);
  };

  const closeSliderImagesModal = () => {
    setIsOpenSliderImagesModal(false);
    changeSliderImagesPath.current = [];
  };

  const loadSliderImages = async (inputFiles: File[]) => {
    const files = await Promise.all(
      inputFiles.map(async (file) => ({
        filename: file.name,
        url: await getBase64FromFile(file),
      })),
    );

    if (changeSliderImagesPath.current.length) {
      changeSlider(files, changeSliderImagesPath.current);
      changeSliderImagesPath.current = [];
    } else {
      insertSlider(files);
    }
    closeSliderImagesModal();
  };

  const openChangeSliderImagesModal = (path: number[]) => {
    changeSliderImagesPath.current = path;
    openSliderImagesModal();
  };

  const getSliderElementFromPath = () => {
    if (!croppedSliderImagePath.current.path.length) {
      return null;
    }

    const nodeEntry = Editor.node(editor, croppedSliderImagePath.current.path);
    const sliderElement = nodeEntry[0];

    if (!editorSliderController.isSliderElement(sliderElement)) {
      return null;
    }

    return sliderElement;
  };

  const getCroppingSliderImageUrl = () => {
    const sliderElement = getSliderElementFromPath();

    if (!sliderElement) {
      return null;
    }

    return sliderElement.files[croppedSliderImagePath.current.index].url;
  };

  const replaceCroppedSliderImage = (url: string) => {
    const sliderElement = getSliderElementFromPath();
    if (!sliderElement) {
      return;
    }

    changeSlider(
      sliderElement.files.map((file, index) => {
        if (croppedSliderImagePath.current.index === index) {
          return {
            url,
            filename: file.filename,
          };
        }

        return file;
      }),
      croppedSliderImagePath.current.path,
    );
    closeCropSliderImageModal();
  };

  const openCropSliderImageModal = (path: number[], index: number) => {
    croppedSliderImagePath.current = {
      index,
      path,
    };
    setIsOpenCropSliderImageModal(true);
  };

  const closeCropSliderImageModal = () => {
    setIsOpenCropSliderImageModal(false);
    croppedSliderImagePath.current = {
      index: 0,
      path: [],
    };
  };

  return {
    replaceCroppedSliderImage,
    croppingSliderImageUrl: getCroppingSliderImageUrl(),
    isOpenCropSliderImageModal,
    closeCropSliderImageModal,
    isOpenSliderImagesModal,
    openSliderImagesModal,
    closeSliderImagesModal,
    openCropSliderImageModal,
    openChangeSliderImagesModal,
    isEditSlider: toBoolean(changeSliderImagesPath.current.length),
    loadSliderImages,
  };
};

export type TWorkspaceSliderContext = ReturnType<typeof useWorkspaceSliderContext>;
