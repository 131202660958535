import { useLocales } from 'entities/config/lib/useLocales';
import { TNewsPost } from 'entities/news/types';
import i18n from 'i18next';
import { Badge } from 'shared/components/Badge/Badge';
import { Icon } from 'shared/components/Icon/Icon';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './NewsTable.module.css';

export const useNewsColumnsConfig: TConfigCreator<TNewsPost> = () => {
  const { defaultLocale } = useLocales();

  return [
    {
      title: i18n.t('content.public'),
      headClassName: styles.publicationNameColumn,
      render: (item) => (
        <div className={styles.publicationNameColumn}>
          {item.pictures?.base ? (
            <div className={styles.previewWrapper}>
              <img
                alt={`${item.title} preview`}
                className={styles.preview}
                src={item.pictures.base.x1}
              />
            </div>
          ) : (
            <div className={styles.previewWrapper}>
              <Icon kind="picture" size="s" />
            </div>
          )}
          <Text className={styles.title}>{item.title}</Text>
        </div>
      ),
    },
    {
      title: i18n.t('content.category'),
      render: (item) => (
        <Text className={styles.categories}>
          {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
        </Text>
      ),
    },
    {
      title: i18n.t('content.createDate'),
      render: (item) => <DateTimeCell dateTimeString={item.createdAt ?? ''} />,
    },
    {
      title: i18n.t('content.importance'),
      render: (item) =>
        item.importance > 0 ? (
          <Badge
            color="green"
            content={i18n.t('content.slider')}
            size="s"
            type="outlined"
          />
        ) : null,
    },
    {
      title: i18n.t('content.status'),
      render: (item) => <Status status={item.status} />,
    },
    {
      title: i18n.t('content.publicDate'),
      render: (item) => <DateTimeCell dateTimeString={item?.published ?? ''} />,
    },

    {
      title: 'ID',
      render: (item) => item.id,
    },
  ];
};
