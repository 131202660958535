import { matchesApiKeys } from 'entities/matches/consts';
import { TCreateMatchDTO, TMatch } from 'entities/matches/types';
import { del, get, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchMatches = <WithIncludes extends boolean>({
  params,
}: TFetchParams) =>
  get<TMatch<WithIncludes>[], TPaginatedMeta>(matchesApiKeys.getMatches, {
    params: { ...params },
  }).then((response) => response.data);

export const fetchMatch = (id: TEntityId) =>
  get<TMatch>(matchesApiKeys.getMatch(id)).then((response) => response.data.data);

export const createMatch = (data: TCreateMatchDTO) =>
  post<TMatch>(matchesApiKeys.createMatch, {
    match: data,
  }).then((response) => response.data.data);

export const deleteMatch = (id: TEntityId) =>
  del(matchesApiKeys.deleteMatch(id)).then((response) => response.data.data);
