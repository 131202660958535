import { fetchManagerSocialNetworks } from 'entities/managerSocialNetworks/api';
import { managerSocialNetworksQueryKeys } from 'entities/managerSocialNetworks/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

type TFetchManagerSocialNetworksParams = {
  filter?: Record<string, string>;
};

export const useFetchManagerSocialNetworksQuery = ({
  filter,
}: TFetchManagerSocialNetworksParams) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [
      managerSocialNetworksQueryKeys.managerSocialNetworks,
      pagination,
      filter,
    ],
    queryFn: () =>
      fetchManagerSocialNetworks({ params: { ...pagination, ...filter } }),
    cacheTime: 0,
  }));
};
