import cn from 'classnames';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { useGetPhotoGalleryFormImages } from 'widgets/photoGalleryForm/hooks/useGetPhotoGalleryFormImages';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';
import { PhotoGalleryFormEmpty } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormEmpty/PhotoGalleryFormEmpty';
import { PhotoGalleryFormList } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormList/PhotoGalleryFormList';
import { PhotoGalleryFormWrapper } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormWrapper/PhotoGalleryFormWrapper';

import styles from './PhotoGalleryFormDropzone.module.css';

export const PhotoGalleryFormDropzone = () => {
  const {
    data: { data: images, meta } = {},
    isLoading,
    isError,
    error,
  } = useGetPhotoGalleryFormImages();
  const { setValue } = useFormContext<TPhotoGalleryFields>();

  const isEmptyImages = !Array.isArray(images) || images.length === 0;

  const totalImages = meta?.total ?? images?.length ?? 0;

  useEffect(() => {
    setValue('totalImages', totalImages, { shouldValidate: true });
  }, [totalImages]);

  if (isLoading) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  if (isError) {
    throw error;
  }
  return (
    <PhotoGalleryFormWrapper
      total={totalImages}
      className={cn(styles.dropzone, {
        [styles.empty]: isEmptyImages,
      })}
    >
      {({ isDragActive, openedUploadModal, open }) =>
        isEmptyImages ? (
          <PhotoGalleryFormEmpty onClick={open} />
        ) : (
          <PhotoGalleryFormList
            images={images}
            openedUploadModal={openedUploadModal}
            total={totalImages}
            imagesWrapperClassName={cn({
              [styles.dragActive]: Array.isArray(images) && isDragActive,
            })}
            onClickUpload={open}
          />
        )
      }
    </PhotoGalleryFormWrapper>
  );
};
