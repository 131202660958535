import { getMonth, getYear, setMonth, setYear } from 'date-fns';
import { TFunction } from 'i18next';
import { range } from 'lodash';
import { useMemo } from 'react';
import { CaptionProps, useNavigation } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { getMinDateFromDate } from 'shared/components/DatePicker/data';
import { Select } from 'shared/components/Form/Select/Select';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';

import styles from './CustomCaption.module.css';
import { NavCaption } from './NavCaption';

type TProps = CaptionProps & {
  setSelected: (value?: Date) => void;
  fromDate: Date;
  toDate: Date;
};

const getMonths = (t: TFunction): string[] => [
  t('shared.month.january'),
  t('shared.month.february'),
  t('shared.month.march'),
  t('shared.month.april'),
  t('shared.month.may'),
  t('shared.month.june'),
  t('shared.month.july'),
  t('shared.month.august'),
  t('shared.month.september'),
  t('shared.month.october'),
  t('shared.month.november'),
  t('shared.month.december'),
];

const NEXT_YEAR = 1;

export const CustomCaption = ({
  displayMonth,
  setSelected,
  toDate,
  fromDate,
}: TProps) => {
  const { t } = useTranslation();
  const months = getMonths(t);
  const { goToMonth, previousMonth, nextMonth } = useNavigation();

  const getNextYear = () => {
    return setYear(displayMonth, getYear(displayMonth) + NEXT_YEAR);
  };

  const getPrevYear = () => {
    return setYear(displayMonth, getYear(displayMonth) - NEXT_YEAR);
  };

  const goToHandler = (date: Date) => {
    const isSameMonth = date.getMonth() !== displayMonth.getMonth();
    const isSameYear = date.getFullYear() !== displayMonth.getFullYear();

    if (isSameMonth || isSameYear) {
      setSelected(undefined);
    }

    goToMonth(date);
  };

  const yearsOptions = useMemo(() => {
    const years = range(fromDate.getFullYear(), toDate.getFullYear() + NEXT_YEAR);

    return years.map((year) => ({
      value: toString(year),
      label: toString(year),
    }));
  }, [fromDate, toDate]);

  const monthsOptions = useMemo(() => {
    return months.map((month) => ({
      value: toString(month),
      label: toString(month),
    }));
  }, []);

  return (
    <div className={styles.container}>
      <NavCaption
        goToHandler={goToHandler}
        maxDate={toDate}
        minDate={getMinDateFromDate(fromDate)}
        next={nextMonth}
        prev={previousMonth}
      >
        <Select
          dropdownWidth={118}
          inputClassName={styles.select}
          multiple={false}
          options={monthsOptions}
          optionsContainerClassName={styles.optionsContainer}
          placeholder={months[getMonth(displayMonth)]}
          selectClassName={styles.selectClassName}
          value={months[getMonth(displayMonth)]}
          onChange={(month) => {
            goToHandler(setMonth(displayMonth, months.indexOf(month)));
          }}
        />
      </NavCaption>
      <NavCaption
        goToHandler={goToHandler}
        maxDate={toDate}
        minDate={getMinDateFromDate(fromDate)}
        next={getNextYear()}
        prev={getPrevYear()}
      >
        <Select
          dropdownWidth={90}
          inputClassName={styles.select}
          multiple={false}
          options={yearsOptions}
          optionsContainerClassName={styles.optionsContainer}
          placeholder={toString(getYear(displayMonth))}
          selectClassName={styles.selectClassName}
          value={toString(getYear(displayMonth))}
          onChange={(year) => goToHandler(setYear(displayMonth, toNumber(year)))}
        />
      </NavCaption>
    </div>
  );
};
