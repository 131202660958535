import { OpponentPlayerCard } from 'entities/opponentPlayers/ui/OpponentPlayerCard/OpponentPlayerCard';
import { OpponentPlayerModal } from 'feature/opponentPlayerModal/OpponentPlayerModal';
import { useCreateOpponentPlayer } from 'pages/opponentTeam/teamPage/hooks/useCreateOpponentPlayer';
import { useFetchOpponentPlayers } from 'pages/opponentTeam/teamPage/hooks/useFetchOpponentPlayers';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CardButton } from 'shared/components/CardButton/CardButton';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';

import styles from './OpponentTeamPage/OpponentTeamPage.module.css';
export const OPPONENT_TEAM_PLAYERS_PAGE_GAP = 24;
export const OpponentTeamPlayers = () => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const { players, page, changePage, pageSize, isShowPagination, isLoading, total } =
    useFetchOpponentPlayers(wrapperRef, teamId);
  const {
    createOpponentPlayer,
    isLoading: isLoadingCreateOpponentPlayer,
    openModal,
    openedModal,
    closeModal,
  } = useCreateOpponentPlayer(teamId ?? '');
  return (
    <>
      <div ref={wrapperRef}>
        {isLoading ? (
          <Centered>
            <Loader showLabel />
          </Centered>
        ) : (
          <div
            className={styles.listWrapper}
            style={{
              gap: `${OPPONENT_TEAM_PLAYERS_PAGE_GAP}px`,
            }}
          >
            <CardButton title={t('shared.create')} onClick={openModal} />
            {players.map((item) => {
              return <OpponentPlayerCard key={item.id} opponentPlayer={item} />;
            })}
          </div>
        )}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={pageSize}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
      <OpponentPlayerModal
        actionSubmit={createOpponentPlayer}
        closeModal={closeModal}
        isLoading={isLoadingCreateOpponentPlayer}
        isOpened={openedModal}
        title={t('content.opponentTeams.createPlayer')}
      />
    </>
  );
};
