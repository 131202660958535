import { SocialNetworkModal } from 'feature/socialNetworkModal/ui/SocialNetworkModal/SocialNetworkModal';
import { useManagerSocialNetworksActions } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/hooks/useManagerSocialNetworksActions';
import { TManagerSocialNetworkItem } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';
export const ManagerSocialNetworksActions = (
  props: TTableActions<TManagerSocialNetworkItem>,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    useManagerSocialNetworksActions(props);
  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <SocialNetworkModal {...editModalProps} />}
    </>
  );
};
