import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { opponentPlayersQueryKeys } from 'entities/opponentPlayers/consts';
import { useEditOpponentPlayerMutation } from 'entities/opponentPlayers/mutations';
import { TOpponentPlayer } from 'entities/opponentPlayers/types';
import { mapOpponentPlayerFieldsToDTO } from 'feature/opponentPlayerModal/mapper/mapOpponentPlayerFieldsToDTO';
import { mapOpponentPlayerToFields } from 'feature/opponentPlayerModal/mapper/mapOpponentPlayerToFields';
import { TOpponentPlayerModalFields } from 'feature/opponentPlayerModal/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';
export const useEditOpponentPlayer = (playerId: TEntityId, teamId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    openedEditModal,
    openEditModal: openEdit,
    closeEditModal,
  } = useModal('Edit');
  const { mutateAsync, isLoading } = useEditOpponentPlayerMutation();
  const { locales } = useLocales();
  const [initialValues, setInitialValues] = useState<TOpponentPlayerModalFields>();
  const openEditModal = async (opponentPlayer: TOpponentPlayer) => {
    setInitialValues(await mapOpponentPlayerToFields(opponentPlayer));
    openEdit();
  };
  const editOpponentPlayer = async (
    opponentPlayerModalFields: TOpponentPlayerModalFields,
  ) => {
    const dto = await mapOpponentPlayerFieldsToDTO(
      opponentPlayerModalFields,
      teamId,
      locales,
    );
    await mutateAsync(
      {
        ...dto,
        id: playerId,
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([opponentPlayersQueryKeys.opponentPlayers]);
        },
        onError: () => {
          notify(t('content.validation.notifyEditPlayerOpponent'), {
            type: 'error',
          });
        },
        onSettled: closeEditModal,
      },
    );
  };
  return {
    isLoading,
    editOpponentPlayer,
    openEditModal,
    closeEditModal,
    openedEditModal,
    initialValues,
  };
};
