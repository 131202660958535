import { useManagerLayout } from 'feature/managerLayout/ui/ManagerLayout/useManagerLayout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TButtonProps } from 'shared/components/Button/Button';
import { Loader } from 'shared/components/Loader/Loader';
import { TWithOptionalChildren } from 'shared/types/common';
import { SecondLayout } from 'shared/ui/layouts/secondLayout/SecondLayout';

import styles from './ManagerLayout.module.css';
type TProps = {
  confirmButtonProps?: TButtonProps;
};
export const ManagerLayout = ({
  children,
  confirmButtonProps = {},
}: TProps & TWithOptionalChildren) => {
  const { t } = useTranslation();
  const { managerId, teamId } = useParams<{
    managerId: string;
    teamId: string;
  }>();
  const { backButtonClickHandler, managerNavigationLinks, isLoading, error } =
    useManagerLayout({
      managerId,
      teamId,
    });
  if (error) {
    throw error;
  }
  if (isLoading) {
    return <Loader className={styles.loading} />;
  }
  return (
    <SecondLayout
      confirmButtonProps={confirmButtonProps}
      navigationLinks={managerNavigationLinks}
      title={t('content.teams.coachingStaff')}
      backButtonProps={{
        onClick: backButtonClickHandler,
      }}
    >
      {children}
    </SecondLayout>
  );
};
