type TFilterType = 'eq' | 'not_eq' | 'lt' | 'gt' | 'lteq' | 'gteq' | 'in' | 'not_in';

type TGetApiFiltersParam<T> = {
  name: string;
  type?: TFilterType;
  value?: T;
};

type TGetApiFiltersReturn<T> = Record<string, T>;

const getFilterName = (name: string, type?: TFilterType) =>
  `filter[${name}]${type ? `[${type}]` : ''}`;

export const getApiFilters = <T>(
  filter: TGetApiFiltersParam<T> | TGetApiFiltersParam<T>[],
): TGetApiFiltersReturn<T> => {
  const filterObject: Record<string, T> = {};

  if (Array.isArray(filter)) {
    filter.forEach(({ name, type, value }) => {
      if (value !== undefined) {
        filterObject[getFilterName(name, type)] = value;
      }
    });
  } else if (filter.value !== undefined) {
    const { name, type, value } = filter;
    filterObject[getFilterName(name, type)] = value;
  }

  return filterObject;
};
