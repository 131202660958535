import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { fetchPhotoGallery } from 'entities/photoGallery/api';
import { photoGalleryQueryKeys } from 'entities/photoGallery/consts';
import { useParams } from 'react-router-dom';
import { toBoolean } from 'shared/lib/toBoolean';
import { mapAPIPhotoGalleryToEditFormFields } from 'widgets/photoGalleryForm/mapper/mapAPIPhotoGalleryToEditFormFields';

export const useGetPhotoGalleryValues = () => {
  const { photoGalleryId } = useParams<{ photoGalleryId: string }>();
  const { defaultLocale } = useLocales();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [photoGalleryQueryKeys.editPhotoGallery, photoGalleryId],
    queryFn: async () =>
      mapAPIPhotoGalleryToEditFormFields(
        await fetchPhotoGallery(photoGalleryId ?? ''),
        defaultLocale,
      ),
    enabled: toBoolean(photoGalleryId),
    cacheTime: 0,
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};
