import { useCreateRouter } from 'app/hooks/useCreateRouter';
import { useSession } from 'feature/auth/model/useSession';
import { RouterProvider } from 'react-router-dom';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { useDefaultLocales } from 'shared/hooks/useDefaultLocale';

export const Router = () => {
  const router = useCreateRouter();
  const { isLoading } = useSession();

  useDefaultLocales();

  if (isLoading) {
    return (
      <Centered>
        <Loader size="l" />
      </Centered>
    );
  }

  return <RouterProvider router={router} />;
};
