import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchPlayerPositionsQuery } from 'entities/playerPositions/query';
import { useFetchPlayersQuery } from 'entities/players/api/queries';
import i18n from 'i18next';
import { TEAM_PAGE_GAP } from 'pages/team/teamPage/TeamPage';
import { useCalcItemsCountByParent } from 'pages/team/TeamsPage/useCalcItemsCountByParent';
import { RefObject, useMemo } from 'react';
import { CARD_WIDTH } from 'shared/components/Card/Card';
import { notify } from 'shared/components/Notification';
import { getApiFilters } from 'shared/lib/apiFilters';
import { compareIds } from 'shared/utils/entityIds';

const CREATE_BUTTON_COUNT = 1;

export const usePlayers = (
  wrapperRef: RefObject<HTMLDivElement>,
  teamId: string | undefined,
) => {
  const { defaultLocale } = useLocales();

  const { isCalculateItems, dynamicItems } = useCalcItemsCountByParent({
    parent: wrapperRef,
    itemWidth: CARD_WIDTH,
    wrapperGap: TEAM_PAGE_GAP,
  });

  const {
    data: { data: players, meta: { total = 0, pageSize = 0, page = 1 } = {} } = {},
    isLoading,
    changePage,
  } = useFetchPlayersQuery(
    {
      pageSize: dynamicItems - CREATE_BUTTON_COUNT,
      filter: getApiFilters({
        name: 'team_id',
        type: 'eq',
        value: teamId,
      }),
    },
    {
      cacheTime: 0,
      enabled: isCalculateItems,
      onError: () => notify(i18n.t('content.teams.notify')),
    },
  );

  const { data: playerPositions, isLoading: isLoadingPlayerPositions } =
    useFetchPlayerPositionsQuery();

  const playersList = useMemo(() => {
    if (players && playerPositions) {
      return players.map((player) => {
        const playerPosition =
          playerPositions.find((position) =>
            compareIds(position.id, player.playerPosition),
          )?.translations[defaultLocale].jobTitle ?? '';

        return {
          ...player,
          playerPosition,
        };
      });
    }

    return [];
  }, [playerPositions, players]);

  const isShowPagination = total > 0 && total > pageSize && !isLoading;

  return {
    playersList,
    total,
    pageSize,
    page,
    isShowPagination,
    changePage,
    isLoading: isLoadingPlayerPositions || isLoading,
  };
};
