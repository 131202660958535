import { useQueryClient } from '@tanstack/react-query';
import { managerTeamAchievementsQueryKeys } from 'entities/managerTeamAchievements/consts';
import { useEditManagerTeamAchievementMutation } from 'entities/managerTeamAchievements/mutations';
import { TEditManagerTeamAchievementDTO } from 'entities/managerTeamAchievements/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useEditManagerTeamAchievement = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: editManagerTeamAchievementMutation, isLoading } =
    useEditManagerTeamAchievementMutation();

  const editManagerTeamAchievement = (
    editTeamAchievementDTO: TEditManagerTeamAchievementDTO,
    onSuccess: () => void,
  ) => {
    editManagerTeamAchievementMutation(editTeamAchievementDTO, {
      onSuccess: () => {
        onSuccess();
        queryClient.resetQueries([
          managerTeamAchievementsQueryKeys.managerTeamAchievements,
        ]);
      },
      onError: () =>
        notify(t('content.manager.notifyNotUpdateTeamAchievements'), {
          type: 'error',
        }),
    });
  };

  return { isEditingManagerTeamAchievement: isLoading, editManagerTeamAchievement };
};
