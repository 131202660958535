import {
  TStoriesSortParams,
  useFetchStoryGroupsQuery,
} from 'entities/stories/queries';
import { useStoriesColumnsConfig } from 'pages/stories/StoriesPage/columnsConfig';
import { useCreateStory } from 'pages/stories/StoriesPage/hooks/useCreateStory';
import { StoriesActions } from 'pages/stories/StoriesPage/StoriesActions/StoriesActions';
import { createSortQuery } from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { getImageSettings } from 'shared/constants';
import { useCommonListTableSort } from 'widgets/elementsList/hooks/useCommonListTableSort';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './StoriesPage.module.css';

const DEFAULT_STORIES_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'created_at',
} as const;

export const StoriesPage = () => {
  const { t } = useTranslation();
  const {
    isImageUploadOpened,
    handleFileAdd,
    handleCloseUploadModal,
    handleOpenUploadModal,
  } = useCreateStory();
  const { sortOptions, handleSort } = useCommonListTableSort<TStoriesSortParams>(
    DEFAULT_STORIES_SORT_OPTIONS,
  );
  const {
    data: { data: storyGroups = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchStoryGroupsQuery({
    sort: createSortQuery(sortOptions),
  });
  return (
    <div className={styles.root}>
      <CommonList
        configCreator={useStoriesColumnsConfig}
        emptyComponentDescription={t('content.story.createFirstStories')}
        error={error}
        isEmpty={storyGroups.length === 0 && !isLoading}
        isLoading={isLoading}
        title={t('content.story.stories')}
        createButtonProps={{
          icon: 'plus',
          iconPosition: 'before',
          iconSize: 'xs',
          onClick: handleOpenUploadModal,
          content: t('shared.create'),
        }}
        paginationProps={{
          totalItems: total,
          selectedPage: page,
          onPageChange: changePage,
        }}
        tableProps={{
          data: storyGroups,
          rowClassName: styles.rowGridTemplate,
          sortOptions,
          onSort: handleSort,
          ActionComponent: StoriesActions,
          actionsCellClassName: styles.actionsColumn,
        }}
      />
      <ImageUploadModal
        aspectRatio={getImageSettings(t).stories.background.aspectRatio}
        hint={getImageSettings(t).stories.background.hint}
        opened={isImageUploadOpened}
        title={t('content.story.loadImage')}
        withCropping
        onClose={handleCloseUploadModal}
        onConfirm={handleFileAdd}
      />
    </div>
  );
};
