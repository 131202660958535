import { TEntityId } from 'shared/types/common';

export const opponentTeamsQueryKeys = {
  opponentTeam: 'opponentTeam',
  opponentTeams: 'opponentTeams',
};

const opponentTeamsBaseUrl = 'opponent_teams';

export const opponentTeamsApiKeys = {
  getTeams: opponentTeamsBaseUrl,
  createTeam: opponentTeamsBaseUrl,
  getOpponentTeam: (id: TEntityId) => `${opponentTeamsBaseUrl}/${id}`,
  editTeam: (id: TEntityId) => `${opponentTeamsBaseUrl}/${id}`,
  deleteTeam: (id: TEntityId) => `${opponentTeamsBaseUrl}/${id}`,
};
