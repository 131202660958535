import { useLocales } from 'entities/config/lib/useLocales';
import { TStoriesSortParams } from 'entities/stories/queries';
import { TStoryGroupData } from 'entities/stories/types';
import { getStatusByPublicationDate } from 'feature/story/getStatusByPublicationDate';
import i18n from 'i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './StoriesPage.module.css';

export const useStoriesColumnsConfig: TConfigCreator<
  TStoryGroupData,
  TStoriesSortParams
> = () => {
  const { defaultLocale } = useLocales();
  return [
    {
      title: i18n.t('content.title'),
      render: (item) => (
        <div className={styles.publicationNameColumn}>
          {item.preview.x1 ? (
            <div>
              <img
                alt="Preview Image"
                className={styles.preview}
                src={item.preview.x1}
              />
            </div>
          ) : (
            <div>
              <Icon kind="picture" size="s" />
            </div>
          )}
          <Text>{item.translations[defaultLocale]?.name}</Text>
        </div>
      ),
      headCellProps: {
        className: styles.publicationNameColumn,
      },
    },
    {
      title: i18n.t('content.story.storiesCount'),
      render: (item) => (
        <Text className={styles.screensCount} weight="SemiBold">
          {item.stories.length}
        </Text>
      ),
    },
    {
      title: i18n.t('content.createDate'),
      render: (item) => <DateTimeCell dateTimeString={item.createdAt} />,
      headCellProps: {
        sort: {
          sortParam: 'created_at',
        },
      },
    },
    {
      title: i18n.t('content.status'),
      render: (item) => (
        <Status status={getStatusByPublicationDate(item.published)} />
      ),
      /* TODO: расскоментировать в рамках доработки фильтра по статусу.
        Сейчас бек рассчитан только на выбор 1го фильтра, необходимо реализовать
        выбор нескольких фильтров на сервере и доработать под это клиент */
      // headCellProps: {
      //   filterName: 'status',
      //   getIsFiltered: (filter) =>
      //     filter.status && filter.status !== EListStatus.All,
      //   getFilterBadge: (filter: TVideoFilter) => (filter.status ? ' ' : null),
      //   filterComponent: StatusFilter,
      // },
    },
    {
      title: i18n.t('content.publicDate'),
      render: (item) => <DateTimeCell dateTimeString={item.published} />,
    },
    {
      title: i18n.t('content.story.views'),
      cellClassName: styles.viewsCount,
      render: (item) => (
        <>
          <Icon kind="eye" size="xs" /> <Text>{item.viewsCount}</Text>
        </>
      ),
    },
    {
      title: 'ID',
      render: (item) => item.id,
    },
  ];
};
