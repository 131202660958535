import { TManagerCareer } from 'entities/managerCareers/model/types';
import { ManagerCareerModal } from 'feature/managerCareerModal/ui/ManagerCareerModal/ManagerCareerModal';
import { useManagerCareersActions } from 'pages/manager/careers/ManagerCareersPage/hooks/useManagerCareersActions';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';

import styles from './ManagerCareersActions.module.css';

export const ManagerCareersActions = (props: TTableActions<TManagerCareer>) => {
  const { t } = useTranslation();
  const {
    actions,
    isDeletingManagerCareer,
    isDeleteModalOpen,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
    editCareerModalProps,
    isActive,
    itemId,
    onIconClick,
  } = useManagerCareersActions(props);
  return (
    <>
      <Actions
        actions={actions}
        className={styles.dropdown}
        isLoading={isDeletingManagerCareer}
        isOpen={isActive}
        itemId={itemId}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        description={t('content.deleteWarning')}
        isOpened={isDeleteModalOpen}
        title={t('content.deleteCareer')}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteCareer}
      />
      <ManagerCareerModal {...editCareerModalProps} />
    </>
  );
};
