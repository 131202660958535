import { useDeletePlayerAchievementMutation } from 'entities/playerAchievements/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerAchievement = () => {
  const { t } = useTranslation();
  const { mutate: deletePlayerAchievementMutation, isLoading } =
    useDeletePlayerAchievementMutation();

  const deletePlayerAchievement = (achievementId: TEntityId) => {
    deletePlayerAchievementMutation(achievementId, {
      onError: () =>
        notify(t('content.player.notifyNotDeleteAchievement'), {
          type: 'error',
        }),
    });
  };

  return {
    isDeletingPlayerAchievement: isLoading,
    deletePlayerAchievement,
  };
};
