import { useQueries, useQuery } from '@tanstack/react-query';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { TEntityId } from 'shared/types/common';

import {
  fetchStory,
  fetchStoryElement,
  fetchStoryGroup,
  fetchStoryGroups,
} from './api';
import { storiesQueryKeys } from './constants';

export type TStoriesSortParams = 'created_at';

type TFetchStoryGroupsParams = {
  sort?: 'created_at' | '-created_at';
};

export const useFetchStoryGroupsQuery = ({ sort }: TFetchStoryGroupsParams) =>
  usePaginationQuery((pagination) => ({
    queryKey: [storiesQueryKeys.storyGroups, sort, pagination],
    queryFn: () => fetchStoryGroups({ params: { ...pagination, sort } }),
    cacheTime: 0,
  }));

export const useFetchStoryGroupById = (id: TEntityId) =>
  useQuery({
    queryKey: [storiesQueryKeys.storyGroup, id],
    queryFn: () => fetchStoryGroup(id),
  });

export const useFetchStoriesByIds = (ids?: string[]) =>
  useQueries({
    queries: ids
      ? ids.map((id) => {
          return {
            queryKey: [storiesQueryKeys.story, id],
            queryFn: () => fetchStory(id),
          };
        })
      : [],
  });

export const useFetchElementsByIds = (ids?: string[]) =>
  useQueries({
    queries: ids
      ? ids.map((id) => {
          return {
            queryKey: [storiesQueryKeys.storyElement, id],
            queryFn: () => fetchStoryElement(id),
          };
        })
      : [],
  });
