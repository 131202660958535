import { useLocales } from 'entities/config/lib/useLocales';
import i18n from 'i18next';
import { useController, useFormContext } from 'react-hook-form';
import { useGetCreatedLocales } from 'shared/components/NewsPostForm/hooks/useGetCreatedLocales';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { notify } from 'shared/components/Notification';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TContentLang, TPostFormFile, TTranslations } from 'shared/types/common';

export const useGetLocalesControl = () => {
  const { defaultLocale } = useLocales();
  const { control, getValues, setValue, trigger, clearErrors } =
    useFormContext<TEditNewsPostFields>();
  const { changeCreatedLocales, createdLocales } = useGetCreatedLocales(control);
  const {
    field: { value: selectedLocale, onChange: changeSelectedLocale },
  } = useController({
    control,
    name: 'locale.selected',
  });

  const isValidContent = () =>
    trigger([
      `content.title.translations.${selectedLocale}`,
      `content.preview.translations.${selectedLocale}`,
      'content.body',
    ]);

  const onLocaleDelete = (locale: TContentLang) => {
    changeCreatedLocales(createdLocales.filter((l) => l !== locale));
    const {
      content,
      locale: { created },
    } = getValues();
    changeSelectedLocale(defaultLocale);

    setValue('content', {
      preview: {
        ...content.preview,
        translations: created.reduce((acc, key) => {
          if (key !== locale) {
            acc[key] = content.preview.translations[key];
          }
          return acc;
        }, {} as TTranslations<TPostFormFile>),
      },
      title: {
        ...content.title,
        translations: created.reduce(
          (acc, key) => {
            if (key !== locale) {
              acc[key] = content.title.translations[key];
            }
            return acc;
          },
          {} as TTranslations<{ title: string }>,
        ),
      },
      body: content.body.map(
        (paragraph) =>
          ({
            ...paragraph,
            translations: created.reduce(
              (acc, key) => {
                if (key !== locale) {
                  acc[key] = paragraph.translations[key];
                }
                return acc;
              },
              {} as TWorkspaceElement['translations'],
            ),
          }) as TWorkspaceElement,
      ),
    });

    clearErrors(`content.title.translations.${locale}`);
    clearErrors(`content.preview.translations.${locale}`);
    // так как нет возможности достать из валидации content body удаляемую локаль, необходимо заново валидировать данное поле
    trigger('content.body');
  };

  const onLocaleSelect = async (locale: TContentLang) => {
    const isValid = await isValidContent();
    const { content } = getValues();

    // если в дефолтной локали появился новый параграф, его нужно перевести в выбранную локаль
    setValue(
      'content.body',
      content.body.map(
        (paragraph) =>
          ({
            ...paragraph,
            translations: {
              ...paragraph.translations,
              [locale]:
                paragraph.translations[locale] ??
                paragraph.translations[defaultLocale],
            },
          }) as TWorkspaceElement,
      ),
    );

    if (!isValid) {
      return;
    }

    changeSelectedLocale(locale);
  };

  const onLocaleAdd = async (locale: TContentLang) => {
    const isValid = await isValidContent();

    if (!isValid) {
      return;
    }

    const { content } = getValues();
    setValue('content', {
      preview: {
        ...content.preview,
        translations: {
          ...content.preview.translations,
          [locale]: content.preview.translations[defaultLocale],
        },
      },
      title: {
        ...content.title,
        translations: {
          ...content.title.translations,
          [locale]: content.title.translations[defaultLocale],
        },
      },
      body: content.body.map((paragraph) => {
        return {
          ...paragraph,
          translations: {
            ...paragraph.translations,
            [locale]: paragraph.translations[defaultLocale],
          },
        } as TWorkspaceElement;
      }),
    });
    changeCreatedLocales([...createdLocales, locale]);
    changeSelectedLocale(locale);
    notify(i18n.t('shared.NewsPostForm.AddNewsPostLocalesControl.hook.notify'), {
      type: 'warning',
    });
  };

  return {
    createdLocales,
    selectedLocale,
    onLocaleAdd,
    onLocaleDelete,
    onLocaleSelect,
  };
};
