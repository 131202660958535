import { useEditPlayerSocialNetworkMutation } from 'entities/playerSocialNetworks/mutations';
import { TPlayerSocialNetwork } from 'entities/playerSocialNetworks/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useEditPlayerSocialNetwork = () => {
  const { t } = useTranslation();
  const { mutate: editPlayerSocialNetworkMutation, isLoading } =
    useEditPlayerSocialNetworkMutation();

  const editPlayerSocialNetwork = (
    playerSocialNetwork: TPlayerSocialNetwork,
    onSuccess: () => void,
  ) => {
    editPlayerSocialNetworkMutation(playerSocialNetwork, {
      onSuccess,
      onError: () =>
        notify(t('content.player.notifyNotUploadSocial'), { type: 'error' }),
    });
  };

  return { isEditingPlayerSocialNetwork: isLoading, editPlayerSocialNetwork };
};
