import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { TImageUpload } from 'shared/types/common';

export const mapImageToEdit = async (url: string) => {
  if (!url) {
    return {
      file: undefined,
      data: '',
    };
  }

  const file = await getFileFromUrl(url);
  const data = await getBase64FromFile(file);

  return {
    file,
    data,
  };
};

export const mapFileToUpload = async (file: File): Promise<TImageUpload> => {
  const base64 = await getBase64FromFile(file);

  return {
    data: clearMetaDataFromBase64Url(base64),
    filename: file.name,
  };
};
