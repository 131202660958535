export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export function trimString(u: unknown) {
  if (typeof u === 'string') {
    return u.trim();
  }

  return u;
}

export function insertStringAt(
  string: string,
  index: number,
  value: string,
): string {
  return string.substring(0, index) + value + string.substring(index);
}

export function replaceBetween(
  origin: string,
  insertion: string,
  startIndex: number,
  endIndex: number,
): string {
  return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
}
