import { Button, TButtonProps } from 'shared/components/Button/Button';

export type TWorkspacePanelUnpublishButtonProps = TButtonProps & {
  isValid: boolean;
};

export const WorkspacePanelUnpublishButton = ({
  color = 'gray',
  kind = 'outlined',
  children,
  ...otherProps
}: TWorkspacePanelUnpublishButtonProps) => {
  return (
    <Button color={color} kind={kind} {...otherProps}>
      {children}
    </Button>
  );
};
