// eslint-disable-next-line no-restricted-imports
import { FieldValues, useForm as useReactHookForm } from 'react-hook-form';
import { UseFormProps, UseFormReturn } from 'react-hook-form/dist/types';
import { isCustomProps } from 'shared/hooks/useReactHookForm/helpers';
import {
  TUseReactHookFormProps,
  TUseReactHookFormReturn,
} from 'shared/hooks/useReactHookForm/types';
import { useReactHookFormCustom } from 'shared/hooks/useReactHookForm/useReactHookFormCustom';

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props?:
    | TUseReactHookFormProps<TFieldValues, TContext, TTransformedValues>
    | UseFormProps<TFieldValues, TContext>,
): TUseReactHookFormReturn<
  typeof props,
  TFieldValues,
  TContext,
  TTransformedValues
> =>
  props && isCustomProps(props)
    ? useReactHookFormCustom<TFieldValues, TContext, TTransformedValues>(
        props as TUseReactHookFormProps<TFieldValues, TContext, TTransformedValues>,
      )
    : (useReactHookForm<TFieldValues, TContext, TTransformedValues>(
        props,
      ) as UseFormReturn<TFieldValues, TContext, TTransformedValues> & {
        submit: VoidFunction;
      });
