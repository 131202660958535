import { getNewsPostStatus, getTitleFromParagraphs } from 'entities/news/helpers';
import { TAPINewsPost, TNewsPost } from 'entities/news/types';
import { TContentLang } from 'shared/types/common';

export const mapApiNewsPostToNewsPost = (
  apiNewsPost: TAPINewsPost,
  defaultLocale: TContentLang,
): TNewsPost => ({
  ...apiNewsPost,
  status: getNewsPostStatus(apiNewsPost.published ?? ''),
  title: getTitleFromParagraphs(apiNewsPost.paragraphs, defaultLocale),
});
