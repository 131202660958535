import { TEditor, TEditorHeadingElements } from 'shared/components/Editor/types';
import { editorHeadingController } from 'shared/components/Editor/utils/heading/editorHeadingController';

export const useWorkspaceHeadingActions = (editor: TEditor) => {
  const toggleHeading = (type: TEditorHeadingElements) => {
    editorHeadingController.changeHeading(editor, type);
  };

  return {
    toggleHeading,
  };
};
