import { useLocales } from 'entities/config/lib/useLocales';
import { TVideoCategory } from 'entities/videoCategories/types';
import { TVideoCategoriesSortParams } from 'pages/settings/categories/VideoCategoriesPage/types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './VideoCategoriesPage.module.css';

export const useVideoCategoriesColumnsConfig: TConfigCreator<
  TVideoCategory,
  TVideoCategoriesSortParams
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.names'),
      headCellProps: {
        sort: {
          sortParam: 'name',
        },
      },
      render: (item) => (
        <>
          {item.picture ? (
            <div className={styles.previewWrapper}>
              <img
                alt={`${item?.translations[defaultLocale]?.name} preview`}
                className={styles.preview}
                src={item.picture.x1}
              />
            </div>
          ) : (
            <div className={styles.previewWrapper}>
              <Icon kind="picture" size="s" />
            </div>
          )}
          <Text className={styles.text}>
            {item?.translations[defaultLocale]?.name}
          </Text>
        </>
      ),
    },
    {
      title: t('content.importance'),
      cellClassName: styles.publicationNameColumn,
      headCellProps: {
        sort: {
          sortParam: 'importance',
        },
      },
      render: (item) => (
        <Text className={styles.importance}>{item?.importance}</Text>
      ),
    },
  ];
};
