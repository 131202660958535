export enum ETeamTabType {
  Player = 'player',
  Manager = 'manager',
}

export type TTeamTab = {
  text: string;
  onClick: () => void;
  tabName: ETeamTabType;
};
