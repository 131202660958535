import {
  TDefaultRightPanelProps,
  TSelectFieldProps,
} from 'feature/rightPanel/types';
import { RightPanelCollapseWrapper } from 'feature/rightPanel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';

type TProps<T extends FieldValues> = {
  labels: TSelectFieldProps<T>;
  categories: TSelectFieldProps<T>;
  description: string;
  control: Control<T>;
};

export const CommonLabel = <T extends FieldValues>({
  labels,
  categories,
  control,
  description,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const { t } = useTranslation();
  return (
    <RightPanelCollapseWrapper
      description={description}
      label={t('content.categorization')}
      title={t('content.chooseCategorization')}
      {...restProps}
    >
      <FormSelect
        control={control}
        fieldName={labels.fieldName}
        label={t('content.labelsWithOtherEnding')}
        multiple={true}
        options={labels.data}
        placeholder={labels.placeholder}
        search
        tip={t('content.canChooseLabels')}
      />
      <FormSelect
        control={control}
        fieldName={categories.fieldName}
        label={t('content.categories')}
        multiple={true}
        options={categories.data}
        placeholder={categories.placeholder}
        search
        tip={t('content.canChooseCategories')}
      />
    </RightPanelCollapseWrapper>
  );
};
