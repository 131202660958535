import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { createPhotoGallery, editPhotoGallery } from 'entities/photoGallery/api';
import { deletePhotoGallery } from 'entities/photoGallery/api';
import { photoGalleryQueryKeys } from 'entities/photoGallery/consts';
import {
  TEditPhotoGalleryDTO,
  TPhotoGallery,
  TPhotoGalleryDTO,
} from 'entities/photoGallery/types';

export const useCreatePhotoGalleryMutation = (
  mutationOptions?: UseMutationOptions<TPhotoGallery, unknown, TPhotoGalleryDTO>,
) =>
  useMutation({
    mutationFn: createPhotoGallery,
    ...mutationOptions,
  });

export const useEditPhotoGalleryMutation = (
  mutationOptions?: UseMutationOptions<TPhotoGallery, unknown, TEditPhotoGalleryDTO>,
) =>
  useMutation({
    mutationFn: editPhotoGallery,
    ...mutationOptions,
  });

export const useDeletePhotoGalleryMutation = () => {
  return useMutation({
    mutationFn: deletePhotoGallery,
    onSuccess: () => {
      return queryClient.invalidateQueries([photoGalleryQueryKeys.photoGallery]);
    },
  });
};
