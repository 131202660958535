import { createContext, memo } from 'react';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { TWithChildren } from 'shared/types/common';

export const NewsPostFormContext = createContext({
  initialValue: {} as TEditNewsPostFields,
});

export const NewsPostFormContextProvider = memo(
  ({
    initialValue,
    children,
  }: { initialValue: TEditNewsPostFields } & TWithChildren) => {
    return (
      <NewsPostFormContext.Provider value={{ initialValue }}>
        {children}
      </NewsPostFormContext.Provider>
    );
  },
);
