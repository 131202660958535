import { TEntityId } from 'shared/types/common';

export const teamsQueryKeys = {
  teams: 'teams',
  team: (id: TEntityId) => `teams/${id}`,
};

const teamsBaseUrl = 'teams';

export const teamsApiKeys = {
  getTeams: teamsBaseUrl,
  getTeam: (id: TEntityId) => `${teamsBaseUrl}/${id}`,
  createTeam: teamsBaseUrl,
  deleteTeam: (id: TEntityId) => `${teamsBaseUrl}/${id}`,
  editTeam: (id: TEntityId) => `${teamsBaseUrl}/${id}`,
};
