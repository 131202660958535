import i18n from 'i18next';

import { TRules } from './types';

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const loginRegExp = /^[^@]+@[^@]+$/;
export const phoneRegExp = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
export const notEmptyString = /\S$/;

export const getDefaultEmailRules = (): TRules => ({
  required: i18n.t('shared.Form.validations.defaultRequired'),
  pattern: {
    value: emailRegExp,
    message: i18n.t('shared.Form.validations.defaultMessage'),
  },
});

export const getDefaultLoginRules = (): TRules => ({
  required: i18n.t('shared.Form.validations.defaultRequired'),
  pattern: {
    value: loginRegExp,
    message: i18n.t('shared.Form.validations.defaultLoginMessage'),
  },
});

export const getDefaultRequiredRules = (): TRules => ({
  required: i18n.t('shared.Form.validations.defaultRequired'),
});

export const getDefaultNotEmptyRules = (): TRules => ({
  required: i18n.t('shared.Form.validations.defaultRequired'),
  pattern: {
    value: notEmptyString,
    message: i18n.t('shared.Form.validations.defaultRequired'),
  },
});

export const getDefaultPhoneRules = (): TRules => ({
  required: i18n.t('shared.Form.validations.defaultPhoneRequired'),
  pattern: {
    value: phoneRegExp,
    message: i18n.t('shared.Form.validations.defaultPhoneMessage'),
  },
});
