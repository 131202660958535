import { TEditor, TEditorHeadingElements } from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { Transforms } from 'slate';

export const editorHeadingController = {
  changeHeading(editor: TEditor, type: TEditorHeadingElements) {
    editorController.unwrapBlockElements(editor);

    Transforms.setNodes(editor, {
      type,
      // если меняется тип элемента, то в других локалях он тоже должен меняться, поэтому тут его необходимо сбрасывать
      translations: {},
      children: [],
    });
  },
};
