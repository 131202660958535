import i18n from 'i18next';
import { TTranslationsMapper } from 'shared/types/common';

export const DEFAULT_PAGINATION_PAGE_SIZE = 15;

export const URL_REGEXP =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const MAX_FILE_SIZE = 7 * 1024 * 1024;

export const getLangNameMap = (): TTranslationsMapper => ({
  ru: i18n.t('shared.russian'),
  en: i18n.t('shared.english'),
  de: i18n.t('shared.german'),
  fr: i18n.t('shared.french'),
});

export const TEXT_MIN_LENGTH = 1;
export const getTextMinLengthMessage = () => i18n.t('shared.textMinLengthMessage');

export const DEFAULT_APP_URL = 'https://crespo.app';
export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd';
