import { EVideoPostStatus, TVideoPost } from 'entities/video/types';
import {
  TPostponedVideoActions,
  TVideoActionsListModal,
} from 'pages/video/VideoPage/videoActions/types';
import { getVideoActions } from 'pages/video/VideoPage/videoActions/utils';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useVideoPostActions = (
  videoPost: TVideoPost,
  setActiveModal: Dispatch<SetStateAction<TVideoActionsListModal | null>>,
) => {
  const { t } = useTranslation();

  const deleteVideoPost = () => setActiveModal('confirmDelete');

  const unpublishVideoPost =
    videoPost.status === EVideoPostStatus.Published
      ? () => setActiveModal('confirmUnpublish')
      : null;

  const postponedActions: TPostponedVideoActions | null = useMemo(() => {
    if (videoPost.status === EVideoPostStatus.Postponed) {
      return {
        publishNow: {
          title: t('content.publicNow'),
          icon: 'plus',
          action: () => setActiveModal('confirmPublishNow'),
        },
        changePublishDate: {
          title: t('content.changeTimePublic'),
          icon: 'clock',
          action: () => setActiveModal('changePublishDate'),
        },
      };
    }
    return null;
  }, []);

  return {
    actions: getVideoActions(deleteVideoPost, unpublishVideoPost, postponedActions),
  };
};
