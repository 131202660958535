import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  createOpponentPlayer,
  editOpponentPlayer,
} from 'entities/opponentPlayers/api';
import { deleteOpponentPlayer } from 'entities/opponentPlayers/api';
import {
  TEditOpponentPlayerDTO,
  TOpponentPlayer,
  TOpponentPlayerDTO,
} from 'entities/opponentPlayers/types';
import { TEntityId } from 'shared/types/common';

export const useCreateOpponentPlayerMutation = (
  options?: UseMutationOptions<TOpponentPlayer, unknown, TOpponentPlayerDTO>,
) =>
  useMutation({
    mutationFn: createOpponentPlayer,
    ...options,
  });

export const useEditOpponentPlayerMutation = (
  options?: UseMutationOptions<TOpponentPlayer, unknown, TEditOpponentPlayerDTO>,
) =>
  useMutation({
    mutationFn: editOpponentPlayer,
    ...options,
  });

export const useDeleteOpponentPlayerMutation = (
  options?: UseMutationOptions<unknown, unknown, TEntityId>,
) => {
  return useMutation({
    mutationFn: deleteOpponentPlayer,
    ...options,
  });
};
