import { useDeletePhotoGalleryImageMutation } from 'entities/photoGalleryImages/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

export const useDeletePhotoGalleryImage = () => {
  const { t } = useTranslation();
  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');
  const { mutateAsync: deletePhotoGalleryImageMutation, isLoading } =
    useDeletePhotoGalleryImageMutation();

  const onClickDeleteModalConfirm = async (photoGalleryId: TEntityId) => {
    closeDeleteModal();
    deletePhotoGalleryImageMutation(photoGalleryId, {
      onError: () =>
        notify(t('content.photoGallery.errorDeleteImage'), { type: 'error' }),
    });
  };

  return {
    openedDeleteModal,
    isDeletingPhotoGalleryImage: isLoading,
    openDeleteModal,
    closeDeleteModal,
    onClickDeleteModalConfirm,
  };
};
