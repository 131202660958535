import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateVideoCategoryMutation } from 'entities/videoCategories/mutations';
import { useFetchVideoCategoriesQuery } from 'entities/videoCategories/queries';
import { useVideoCategoriesColumnsConfig } from 'pages/settings/categories/VideoCategoriesPage/columnsConfig';
import { TVideoCategoriesSortParams } from 'pages/settings/categories/VideoCategoriesPage/types';
import { VideoCategoriesActions } from 'pages/settings/categories/VideoCategoriesPage/VideoCategoriesActions';
import { createSortQuery } from 'pages/video/VideoPage/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { useCommonListTableSort } from 'widgets/elementsList/hooks/useCommonListTableSort';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';
import { TVideoCategoriesFormFields } from 'widgets/videoCategoriesForm/types';
import { VideoCategoriesFormModal } from 'widgets/videoCategoriesForm/VideoCategoriesFormModal';

import styles from './VideoCategoriesPage.module.css';

const DEFAULT_VIDEO_CATEGORIES_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'importance',
} as const;

export const VideoCategoriesPage = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const createVideoCategories = useCreateVideoCategoryMutation();
  const { sortOptions, handleSort } =
    useCommonListTableSort<TVideoCategoriesSortParams>(
      DEFAULT_VIDEO_CATEGORIES_SORT_OPTIONS,
    );
  const {
    data: { data: categories = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchVideoCategoriesQuery({
    sort: createSortQuery(sortOptions),
  });
  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);
  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);
  const createVideoCategory = async (data: TVideoCategoriesFormFields) => {
    const base64 = await getBase64FromFile(data.picture);
    createVideoCategories.mutate({
      translations: data.translations,
      picture: {
        filename: data.picture!.name,
        data: clearMetaDataFromBase64Url(base64),
      },
      importance: data.importance || '0',
    });
    closeModal();
  };
  const initialValues = useMemo(() => {
    return {
      picture: undefined,
      importance: '',
      translations: reduceTranslationObject(locales, () => ({
        name: '',
      })),
    };
  }, [locales]);
  return (
    <div className={styles.root}>
      <CommonList
        configCreator={useVideoCategoriesColumnsConfig}
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.categoryVideos')}
        createButtonProps={{
          content: t('shared.create'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: categories,
          rowClassName: styles.rowGridTemplate,
          sortOptions,
          onSort: handleSort,
          ActionComponent: VideoCategoriesActions,
        }}
      />
      <VideoCategoriesFormModal
        actionSubmit={createVideoCategory}
        closeModal={closeModal}
        initialValues={initialValues}
        isOpened={showCreateModal}
        subtitle={t('content.settings.nameCategory')}
        title={t('content.settings.creatingCategory')}
      />
    </div>
  );
};
