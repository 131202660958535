import { parseISO } from 'date-fns';
import { TPhotoGallery } from 'entities/photoGallery/types';
import { toString } from 'shared/lib/toString';
import { TContentLang, TPartialTranslations } from 'shared/types/common';
import { mapImageToEdit } from 'shared/utils/image';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

export const mapAPIPhotoGalleryToEditFormFields = async (
  photoGallery: TPhotoGallery,
  defaultLocale: TContentLang,
): Promise<TPhotoGalleryFields> => {
  const {
    id,
    availableLocales,
    pictures,
    categories,
    seasons,
    matches,
    labels,
    managers,
    players,
    tournaments,
    translations,
    teams,
    published,
  } = photoGallery;

  return {
    id,
    locale: {
      created: availableLocales,
      selected: defaultLocale,
    },
    preview: await mapImageToEdit(pictures.base.x3),
    labels: labels.map(({ id }) => toString(id)),
    categories: categories.map(({ id }) => toString(id)),
    tournament: tournaments.length ? toString(tournaments[0].id) : undefined,
    season: seasons.length ? toString(seasons[0].id) : undefined,
    teams: teams.map(({ id }) => toString(id)),
    players: players.map(({ id }) => toString(id)),
    managers: managers.map(({ id }) => toString(id)),
    match: matches.length ? toString(matches[0].id) : undefined,
    sharingLink: translations[defaultLocale]?.sharingLink ?? '',
    published: published ? parseISO(published) : undefined,
    translations: availableLocales?.reduce<TPartialTranslations<{ title: string }>>(
      (acc, locale) => {
        acc[locale] = {
          title: translations[locale].name,
        };
        return acc;
      },
      {},
    ),
  };
};
