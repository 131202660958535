import { useFetchManagerTeamAchievementsQuery } from 'entities/managerTeamAchievements/queries';
import { useNavigate, useParams } from 'react-router-dom';
import { getApiFilters } from 'shared/lib/apiFilters';
import { routes } from 'shared/routes';

export const useManagerTeamAchievements = () => {
  const navigate = useNavigate();
  const { teamId = '', managerId = '' } = useParams<{
    teamId: string;
    managerId: string;
  }>();

  const {
    data: {
      data: managerTeamAchievements = [],
      meta: { total = 0, page = 1 } = {},
    } = {},
    isLoading: isManagerTeamAchievementsLoading,
    error: managerTeamAchievementsError,
    changePage,
  } = useFetchManagerTeamAchievementsQuery({
    filter: getApiFilters({
      name: 'manager_id',
      type: 'eq',
      value: managerId,
    }),
  });

  const handleConfirmButtonClick = () => {
    navigate(routes.editTeam.as(teamId), {
      replace: true,
    });
  };

  return {
    total,
    page,
    managerTeamAchievements,
    isLoading: isManagerTeamAchievementsLoading,
    error: managerTeamAchievementsError,
    handleConfirmButtonClick,
    changePage,
  };
};
