type THostingService = Record<string, (url: string) => string | false>;

const getYoutubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const getRutubeId = (url: string) => {
  const regExp = /^.*(rutube.ru\/video\/|v\/|u\/\w\/|embed\/)([^#&?]*).*/;
  const match = url.match(regExp);
  const id = match ? match[2].replace('/', '') : null;

  return id && id.length === 32 ? id : null;
};

export const embedLinkFactory: THostingService = {
  'youtube.com': (url: string) => {
    const youtubeId = getYoutubeId(url);
    if (!youtubeId) {
      return false;
    }
    return `https://www.youtube.com/embed/${youtubeId}?color=white&theme=light&wmode=transparent`;
  },
  'rutube.ru': (url: string) => {
    const rutubeId = getRutubeId(url);
    if (!rutubeId) {
      return false;
    }
    return `https://rutube.ru/play/embed/${rutubeId}`;
  },
};
