import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';

export const usePhotoGalleryFormBackButton = () => {
  const navigate = useNavigate();

  const onClickBackButton = useCallback(() => {
    navigate(routes.photoGallery);
  }, [navigate]);

  return {
    onClickBackButton,
  };
};
