import { useFetchPlayerCareersQuery } from 'entities/playerCareers/queries';
import { PlayerCareerModal } from 'feature/playerCareerModal/PlayerCareerModal';
import { PlayerLayout } from 'feature/playerLayout/ui/PlayerLayout/PlayerLayout';
import { usePlayerCareersColumnsConfig } from 'pages/player/careers/PlayerCareersPage/columnsConfig';
import { useCreatePlayerCareer } from 'pages/player/careers/PlayerCareersPage/hooks/useCreatePlayerCareer';
import { PlayerCareersActions } from 'pages/player/careers/PlayerCareersPage/PlayerCareersActions/PlayerCareersActions';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getApiFilters } from 'shared/lib/apiFilters';
import { routes } from 'shared/routes';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './PlayerCareersPage.module.css';
import tableStyles from './PlayerCareersTable.module.css';

export const PlayerCareersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams();
  const onClick = () => {
    navigate(routes.playerTeamAchievements.as(teamId, playerId));
  };

  const {
    data: { data: careers = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchPlayerCareersQuery({
    filter: getApiFilters({
      name: 'player_id',
      value: playerId,
    }),
  });

  const { openCreateModal, createCareerModalProps } = useCreatePlayerCareer();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick,
      }}
    >
      <CommonList
        className={styles.wrapper}
        configCreator={usePlayerCareersColumnsConfig}
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.career')}
        createButtonProps={{
          content: t('content.createCareer'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: careers,
          rowClassName: tableStyles.rowGridTemplate,
          ActionComponent: PlayerCareersActions,
        }}
      />
      <PlayerCareerModal {...createCareerModalProps} />
    </PlayerLayout>
  );
};
