import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { MAX_FILE_SIZE } from 'shared/consts';
import { toBoolean } from 'shared/lib/toBoolean';
import { TWithClassName } from 'shared/types/common';
import { useHandleRouterChange } from 'widgets/photoGalleryForm/ui/hooks/useHandleRouterChange';
import { usePhotoGalleryImageUploading } from 'widgets/photoGalleryForm/ui/hooks/usePhotoGalleryImageUploading';
import { PHOTO_GALLERY_DROPZONE_MAX_FILES_COUNT } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormDropzone/constants';
import { PhotoGalleryFormUploadModal } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormUploadModal/PhotoGalleryFormUploadModal';

type TChildrenCallbackArgs = {
  isDragActive: boolean;
  openedUploadModal: boolean;
  open: VoidFunction;
};

type TChildrenCallback = (args: TChildrenCallbackArgs) => ReactNode;

type TProps = {
  total: number;
  children: TChildrenCallback;
} & TWithClassName;

export const PhotoGalleryFormWrapper = ({ total, children, className }: TProps) => {
  const { t } = useTranslation();
  const maxFiles = Math.max(PHOTO_GALLERY_DROPZONE_MAX_FILES_COUNT - total, 0);
  const {
    openedUploadModal,
    images: { totalUploading, uploadingImages },
    onDrop,
    actions,
  } = usePhotoGalleryImageUploading(maxFiles);
  const { openedConfirmLeaveModal, closeConfirmLeaveModal, confirmLeave } =
    useHandleRouterChange({
      isBlock: totalUploading > 0,
      cancelUploading: actions.cancelAllUploading,
    });

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/webp': ['.webp'],
    },
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    disabled: !toBoolean(maxFiles),
    maxFiles,
    maxSize: MAX_FILE_SIZE,
    onDrop,
  });

  return (
    <div
      {...getRootProps({
        className,
      })}
    >
      <input {...getInputProps()} />
      {children({
        isDragActive,
        openedUploadModal,
        open,
      })}
      <PhotoGalleryFormUploadModal
        actions={actions}
        isOpen={openedUploadModal}
        totalUploading={totalUploading}
        uploadingImages={uploadingImages}
      />
      <ConfirmationModal
        description={t('content.photoGallery.leaveModalDescription')}
        isOpened={openedConfirmLeaveModal}
        title={t('content.photoGallery.leaveModalTitle')}
        onClose={closeConfirmLeaveModal}
        onConfirm={confirmLeave}
      />
    </div>
  );
};
