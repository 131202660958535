import { SwitchField } from 'shared/components/Form/Switch';
import { Text } from 'shared/components/Text/Text';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from '../UIKit.module.css';

export const SwitchExample = () => {
  const { control } = useForm({
    defaultValues: {
      small: false,
      large: false,
      smallDisabledActive: true,
      smallDisabledInactive: false,
      largeDisabledActive: true,
      largeDisabledInactive: false,
    },
  });

  return (
    <div>
      <Text size={24} tag="h2">
        Switch
      </Text>
      <div className={styles.row}>
        <SwitchField control={control} fieldName="small" label="Small" size="s" />
        <SwitchField control={control} fieldName="large" label="Large" size="l" />
        <SwitchField
          control={control}
          disabled
          fieldName="smallDisabledActive"
          label="Small Disabled Active"
          size="s"
        />
        <SwitchField
          control={control}
          disabled
          fieldName="smallDisabledInactive"
          label="Small Disabled Inactive"
          size="s"
        />
        <SwitchField
          control={control}
          disabled
          fieldName="largeDisabledActive"
          label="Large Disabled Active"
          size="l"
        />
        <SwitchField
          control={control}
          disabled
          fieldName="largeDisabledInactive"
          label="Large Disabled Inactive"
          size="l"
        />
      </div>
    </div>
  );
};
