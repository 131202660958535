import { TCreateOpponentTeamDTO } from 'entities/opponentTeams/types';
import { TOpponentTeamModalFields } from 'feature/opponentTeamModal/OpponentTeamModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { mapFileToUpload } from 'shared/utils/image';

export const mapOpponentTeamModalFieldsToDTO = async (
  createTeamModalFields: TOpponentTeamModalFields,
  locales: TContentLang[],
): Promise<TCreateOpponentTeamDTO> => {
  return {
    countryId: createTeamModalFields.countryId,
    translations: reduceTranslationObject(locales, (locale) => ({
      name: createTeamModalFields.name[locale],
      clubName: createTeamModalFields.clubName[locale],
    })),
    picture: createTeamModalFields.picture
      ? await mapFileToUpload(createTeamModalFields.picture)
      : null,
  };
};
