import { TSocialNetworkModalValue } from 'feature/socialNetworkModal/types';
import { useDeleteManagerSocialNetwork } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/hooks/useDeleteManagerSocialNetwork';
import { useEditManagerSocialNetwork } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/hooks/useEditManagerSocialNetwork';
import { TManagerSocialNetworkItem } from 'pages/manager/socialNetworks/ManagerSocialNetworksPage/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';
export const useManagerSocialNetworksActions = ({
  item: managerSocialNetwork,
  onIconClick,
  closeActiveItemId,
  isActive,
}: TTableActions<TManagerSocialNetworkItem>) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { id, managerId, socialNetwork, url } = managerSocialNetwork;
  const { isDeletingManagerSocialNetwork, deleteManagerSocialNetwork } =
    useDeleteManagerSocialNetwork();
  const { isEditingManagerSocialNetwork, editManagerSocialNetwork } =
    useEditManagerSocialNetwork();
  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deleteManagerSocialNetwork(id);
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmEditing = ({
    url: newUrl,
    socialNetworkId: newSocialNetworkId,
  }: TSocialNetworkModalValue) => {
    editManagerSocialNetwork(
      {
        id,
        managerId,
        url: newUrl,
        socialNetworkId: newSocialNetworkId,
      },
      handleCloseEditModal,
    );
  };
  return {
    actionsProps: {
      actions,
      isOpen: isActive,
      isLoading: isDeletingManagerSocialNetwork,
      onIconClick,
      itemId: id,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteSocial'),
    },
    editModalProps: {
      initialValue: {
        url,
        socialNetworkId: toString(socialNetwork.id),
      },
      title: t('content.editLink'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingManagerSocialNetwork,
    },
  };
};
