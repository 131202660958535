import cn from 'classnames';
import { Button, TButtonProps } from 'shared/components/Button/Button';

import styles from './WorkspacePanelPublishButton.module.css';

export type TWorkspacePanelPublishButtonProps = TButtonProps & { isValid?: boolean };

export const WorkspacePanelPublishButton = ({
  isValid,
  children,
  ...otherProps
}: TWorkspacePanelPublishButtonProps) => {
  return (
    <Button
      className={cn(
        typeof isValid !== 'undefined' && {
          [styles.disabled]: !isValid,
        },
      )}
      {...otherProps}
    >
      {children}
    </Button>
  );
};
