import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { TAchievementModalValue } from 'feature/achievementModal/types';
import { useDeletePlayerTeamAchievement } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/hooks/useDeletePlayerTeamAchievement';
import { useEditPlayerTeamAchievement } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/hooks/useEditPlayerTeamAchievement';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const usePlayerTeamAchievementsActions = ({
  onIconClick,
  item: playerTeamAchievement,
  closeActiveItemId,
  isActive,
}: TTableActions<TPlayerTeamAchievement>) => {
  const { t } = useTranslation();
  const { id, playerId, year, translations } = playerTeamAchievement;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);

  const { deletePlayerTeamAchievement, isDeletingPlayerTeamAchievement } =
    useDeletePlayerTeamAchievement();
  const { isEditingPlayerTeamAchievement, editPlayerTeamAchievement } =
    useEditPlayerTeamAchievement();

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerTeamAchievement(id);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    closeActiveItemId();
  };

  const handleConfirmEditing = ({ translations, year }: TAchievementModalValue) => {
    editPlayerTeamAchievement(
      {
        ...playerTeamAchievement,
        playerId: toNumber(playerId),
        year: toNumber(year),
        translations,
      },
      handleCloseEditModal,
    );
  };

  return {
    actionsProps: {
      actions,
      itemId: playerTeamAchievement.id,
      isLoading: isDeletingPlayerTeamAchievement,
      isOpen: isActive,
      onIconClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteAchievement'),
    },
    editModalProps: {
      initialValue: {
        year: toString(year),
        translations,
      },
      title: t('content.editTeamAchievements'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingPlayerTeamAchievement,
    },
  };
};
