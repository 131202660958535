import { endSession } from 'entities/session/model/api';
import { redirect } from 'react-router-dom';
import { getTokenState } from 'shared/api/token';
import { routes } from 'shared/routes';

export const logout = async () => {
  const tokenState = getTokenState();
  try {
    await endSession();
  } catch (e) {
    // не обработано
  }
  tokenState.resetToken();
  return redirect(routes.auth);
};
