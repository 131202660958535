import { Badge } from 'shared/components/Badge/Badge';
import { Text } from 'shared/components/Text/Text';

import styles from '../UIKit.module.css';

export const BadgeExample = () => {
  return (
    <>
      <Text size={24} tag="h2">
        Badge
      </Text>
      <div className={styles.row}>
        <Badge color="green" content="Badge" size="l" type="white" />
        <Badge color="green" content="Badge" size="l" type="outlined" />
        <Badge color="green" content="Badge" size="l" type="soft" />
        <Badge color="green" content="Badge" size="l" type="solid" />
      </div>
      <div className={styles.row}>
        <Badge content="Badge-sm" size="s" type="white" />
        <Badge content="Badge" type="white" />
        <Badge content="Badge-lg" size="l" type="white" />
      </div>
      <div className={styles.row}>
        <Badge content="Badge-sm" shape="pilled" size="s" type="white" />
        <Badge content="Badge" shape="pilled" type="white" />
        <Badge content="Badge-lg" shape="pilled" size="l" type="white" />
      </div>
      <div className={styles.row}>
        <Badge
          color="green"
          content="Badge-sm"
          shape="pilled"
          size="s"
          type="solid"
        />
        <Badge color="green" content="Badge" shape="pilled" type="solid" />
        <Badge
          color="green"
          content="Badge-lg"
          shape="pilled"
          size="l"
          type="solid"
        />
      </div>
      <div className={styles.row}>
        <Badge content="Badge" shape="rounded" size="l" type="white" />
        <Badge content="Badge" shape="pilled" size="l" type="white" />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <div className={styles.badgerGroup}>
          <Badge
            color="dark"
            content="Badge"
            shape="rounded"
            size="m"
            type="outlined"
          />
          <Badge
            color="gray"
            content="Badge"
            shape="rounded"
            size="m"
            type="outlined"
          />
          <Badge
            color="red"
            content="Badge"
            shape="rounded"
            size="m"
            type="outlined"
          />
          <Badge
            color="light"
            content="Badge"
            shape="rounded"
            size="m"
            type="outlined"
          />
          <Badge
            color="green"
            content="Badge"
            shape="rounded"
            size="m"
            type="outlined"
          />
        </div>
        <div className={styles.badgerGroup}>
          <Badge color="dark" content="Badge" shape="rounded" size="m" type="soft" />
          <Badge color="gray" content="Badge" shape="rounded" size="m" type="soft" />
          <Badge color="red" content="Badge" shape="rounded" size="m" type="soft" />
          <Badge
            color="light"
            content="Badge"
            shape="rounded"
            size="m"
            type="soft"
          />
          <Badge
            color="green"
            content="Badge"
            shape="rounded"
            size="m"
            type="soft"
          />
        </div>
        <div className={styles.badgerGroup}>
          <Badge
            color="dark"
            content="Badge"
            shape="rounded"
            size="m"
            type="solid"
          />
          <Badge
            color="gray"
            content="Badge"
            shape="rounded"
            size="m"
            type="solid"
          />
          <Badge color="red" content="Badge" shape="rounded" size="m" type="solid" />
          <Badge
            color="light"
            content="Badge"
            shape="rounded"
            size="m"
            type="solid"
          />
          <Badge
            color="green"
            content="Badge"
            shape="rounded"
            size="m"
            type="solid"
          />
        </div>
        <div className={styles.badgerGroup}>
          <Badge
            color="light"
            content="Badge"
            shape="rounded"
            size="m"
            type="white"
          />
        </div>
      </div>
    </>
  );
};
