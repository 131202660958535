import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import { getRenderActions } from 'feature/workspacePanel/utils';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { AddNewsPostPreviewContent } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewContent';
import { AddNewsPostPublishModal } from 'shared/components/NewsPostForm/components/AddNewsPostPublish/AddNewsPostPublishModal';
import { useCreateNewsPost } from 'shared/components/NewsPostForm/hooks/useCreateNewsPost';
import { useEditNewsPost } from 'shared/components/NewsPostForm/hooks/useEditNewsPost';
import { useGetLocalesControl } from 'shared/components/NewsPostForm/hooks/useGetLocalesControl';
import { useIsEditNewsPost } from 'shared/components/NewsPostForm/hooks/useIsEditNewsPost';
import { useIsPublishNewsPost } from 'shared/components/NewsPostForm/hooks/useIsPublishNewsPost';
import { useNewsWorkspacePanelPublish } from 'shared/components/NewsPostForm/hooks/useNewsWorkspacePanelPublish';
import { useNewsWorkspacePanelUnPublish } from 'shared/components/NewsPostForm/hooks/useNewsWorkspacePanelUnPublish';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { routes } from 'shared/routes';

import styles from './AddNewsPostWorkspacePanel.module.css';

export const AddNewsPostWorkspacePanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const {
    trigger,
    formState: { isValid, isSubmitting },
    handleSubmit,
    control,
  } = useFormContext<TEditNewsPostFields>();
  const localesControlProps = useGetLocalesControl();
  const { field } = useController({
    control,
    name: 'publishDate',
  });
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const isEdit = useIsEditNewsPost();
  const isPublish = useIsPublishNewsPost();
  const createNewsPost = useCreateNewsPost();
  const editNewsPost = useEditNewsPost();

  const {
    publishDate,
    isOpenPublishModal,
    onClosePublishModal,
    isOpenChooseDateModal,
    onCloseChooseDate,
    onClickPublish,
    onDateChange,
    onPublish,
    onClickChooseDate,
  } = useNewsWorkspacePanelPublish({
    trigger,
    control,
    handleSubmit,
    createNewsPost,
    editNewsPost,
    isEdit,
    isPublish,
  });

  const unPublishProps = useNewsWorkspacePanelUnPublish({
    isEdit,
    isPublish,
    editNewsPost,
    control,
    trigger,
    handleSubmit,
  });

  const onDraftNewsPost = () => {
    field.onChange(undefined);
    handleSubmit(createNewsPost)();
  };

  const draftButtonProps = isEdit
    ? undefined
    : {
        isDisabled: isSubmitting,
        children: t('shared.NewsPostForm.AddNewsPostDraftButton.button'),
        onClick: onDraftNewsPost,
        isValid,
      };

  const unpublishButtonProps =
    !isEdit || !isPublish
      ? undefined
      : {
          isValid,
          children: t('content.deleteFromPublication'),
          onClick: unPublishProps?.onClickUnpublishBtn,
          isDisabled: isSubmitting,
        };

  const openPreviewModal = () => {
    setIsOpenPreviewModal(true);
  };

  const closePreviewModal = () => {
    setIsOpenPreviewModal(false);
  };

  const handleBackButtonClick = () => {
    setIsClosing(true);
  };

  const handleBackButtonClose = () => {
    setIsClosing(false);
  };

  const handleBack = () => {
    navigate(routes.news);
  };

  const renderActions = getRenderActions({
    draftButtonProps,
    unpublishButtonProps,
    publishButtonProps: {
      isLoading: isSubmitting,
      isValid: isValid,
      onClick: onClickPublish,
      children: t('shared.confirm'),
    },
    onClickPreviewButton: openPreviewModal,
  });

  return (
    <>
      <WorkspacePanel
        localesControlProps={localesControlProps}
        renderActions={renderActions}
        backButtonProps={{
          onClick: handleBackButtonClick,
        }}
      />
      <ConfirmationModal
        isOpened={isClosing}
        description={t('shared.NewsPostForm.AddNewsPostBackButton.description', {
          mark: '.',
        })}
        title={t('shared.backToRoot.confirmationModal.titleWithMark', {
          mark: '?',
        })}
        onClose={handleBackButtonClose}
        onConfirm={handleBack}
      />
      <OverlayModal
        innerClassName={styles.modalInner}
        opened={isOpenPreviewModal}
        onClose={closePreviewModal}
      >
        <IconButton
          className={styles.closeButton}
          icon="x"
          iconSize="m"
          onClick={closePreviewModal}
        />
        <AddNewsPostPreviewContent />
      </OverlayModal>
      <AddNewsPostPublishModal
        isOpened={isOpenPublishModal}
        onClickChooseDate={onClickChooseDate}
        onClose={onClosePublishModal}
        onPublish={onPublish}
      />
      <DatePicker
        date={publishDate}
        isOpened={isOpenChooseDateModal}
        onClose={onCloseChooseDate}
        onDateChange={onDateChange}
      />
    </>
  );
};
