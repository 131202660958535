import { PlayerLayout } from 'feature/playerLayout/ui/PlayerLayout/PlayerLayout';
import { useCreatePlayer } from 'pages/player/create/CreatePlayerPage/useCreatePlayer';
import { PlayerForm } from 'widgets/playerForm/ui/PlayerForm/PlayerForm';
import { usePlayerForm } from 'widgets/playerForm/ui/PlayerForm/usePlayerForm';

export const CreatePlayerPage = () => {
  const playerForm = usePlayerForm();
  const { createPlayer, isLoading } = useCreatePlayer();

  const createPlayerHandler = () => {
    createPlayer(playerForm.getValues());
  };

  return (
    <PlayerLayout
      confirmButtonProps={{
        isDisabled: !playerForm.formState.isValid,
        onClick: createPlayerHandler,
        isLoading,
      }}
    >
      <PlayerForm control={playerForm.control} />
    </PlayerLayout>
  );
};
