import { fetchPhotoGalleries } from 'entities/photoGallery/api';
import { photoGalleryQueryKeys } from 'entities/photoGallery/consts';
import { TPhotoGalleryTableSortParams } from 'pages/photogallery/PhotoGalleryPage/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchPhotoGalleriesParams = {
  sort:
    | `-${TPhotoGalleryTableSortParams}`
    | TPhotoGalleryTableSortParams
    | undefined;
  filter: Record<string, string>;
};

export const useFetchPhotoGalleriesQuery = (
  { sort, filter }: TFetchPhotoGalleriesParams,
  defaultPagination?: TPaginatedParameters,
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [photoGalleryQueryKeys.photoGallery, pagination, sort, filter],
      queryFn: () =>
        fetchPhotoGalleries({ params: { ...pagination, sort, ...filter } }),
      cacheTime: 0,
    }),
    defaultPagination,
  );
