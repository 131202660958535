import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { TIconSize } from 'shared/components/Icon/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';

type TProps = {
  iconSize?: TIconSize;
  className?: string;
  textToCopy: string;
};

export const CopyButton = ({ iconSize, textToCopy, className }: TProps) => {
  const { t } = useTranslation();

  const [state, copyToClipboard] = useCopyToClipboard();
  return (
    <Tooltip
      tip={state.value === textToCopy ? t('shared.copied') : t('shared.copy')}
    >
      <div>
        <IconButton
          className={className}
          icon="copy"
          iconSize={iconSize}
          onClick={() => copyToClipboard(textToCopy)}
        />
      </div>
    </Tooltip>
  );
};
