import { useApiSelection } from 'feature/auth/lib/useApiSelection';
import styles from 'feature/auth/ui/AuthForm/AuthForm.module.css';
import { API_LIST } from 'shared/api/constants';
import { TAPIName } from 'shared/api/types';
import { CustomSelect as SimpleSelect } from 'shared/components/Form/SimpleSelect/CustomSelect';
import { isProduction } from 'shared/constants';

const labelResolver = (option: { label: string; value: TAPIName }) => option.label;
const valueResolver = (option: { label: string; value: TAPIName }) => option.value;

export const AuthSelectSandbox = () => {
  const { selectedApi, onChangeApi } = useApiSelection();

  if (isProduction) {
    return null;
  }

  return (
    <SimpleSelect
      className={styles.apiSelect}
      labelResolver={labelResolver}
      options={API_LIST}
      placeholder="Select API"
      value={selectedApi}
      valueResolver={valueResolver}
      onChange={onChangeApi}
    />
  );
};
