import { range } from 'lodash';
import { Skeleton } from 'shared/components/Skeleton';
import { TCommonSkeleton } from 'shared/components/Skeleton/types';

import { TCell } from '../TCell';
import { TRow, TTableRowProps as RowProps } from '../TRow/TRow';

export type TTableSkeletonProps = {
  className?: string;
  rowsCount?: number;
  skeletonTemplate: TCommonSkeleton[];
  padding?: RowProps['padding'];
};

export const TSkeleton = ({
  className,
  rowsCount = 3,
  padding = 's',
  skeletonTemplate,
}: TTableSkeletonProps) => {
  const rowsArray = range(rowsCount);
  const skeleton = skeletonTemplate.map((item, index) => (
    <TCell key={index}>
      <Skeleton height={item.height} variant={item.variant} width={item.width} />
    </TCell>
  ));
  return (
    <>
      {rowsArray.map((_, index) => (
        <TRow
          key={index}
          className={className}
          hoverable={false}
          isActive={false}
          padding={padding}
        >
          {skeleton}
        </TRow>
      ))}
    </>
  );
};
