import { mapApiNewsPostToNewsPost } from 'entities/news/mapper/mapApiNewsPostToNewsPost';
import { TAPINewsPost, TNewsPost } from 'entities/news/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TContentLang } from 'shared/types/common';

export const mapNewsPostsResponse = (
  responseData: TResponseData<TAPINewsPost[], TPaginatedMeta>,
  defaultLocale: TContentLang,
): TResponseData<TNewsPost[], TPaginatedMeta> => {
  return {
    ...responseData,
    data: responseData.data.map((apiNewsPost) =>
      mapApiNewsPostToNewsPost(apiNewsPost, defaultLocale),
    ),
  };
};
