import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchManagerPositionsQuery } from 'entities/managerPositions/query';
import { useFetchManagersQuery } from 'entities/managers/api/queries';
import { TEAM_PAGE_GAP } from 'pages/team/teamPage/TeamPage';
import { useCalcItemsCountByParent } from 'pages/team/TeamsPage/useCalcItemsCountByParent';
import { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CARD_WIDTH } from 'shared/components/Card/Card';
import { notify } from 'shared/components/Notification';
import { getApiFilters } from 'shared/lib/apiFilters';
import { compareIds } from 'shared/utils/entityIds';

const CREATE_BUTTON_COUNT = 1;

export const useManagers = (
  wrapperRef: RefObject<HTMLDivElement>,
  teamId: string | undefined,
) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const { isCalculateItems, dynamicItems } = useCalcItemsCountByParent({
    parent: wrapperRef,
    itemWidth: CARD_WIDTH,
    wrapperGap: TEAM_PAGE_GAP,
  });

  const {
    data: { data: managers, meta: { total = 0, pageSize = 0, page = 1 } = {} } = {},
    isLoading,
    changePage,
  } = useFetchManagersQuery(
    {
      pageSize: dynamicItems - CREATE_BUTTON_COUNT,
      filter: getApiFilters({
        name: 'team_id',
        type: 'eq',
        value: teamId,
      }),
    },
    {
      cacheTime: 0,
      enabled: isCalculateItems,
      onError: () => notify(t('content.notifyNotManagersList')),
    },
  );

  const { data: managerPositions, isLoading: isLoadingManagerPositions } =
    useFetchManagerPositionsQuery();

  const isShowPagination = total > 0 && total > pageSize && !isLoading;

  const managersList = useMemo(() => {
    if (managers && managerPositions) {
      return managers.map((manager) => {
        const managerPosition =
          managerPositions.find((position) =>
            compareIds(position.id, manager.managerPosition),
          )?.translations[defaultLocale].jobTitle ?? '';

        return {
          ...manager,
          managerPosition,
        };
      });
    }

    return [];
  }, [managerPositions, managers]);

  return {
    managersList,
    total,
    pageSize,
    page,
    changePage,
    isShowPagination,
    isLoading: isLoadingManagerPositions || isLoading,
  };
};
