import { photoGalleryCategoriesApiKeys } from 'entities/photoGalleryCategories/consts';
import {
  TPhotoGalleryCategory,
  TPhotoGalleryEditCategories,
} from 'entities/photoGalleryCategories/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId, TTranslationsObject } from 'shared/types/common';

export const fetchPhotoGalleryCategories = ({ params }: TFetchParams) =>
  get<TPhotoGalleryCategory[], TPaginatedMeta>(
    photoGalleryCategoriesApiKeys.getPhotoGalleryCategories,
    {
      params,
    },
  ).then((response) => response.data);

export const createPhotoGalleryCategory = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TPhotoGalleryCategory>(
    photoGalleryCategoriesApiKeys.createPhotoGalleryCategory,
    {
      photoGalleryCategory: data,
    },
  ).then((response) => response.data.data);

export const deletePhotoGalleryCategory = (categoryId: TEntityId) =>
  del(photoGalleryCategoriesApiKeys.deletePhotoGalleryCategory(categoryId)).then(
    (response) => response.data.data,
  );

export const editPhotoGalleryCategory = ({
  translations,
  id,
}: TPhotoGalleryEditCategories) =>
  patch(photoGalleryCategoriesApiKeys.editPhotoGalleryCategory(id), {
    photoGalleryCategory: { translations },
  }).then((response) => response.data.data);
