import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { Button } from 'shared/components/Button/Button';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Icon } from 'shared/components/Icon/Icon';
import { useFloat } from 'shared/hooks/useFloat';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';

import styles from './WorkspacePanelPreviewSelect.module.css';

export const PREVIEW_SIZES: TPreviewSizeOption[] = [
  { id: 1, width: 430, height: 932, label: 'iPhone 14 Pro Max' },
  { id: 2, width: 393, height: 852, label: 'iPhone 14 Pro' },
  { id: 3, width: 428, height: 926, label: 'iPhone 14 Plus' },
  { id: 4, width: 390, height: 844, label: 'iPhone 14' },
  { id: 5, width: 428, height: 926, label: 'iPhone 13 Pro Max' },
  { id: 6, width: 390, height: 844, label: 'iPhone 13/13 Pro' },
  { id: 7, width: 375, height: 812, label: 'iPhone 13 mini' },
  { id: 8, width: 320, height: 568, label: 'iPhone SE' },
  { id: 9, width: 375, height: 667, label: 'iPhone 8' },
  { id: 10, width: 360, height: 640, label: 'Android Small' },
  { id: 11, width: 360, height: 800, label: 'Android Large' },
];

export type TPreviewSize = {
  width: number;
  height: number;
};

export type TPreviewSizeOption = {
  id: number;
  label: string;
} & TPreviewSize;

type TProps = {
  onShowPreview: (option: TPreviewSizeOption) => void;
};

export const WorkspacePanelPreviewSelect = ({ onShowPreview }: TProps) => {
  const { t } = useTranslation();
  const [previewSizeId, setPreviewSizeId] = useState(1);
  const [isOpen, toggleOpened] = useToggle(false);

  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    offset: 8,
    placement: 'bottom',
    onChange: toggleOpened,
  });

  const showPreview = () => {
    const previewSize = PREVIEW_SIZES.find((size) => size.id === previewSizeId);
    if (previewSize) {
      onShowPreview(previewSize);
      toggleOpened(false);
    }
  };

  const handleSelect = (_name: string, value: string | number) => {
    setPreviewSizeId(toNumber(value));
  };

  return (
    <>
      <div
        className={cn(styles.control, { [styles.active]: isOpen })}
        ref={triggerRef}
        {...trigger}
      >
        <Icon kind="play" size="m" />
        <Icon kind={isOpen ? 'chevron-up' : 'chevron-down'} size="s" />
      </div>
      {isOpen && (
        <FloatingPortal>
          <Dropdown className={styles.dropdown} {...floating}>
            {PREVIEW_SIZES.map((size, index) => (
              <div key={index} className={styles.option}>
                <RadioButton
                  checked={size.id === previewSizeId}
                  label={size.label}
                  name="previewSize"
                  value={toString(size.id)}
                  onChange={handleSelect}
                />
                <div>{`${size.width}x${size.height}`}</div>
              </div>
            ))}
            <Button className={styles.button} onClick={showPreview}>
              {t('shared.confirm')}
            </Button>
          </Dropdown>
        </FloatingPortal>
      )}
    </>
  );
};
