import cn from 'classnames';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { InlineChromiumBugfix } from 'shared/components/Editor/utils/editor/InlineChromiumBugfix';
import { RenderElementProps, useSelected } from 'slate-react';

import styles from './EditorLinkElement.module.css';

export const EditorLinkElement = (props: RenderElementProps) => {
  const selected = useSelected();

  if (props.element.type !== EditorElementTypeEnum.Link) {
    return null;
  }

  return (
    <a
      {...props.attributes}
      href={props.element.url}
      className={cn({
        [styles.selected]: selected,
      })}
    >
      <InlineChromiumBugfix />
      {props.children}
      <InlineChromiumBugfix />
    </a>
  );
};
