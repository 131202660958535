import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { useLocales } from 'entities/config/lib/useLocales';
import { EPlayerStatus } from 'entities/players/model/types';
import { useParams } from 'react-router-dom';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toBoolean } from 'shared/lib/toBoolean';
import { TContentLang } from 'shared/types/common';
import { TPlayerFormFields } from 'widgets/playerForm/types/types';
import { playerFormValidationSchema } from 'widgets/playerForm/ui/PlayerForm/validationSchema';
export type TPlayerFormProps = {
  initialValues?: TPlayerFormFields;
};
const generateDefaultValues = (
  locales: TContentLang[],
  teamId: string,
): TPlayerFormFields => ({
  firstName: reduceTranslationObject(locales, () => ''),
  lastName: reduceTranslationObject(locales, () => ''),
  countryId: '',
  number: '',
  weight: '',
  height: '',
  teamId,
  playerPositionId: '',
  status: EPlayerStatus.PartOfTeam,
  discarded: 'false',
});
export const usePlayerForm = ({ initialValues }: TPlayerFormProps = {}) => {
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const { config } = useConfig();
  const { locales } = useLocales();
  return useForm<TPlayerFormFields>({
    defaultValues: generateDefaultValues(locales, teamId ?? ''),
    values: initialValues,
    mode: 'onChange',
    resolver: zodResolver(
      playerFormValidationSchema(locales, config!.whitelistDomains),
    ),
    validateOnLoad: toBoolean(initialValues),
  });
};
