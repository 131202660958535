import { AchievementModal } from 'feature/achievementModal/AchievementModal';
import { PlayerLayout } from 'feature/playerLayout/ui/PlayerLayout/PlayerLayout';
import { usePlayerTeamAchievementsColumnsConfig } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/columnsConfig';
import { useCreatePlayerTeamAchievement } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/hooks/useCreatePlayerTeamAchievement';
import { usePlayerTeamAchievements } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/hooks/usePlayerTeamAchievements';
import { PlayerTeamAchievementsActions } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/PlayerTeamAchievementsActions';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './PlayerTeamAchievementsPage.module.css';
import tableStyles from './PlayerTeamAchievementsTable.module.css';

export const PlayerTeamAchievementsPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    playerTeamAchievements,
    page,
    total,
    handleConfirmButtonClick,
  } = usePlayerTeamAchievements();
  const { createTeamAchievementModalProps, openCreateAchievementModal } =
    useCreatePlayerTeamAchievement();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonList
        className={styles.wrapper}
        configCreator={usePlayerTeamAchievementsColumnsConfig}
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.teamAchievements')}
        createButtonProps={{
          content: t('content.createAchievement'),
          onClick: openCreateAchievementModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(playerTeamAchievements, page),
          totalItems: calculateTotalItems(playerTeamAchievements, total),
          onPageChange: changePage,
        }}
        tableProps={{
          wrapperClassName: tableStyles.root,
          data: playerTeamAchievements,
          headRowClassName: tableStyles.headRow,
          rowClassName: tableStyles.rowGridTemplate,
          ActionComponent: PlayerTeamAchievementsActions,
        }}
      />
      {createTeamAchievementModalProps.isOpened && (
        <AchievementModal {...createTeamAchievementModalProps} />
      )}
    </PlayerLayout>
  );
};
