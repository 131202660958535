import { Brick } from 'shared/components/Skeleton/components/Brick';
import { TCommonSkeleton } from 'shared/components/Skeleton/types';

export const Skeleton = ({ variant, width, height }: TCommonSkeleton) => {
  switch (variant) {
    case 'round': {
      return <Brick height={height} round width={width} />;
    }
    default: {
      return <Brick height={height} width={width} />;
    }
  }
};
