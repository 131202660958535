import { useLocales } from 'entities/config/lib/useLocales';
import { TVideoPost } from 'entities/video/types';
import { ImportanceFilter } from 'feature/filters/ui/ImportanceFilter/ImportanceFilter';
import { TVideoFilter, TVideoTableSortParams } from 'pages/video/VideoPage/types';
import { VideoCategoryFilter } from 'pages/video/VideoPage/VideoCategoryFilter';
import { useTranslation } from 'react-i18next';
import { Badge } from 'shared/components/Badge/Badge';
import { Icon } from 'shared/components/Icon/Icon';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './VideoTable.module.css';

export const useVideoColumnsConfig: TConfigCreator<
  TVideoPost,
  TVideoTableSortParams,
  TVideoFilter
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  return [
    {
      title: t('content.title'),
      render: (item) => (
        <div className={styles.publicationNameColumn}>
          {item.pictures.base ? (
            <div className={styles.previewWrapper}>
              <img
                alt={`${item.title} preview`}
                className={styles.preview}
                src={item.pictures.base.x1}
              />
            </div>
          ) : (
            <div className={styles.previewWrapper}>
              <Icon kind="picture" size="s" />
            </div>
          )}
          <Text className={styles.title}>{item.title}</Text>
        </div>
      ),
      headCellProps: {
        className: styles.publicationNameColumn,
      },
    },
    {
      title: t('content.category'),
      render: (item) => (
        <Text className={styles.categories}>
          {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
        </Text>
      ),
      headCellProps: {
        filter: {
          filterName: 'categories',
          getIsFiltered: (filter) =>
            toBoolean(filter.categories && filter.categories.length > 0),
          getFilterBadge: (filter) =>
            filter.categories?.length ? toString(filter.categories?.length) : null,
          FilterComponent: VideoCategoryFilter,
        },
      },
    },
    {
      title: t('content.createDate'),
      render: (item) => <DateTimeCell dateTimeString={item.createdAt ?? ''} />,
      headCellProps: {
        sort: {
          sortParam: 'created_at',
        },
      },
    },
    {
      title: t('content.importance'),
      render: (item) =>
        item.importance > 0 ? (
          <Badge
            color="green"
            content={t('content.slider')}
            size="s"
            type="outlined"
          />
        ) : null,
      headCellProps: {
        filter: {
          filterName: 'importance',
          filterWidth: 162,
          getIsFiltered: (filter) => toBoolean(filter.importance),
          getFilterBadge: (filter) => (filter.importance ? ' ' : null),
          FilterComponent: ImportanceFilter<TVideoFilter>,
        },
      },
    },
    {
      title: t('content.status'),
      render: (item) => <Status status={item.status} />,
      /* TODO: расскоментировать в рамках доработки фильтра по статусу.
          Сейчас бек рассчитан только на выбор 1го фильтра, необходимо реализовать
          выбор нескольких фильтров на сервере и доработать под это клиент */
      // headCellProps: {
      //   filterName: 'status',
      //   getIsFiltered: (filter) =>
      //     filter.status && filter.status !== EListStatus.All,
      //   getFilterBadge: (filter) => (filter.status ? ' ' : null),
      //   filterComponent: StatusFilter,
      // },
    },
    {
      title: t('content.publicDate'),
      render: (item) => <DateTimeCell dateTimeString={item?.published ?? ''} />,
      headCellProps: {
        sort: {
          sortParam: 'published',
        },
      },
    },
    {
      title: 'ID',
      render: (item) => item.id,
    },
  ];
};
