import i18n from 'i18next';
import { notify } from 'shared/components/Notification';

export const onBlurVideoLinkHandler = (isValidLink: boolean, value: string) => {
  if (!isValidLink && value) {
    notify(i18n.t('shared.handlers.notify'), {
      type: 'error',
    });
  }
};
