import { TPlayerCreateCareerDTO } from 'entities/playerCareers/types';
import { TPlayerCareerModalFields } from 'feature/playerCareerModal/PlayerCareerModal';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang, TEntityId } from 'shared/types/common';

const MOCK_FIELD = '_';

export type TMapCreatePlayerDTOProps = {
  data: TPlayerCareerModalFields;
  locales: TContentLang[];
  playerId: TEntityId;
};

export const mapCreatePlayerCareerDTO = async ({
  playerId,
  locales,
  data,
}: TMapCreatePlayerDTOProps): Promise<TPlayerCreateCareerDTO> => {
  const base64 = await getBase64FromFile(data?.teamPicture);

  return {
    career: {
      ...data,
      playerId,
      translations: reduceTranslationObject(locales, (locale) => ({
        teamName: data?.translations?.[locale]?.teamName,
        jobTitle: MOCK_FIELD,
      })),
      teamPicture: {
        filename: data.teamPicture!.name,
        data: clearMetaDataFromBase64Url(base64),
      },
    },
  };
};
