import { TFilterComponentProps } from 'feature/filters/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Status } from 'shared/components/Status';
import { EListStatus, TContentStatus } from 'shared/types/common';

import styles from '../Filter.module.css';

export const STATUSES: TContentStatus[] = [
  EListStatus.Postponed,
  EListStatus.Published,
  EListStatus.Draft,
];

type TProps<FilterType> = TFilterComponentProps<FilterType>;

export const StatusFilter = <FilterType extends { status: TContentStatus }>({
  filter,
  setFilter,
}: TProps<FilterType>) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<TContentStatus>(
    filter.status ?? EListStatus.Postponed,
  );

  const onSubmit = () => {
    setFilter({ ...filter, status: filterValue });
  };

  const onClick = (_name: string, value: TContentStatus) => {
    setFilterValue(value);
  };

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      {STATUSES.map((status) => (
        <RadioButton
          key={status}
          checked={filterValue === status}
          className={styles.radio}
          name={status}
          value={status}
          label={
            <Status containerClassName={styles.status} size={14} status={status} />
          }
          onChange={onClick}
        />
      ))}
      <Button htmlType="submit" onClick={onSubmit}>
        {t('shared.confirm')}
      </Button>
    </form>
  );
};
