import cn from 'classnames';
import { MouseEvent } from 'react';
import { OnChangeEyedrop, useEyeDrop } from 'react-eyedrop';
import { Icon } from 'shared/components/Icon/Icon';

import styles from './EyeDropper.module.css';

type TProps = {
  className?: string;
  setIsEyeDropActive: (active: boolean) => void;
  onColorPick: (value: string) => void;
};
export const EyeDropper = ({
  className,
  setIsEyeDropActive,
  onColorPick,
}: TProps) => {
  const handlePickStart = () => {
    setIsEyeDropActive(true);
  };
  const handlePickEnd = () => {
    setIsEyeDropActive(false);
  };

  const handleChange = (changes: OnChangeEyedrop) => {
    onColorPick(changes.hex);
  };
  const [_colors, pickColor] = useEyeDrop({
    once: true,
    cursorActive: 'crosshair',
    onPickStart: handlePickStart,
    onPickEnd: handlePickEnd,
    onChange: handleChange,
  });
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    pickColor();
  };
  return (
    <button
      className={cn(styles.root, className)}
      type="button"
      onClick={handleClick}
    >
      <Icon className={styles.icon} kind="dropper" size="s" />
    </button>
  );
};
