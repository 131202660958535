import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { KeyboardEventHandler, useCallback } from 'react';
import { TEditorImageElementProps } from 'shared/components/Editor/elements/EditorImageElement/EditorImageElement';
import { editorRenderElements } from 'shared/components/Editor/elements/editorRenderElements';
import { editorRenderLeaf } from 'shared/components/Editor/elements/editorRenderLeaf';
import { editorRenderPlaceholder } from 'shared/components/Editor/elements/editorRenderPlaceholder';
import { TEditorSliderElementProps } from 'shared/components/Editor/elements/EditorSliderElement/EditorSliderElement';
import { TEditorVideoElementProps } from 'shared/components/Editor/elements/EditorVideoElement/EditorVideoElement';
import { editorEnterHandler } from 'shared/components/Editor/utils/editor/editorEnterHandler';
import { editorKeyDownBugfix } from 'shared/components/Editor/utils/editor/editorKeyDownBugfix';
import { TEditorDisableStructureChange } from 'shared/components/Editor/utils/structureChange/types';
import { useDisableStructureChange } from 'shared/components/Editor/utils/structureChange/useDisableStructureChange';
import { Editable, RenderElementProps, useSlate } from 'slate-react';

import styles from './Editor.module.css';
import defaultElementsStyles from './elements/editorElementsStyle.module.css';

type TProps = {
  className?: string;
  placeholder?: string;
};

export const Editor = ({
  className,
  placeholder,
  disabledStructureChange = false,
  ...editorElementsProps
}: TProps &
  TEditorImageElementProps &
  TEditorSliderElementProps &
  TEditorVideoElementProps &
  TEditorDisableStructureChange) => {
  const editor = useSlate();
  const { defaultLocale } = useLocales();
  useDisableStructureChange(disabledStructureChange, editor);

  const renderElement = useCallback(
    (props: RenderElementProps) =>
      // TODO: delete changeImage and changeSlider
      editorRenderElements({
        ...props,
        ...editorElementsProps,
        disabledStructureChange,
      }),
    [disabledStructureChange],
  );

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      editorKeyDownBugfix(editor, event);
      editorEnterHandler(editor, event, defaultLocale, disabledStructureChange);
    },
    [editor],
  );

  return (
    <div>
      <Editable
        className={cn(styles.editor, defaultElementsStyles.root, className)}
        placeholder={placeholder}
        renderElement={renderElement}
        renderLeaf={editorRenderLeaf}
        renderPlaceholder={editorRenderPlaceholder}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};
