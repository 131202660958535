import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { createLabel, deleteLabel, editLabel } from 'entities/labels/api';
import { labelsQueryKeys } from 'entities/labels/consts';

export const useCreateLabelsMutation = () => {
  return useMutation({
    mutationFn: createLabel,
    onSuccess: () => {
      return queryClient.invalidateQueries([labelsQueryKeys.labels]);
    },
  });
};

export const useEditLabelsMutation = () => {
  return useMutation({
    mutationFn: editLabel,
    onSuccess: () => {
      return queryClient.invalidateQueries([labelsQueryKeys.labels]);
    },
  });
};

export const useDeleteLabelsMutation = () => {
  return useMutation({
    mutationFn: deleteLabel,
    onSuccess: () => {
      return queryClient.invalidateQueries([labelsQueryKeys.labels]);
    },
  });
};
