import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createPlayerAchievement,
  deletePlayerAchievement,
  editPlayerAchievement,
} from 'entities/playerAchievements/api';
import { playerAchievementsQueryKeys } from 'entities/playerAchievements/consts';

export const useCreatePlayerAchievementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPlayerAchievement,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        playerAchievementsQueryKeys.playerAchievements,
      ]);
    },
  });
};

export const useDeletePlayerAchievementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePlayerAchievement,
    onSuccess: () =>
      queryClient.resetQueries([playerAchievementsQueryKeys.playerAchievements]),
  });
};

export const useEditPlayerAchievementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editPlayerAchievement,
    onSuccess: () =>
      queryClient.resetQueries([playerAchievementsQueryKeys.playerAchievements]),
  });
};
