import { useLocales } from 'entities/config/lib/useLocales';
import { mapAPIVideoPostToVideoPostFields } from 'entities/video/mapper/mapAPIVideoPostToVideoPostFields';
import { TVideoPost } from 'entities/video/types';
import { useCallback, useState } from 'react';
import { useEditVideoPost } from 'widgets/videoForm/hooks/useEditVideoPost';

type TUsePublishVideo = {
  closeConfirmationModal: () => void;
  videoPost: TVideoPost;
};

export const usePublishVideo = ({
  videoPost,
  closeConfirmationModal,
}: TUsePublishVideo) => {
  const { defaultLocale } = useLocales();
  const [isPublishing, setPublishing] = useState(false);
  const editVideoPost = useEditVideoPost();

  return {
    changeVideoPublishDate: useCallback(async (publishDate?: Date) => {
      setPublishing(true);

      const initialValue = await mapAPIVideoPostToVideoPostFields(
        videoPost,
        defaultLocale,
      );

      closeConfirmationModal();

      await editVideoPost({
        ...initialValue,
        publishDate,
      });

      setPublishing(false);
    }, []),
    isPublishing,
  };
};
