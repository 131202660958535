import { ReactElement, ReactNode } from 'react';
import { TooltipContent } from 'shared/components/Tooltip/TooltipContent';
import { TooltipContext } from 'shared/components/Tooltip/TooltipContext';
import { TooltipTrigger } from 'shared/components/Tooltip/TooltipTrigger';
import { TTooltipOptions, useTooltip } from 'shared/components/Tooltip/useTooltip';

type TProps = {
  children: ReactElement;
  tip: ReactNode;
  contentClassName?: string;
};

export const Tooltip = ({
  children,
  tip,
  contentClassName,
  ...options
}: TProps & TTooltipOptions) => {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent className={contentClassName}>{tip}</TooltipContent>
    </TooltipContext.Provider>
  );
};
