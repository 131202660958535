import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';
import { PhotoGalleryFormDropzone } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormDropzone/PhotoGalleryFormDropzone';

import styles from './PhotoGalleryFormWorkspace.module.css';

export const PhotoGalleryFormWorkspace = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<TPhotoGalleryFields>();
  const currentLocale = watch('locale.selected');

  return (
    <div className={styles.content}>
      <FormInput
        control={control}
        fieldName={`translations.${currentLocale}.title`}
        label={t('content.photoGallery.workspaceInputLabel')}
        maxLength={255}
        placeholder={t('content.photoGallery.workspaceInputPlaceholder')}
        required
        showErrors
        size="l"
      />
      <PhotoGalleryFormDropzone />
    </div>
  );
};
