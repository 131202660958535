import i18n from 'i18next';
import { getTextMinLengthMessage } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

const getTextMaxNumberLengthMessage = () =>
  i18n.t('shared.validation.warningMaxSize');

const localesConverter = (locales: TContentLang[]) => {
  return reduceTranslationObject(locales, () =>
    z.object({ teamName: z.string().min(1, getTextMinLengthMessage()) }),
  );
};

export const playerCareerModalValidationSchema = (locales: TContentLang[]) =>
  z
    .object({
      translations: z.object({ ...localesConverter(locales) }),
      teamPicture: fileZodValidation(i18n.t('shared.validation.preview')),
      season: z.string().min(1, getTextMinLengthMessage()),
      championshipCount: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(3, getTextMaxNumberLengthMessage()),
      cupsCount: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(3, getTextMaxNumberLengthMessage()),
      eurocupsCount: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(3, getTextMaxNumberLengthMessage()),
    })
    .passthrough();
