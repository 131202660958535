import cn from 'classnames';
import { useState } from 'react';
import { Button } from 'shared/components/Button/Button';
import { ImageModal } from 'shared/components/ImageModal/ImageModal';

import styles from './PreviewImageButton.module.css';

type TProps = {
  url?: string;
  className?: string;
};

export const PreviewImageIconButton = ({ url, className }: TProps) => {
  const [isShowImagePreviewModal, setIsShowImagePreviewModal] = useState(false);

  const showPreviewHandler = () => {
    setIsShowImagePreviewModal(true);
  };

  return (
    <>
      <Button
        className={cn(styles.previewIconButton, className)}
        color="gray"
        icon="play"
        iconPosition="after"
        isDisabled={!url}
        kind="light"
        size="s"
        onClick={showPreviewHandler}
      />
      <ImageModal
        imageUrl={url ?? ''}
        isShow={isShowImagePreviewModal}
        onClose={() => setIsShowImagePreviewModal(false)}
      />
    </>
  );
};
