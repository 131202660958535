import { useQuery } from '@tanstack/react-query';
import { tournamentQueryKeys } from 'entities/tournaments/consts';
import { TFetchTournamentsParams } from 'entities/tournaments/types';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchTournament, fetchTournaments } from './api';

export const useFetchTournamentsQuery = <T extends TFetchTournamentsParams>(
  params: T,
) =>
  useQuery({
    queryKey: [tournamentQueryKeys.tournaments(params)],
    queryFn: () => fetchTournaments(params),
  });

export const useFetchTournamentQuery = (id: TEntityId) =>
  useQuery({
    queryKey: [tournamentQueryKeys.tournament(id)],
    queryFn: () => fetchTournament(id),
    enabled: toBoolean(id),
  });
