import {
  EditorElementTypeEnum,
  TEditor,
  TEditorImage,
  TEditorImageElement,
} from 'shared/components/Editor/types';
import { createImageElement } from 'shared/components/Editor/utils/image/createImageElement';
import { editorParagraphController } from 'shared/components/Editor/utils/paragraph/editorParagraphController';
import { toBoolean } from 'shared/lib/toBoolean';
import { TContentLang } from 'shared/types/common';
import { Element, Node, Transforms } from 'slate';

export const editorImageController = {
  isImageElement(node?: Node): node is TEditorImageElement {
    return toBoolean(
      node && Element.isElement(node) && node.type === EditorElementTypeEnum.Image,
    );
  },

  insertImage(editor: TEditor, file: TEditorImage, defaultLocale: TContentLang) {
    const imageElement = createImageElement(file);
    Transforms.insertNodes(editor, imageElement);
    editorParagraphController.insertParagraph(editor, '', defaultLocale);
  },

  changeImage(editor: TEditor, path: number[], file: TEditorImage) {
    Transforms.setNodes(
      editor,
      {
        file,
      },
      { at: path },
    );
  },
};
