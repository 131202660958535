import { TEntityId } from 'shared/types/common';

const photoGalleryBaseUrl = 'photo_galleries';

export const photoGalleryQueryKeys = {
  photoGallery: 'photoGallery',
  editPhotoGallery: 'editPhotoGallery',
};

export const photoGalleryApiKeys = {
  getPhotoGalleries: photoGalleryBaseUrl,
  deletePhotoGallery: (photoGalleryId: TEntityId) =>
    `${photoGalleryBaseUrl}/${photoGalleryId}`,
  createPhotoGallery: photoGalleryBaseUrl,
  editPhotoGallery: (photoGalleryId: TEntityId) =>
    `${photoGalleryBaseUrl}/${photoGalleryId}`,
  getPhotoGallery: (photoGalleryId: TEntityId) =>
    `${photoGalleryBaseUrl}/${photoGalleryId}`,
};
