import cn from 'classnames';
import { ReactElement, useMemo } from 'react';
import { TabsContext } from 'shared/components/Tabs/context';
import { notFalsy } from 'shared/guards';

import { Tab } from './Tab';
import styles from './Tabs.module.css';
import { TTabsProps } from './types';

type TTabsWithComponents = ((props: TTabsProps) => ReactElement) & {
  Tab: typeof Tab;
};

export const Tabs: TTabsWithComponents = ({
  selected,
  onSelect,
  children,
  className,
}: TTabsProps) => {
  const tabsContext = useMemo(() => ({ selected, onSelect }), [onSelect, selected]);
  return (
    <div className={cn(styles.root, className)}>
      <TabsContext.Provider value={tabsContext}>
        <ul className={cn(styles.tabList)}>{children}</ul>
      </TabsContext.Provider>
      {children.filter(notFalsy).map((child) => {
        if (selected === child.props.value) {
          return child.props.children;
        }

        return null;
      })}
    </div>
  );
};

Tabs.Tab = Tab;
