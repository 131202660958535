import { compareAsc, parseISO } from 'date-fns';
import { EVideoPostStatus } from 'entities/video/types';

export const getVideoPostStatus = (date: string): EVideoPostStatus => {
  if (!date) {
    return EVideoPostStatus.Draft;
  }
  const currentDate = parseISO(new Date().toISOString());
  const publishDate = parseISO(date);

  if (compareAsc(currentDate, publishDate) > 0) {
    return EVideoPostStatus.Published;
  }

  return EVideoPostStatus.Postponed;
};
