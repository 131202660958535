import { stadiumsApiKeys } from 'entities/stadiums/consts';
import { TStadium } from 'entities/stadiums/types';
import { get, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TTranslationsObject } from 'shared/types/common';

export const fetchStadiums = ({ params }: TFetchParams) =>
  get<TStadium[], TPaginatedMeta>(stadiumsApiKeys.getStadiums, {
    params,
  }).then((response) => response.data);

export const createStadium = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TStadium>(stadiumsApiKeys.createStadium, { stadium: data }).then(
    (response) => response.data.data,
  );
