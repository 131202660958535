import { useEditPlayerTeamAchievementMutation } from 'entities/playerTeamAchievements/mutations';
import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useEditPlayerTeamAchievement = () => {
  const { t } = useTranslation();
  const { mutate: editPlayerTeamAchievementMutation, isLoading } =
    useEditPlayerTeamAchievementMutation();

  const editPlayerTeamAchievement = (
    playerTeamAchievement: TPlayerTeamAchievement,
    onSuccess: () => void,
  ) => {
    editPlayerTeamAchievementMutation(playerTeamAchievement, {
      onSuccess,
      onError: () =>
        notify(t('content.player.notifyNotUpdateTeamAchievements'), {
          type: 'error',
        }),
    });
  };

  return { isEditingPlayerTeamAchievement: isLoading, editPlayerTeamAchievement };
};
