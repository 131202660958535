import { useMemo, useState } from 'react';
import { TSelectOption } from 'shared/components/Form/Select/types';

export const useSelectSearch = (options: TSelectOption[]) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearch = (value: string) => {
    setSearchValue(value.toLowerCase());
  };

  const isOptionSearched = (option: TSelectOption) => {
    return option.label.toLowerCase().startsWith(searchValue);
  };

  const searchedOptions = useMemo(() => {
    if (!searchValue) {
      return options;
    }

    return options.filter(isOptionSearched);
  }, [options, searchValue]);

  return {
    onSearch,
    resetSearch: () => {
      setSearchValue('');
    },
    searchedOptions,
  };
};
