import { fetchUser } from 'entities/user/model/api';
import { useUserStore } from 'entities/user/model/store';
import { useEffect, useState } from 'react';
import { getTokenState, useToken } from 'shared/api/token';

const resetToken = getTokenState().resetToken;

export const useUser = () => {
  const token = useToken();
  const [isLoading, setIsLoading] = useState(true);

  const { user, setUser } = useUserStore((state) => state);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      fetchUser()
        .then((user) => {
          setUser(user);
        })
        .catch(resetToken)
        .finally(() => setIsLoading(false));
    }
  }, [token]);

  return { user, isLoading };
};
