import { GenericAbortSignal } from 'axios';
import { useCreatePhotoGalleryImageMutation } from 'entities/photoGalleryImages/mutations';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

type TCreateImageArgs = {
  image: File;
  signal: GenericAbortSignal;
};

export const useCreatePhotoGalleryImage = () => {
  const { photoGalleryId } = useParams<{ photoGalleryId: string }>();
  const { getValues } = useFormContext<TPhotoGalleryFields>();

  const { mutateAsync: createPhotoGalleryImage } =
    useCreatePhotoGalleryImageMutation();

  return async ({ image, signal }: TCreateImageArgs) => {
    const data = clearMetaDataFromBase64Url(await getBase64FromFile(image));

    await createPhotoGalleryImage({
      signal,
      photoGalleryId: photoGalleryId ?? getValues('id'),
      picture: {
        filename: image.name,
        data,
      },
    });
  };
};
