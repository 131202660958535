import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Text } from 'shared/components/Text/Text';
import { TSecondLayoutNavigationLink } from 'shared/ui/layouts/secondLayout/types';

import styles from './SecondLayoutNavigationLink.module.css';

type TProps = {
  link: TSecondLayoutNavigationLink;
};

export const SecondLayoutNavigationLink = ({ link }: TProps) => {
  return (
    <li>
      <NavLink
        end
        to={link.route}
        className={({ isActive }) =>
          cn(styles.link, {
            [styles.isActive]: isActive,
            [styles.disabled]: link.disabled,
          })
        }
      >
        <Text size={12}>{link.name}</Text>
      </NavLink>
    </li>
  );
};
