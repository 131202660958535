import { findKey } from 'lodash';
import { HEX_TRANSPARENCIES } from 'shared/components/ColorPicker/constants';
import { toNumber } from 'shared/lib/toNumber';

export const opacityFromHex = (hexOpacity: string): number => {
  const opacityNumber = findKey(HEX_TRANSPARENCIES, (value) => value === hexOpacity);
  if (!opacityNumber) {
    return 100;
  }

  return toNumber(opacityNumber);
};
