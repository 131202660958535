import { newsCategoriesQueryKeys } from 'entities/newsCategories/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

import { fetchNewsCategories } from './api';

export const useFetchNewsCategoriesQuery = (
  defaultPagination?: TPaginatedParameters,
) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [newsCategoriesQueryKeys.newsCategories, pagination],
      queryFn: () =>
        fetchNewsCategories({ params: { ...pagination, sort: 'name' } }),
      cacheTime: 0,
    }),
    defaultPagination,
  );
};
