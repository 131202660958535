import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';

type TProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const UnpublishConfirmationModal = ({
  isOpened,
  onClose,
  onConfirm,
}: TProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      description={t('shared.NewsPostForm.AddNewsPostUnPublishButton.description')}
      isOpened={isOpened}
      title={t('shared.NewsPostForm.AddNewsPostUnPublishButton.title', {
        mark: '?',
      })}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
