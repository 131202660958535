import { useLocales } from 'entities/config/lib/useLocales';
import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './ManagerTeamAchievementsPage.module.css';

export const useManagerTeamAchievementsColumnsConfig: TConfigCreator<
  TManagerTeamAchievement
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  return [
    {
      title: t('content.title'),
      cellClassName: styles.nameColumn,
      render: (item) => (
        <Text className={styles.text} color="var(--gray-500)">
          {item.translations?.[defaultLocale]?.title}
        </Text>
      ),
    },
    {
      title: t('content.year'),
      render: (item) => <Text color="var(--gray-500)">{item.year}</Text>,
    },
    {
      title: 'ID',
      render: (item) => <Text color="var(--gray-500)">{item.id}</Text>,
    },
  ];
};
