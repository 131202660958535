import { useCreatePlayerSocialNetworkMutation } from 'entities/playerSocialNetworks/mutations';
import { TSocialNetworkModalValue } from 'feature/socialNetworkModal/types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { notify } from 'shared/components/Notification';

export const useCreatePlayerSocialNetwork = () => {
  const { t } = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useBoolean(false);
  const { playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();
  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const { mutate: createPlayerSocialNetworkMutation, isLoading } =
    useCreatePlayerSocialNetworkMutation();

  const createPlayerSocialNetwork = (socialNetwork: TSocialNetworkModalValue) => {
    createPlayerSocialNetworkMutation(
      {
        ...socialNetwork,
        playerId,
      },
      {
        onError: () =>
          notify(t('content.player.notifyNotCreateSocial'), { type: 'error' }),
        onSuccess: closeCreateModal,
      },
    );
  };

  return {
    openCreateModal,
    createSocialNetworkModalProps: {
      title: t('content.addLink'),
      closeModal: closeCreateModal,
      actionSubmit: createPlayerSocialNetwork,
      isOpened: isCreateModalOpen,
      isLoading,
      initialValue: {
        url: 'https://',
        socialNetworkId: '',
      },
    },
  };
};
