import cn from 'classnames';
import { forwardRef, HTMLAttributes, useRef } from 'react';
import formCommonStyles from 'shared/components/Form/FormCommonStyles.module.css';
import styles from 'shared/components/Form/Select/Select.module.css';
import { TBaseSelectProps } from 'shared/components/Form/Select/types';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TWithOptionalChildren } from 'shared/types/common';

export const BaseSelect = forwardRef<
  HTMLDivElement,
  Omit<HTMLAttributes<HTMLDivElement>, 'placeholder'> &
    TWithOptionalChildren &
    TBaseSelectProps
>(
  (
    {
      placeholder,
      size = 's',
      icon,
      isActive,
      className,
      inputClassName,
      children,
      search = false,
      onSearch,
      name,
      placeholderClassName,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const placeholderIsString = typeof placeholder === 'string';

    const handleFocusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    return (
      <div
        className={cn(
          styles.root,
          {
            [styles.disabled]: disabled,
          },
          className,
        )}
        onClick={handleFocusInput}
      >
        <div
          ref={ref}
          className={cn(
            formCommonStyles.formControl,
            formCommonStyles[size],
            styles.select,
            {
              [styles.controlActive]: isActive,
            },
            inputClassName,
          )}
          {...props}
        >
          {search && (
            <input
              autoComplete="off"
              autoFocus
              className={styles.searchInput}
              name={name}
              placeholder={typeof placeholder === 'string' ? placeholder : undefined}
              ref={inputRef}
              type="text"
              onChange={(e) => onSearch?.(e.target.value)}
            />
          )}
          {!search && placeholderIsString && (
            <Text className={cn(styles.placeholder, placeholderClassName)} size={12}>
              {placeholder}
            </Text>
          )}
          {!search && !placeholderIsString && placeholder}
          <Icon className={styles.icon} kind={icon} size="xs" />
        </div>
        {children}
      </div>
    );
  },
);
