import { useLocales } from 'entities/config/lib/useLocales';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { CopyButton } from 'shared/components/CopyButton/CopyButton';
import { Icon } from 'shared/components/Icon/Icon';
import { useGetCreatedLocales } from 'shared/components/NewsPostForm/hooks/useGetCreatedLocales';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { Text } from 'shared/components/Text/Text';
import { getLangNameMap } from 'shared/consts';
import { toString } from 'shared/lib/toString';

import styles from './AddNewsPostPublish.module.css';

type TProps = {
  onClose: () => void;
  onPublish: () => void;
  onClickChooseDate: () => void;
  isOpened: boolean;
};

export const AddNewsPostPublishModal = ({
  onClose,
  onPublish,
  onClickChooseDate,
  isOpened,
}: TProps) => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const { control, formState } = useFormContext<TEditNewsPostFields>();
  const { createdLocales } = useGetCreatedLocales(control);
  const {
    field: { value: newsPostId },
  } = useController({
    control,
    name: 'id',
  });

  const {
    field: { value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const filledLocales = createdLocales
    .map((locale) => getLangNameMap()[locale])
    .join(', ');

  const notFilledLocales = locales
    .filter((lang) => !createdLocales.includes(lang))
    .map((locale) => getLangNameMap()[locale])
    .join(', ');

  return (
    <ActionModal
      cancelText={t('shared.planningText')}
      confirmText={publishDate ? t('shared.confirm') : t('shared.publish')}
      contentClassName={styles.mainContent}
      opened={isOpened}
      title={t('shared.publish')}
      width={549}
      cancelButtonProps={{
        icon: 'calendar',
        iconPosition: 'before',
        isDisabled: formState.isSubmitting,
        onClick: onClickChooseDate,
      }}
      confirmButtonProps={{
        isLoading: formState.isSubmitting,
        kind: 'primary',
        onClick: onPublish,
      }}
      onClose={onClose}
    >
      {newsPostId && (
        <div className={styles.infoId}>
          <Text>{t('shared.NewsPostForm.AddNewsPostPublishModal.text')}</Text>
          <div className={styles.copyId}>
            <CopyButton iconSize="s" textToCopy={toString(newsPostId)} />
            <Text className={styles.textMuted} size={14}>
              ID: {newsPostId}
            </Text>
          </div>
        </div>
      )}
      <div className={styles.info}>
        <Text>{t('shared.filledLocales')}</Text>
        <Text className={styles.textMuted} size={16}>
          {filledLocales}
        </Text>
      </div>
      {notFilledLocales.length > 0 && (
        <div className={styles.info}>
          <Text>{t('shared.notFilledLocales')}</Text>
          <Text className={styles.textMuted} size={16}>
            {notFilledLocales}
          </Text>
          <div className={styles.alertMessage}>
            <Icon kind="alert-circle" size="s" />
            <Text className={styles.textMuted}>
              {t('content.publishModal.warningUnpublishLocalization')}
            </Text>
          </div>
        </div>
      )}
    </ActionModal>
  );
};
