import { useState } from 'react';
import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import {
  TAddNewsPostFields,
  TEditNewsPostFields,
} from 'shared/components/NewsPostForm/types';

type TUseNewsWorkspacePanelPublish = Partial<UseFormReturn<TEditNewsPostFields>> & {
  isEdit: boolean;
  isPublish: boolean;
  createNewsPost: (data: TAddNewsPostFields) => Promise<void>;
  editNewsPost: (data: TEditNewsPostFields) => Promise<void>;
};

export const useNewsWorkspacePanelPublish = ({
  trigger,
  control,
  handleSubmit,
  createNewsPost,
  editNewsPost,
  isEdit,
  isPublish,
}: TUseNewsWorkspacePanelPublish) => {
  const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
  const [isOpenChooseDateModal, setIsOpenChooseDateModal] = useState(false);
  const {
    field: { onChange: onPublishDateChange, value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const onClickPublish = async () => {
    const isValid = await trigger?.();
    if (!isValid) {
      return;
    }

    if (isEdit && isPublish) {
      await handleSubmit?.(editNewsPost)();
      return;
    }

    setIsOpenPublishModal(true);
  };

  const onClosePublishModal = () => setIsOpenPublishModal(false);

  const onDateChange = (date: Date | undefined) => {
    onPublishDateChange(date);
    setIsOpenPublishModal(true);
    setIsOpenChooseDateModal(false);
  };

  const onPublish = () => {
    if (!publishDate) {
      onPublishDateChange(new Date());
    }

    if (isEdit) {
      handleSubmit?.(editNewsPost)();
    } else {
      handleSubmit?.(createNewsPost)();
    }
  };

  const onClickChooseDate = () => {
    setIsOpenPublishModal(false);
    setIsOpenChooseDateModal(true);
  };

  const onCloseChooseDate = () => {
    setIsOpenChooseDateModal(false);
    setIsOpenPublishModal(true);
  };

  return {
    publishDate,
    isOpenPublishModal,
    onClosePublishModal,
    isOpenChooseDateModal,
    onCloseChooseDate,
    onClickPublish,
    onDateChange,
    onPublish,
    onClickChooseDate,
  };
};
