import { queryClient } from 'app/Providers';
import { useLocales } from 'entities/config/lib/useLocales';
import { managersQueryKeys } from 'entities/managers/api/consts';
import { useCreateManagerMutation } from 'entities/managers/api/mutations';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';
import { mapManagerFormFieldsToDTO } from 'widgets/managerForm/mapper/mapManagerFormFieldsToDTO';
import { TManagerFormFields } from 'widgets/managerForm/types';
export const useCreateManager = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const navigate = useNavigate();
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const { mutate, isLoading } = useCreateManagerMutation({
    onSuccess: (manager) => {
      queryClient.setQueryData(
        [managersQueryKeys.manager, toString(manager.id)],
        manager,
      );
      if (teamId) {
        navigate(routes.managerSocialNetworks.as(teamId, manager.id));
      }
    },
    onError: () => {
      notify(t('content.manager.notifyNotCreate'), {
        type: 'error',
      });
    },
  });
  const createManager = async (managerFormFields: TManagerFormFields) => {
    const createManagerDTO = await mapManagerFormFieldsToDTO(
      managerFormFields,
      locales,
    );
    mutate(createManagerDTO);
  };
  return {
    isLoading,
    createManager,
  };
};
