import { managerCareersApiKeys } from 'entities/managerCareers/api/consts';
import {
  TCreateManagerCareerDTO,
  TEditManagerCareerDTO,
  TManagerCareer,
} from 'entities/managerCareers/model/types';
import { TPlayerCareer } from 'entities/playerCareers/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchManagerCareers = ({ params }: TFetchParams) =>
  get<TManagerCareer[], TPaginatedMeta>(managerCareersApiKeys.getManagerCareers, {
    params,
  }).then((response) => response.data);

export const createManagerCareer = (data: TCreateManagerCareerDTO) =>
  post<TManagerCareer>(managerCareersApiKeys.createManagerCareer, data).then(
    (response) => response.data.data,
  );

export const editManagerCareer = ({ id, ...dto }: TEditManagerCareerDTO) =>
  patch<TPlayerCareer>(managerCareersApiKeys.editManagerCareer(id), dto).then(
    (response) => response.data.data,
  );

export const deleteManagerCareer = (careerId: TEntityId) =>
  del(managerCareersApiKeys.deleteCareer(careerId)).then(
    (response) => response.data.data,
  );
