import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';

import styles from './ImageModal.module.css';

type TProps = {
  imageUrl: string;
  isShow: boolean;
  onClose: () => void;
};

export const ImageModal = ({ imageUrl, isShow, onClose }: TProps) => {
  return (
    <OverlayModal innerClassName={styles.overlay} opened={isShow} onClose={onClose}>
      <img alt="" className={styles.previewImage} src={imageUrl} />
    </OverlayModal>
  );
};
