import cn from 'classnames';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { TEditorDisableStructureChange } from 'shared/components/Editor/utils/structureChange/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Transforms } from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';

import styles from './EditorImageElement.module.css';

export type TEditorImageElementProps = {
  changeImage: (path: number[]) => void;
  cropImage: (path: number[]) => void;
};

export const EditorImageElement = ({
  attributes,
  element,
  changeImage,
  cropImage,
  children,
  disabledStructureChange,
}: RenderElementProps &
  TEditorImageElementProps &
  TEditorDisableStructureChange) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  if (element.type !== EditorElementTypeEnum.Image) {
    return null;
  }

  const deleteImage = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  };

  const changeImageHandler = () => {
    const path = ReactEditor.findPath(editor, element);
    changeImage(path);
  };

  const cropImageHandler = () => {
    const path = ReactEditor.findPath(editor, element);
    cropImage(path);
  };

  return (
    <div {...attributes}>
      {children}
      <div className={styles.imageWrapper} contentEditable={false}>
        <img
          alt=""
          className={cn(styles.image, selected && focused && styles.focusedImage)}
          src={element.file.url}
        />
        <div className={styles.buttonWrapper}>
          <IconButton
            className={styles.button}
            icon="edit-02"
            iconSize="s"
            onClick={changeImageHandler}
          />
          <IconButton
            className={styles.button}
            icon="crop-01"
            iconSize="s"
            onClick={cropImageHandler}
          />
          {!disabledStructureChange && (
            <IconButton
              className={styles.button}
              icon="trash"
              iconSize="s"
              onClick={deleteImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
