import cn from 'classnames';
import { LoadingIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import { TOption } from 'shared/components/Form/NewSelect/types';
import { Icon } from 'shared/components/Icon/Icon';

import styles from '../NewSelect.module.css';

export const Spinner = (props: LoadingIndicatorProps<TOption>) => {
  return (
    <div
      className={cn(styles.loader, props.getClassNames('loadingIndicator', props))}
    >
      <Icon kind="spinner" size="s" />
    </div>
  );
};
