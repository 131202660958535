import { useLocales } from 'entities/config/lib/useLocales';
import { NewsPostForm } from 'shared/components/NewsPostForm/NewsPostForm';

import { createAddNewsPostInitialValue } from './createAddNewsPostInitialValue';

export const AddNewsPost = () => {
  const { defaultLocale } = useLocales();

  return (
    <NewsPostForm initialValue={createAddNewsPostInitialValue(defaultLocale)} />
  );
};
