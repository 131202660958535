import cn from 'classnames';
import { noop } from 'lodash';
import { forwardRef, MouseEventHandler } from 'react';
import { Icon, TIconProps } from 'shared/components/Icon/Icon';
import { TIconSize, TIconType } from 'shared/components/Icon/types';
import { Loader } from 'shared/components/Loader/Loader';
import { preventDefaultEvent } from 'shared/lib/preventDefaultEvent';

import styles from './IconButton.module.css';

export type TIconButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: TIconType;
  className?: string;
  disabled?: boolean;
  iconSize?: TIconSize;
  iconProps?: Omit<TIconProps, 'kind' | 'ref' | 'size'>;
  isLoading?: boolean;
};

// TODO: Заверстать компонент согласно макету, сейчас совсем не соответствует

export const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(
  (
    { onClick, isLoading, icon, className, disabled, iconSize = 'l', iconProps },
    ref,
  ) => (
    <button
      className={cn(styles.root, className)}
      disabled={disabled}
      ref={ref}
      type="button"
      onClick={isLoading ? noop : onClick}
      onMouseDown={preventDefaultEvent}
    >
      {isLoading ? (
        <Loader size="xs" />
      ) : (
        <Icon kind={icon} size={iconSize} {...iconProps} />
      )}
    </button>
  ),
);

IconButton.displayName = 'IconButton';
