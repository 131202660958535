import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider } from 'react-hook-form';
import { AddNewsPostRightPanel } from 'shared/components/NewsPostForm/components/AddNewsPostRightPanel/AddNewsPostRightPanel';
import { AddNewsPostWorkspace } from 'shared/components/NewsPostForm/components/AddNewsPostWorkspace';
import { AddNewsPostWorkspacePanel } from 'shared/components/NewsPostForm/components/AddNewsPostWorkspacePanel/AddNewsPostWorkspacePanel';
import { NewsPostFormContextProvider } from 'shared/components/NewsPostForm/context';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { addNewsPostValidationSchema } from 'shared/components/NewsPostForm/validationSchema';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from './NewsPostForm.module.css';

type TProps = {
  initialValue: TEditNewsPostFields;
};

export const NewsPostForm = ({ initialValue }: TProps) => {
  const methods = useForm<TEditNewsPostFields>({
    values: initialValue,
    mode: 'onChange',
    resolver: zodResolver(addNewsPostValidationSchema()),
  });

  return (
    <NewsPostFormContextProvider initialValue={initialValue}>
      <FormProvider {...methods}>
        <form className={styles.root}>
          <AddNewsPostWorkspacePanel />
          <div className={styles.content}>
            <div className={styles.editWrapper}>
              <AddNewsPostWorkspace />
            </div>
            <AddNewsPostRightPanel />
          </div>
        </form>
      </FormProvider>
    </NewsPostFormContextProvider>
  );
};
