import { useFetchManagerQuery } from 'entities/managers/api/queries';
import { useFetchTeamQuery } from 'entities/teams/queries';
import { getManagerNavigationLinks } from 'feature/managerLayout/model/getManagerNavigationLinks';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';
type TProps = {
  teamId?: TEntityId;
  managerId?: TEntityId;
};
export const useManagerLayout = ({ teamId, managerId }: TProps) => {
  const navigate = useNavigate();
  const {
    data: manager,
    isLoading: isLoadingManager,
    error: managerError,
  } = useFetchManagerQuery(managerId);
  const {
    data: team,
    isLoading: isLoadingTeam,
    error: teamError,
  } = useFetchTeamQuery(teamId);
  const managerNavigationLinks = getManagerNavigationLinks(team?.id, manager?.id);
  const backButtonClickHandler = () => {
    if (team?.id) {
      navigate(routes.editTeam.as(team.id));
    }
  };
  return {
    error: teamError || managerError,
    isLoading: (isLoadingTeam && teamId) || (isLoadingManager && managerId),
    managerNavigationLinks,
    backButtonClickHandler,
  };
};
