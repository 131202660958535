import cn from 'classnames';
import { ReactNode } from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Text, TTextProps } from 'shared/components/Text/Text';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './Badge.module.css';

type TProps = {
  type?: 'white' | 'outlined' | 'soft' | 'solid';
  size?: 's' | 'm' | 'l';
  shape?: 'rounded' | 'pilled';
  color?: 'green' | 'red' | 'light' | 'dark' | 'gray';
  textProps?: Omit<TTextProps, 'size' | 'children'>;
  onClose?: () => void;
  content: ReactNode;
  imageUrl?: string | null;
  className?: string;
};

export const Badge = ({
  content,
  size = 'm',
  color = 'light',
  shape = 'rounded',
  type = 'white',
  textProps = {},
  onClose,
  imageUrl,
  className,
}: TProps) => {
  const isHaveCloseButton = toBoolean(onClose);

  return (
    <div
      className={cn(
        styles.base,
        styles[size],
        styles[color],
        styles[shape],
        styles[type],
        { [styles.closeButton]: isHaveCloseButton },
        className,
      )}
    >
      {imageUrl && (
        <div className={styles.imageWrapper}>
          <img alt="" src={imageUrl} />
        </div>
      )}
      {typeof content === 'string' ? (
        <Text
          className={styles.text}
          size={size === 's' ? 10 : 12}
          weight="SemiBold"
          {...textProps}
        >
          {content}
        </Text>
      ) : (
        content
      )}
      {isHaveCloseButton && (
        <Icon className={styles.closeIcon} kind="x" size="xs" onClick={onClose} />
      )}
    </div>
  );
};
