import cn from 'classnames';
import { TPhotoGalleryImage } from 'entities/photoGalleryImages/types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Centered } from 'shared/components/Centered/Centered';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { CropperModal } from 'shared/components/cropper/CropperModal/CropperModal';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Image } from 'shared/components/Image/Image';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { Loader } from 'shared/components/Loader/Loader';
import { toBoolean } from 'shared/lib/toBoolean';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';
import { useCropPhotoGalleryImage } from 'widgets/photoGalleryForm/ui/hooks/useCropPhotoGalleryImage';
import { useEditPhotoGalleryImage } from 'widgets/photoGalleryForm/ui/hooks/useEditPhotoGalleryImage';
import { PhotoGalleryFormImageCardPlug } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormImageCard/PhotoGalleryFormImageCardPlug';

import { useDeletePhotoGalleryImage } from '../hooks/useDeletePhotoGalleryImage';
import styles from './PhotoGalleryFormImageCard.module.css';

type TProps = {
  image: TPhotoGalleryImage;
  isLast?: boolean;
};

export const PhotoGalleryFormImageCard = ({ image, isLast }: TProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TPhotoGalleryFields>();
  const {
    field: { value: published },
  } = useController({
    control,
    name: 'published',
  });

  const {
    openedDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    onClickDeleteModalConfirm,
    isDeletingPhotoGalleryImage,
  } = useDeletePhotoGalleryImage();

  const { onClickCropButton, cropModalProps, isLoadingCropping } =
    useCropPhotoGalleryImage(image);
  const {
    closeImageEditModal,
    onConfirmEdit,
    openImageEditModal,
    openedImageEditModal,
    isLoadingEditing,
  } = useEditPhotoGalleryImage(image);

  const isLoading =
    isDeletingPhotoGalleryImage || isLoadingEditing || isLoadingCropping;

  return (
    <>
      <div className={cn(styles.root, { [styles.edit]: isLoading })}>
        <div className={styles.cover}></div>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Centered>
              <Loader />
            </Centered>
          </div>
        )}
        <div className={styles.actions}>
          <IconButton
            className={styles.action}
            icon="edit-02"
            iconSize="s"
            onClick={openImageEditModal}
          />
          <IconButton
            className={styles.action}
            icon="crop-01"
            iconSize="s"
            onClick={onClickCropButton}
          />
          <IconButton
            className={styles.action}
            disabled={toBoolean(published) && isLast}
            icon="trash"
            iconSize="s"
            onClick={openDeleteModal}
          />
        </div>
        <Image
          DefaultComponent={<PhotoGalleryFormImageCardPlug />}
          alt="photo gallery image"
          className={styles.image}
          src={image.picture.x3}
        />
      </div>
      <ConfirmationModal
        description={t('content.photoGallery.imageCard.deleteImageDescription')}
        isLoading={isDeletingPhotoGalleryImage}
        isOpened={openedDeleteModal}
        title={t('content.photoGallery.imageCard.deleteImageTitle')}
        onClose={closeDeleteModal}
        onConfirm={() => onClickDeleteModalConfirm(image.id)}
      />
      <CropperModal {...cropModalProps} />
      <ImageUploadModal
        aspectRatio={16 / 9}
        mode="optional"
        opened={openedImageEditModal}
        title={t('content.photoGallery.imageCard.uploadingModalTitle')}
        withCropping
        onClose={closeImageEditModal}
        onConfirm={onConfirmEdit}
      />
    </>
  );
};
