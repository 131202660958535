import { AxiosResponse } from 'axios';
import { JSONAPIDocument } from 'json-api-serializer';
import { deserializeApiData } from 'shared/api/jsonApi/deserializeApiData';
import { deserializeApiMeta } from 'shared/api/jsonApi/deserializeApiMeta';
import { TResponseData } from 'shared/api/types';

export const handleJsonApiResponse = (
  response: AxiosResponse<JSONAPIDocument>,
): AxiosResponse<TResponseData<unknown>> => {
  return {
    ...response,
    data: {
      ...response.data,
      data: deserializeApiData(response.data),
      meta: deserializeApiMeta(response.data.meta),
    },
  };
};
