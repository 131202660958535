import { TPlayerCareer } from 'entities/playerCareers/types';
import { useDeletePlayerCareer } from 'pages/player/careers/PlayerCareersPage/hooks/useDeletePlayerCareer';
import { useEditPlayerCareer } from 'pages/player/careers/PlayerCareersPage/hooks/useEditPlayerCareer';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const usePlayerCareersActions = ({
  isActive,
  onIconClick,
  closeActiveItemId,
  item: career,
}: TTableActions<TPlayerCareer>) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingPlayerCareer, deletePlayerCareer } = useDeletePlayerCareer();

  const { openEditModal, editCareerModalProps } = useEditPlayerCareer(
    career.id,
    career,
  );

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: () => setIsDeleteModalOpen(true),
    },
  ];

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerCareer(career.id);
  };

  return {
    actions,
    isDeleteModalOpen,
    isDeletingPlayerCareer,
    isActive,
    itemId: career.id,
    editCareerModalProps,
    onIconClick,
    handleConfirmDeletion,
    handleCloseDeleteModal,
  };
};
