import { useConfig } from 'entities/config/lib/useConfig';
import { sortBy } from 'lodash';
import { TContentLang } from 'shared/types/common';

type THookReturnType = {
  locales: TContentLang[];
  defaultLocale: TContentLang;
};

export const useLocales = (): THookReturnType => {
  const { config } = useConfig();

  if (!config) {
    throw new Error(
      'Config is undefined. Check if config data is used outside of protected routes',
    );
  }

  const { availableLocales, defaultLocale } = config;

  const sortByDefaultLocale = (locale: TContentLang) => locale !== defaultLocale;

  const sortedLocales = sortBy(availableLocales, sortByDefaultLocale);

  // for case when default locale is not found in availableLocales list
  const locales = sortedLocales.includes(defaultLocale)
    ? sortedLocales
    : [defaultLocale, ...sortedLocales];

  return { locales, defaultLocale };
};
