import cn from 'classnames';
import { Text } from 'shared/components/Text/Text';

import styles from './Information.module.css';

type TCommonInformation = {
  name: string;
  lastName: string;
  position: string;
};

export const CommonInformation = ({
  name,
  lastName,
  position,
}: TCommonInformation) => {
  return (
    <div className={styles.content}>
      <Text
        className={styles.text}
        color="var(--gray-500)"
        size={16}
        tag="p"
        weight="Bold"
      >
        {name}
      </Text>
      <Text
        className={styles.text}
        color="var(--gray-500)"
        size={16}
        tag="p"
        weight="Bold"
      >
        {lastName}
      </Text>
      <Text
        className={cn(styles.position, styles.text)}
        color="var(--gray-450)"
        size={12}
        tag="p"
      >
        {position}
      </Text>
    </div>
  );
};
