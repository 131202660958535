import { useMutation } from '@tanstack/react-query';
import {
  createManagerTeamAchievement,
  deleteManagerTeamAchievement,
  editManagerTeamAchievement,
} from 'entities/managerTeamAchievements/api';

export const useCreateManagerTeamAchievementMutation = () => {
  return useMutation({
    mutationFn: createManagerTeamAchievement,
  });
};

export const useDeleteManagerTeamAchievementMutation = () => {
  return useMutation({
    mutationFn: deleteManagerTeamAchievement,
  });
};

export const useEditManagerTeamAchievementMutation = () => {
  return useMutation({
    mutationFn: editManagerTeamAchievement,
  });
};
