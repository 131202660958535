import { useEditPhotoGalleryImageMutation } from 'entities/photoGalleryImages/mutations';
import { TPhotoGalleryImage } from 'entities/photoGalleryImages/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TCropperModalProps } from 'shared/components/cropper/CropperModal/CropperModal';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getFilenameFromUrl } from 'shared/lib/getFilenameFromUrl';

export const useCropPhotoGalleryImage = ({
  photoGalleryId,
  picture,
  id,
}: TPhotoGalleryImage) => {
  const { t } = useTranslation();
  const url = picture.x3;
  const { openCropImageModal, closeCropImageModal, openedCropImageModal } =
    useModal('CropImage');
  const { mutate: editPhotoGalleryImage, isLoading: isLoadingCropping } =
    useEditPhotoGalleryImageMutation();

  const onConfirmCrop = useCallback(
    async (base64Url: string) => {
      try {
        closeCropImageModal();
        const data = clearMetaDataFromBase64Url(base64Url);

        editPhotoGalleryImage(
          {
            id,
            photoGalleryId,
            picture: {
              filename: getFilenameFromUrl(url),
              data,
            },
          },
          {
            onSuccess: () => {
              notify(t('content.photoGallery.successLoadImage'), {
                type: 'success',
              });
              closeCropImageModal();
            },
            onError: () => {
              notify(t('content.photoGallery.errorLoadImage'), {
                type: 'error',
              });
              closeCropImageModal();
            },
          },
        );
      } catch (error) {
        notify(t('content.photoGallery.errorLoadImage'), {
          type: 'error',
        });
      }
    },
    [editPhotoGalleryImage, id, photoGalleryId],
  );

  return {
    onClickCropButton: openCropImageModal,
    isLoadingCropping,
    cropModalProps: {
      onCrop: onConfirmCrop,
      defaultAspectRatio: 16 / 9,
      isOpen: openedCropImageModal,
      imageBase64Url: url,
      onClose: closeCropImageModal,
      mode: 'optional',
    } satisfies TCropperModalProps,
  };
};
