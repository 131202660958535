import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { CountrySelect } from 'feature/countrySelect/CountrySelect';
import { opponentTeamModalValidationSchema } from 'feature/opponentTeamModal/validationSchema';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { getImageSettings } from 'shared/constants';
import { getLangNameMap } from 'shared/consts';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';

import styles from './OpponentTeamModal.module.css';

export type TOpponentTeamModalFields = {
  picture?: File;
  countryId: string;
  clubName: TTranslations<string>;
  name: TTranslations<string>;
};

type TProps = {
  title: string;
  closeModal: () => void;
  actionSubmit: (data: TOpponentTeamModalFields) => void;
  isOpened: boolean;
  initialValues?: TOpponentTeamModalFields;
  isLoading?: boolean;
};

export function OpponentTeamModal({
  closeModal,
  actionSubmit,
  initialValues,
  isOpened,
  title,
  isLoading,
}: TProps) {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const { hint, aspectRatio } = getImageSettings(t).opponentTeam.picture;

  const defaultValues: TOpponentTeamModalFields = useMemo(() => {
    return {
      defaultTeam: false,
      countryId: '',
      picture: undefined,
      name: reduceTranslationObject(locales, () => ''),
      clubName: reduceTranslationObject(locales, () => ''),
    };
  }, [locales]);

  const {
    control,
    submit,
    formState: { isValid },
    reset,
  } = useForm<TOpponentTeamModalFields>({
    values: initialValues ?? defaultValues,
    mode: 'onChange',
    resolver: zodResolver(opponentTeamModalValidationSchema(locales)),
    actionSubmit,
  });

  const closeModalHandler = useCallback(() => {
    closeModal();
    reset();
  }, [isOpened]);

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
        isLoading,
      }}
      onClose={closeModalHandler}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        {t('content.teams.teamName')}
      </Text>
      <div className={styles.form}>
        {locales?.map((locale) => (
          <FormInput
            key={locale}
            control={control}
            fieldName={`name.${locale}`}
            inputClassName={styles.inputForm}
            label={getLangNameMap()[locale]}
            maxLength={255}
            placeholder={t('content.inputPlaceholder')}
            required
            size="s"
          />
        ))}
      </div>
      <div className={styles.inputsList}>
        <Text size={14} tag="h3" weight="SemiBold">
          {t('content.manager.clubName')}
        </Text>
        <div className={styles.form}>
          {locales?.map((locale) => (
            <FormInput
              key={locale}
              control={control}
              fieldName={`clubName.${locale}`}
              inputClassName={styles.inputForm}
              label={getLangNameMap()[locale]}
              maxLength={255}
              placeholder={t('content.inputPlaceholder')}
              required
              size="s"
            />
          ))}
        </div>
      </div>
      <div className={styles.formTeam}>
        <CountrySelect
          className={styles.country}
          control={control}
          fieldName="countryId"
          labelClassName={styles.text}
        />
      </div>
      <div className={styles.formTeam}>
        <UploadImage
          aspectRatio={aspectRatio}
          control={control}
          fieldName="picture"
          hint={hint}
          imageRequirementText={hint}
          label={t('content.teams.logo')}
          labelClassName={styles.text}
          modalTitle={t('shared.imageSetting')}
          showIcon={false}
        />
      </div>
    </ActionModal>
  );
}
