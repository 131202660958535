import cn from 'classnames';
import { useCallback, useContext } from 'react';
import { TabsContext } from 'shared/components/Tabs/context';
import { TTabProps } from 'shared/components/Tabs/types';

import styles from './Tabs.module.css';

export const Tab = ({ label, value }: TTabProps) => {
  const { selected, onSelect } = useContext(TabsContext);

  const isActive = selected === value;

  const handleClick = useCallback(() => {
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  }, [value, onSelect]);

  return (
    <li
      className={cn(styles.tab, { [styles.isActive]: isActive })}
      role="tab"
      onClick={handleClick}
    >
      {label}
    </li>
  );
};
