import { Controller, FieldValues } from 'react-hook-form';
import { Input } from 'shared/components/Form/Input/Input';
import { TFormInputProps, TRenderInputProps } from 'shared/components/Form/types';

export const FormInput = <T extends FieldValues>(props: TFormInputProps<T>) => {
  const { control, fieldName, rules, ...inputProps } = props;

  const renderInput = (renderProps: TRenderInputProps<T>) => {
    const {
      field,
      fieldState: { error },
    } = renderProps;

    return <Input error={error} {...field} {...inputProps} />;
  };

  return (
    <Controller
      key={fieldName}
      control={control}
      name={fieldName}
      render={renderInput}
      rules={rules}
    />
  );
};
