import { useController, useFormContext } from 'react-hook-form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { Workspace } from 'shared/components/Workspace/Workspace';

export const AddNewsPostWorkspace = () => {
  const { control } = useFormContext<TEditNewsPostFields>();
  const {
    field: { value: currentLocale },
  } = useController({
    control,
    name: 'locale.selected',
  });

  return <Workspace control={control} currentLocale={currentLocale} />;
};
