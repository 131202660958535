import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createPlayerSocialNetwork,
  deletePlayerSocialNetwork,
  editPlayerSocialNetwork,
} from 'entities/playerSocialNetworks/api';
import { playerSocialNetworksQueryKeys } from 'entities/playerSocialNetworks/consts';

export const useDeletePlayerSocialNetworkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePlayerSocialNetwork,
    onSuccess: () =>
      queryClient.resetQueries([playerSocialNetworksQueryKeys.playerSocialNetworks]),
  });
};

export const useEditPlayerSocialNetworkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editPlayerSocialNetwork,
    onSuccess: () =>
      queryClient.resetQueries([playerSocialNetworksQueryKeys.playerSocialNetworks]),
  });
};

export const useCreatePlayerSocialNetworkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPlayerSocialNetwork,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        playerSocialNetworksQueryKeys.playerSocialNetworks,
      ]);
    },
  });
};
