import { opponentPlayersApiKeys } from 'entities/opponentPlayers/consts';
import {
  TEditOpponentPlayerDTO,
  TOpponentPlayer,
  TOpponentPlayerDTO,
} from 'entities/opponentPlayers/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchOpponentPlayers = ({ params }: TFetchParams) =>
  get<TOpponentPlayer[], TPaginatedMeta>(opponentPlayersApiKeys.getOpponentPlayers, {
    params,
  }).then((response) => response.data);

export const deleteOpponentPlayer = (playerId: TEntityId) =>
  del(opponentPlayersApiKeys.deleteOpponentPlayer(playerId));

export const createOpponentPlayer = (dto: TOpponentPlayerDTO) =>
  post<TOpponentPlayer>(opponentPlayersApiKeys.createOpponentPlayer, dto).then(
    (response) => response.data.data,
  );

export const editOpponentPlayer = ({ id, ...dto }: TEditOpponentPlayerDTO) =>
  patch<TOpponentPlayer>(opponentPlayersApiKeys.editOpponentPlayer(id), dto).then(
    (response) => response.data.data,
  );
