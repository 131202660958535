import { useLocales } from 'entities/config/lib/useLocales';
import { StoryEditor } from 'pages/stories/edit/EditStory/components/StoryEditor/StoryEditor';
import { StoryPreviewModal } from 'pages/stories/edit/EditStory/components/StoryPreviewModal/StoryPreviewModal';
import { StoryWorkspacePanel } from 'pages/stories/edit/EditStory/components/StoryWorkspacePanel/StoryWorkspacePanel';
import { storyEditorInitialState } from 'pages/stories/edit/EditStory/constants';
import {
  ModalContext,
  StoryEditorContext,
  StoryEditorDispatchContext,
} from 'pages/stories/edit/EditStory/context';
import { useStoryEditorData } from 'pages/stories/edit/EditStory/hooks/useStoryEditorData';
import { storyEditorStateReducer } from 'pages/stories/edit/EditStory/state';
import { TModals } from 'pages/stories/edit/EditStory/types';
import { useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'shared/components/Loader/Loader';
import { EyeDropContext } from 'shared/contexts/eyedropContext';

import styles from './EditStory.module.css';

export const EditStory = () => {
  const { defaultLocale } = useLocales();
  const { storyGroupId } = useParams() as { storyGroupId: string };
  const [state, dispatch] = useReducer(
    storyEditorStateReducer,
    storyEditorInitialState(defaultLocale),
  );
  const [isEyeDropActive, setIsEyeDropActive] = useState(false);
  const {
    isStoriesInitialLoading,
    isStoryGroupLoading,
    storyGroup,
    stories,
    storyElements,
    error,
    createdLocales,
  } = useStoryEditorData(storyGroupId);

  const [openedModal, openModal] = useState<TModals | null>(null);

  if (isStoryGroupLoading || isStoriesInitialLoading) {
    return <Loader className={styles.loading} showLabel />;
  }

  if (!storyGroup || stories.length === 0 || error) {
    return null;
  }

  return (
    <ModalContext.Provider value={{ openModal, openedModal }}>
      <StoryEditorContext.Provider
        value={{
          ...state,
          storyGroup,
          stories,
          storyElements,
          createdLocales,
        }}
      >
        <StoryEditorDispatchContext.Provider value={dispatch}>
          <EyeDropContext.Provider value={{ isEyeDropActive, setIsEyeDropActive }}>
            <div className={styles.root}>
              <StoryWorkspacePanel
                createdLocales={createdLocales}
                selectedLocale={state.selectedLocale}
                selectedStoryGroupId={storyGroupId}
              />
              <StoryEditor
                createdLocales={createdLocales}
                selectedLocale={state.selectedLocale}
              />
              {state.selectedPreviewSize && (
                <StoryPreviewModal previewSize={state.selectedPreviewSize} />
              )}
            </div>
          </EyeDropContext.Provider>
        </StoryEditorDispatchContext.Provider>
      </StoryEditorContext.Provider>
    </ModalContext.Provider>
  );
};
