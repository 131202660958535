import { useLocales } from 'entities/config/lib/useLocales';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TContentLang } from 'shared/types/common';

import styles from '../AddNewsPostPreview.module.css';

export const AddNewsPostPreviewImageElement = ({
  element,
  lang,
}: {
  element: TWorkspaceElement;
  lang: TContentLang;
}) => {
  const { defaultLocale } = useLocales();
  if (element.type !== EditorElementTypeEnum.Image) {
    return null;
  }

  const defaultLangUrl = element.translations[defaultLocale]?.url ?? '';
  const url = element.translations[lang]?.url ?? defaultLangUrl;

  return <img alt="" className={styles.image} src={url} />;
};
