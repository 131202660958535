import { TFilter } from 'feature/filters/types';
import { useState } from 'react';
import { Badge } from 'shared/components/Badge/Badge';
import { TTableSubComponents, TTableWithRef } from 'shared/components/SimpleTable';
import { Text } from 'shared/components/Text/Text';
import { TColumnConfig, TDataItem } from 'widgets/elementsList/types';

import styles from '../CommonListTable.module.css';
type TProps<SortBy extends string, Filter extends TFilter> = {
  tableComponent: TTableWithRef & TTableSubComponents<SortBy>;
  onFilter?: (args: Filter) => void;
  filter?: Filter | null;
};
export const CommonListTableHeadCellFilterable = <
  T extends TDataItem,
  SortBy extends string,
  Filter extends TFilter,
>({
  title,
  onFilter,
  filter,
  tableComponent: Table,
  headCellProps,
}: TColumnConfig<T, SortBy, Filter> & TProps<SortBy, Filter>) => {
  const {
    FilterComponent: Filter,
    getIsFiltered,
    filterName,
    getFilterBadge,
    ...restFilterProps
  } = headCellProps?.filter ?? {};
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const initialFilter = {} as Filter;
  const handleFilterSet = (args: Filter) => {
    onFilter?.(args);
    setIsFilterOpen(false);
  };
  const handleClearFilter = () => {
    if (filterName && onFilter) {
      const newFilter = {
        ...filter,
      } as Filter;
      delete newFilter[filterName];
      onFilter(newFilter);
    }
  };
  const filterCount = filter && getFilterBadge ? getFilterBadge(filter) : null;
  return (
    <Table.HeadCell
      filter={{
        ...restFilterProps,
        isFilterOpen,
        isFiltered: getIsFiltered?.(filter || initialFilter),
        setFilterOpen: setIsFilterOpen,
        FilterComponent: Filter && (
          <Filter filter={filter || initialFilter} setFilter={handleFilterSet} />
        ),
      }}
    >
      <Text className={styles.filterTitle}>
        {title}
        {filterCount && (
          <Badge
            className={styles.filterCount}
            color="green"
            content={filterCount}
            shape="pilled"
            size="s"
            type="solid"
            onClose={handleClearFilter}
          />
        )}
      </Text>
    </Table.HeadCell>
  );
};
