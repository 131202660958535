import { format } from 'date-fns';
import {
  EOpponentPlayerStatus,
  TOpponentPlayerDTO,
} from 'entities/opponentPlayers/types';
import { TOpponentPlayerModalFields } from 'feature/opponentPlayerModal/types';
import { BACKEND_DATE_FORMAT } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { TContentLang, TEntityId } from 'shared/types/common';
import { mapFileToUpload } from 'shared/utils/image';
export const mapOpponentPlayerFieldsToDTO = async (
  fields: TOpponentPlayerModalFields,
  teamId: TEntityId,
  locales: TContentLang[],
): Promise<TOpponentPlayerDTO> => {
  return {
    status: EOpponentPlayerStatus.PartOfTeam,
    picture: fields.picture ? await mapFileToUpload(fields.picture) : null,
    teamId: toNumber(teamId),
    discardedAt:
      fields.discarded === 'true' && fields.discardedAt
        ? format(fields.discardedAt, BACKEND_DATE_FORMAT)
        : null,
    nickname: fields.nickname ?? '',
    number: fields.number,
    translations: reduceTranslationObject(locales, (locale) => ({
      firstName: fields.translations[locale]?.firstName ?? '',
      lastName: fields.translations[locale]?.lastName ?? '',
    })),
    countryId: toNumber(fields.countryId),
  };
};
