import { format } from 'date-fns';
import { useState } from 'react';
import { Button } from 'shared/components/Button/Button';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { Text } from 'shared/components/Text/Text';

export const DatePickerExample = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [date, setDate] = useState<Date>();
  return (
    <>
      <Text size={24} tag="h2">
        Date Picker
      </Text>
      <Button onClick={() => setIsOpened(true)}>
        {date ? `picket date: ${format(date, 'yyyy-MM-dd')}` : 'Open me!'}
      </Button>
      <DatePicker
        isOpened={isOpened}
        onClose={() => setIsOpened(!isOpened)}
        onDateChange={(event?: Date) => {
          setDate(event);
          setIsOpened(false);
        }}
      />
    </>
  );
};
