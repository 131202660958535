import { RenderPlaceholderProps } from 'slate-react';

export const editorRenderPlaceholder = ({
  attributes,
  children,
}: RenderPlaceholderProps) => (
  <span
    {...attributes}
    style={{
      ...attributes.style,
      color: 'var(--gray-500)',
      opacity: 1,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
    }}
  >
    {children}
  </span>
);
