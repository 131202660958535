import { format, isValid } from 'date-fns';
import { MouseEvent, useEffect, useState } from 'react';
import { ClassNames, DateFormatter, DayPicker } from 'react-day-picker';
import defaultStyles from 'react-day-picker/dist/style.module.css';
import { Footer } from 'shared/components/DatePicker/components/Footer';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { TODAY } from 'shared/constants';

import { CustomCaption } from './components/CustomCaption/CustomCaption';
import { MAX_DATE, MIDNIGHT_TIME } from './data';
import styles from './DatePicker.module.css';

const formatWeekdayName: DateFormatter = (date) => format(date, 'eeeee');

type TProps = {
  date?: Date;
  isOpened: boolean;
  onDateChange: (date: Date) => void;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
  fromDate?: Date;
  toDate?: Date;
  isShowTimePicker?: boolean;
};
export const DatePicker = ({
  date,
  isOpened,
  onDateChange,
  onClose,
  fromDate = TODAY,
  toDate = MAX_DATE,
  isShowTimePicker = true,
}: TProps) => {
  const [selected, setSelected] = useState<Date | undefined>(date);

  useEffect(() => {
    setSelected(date);
  }, [date]);

  const classNames: ClassNames = {
    ...defaultStyles,
    ...styles,
  };

  const handleDateChange = (date?: Date) => {
    if (!date) {
      return;
    }
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      selected ? selected.getHours() : MIDNIGHT_TIME,
      selected ? selected.getMinutes() : MIDNIGHT_TIME,
    );
    setSelected(newDate);
  };

  const handleTimeChange = (date: Date) => {
    if (isValid(date)) {
      setSelected(date);
    }
  };

  return (
    <OverlayModal opened={isOpened} onClose={onClose}>
      <div className={styles.container}>
        <DayPicker
          captionLayout="dropdown-buttons"
          className={styles.container}
          classNames={classNames}
          defaultMonth={selected}
          fixedWeeks
          formatters={{ formatWeekdayName }}
          fromDate={fromDate}
          mode="single"
          selected={selected}
          showOutsideDays
          toDate={toDate}
          weekStartsOn={1}
          components={{
            Caption: ({ displayMonth }) => (
              <CustomCaption
                displayMonth={displayMonth}
                fromDate={fromDate}
                setSelected={setSelected}
                toDate={toDate}
              />
            ),
          }}
          onSelect={handleDateChange}
        />
        <Footer
          date={selected}
          isShowTimePicker={isShowTimePicker}
          onCancel={onClose}
          onConfirm={onDateChange}
          onTimeChange={handleTimeChange}
        />
      </div>
    </OverlayModal>
  );
};
