import { TEntityId } from 'shared/types/common';

export const managerSocialNetworksQueryKeys = {
  managerSocialNetworks: 'managerSocialNetworks',
};

const managerSocialNetworksBaseUrl = 'manager/social_networks';

export const managerSocialNetworksApiKeys = {
  getManagerSocialNetworks: managerSocialNetworksBaseUrl,
  createManagerSocialNetworks: managerSocialNetworksBaseUrl,
  deleteManagerSocialNetwork: (managerId: TEntityId) =>
    `${managerSocialNetworksBaseUrl}/${managerId}`,
  editManagerSocialNetwork: (managerId: TEntityId) =>
    `${managerSocialNetworksBaseUrl}/${managerId}`,
};
