import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { TAchievementModalValue } from 'feature/achievementModal/types';
import { useDeleteManagerTeamAchievement } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/hooks/useDeleteManagerTeamAchievement';
import { useEditManagerTeamAchievement } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/hooks/useEditManagerTeamAchievement';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';
export const useManagerTeamAchievementsActions = ({
  item: managerTeamAchievement,
  onIconClick,
  closeActiveItemId,
  isActive,
}: TTableActions<TManagerTeamAchievement>) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingManagerTeamAchievement, deleteManagerTeamAchievement } =
    useDeleteManagerTeamAchievement();
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { isEditingManagerTeamAchievement, editManagerTeamAchievement } =
    useEditManagerTeamAchievement();
  const { id, managerId, translations, year } = managerTeamAchievement;
  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deleteManagerTeamAchievement(id);
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmEditing = ({ translations, year }: TAchievementModalValue) => {
    editManagerTeamAchievement(
      {
        ...managerTeamAchievement,
        managerId: toNumber(managerId),
        year: toNumber(year),
        translations,
      },
      handleCloseEditModal,
    );
  };
  return {
    actionsProps: {
      actions,
      itemId: id,
      isLoading: isDeletingManagerTeamAchievement,
      isOpen: isActive,
      onIconClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteAchievement'),
    },
    editModalProps: {
      initialValue: {
        year: toString(year),
        translations,
      },
      title: t('content.editTeamAchievements'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingManagerTeamAchievement,
    },
  };
};
