import { TAPIError } from 'shared/api/types';
import { notify } from 'shared/components/Notification';

export const defaultErrorHandler = (reason: TAPIError) => {
  const message = reason.response?.data.errors
    ? reason.response?.data.errors
        .map((error) => {
          let title = error.title;
          if (error.details) {
            title += `: ${error.attribute} ${error.details}`;
          }
          return title;
        })
        .join(', ')
    : reason.message;
  notify(message, { type: 'error' });
};
