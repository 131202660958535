import 'swiper/css';
import 'swiper/css/navigation';

import { ReactNode } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './NewsPostPreviewSlider.module.css';

type TProps = {
  nodes: ReactNode[];
};

const baseOptions = {
  speed: 700,
  spaceBetween: 8,
  navigation: {
    enabled: true,
  },
  autoHeight: true,
  modules: [Navigation],
  className: styles.slider,
};

export const NewsPostPreviewSlider = ({ nodes }: TProps) => {
  return (
    <Swiper {...baseOptions}>
      {nodes.map((node, i) => (
        <SwiperSlide key={i}>{node}</SwiperSlide>
      ))}
    </Swiper>
  );
};
