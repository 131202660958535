import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { CommonReview } from 'feature/rightPanel/ui/CommonReview/CommonReview';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const VideoReview = (commonProps: TDefaultRightPanelProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TVideoPostFields>();

  const { field: matchField } = useController({
    control,
    name: 'match',
  });

  const { fieldState: reviewFieldState } = useController({
    control,
    name: 'review',
  });

  return (
    <CommonReview
      control={control}
      description={t('content.video.videoReview.description', { mark: '.' })}
      disabledSwitch={!matchField.value}
      fieldName="review"
      isError={reviewFieldState.invalid}
      label={t('content.video.videoReview.label')}
      switchLabel={t('content.video.videoReview.switchLabel')}
      switchTip={t('content.video.videoReview.switchTip')}
      title={t('content.video.videoReview.title')}
      {...commonProps}
    />
  );
};
