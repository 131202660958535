import { TCreateTeamDTO } from 'entities/teams/types';
import { TTeamModalFields } from 'feature/teamModal/TeamModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { mapFileToUpload } from 'shared/utils/image';

export const mapTeamModalFieldsToDTO = async (
  createTeamModalFields: TTeamModalFields,
  locales: TContentLang[],
): Promise<TCreateTeamDTO> => {
  return {
    defaultTeam: createTeamModalFields.defaultTeam,
    countryId: createTeamModalFields.countryId,
    translations: reduceTranslationObject(locales, (locale) => ({
      name: createTeamModalFields.name[locale],
      clubName: createTeamModalFields.clubName[locale],
    })),
    picture: createTeamModalFields.picture
      ? await mapFileToUpload(createTeamModalFields.picture)
      : null,
  };
};
