import { BackButton } from 'pages/stories/edit/EditStory/components/forms/components/BackButton/BackButton';
import { TitleColors } from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/components/TitleColors/TitleColors';
import { useTranslation } from 'react-i18next';
import { Divider } from 'shared/components/Divider';
import { Form, FormInput } from 'shared/components/Form';
import { Checkbox } from 'shared/components/Form/Checkbox/Checkbox';

import { TCommonElementFormProps } from '../../forms/types';
import commonElementFormStyles from '../ElementForm.module.css';
import { AnswerInputs } from './components/AnswerInputs/AnswerInputsWrapper';
import { AnswerRadioButtons } from './components/AnswerRadioButtons/AnswerRadioButtons';
import styles from './QuizElementForm.module.css';
import { useQuizElementForm } from './useQuizElementForm';

export const QuizElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'quiz'>) => {
  const { t } = useTranslation();
  const {
    control,
    submit,
    fields,
    titleDisabled,
    getSwitchField,
    handleChangeCorrectAnswer,
  } = useQuizElementForm(selectedElement);

  return (
    <div className={commonElementFormStyles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FormInput
          control={control}
          fieldName="title"
          inputClassName={commonElementFormStyles.input}
          label={t('content.heading')}
          maxLength={150}
          placeholder={t('content.inputPlaceholder')}
        />
        <Divider />
        <AnswerInputs
          control={control}
          fields={fields}
          getSwitchField={getSwitchField}
        />
        <Divider />
        <AnswerRadioButtons
          control={control}
          fields={fields}
          onChange={handleChangeCorrectAnswer}
        />
        <Divider />
        <Checkbox
          checkboxLabel={t('content.story.hiddenHeading')}
          control={control}
          fieldName="titleDisabled"
          inline
          inlineInputClassName={commonElementFormStyles.formField}
        />
        {!titleDisabled && <TitleColors control={control} />}
      </Form>
      <BackButton />
    </div>
  );
};
