import { PlayerLayout } from 'feature/playerLayout/ui/PlayerLayout/PlayerLayout';
import { SocialNetworkModal } from 'feature/socialNetworkModal/ui/SocialNetworkModal/SocialNetworkModal';
import { usePlayerSocialNetworksColumnsConfig } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/columnsConfig';
import { useCreatePlayerSocialNetwork } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/hooks/useCreatePlayerSocialNetwork';
import { usePlayerSocialNetworks } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/hooks/usePlayerSocialNetworks';
import { PlayerSocialNetworksActions } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/PlayerSocialNetworksActions/PlayerSocialNetworksActions';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './PlayerSocialNetworksPage.module.css';
import tableStyles from './PlayerSocialNetworksTable.module.css';

export const PlayerSocialNetworksPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    socialLinks,
    page,
    total,
    handleConfirmButtonClick,
  } = usePlayerSocialNetworks();
  const { openCreateModal, createSocialNetworkModalProps } =
    useCreatePlayerSocialNetwork();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonList
        className={styles.wrapper}
        configCreator={usePlayerSocialNetworksColumnsConfig}
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.socialLinks')}
        createButtonProps={{
          content: t('content.addLinkOtherEnding'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(socialLinks, page),
          totalItems: calculateTotalItems(socialLinks, total),
          onPageChange: changePage,
        }}
        tableProps={{
          wrapperClassName: tableStyles.root,
          data: socialLinks,
          headRowClassName: tableStyles.headRow,
          rowClassName: tableStyles.rowGridTemplate,
          ActionComponent: PlayerSocialNetworksActions,
        }}
      />
      {createSocialNetworkModalProps.isOpened && (
        <SocialNetworkModal {...createSocialNetworkModalProps} />
      )}
    </PlayerLayout>
  );
};
