import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { TAchievementModalValue } from 'feature/achievementModal/types';
import { useDeletePlayerAchievement } from 'pages/player/achievements/PlayerAchievementsPage/hooks/useDeletePlayerAchievement';
import { useEditPlayerAchievement } from 'pages/player/achievements/PlayerAchievementsPage/hooks/useEditPlayerAchievement';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const usePlayerAchievementsActions = ({
  isActive,
  onIconClick,
  closeActiveItemId,
  item: playerAchievement,
}: TTableActions<TPlayerAchievement>) => {
  const { t } = useTranslation();
  const { id, playerId, year, translations } = playerAchievement;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);

  const { deletePlayerAchievement, isDeletingPlayerAchievement } =
    useDeletePlayerAchievement();

  const { isEditingPlayerAchievement, editPlayerAchievement } =
    useEditPlayerAchievement();

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerAchievement(id);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    closeActiveItemId();
  };

  const handleConfirmEditing = ({ translations, year }: TAchievementModalValue) => {
    editPlayerAchievement(
      {
        ...playerAchievement,
        playerId: toNumber(playerId),
        year: toNumber(year),
        translations,
      },
      handleCloseEditModal,
    );
  };

  return {
    actionsProps: {
      actions,
      itemId: id,
      isLoading: isDeletingPlayerAchievement || isEditingPlayerAchievement,
      isOpen: isActive,
      onIconClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteAchievement'),
    },
    editModalProps: {
      initialValue: {
        year: toString(year),
        translations,
      },
      title: t('content.player.editAchievement'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingPlayerAchievement,
    },
  };
};
