import { Alert } from 'shared/components/Alert/Alert';
import { Text } from 'shared/components/Text/Text';

export const AlertExample = () => {
  return (
    <div>
      <Text size={24} tag="h2">
        Alert
      </Text>
      <Alert
        icon="alert-triangle"
        message="this is a messsage"
        title="Hello"
        variant="green"
      />
    </div>
  );
};
