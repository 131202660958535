export const toBoolean = (value: unknown): boolean => {
  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    value = value.trim().toLowerCase();

    if (value === 'true' || value === '1') {
      return true;
    }

    if (value === 'false' || value === '0' || value === '') {
      return false;
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  return Boolean(value);
};
