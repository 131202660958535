import { createEditorParagraphElement } from 'shared/components/Editor/utils/paragraph/createEditorParagraphElement';
import { TAddNewsPostFields } from 'shared/components/NewsPostForm/types';
import { TContentLang, TPostFormFile, TTranslations } from 'shared/types/common';

export const createAddNewsPostInitialValue = (
  defaultLocale: TContentLang,
): TAddNewsPostFields => ({
  content: {
    preview: {
      id: '',
      translations: {
        [defaultLocale]: {
          file: undefined,
          data: '',
        },
      } as TTranslations<TPostFormFile>,
    },
    title: {
      id: '',
      translations: {
        [defaultLocale]: {
          title: '',
        },
      } as TTranslations<{
        title: string;
      }>,
    },
    body: [createEditorParagraphElement('', defaultLocale)],
  },
  locale: {
    created: [defaultLocale],
    selected: defaultLocale,
  },
  preview: {
    file: undefined,
    data: '',
  },
  labels: [],
  categories: [],
  teams: [],
  tournament: undefined,
  match: undefined,
  players: [],
  managers: [],
  important: false,
  slider: {
    file: undefined,
    data: '',
  },
  publishDate: undefined,
});
