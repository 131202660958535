import { useLocales } from 'entities/config/lib/useLocales';
import { TManagerCareer } from 'entities/managerCareers/model/types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from '../ManagerCareersPage.module.css';

export const useManagerCareersColumnsConfig: TConfigCreator<TManagerCareer> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.club'),
      render: (item) => (
        <>
          <div className={styles.previewWrapper}>
            {item.teamPicture ? (
              <img
                alt={`${item?.translations[defaultLocale]?.teamName} preview`}
                src={item.teamPicture.x1}
              />
            ) : (
              <Icon kind="picture" size="s" />
            )}
          </div>
          <Text className={styles.text}>
            {item?.translations[defaultLocale]?.teamName}
          </Text>
        </>
      ),
    },
    {
      title: t('content.manager.jobTitle'),
      render: (item) => (
        <Text className={styles.text}>
          {item.translations[defaultLocale]?.jobTitle}
        </Text>
      ),
    },
    {
      title: t('content.season'),
      render: (item) => <Text>{item.season}</Text>,
    },
    {
      title: 'ID',
      render: (item) => <Text>{item.id}</Text>,
    },
  ];
};
