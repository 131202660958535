import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { AchievementModal } from 'feature/achievementModal/AchievementModal';
import { useManagerTeamAchievementsActions } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/hooks/useManagerTeamAchievementsActions';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';
export const ManagerTeamAchievementsActions = (
  props: TTableActions<TManagerTeamAchievement>,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    useManagerTeamAchievementsActions(props);
  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <AchievementModal {...editModalProps} />}
    </>
  );
};
