import cn from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import {
  TFormSwitchProps,
  TRenderInputProps,
  TSwitchOwnProps,
} from 'shared/components/Form/types';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';

import styles from './Switch.module.css';

type TProps = TSwitchOwnProps & Omit<ComponentProps<'input'>, 'size'>;

export const Switch = forwardRef<HTMLInputElement, TProps>(
  ({ className, checked, size = 's', disabled, ...rest }, ref) => (
    <label>
      <input
        {...rest}
        checked={checked}
        className={cn(styles.input, className)}
        disabled={disabled}
        ref={ref}
        type="checkbox"
      />
      <span
        className={cn(styles.box, styles[size], {
          [styles.checked]: checked,
          [styles.checkedS]: checked && size === 's',
          [styles.checkedL]: checked && size === 'l',
          [styles.disabled]: disabled,
        })}
      />
    </label>
  ),
);

Switch.displayName = 'Switch';
export const SwitchField = <T extends FieldValues>(props: TFormSwitchProps<T>) => {
  const { control, fieldName, className, label, size, disabled } = props;

  const renderInput = (renderProps: TRenderInputProps<T>) => {
    const { field } = renderProps;
    return (
      <Field className={className} label={label}>
        <Switch
          {...field}
          checked={toBoolean(field.value)}
          disabled={disabled}
          size={size}
          value={toString(toBoolean(field.value))}
        />
      </Field>
    );
  };

  return <Controller control={control} name={fieldName} render={renderInput} />;
};
