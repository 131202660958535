import i18n from 'i18next';
import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

export type TPlayerNavigationLink = {
  name: string;
  route: string;
  disabled?: boolean;
};

export const getPlayerNavigationLinks = (
  teamId: TEntityId = '',
  playerId: TEntityId = '',
): TPlayerNavigationLink[] => [
  {
    name: i18n.t('content.baseData'),
    route: playerId
      ? routes.editPlayer.as(teamId, playerId)
      : routes.createPlayer.as(teamId),
  },
  {
    name: i18n.t('content.linkToSocial'),
    route: routes.playerSocialNetworks.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
  {
    name: i18n.t('content.career'),
    route: routes.playerCareers.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
  {
    name: i18n.t('content.teamAchievements'),
    route: routes.playerTeamAchievements.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
  {
    name: i18n.t('content.player.personalAchievement'),
    route: routes.playerAchievements.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
];
