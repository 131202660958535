import 'app/assets/styles/base.css';
import 'shared/localesLanguages/i18n';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { initSentry } from 'shared/sentry';

import { Providers } from './Providers';

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);

const isProductionBundle = process.env.NODE_ENV === 'production';

if (isProductionBundle) {
  initSentry();
}

root.render(
  <StrictMode>
    <Providers />
  </StrictMode>,
);
