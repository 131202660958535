import { ManagerInformation } from 'shared/components/Card/CardInformationPart/ManagerInformation';
import { OpponentInformation } from 'shared/components/Card/CardInformationPart/OpponentInformation';
import { PlayerInformation } from 'shared/components/Card/CardInformationPart/PlayerInformation';
import { TeamInformation } from 'shared/components/Card/CardInformationPart/TeamInformation';
import { ECardType, TCardInformation } from 'shared/components/Card/types';

const elementMapper = {
  [ECardType.Player]: PlayerInformation,
  [ECardType.Opponent]: OpponentInformation,
  [ECardType.Manager]: ManagerInformation,
  [ECardType.Team]: TeamInformation,
};

export const CardInformation = (props: TCardInformation) =>
  elementMapper[props.type](props);
