import cn from 'classnames';
import { TSettingSubNavigationLink } from 'feature/settingsNavigation/types';
import { NavLink } from 'react-router-dom';
import { Text } from 'shared/components/Text/Text';

import styles from './SettingsNavigationLink.module.css';

type TProps = {
  link: TSettingSubNavigationLink;
  className: string;
};

export const SettingsNavigationLink = ({ link, className }: TProps) => (
  <NavLink
    to={link.route}
    className={({ isActive }) =>
      cn(styles.link, { [styles.linkActive]: isActive }, className)
    }
  >
    <Text size={12}>{link.name}</Text>
  </NavLink>
);
