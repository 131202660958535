import { useFetchNewsPostForEditQuery } from 'entities/news/queries';
import { useParams } from 'react-router-dom';
import { Loader } from 'shared/components/Loader/Loader';
import { NewsPostForm } from 'shared/components/NewsPostForm/NewsPostForm';

import styles from './EditNewsPost.module.css';

export const EditNewsPost = () => {
  const { newsPostId } = useParams<{ newsPostId: string }>();
  const { data, isLoading, isError, error } =
    useFetchNewsPostForEditQuery(newsPostId);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <Loader className={styles.loading} />;
  }

  return <NewsPostForm initialValue={data} />;
};
