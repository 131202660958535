import { Button } from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';

import styles from '../UIKit.module.css';

export const ButtonExample = () => {
  return (
    <>
      <Text size={24} tag="h2">
        Button
      </Text>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Types:
        </Text>
        <Button kind="primary">Text</Button>
        <Button kind="secondary">Text</Button>
        <Button kind="outlined">Text</Button>
        <Button kind="light">Text</Button>
        <Button kind="ghost">Text</Button>
        <Button kind="basic">Text</Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Sizes:
        </Text>
        <Button size="l">Size: L</Button>
        <Button size="m">Size: M</Button>
        <Button size="s">Size: S</Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Disabled:
        </Text>
        <Button isDisabled size="l">
          Size: L
        </Button>
        <Button isDisabled size="m">
          Size: M
        </Button>
        <Button isDisabled size="s">
          Size: S
        </Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Colors:
        </Text>
        <Button color="green">Primary</Button>
        <Button color="gray">Primary</Button>
        <Button color="blue">Primary</Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Secondary:
        </Text>
        <Button color="green" kind="secondary">
          Secondary
        </Button>
        <Button color="gray" kind="secondary">
          Secondary
        </Button>
        <Button color="blue" kind="secondary">
          Secondary
        </Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Outlined:
        </Text>
        <Button color="green" kind="outlined">
          Outlined
        </Button>
        <Button color="gray" kind="outlined">
          Outlined
        </Button>
        <Button color="blue" kind="outlined">
          Outlined
        </Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Ghost:
        </Text>
        <Button color="green" kind="ghost">
          Ghost
        </Button>
        <Button color="gray" kind="ghost">
          Ghost
        </Button>
        <Button color="blue" kind="ghost">
          Ghost
        </Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Light:
        </Text>
        <Button color="green" kind="light">
          Light
        </Button>
        <Button color="gray" kind="light">
          Light
        </Button>
        <Button color="blue" kind="light">
          Light
        </Button>
      </div>
      <div className={styles.row}>
        <Text size={16} tag="h3">
          Loading:
        </Text>
        <Button isLoading kind="primary">
          Text
        </Button>
        <Button color="gray" isLoading>
          Primary
        </Button>
        <Button color="blue" isLoading>
          Primary
        </Button>
        <Button isLoading kind="secondary">
          Text
        </Button>
        <Button color="gray" isLoading kind="secondary">
          Secondary
        </Button>
        <Button color="blue" isLoading kind="secondary">
          Secondary
        </Button>
        <Button isLoading kind="outlined">
          Text
        </Button>
        <Button isLoading kind="light">
          Text
        </Button>
        <Button isLoading kind="ghost">
          Text
        </Button>
        <Button isLoading kind="basic">
          Text
        </Button>
      </div>
    </>
  );
};
