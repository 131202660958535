import cn from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './TCell.module.css';

export type TTableCellProps = HTMLAttributes<HTMLDivElement>;

export const TCell = ({ children, className, ...other }: TTableCellProps) => (
  <div {...other} className={cn(styles.cell, className)}>
    {children}
  </div>
);
