import { TCropper } from 'shared/components/cropper/types';

export const cropperController = {
  isActiveCropping(cropper: TCropper) {
    return cropper.getCropBoxData().width > 0;
  },

  getCroppedUrl(cropper: TCropper, mimeType?: string) {
    return cropper.getCroppedCanvas().toDataURL(mimeType);
  },
};
