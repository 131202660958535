import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { fetchVideoPost, fetchVideoPosts } from 'entities/video/api';
import { videoQueryKeys } from 'entities/video/consts';
import { mapAPIVideoPostToVideoPostFields } from 'entities/video/mapper/mapAPIVideoPostToVideoPostFields';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

export type TFetchVideoPostsParams = {
  sort: 'published' | 'created_at' | '-published' | '-created_at' | undefined;
  filter?: Record<string, string>;
};

export const useFetchVideosQuery = ({ sort, filter }: TFetchVideoPostsParams) => {
  const { defaultLocale } = useLocales();

  return usePaginationQuery((pagination) => ({
    queryKey: [videoQueryKeys.videoPosts, pagination, sort, filter],
    queryFn: () =>
      fetchVideoPosts({ params: { ...pagination, ...filter, sort } }, defaultLocale),
    cacheTime: 0,
  }));
};

export const useFetchVideoForEditQuery = (videoPostId: TEntityId) => {
  const { defaultLocale } = useLocales();
  return useQuery({
    queryKey: [videoQueryKeys.videoPostForEdit, videoPostId],
    queryFn: async () =>
      await mapAPIVideoPostToVideoPostFields(
        await fetchVideoPost(videoPostId),
        defaultLocale,
      ),
    enabled: toBoolean(videoPostId),
    cacheTime: 0,
  });
};
