import cn from 'classnames';
import { ReactNode } from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TWithChildren } from 'shared/types/common';

import styles from './Collapse.module.css';

export type TCollapseProps = {
  label: ReactNode;
  isError?: boolean;
  disabled?: boolean;
  isOpen: boolean;
  onClick: () => void;
};

export const Collapse = ({
  label,
  isError = false,
  isOpen = false,
  disabled = false,
  onClick,
  children,
}: TCollapseProps & TWithChildren) => {
  return (
    <div className={styles.root}>
      <button
        disabled={disabled}
        type="button"
        className={cn(styles.header, {
          [styles.active]: isOpen,
          [styles.disabled]: disabled,
          [styles.error]: isError,
        })}
        onClick={onClick}
      >
        {typeof label === 'string' ? <Text size={14}>{label}</Text> : label}
        <Icon
          className={cn(styles.button, { [styles.activeButton]: isOpen })}
          kind="chevron-down"
          size="s"
        />
      </button>
      {isOpen && <div className={styles.body}>{children}</div>}
    </div>
  );
};
