import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { managerCareersQueryKeys } from 'entities/managerCareers/api/consts';
import { useCreateManagerCareerMutation } from 'entities/managerCareers/api/mutations';
import { mapCreateManagerCareerDTO } from 'entities/managerCareers/mapper/mapCreateManagerCareerDTO';
import { TManagerCareerModalFields } from 'feature/managerCareerModal/types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { notify } from 'shared/components/Notification';

export const useCreateManagerCareer = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { locales } = useLocales();

  const [isCreateModalOpen, setIsCreateModalOpen] = useBoolean(false);
  const { managerId = '' } = useParams<{
    managerId: string;
  }>();
  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const { mutateAsync: createCareerMutation, isLoading } =
    useCreateManagerCareerMutation();

  const createManagerCareerHandler = async (data: TManagerCareerModalFields) => {
    const createManagerCareerDTO = await mapCreateManagerCareerDTO({
      data,
      locales,
      managerId,
    });

    await createCareerMutation(createManagerCareerDTO, {
      onSuccess: () => {
        queryClient.resetQueries([managerCareersQueryKeys.managerCareers]);
      },
      onError: () => {
        notify(t('content.manager.notifyNotCreateCareer'), { type: 'error' });
      },
    });
    closeCreateModal();
  };

  return {
    openCreateModal,
    createCareerModalProps: {
      title: t('content.createCareer'),
      closeModal: closeCreateModal,
      actionSubmit: createManagerCareerHandler,
      isOpened: isCreateModalOpen,
      isLoading,
    },
  };
};
