import './circularProgressBarStyles.css';

import cn from 'classnames';
import { forwardRef } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';

import styles from './Loader.module.css';
import { TLoaderSize } from './types';

type TProps = {
  className?: string;
  size?: TLoaderSize;
  percent?: number;
  showLabel?: boolean;
};

const strokeWidthBySize = {
  xxs: 10,
  xs: 3,
  s: 5,
  m: 7,
  l: 8,
} as const;

const textSizeBySize = {
  xxs: 12,
  xs: 12,
  s: 14,
  m: 16,
  l: 18,
} as const;

export const Loader = forwardRef<HTMLDivElement, TProps>(
  ({ className, size = 'm', showLabel, percent }, ref) => {
    const { t } = useTranslation();
    const label =
      percent || percent === 0
        ? `${percent}%`
        : `${t('shared.Loader.uploadText')}...`;
    return (
      <div className={cn(styles.root, className)} ref={ref}>
        <div className={cn(styles[size])}>
          <CircularProgressbar
            className={percent === undefined ? styles.rotating : undefined}
            strokeWidth={strokeWidthBySize[size]}
            value={percent || percent === 0 ? percent : 30}
          />
        </div>
        {showLabel && (
          <Text
            className={styles.label}
            size={textSizeBySize[size]}
            tag="div"
            weight="Bold"
          >
            {label}
          </Text>
        )}
      </div>
    );
  },
);
