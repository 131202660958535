import { TEntityId } from 'shared/types/common';

export const playerAchievementsQueryKeys = {
  playerAchievements: 'playerAchievements',
};

const playerAchievementsBaseUrl = 'player/achievements';

export const playerAchievementsApiKeys = {
  getPlayerAchievements: playerAchievementsBaseUrl,
  createPlayerAchievement: playerAchievementsBaseUrl,
  deletePlayerAchievement: (achievementId: TEntityId) =>
    `${playerAchievementsBaseUrl}/${achievementId}`,
  editPlayerAchievement: (achievementId: TEntityId) =>
    `${playerAchievementsBaseUrl}/${achievementId}`,
};
