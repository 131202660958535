import { RenderLeafProps } from 'slate-react';

export const editorRenderLeaf = ({
  attributes,
  children,
  leaf,
}: RenderLeafProps) => {
  return (
    <span
      {...attributes}
      style={{
        fontStyle: leaf.italic ? 'italic' : 'normal',
        fontWeight: leaf.bold ? 'bold' : 'normal',
        textDecoration:
          leaf.underline || leaf.deleted
            ? `${leaf.underline ? 'underline' : ''} ${leaf.deleted ? 'line-through' : ''}`
            : 'none',
      }}
    >
      {children}
    </span>
  );
};
