import { useLocales } from 'entities/config/lib/useLocales';
import { TPhotoGallery } from 'entities/photoGallery/types';
import { PhotoGalleryCategoryFilter } from 'pages/photogallery/PhotoGalleryPage/PhotoGalleryCategoryFilter';
import { PhotoGalleryLabelFilter } from 'pages/photogallery/PhotoGalleryPage/PhotoGalleryLabelFilter';
import {
  TPhotoGalleryFilter,
  TPhotoGalleryTableSortParams,
} from 'pages/photogallery/PhotoGalleryPage/types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { Text } from 'shared/components/Text/Text';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './PhotoGalleryPage.module.css';

export const usePhotoGalleryColumnsConfig: TConfigCreator<
  TPhotoGallery,
  TPhotoGalleryTableSortParams,
  TPhotoGalleryFilter
> = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  return [
    {
      title: t('content.photoGalleryList.title'),
      headCellProps: {
        sort: {
          sortParam: 'name',
        },
      },
      cellClassName: styles.publicationNameColumn,
      render: (item) => (
        <>
          {item.pictures.base && item.pictures.base.x1 ? (
            <div className={styles.previewWrapper}>
              <img
                alt={`${item?.translations[defaultLocale]?.name} preview`}
                className={styles.preview}
                src={item.pictures.base.x1}
              />
            </div>
          ) : (
            <div className={styles.previewWrapper}>
              <Icon kind="picture" size="s" />
            </div>
          )}
          <Text className={styles.text}>
            {item?.translations[defaultLocale]?.name}
          </Text>
        </>
      ),
    },
    {
      title: t('content.createDate'),
      headCellProps: {
        sort: {
          sortParam: 'created_at',
        },
      },
      cellClassName: styles.publicationNameColumn,
      render: (item) => <DateTimeCell dateTimeString={item.createdAt} />,
    },
    {
      title: t('content.category'),
      render: (item) => (
        <Text className={styles.text}>
          {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
        </Text>
      ),
      headCellProps: {
        filter: {
          filterName: 'categories',
          getIsFiltered: (filter) =>
            toBoolean(filter.categories && filter.categories.length > 0),
          getFilterBadge: (filter) =>
            filter.categories?.length ? toString(filter.categories?.length) : null,
          FilterComponent: PhotoGalleryCategoryFilter,
        },
      },
    },
    {
      title: t('content.commonList.labelColumnLabel'),
      render: (item) => (
        <Text className={styles.text}>
          {item.labels?.[0]?.translations[defaultLocale]?.name}
        </Text>
      ),
      headCellProps: {
        filter: {
          filterName: 'labels',
          getIsFiltered: (filter) =>
            toBoolean(filter.labels && filter.labels.length > 0),
          getFilterBadge: (filter) =>
            filter.labels?.length ? toString(filter.labels?.length) : null,
          FilterComponent: PhotoGalleryLabelFilter,
        },
      },
    },
    {
      title: t('content.publicDate'),
      render: (item) => (
        <Text className={styles.categories}>
          <DateTimeCell dateTimeString={item?.published ?? ''} />
        </Text>
      ),
      headCellProps: {
        sort: {
          sortParam: 'published',
        },
      },
    },
    {
      title: 'ID',
      render: (item) => <Text className={styles.text}>{item?.id}</Text>,
    },
  ];
};
