import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';

import styles from './Dashboard.module.css';

export const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Text size={30} tag="h2" weight="SemiBold">
        {t('content.welcome', { mark: '!' })}
      </Text>
      <Text size={24}>{t('content.startWork')}</Text>
    </div>
  );
};
