import cn from 'classnames';
import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { RightPanelCollapseWrapper } from 'feature/rightPanel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues, Path } from 'react-hook-form';
import { SwitchField } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from '../RightPanel.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  description: string;
  label: string;
  title: string;
  switchLabel: string;
  switchTip?: string;
  isError: boolean;
  disabledSwitch?: boolean;
};

export const CommonReview = <T extends FieldValues>({
  control,
  fieldName,
  description,
  switchLabel,
  label,
  title,
  isError,
  disabledSwitch,
  switchTip,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  return (
    <RightPanelCollapseWrapper
      description={description}
      isError={isError}
      label={label}
      title={title}
      {...restProps}
    >
      <div className={styles.switchWrapper}>
        <SwitchField
          control={control}
          disabled={disabledSwitch}
          fieldName={fieldName}
        />
        <Text className={cn({ [styles.disabledSwitch]: disabledSwitch })} size={12}>
          {switchLabel}
        </Text>
        {toBoolean(switchTip) && (
          <Tooltip placement="top-start" tip={switchTip}>
            <Icon className={styles.tooltip} kind="info" size="xs" />
          </Tooltip>
        )}
      </div>
    </RightPanelCollapseWrapper>
  );
};
