import { EditorBulletedListElement } from 'shared/components/Editor/elements/EditorBulletedListElement/EditorBulletedListElement';
import { EditorHeadingTwoElement } from 'shared/components/Editor/elements/EditorHeadingTwoElement';
import {
  EditorImageElement,
  TEditorImageElementProps,
} from 'shared/components/Editor/elements/EditorImageElement/EditorImageElement';
import { EditorLinkElement } from 'shared/components/Editor/elements/EditorLinkElement/EditorLinkElement';
import { EditorListItemElement } from 'shared/components/Editor/elements/EditorListItemElement/EditorListItemElement';
import { EditorParagraphElement } from 'shared/components/Editor/elements/EditorParagraphElement';
import {
  EditorSliderElement,
  TEditorSliderElementProps,
} from 'shared/components/Editor/elements/EditorSliderElement/EditorSliderElement';
import {
  EditorVideoElement,
  TEditorVideoElementProps,
} from 'shared/components/Editor/elements/EditorVideoElement/EditorVideoElement';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { TEditorDisableStructureChange } from 'shared/components/Editor/utils/structureChange/types';
import { RenderElementProps } from 'slate-react';

const elementMapper = {
  [EditorElementTypeEnum.Paragraph]: EditorParagraphElement,
  [EditorElementTypeEnum.Slider]: EditorSliderElement,
  [EditorElementTypeEnum.Image]: EditorImageElement,
  [EditorElementTypeEnum.Video]: EditorVideoElement,
  [EditorElementTypeEnum.Link]: EditorLinkElement,
  [EditorElementTypeEnum.BulletedList]: EditorBulletedListElement,
  [EditorElementTypeEnum.ListItem]: EditorListItemElement,
  [EditorElementTypeEnum.HeadingTwo]: EditorHeadingTwoElement,
};

export const editorRenderElements = (
  props: RenderElementProps &
    TEditorImageElementProps &
    TEditorSliderElementProps &
    TEditorVideoElementProps &
    TEditorDisableStructureChange,
) => elementMapper[props.element.type](props);
