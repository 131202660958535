import { useFetchTeamQuery } from 'entities/teams/queries';

export const useTeam = (teamId: string | undefined) => {
  const { data, error, isLoading } = useFetchTeamQuery(teamId);

  if (error) {
    throw error;
  }

  return {
    isLoading,
    data,
  };
};
