import { useChangeAppLocale } from 'pages/settings/language/useChangeAppLocale';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Text } from 'shared/components/Text/Text';
import { AppLanguages } from 'shared/types/common';

import styles from './LanguageSettingPage.module.css';

export const LanguageSettingPage = () => {
  const { i18n, t } = useTranslation();
  const changeAppLocale = useChangeAppLocale();

  return (
    <div className={styles.root}>
      <Text size={20} tag="h1" weight="Bold">
        {t('content.options.language')}
      </Text>
      <div className={styles.wrapper}>
        <RadioButton
          checked={i18n.language === AppLanguages.ru}
          label={t('content.onlyRussian')}
          name="language"
          value={AppLanguages.ru}
          onChange={() => changeAppLocale(AppLanguages.ru)}
        />
        <RadioButton
          checked={i18n.language === AppLanguages.en}
          label={t('content.onlyEnglish')}
          name="language"
          value={AppLanguages.en}
          onChange={() => changeAppLocale(AppLanguages.en)}
        />
      </div>
    </div>
  );
};
