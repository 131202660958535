import cn from 'classnames';
import { TFilter } from 'feature/filters/types';
import i18n from 'i18next';
import { ReactNode } from 'react';
import { Button, TButtonProps } from 'shared/components/Button/Button';
import { Loader } from 'shared/components/Loader/Loader';
import {
  Pagination,
  TPaginationProps,
} from 'shared/components/Pagination/Pagination';
import { Text } from 'shared/components/Text/Text';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';
import { TConfigCreator, TDataItem } from 'widgets/elementsList/types';
import {
  CommonListTable,
  TCommonListTableProps,
} from 'widgets/elementsList/ui/CommonListTable/CommonListTable';

import { EmptyListPage } from '../EmptyList/EmptyListPage';
import styles from './CommonList.module.css';
type TCreateButtonProps = Omit<TButtonProps, 'children'> & {
  content: ReactNode;
};
type TTableProps<
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
> = Omit<TCommonListTableProps<T, SortBy, FilterType>, 'columns'> & {
  wrapperClassName?: string;
};
type TProps<
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
> = {
  title: string;
  createButtonProps: TCreateButtonProps;
  isLoading: boolean;
  isEmpty: boolean;
  error: unknown;
  emptyComponentDescription?: string;
  className?: string;
  paginationProps: TPaginationProps;
  tableProps: TTableProps<T, SortBy, FilterType>;
  configCreator: TConfigCreator<T, SortBy, FilterType>;
};
export const CommonList = <
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
>({
  title,
  createButtonProps: {
    content,
    onClick: onCreateButtonClick,
    icon: createButtonIcon = 'plus',
    iconPosition: createButtonIconPosition = 'before',
    iconSize: createButtonIconSize = 'xs',
    className: createButtonClassName,
    ...restCreateButtonProps
  },
  className,
  isLoading,
  error,
  isEmpty,
  emptyComponentDescription = i18n.t('shared.Form.select.text'),
  paginationProps,
  tableProps: { wrapperClassName, ...tableProps },
  configCreator,
}: TProps<T, SortBy, FilterType>) => {
  const columnsConfig = configCreator();
  if (isLoading) {
    return (
      <div className={styles.root}>
        <Loader className={styles.loading} showLabel />
      </div>
    );
  }
  if (error) {
    throw error;
  }
  if (isEmpty && !isLoading) {
    return (
      <EmptyListPage
        createButtonContent={content}
        message={emptyComponentDescription}
        onCreateButtonClick={onCreateButtonClick}
      />
    );
  }
  const isShowPagination =
    paginationProps.totalItems > 0 &&
    paginationProps.totalItems >
      (paginationProps?.pageSize ?? DEFAULT_PAGINATION_PAGE_SIZE) &&
    !isLoading;
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.header}>
        <Text size={24} tag="h1" weight="Bold">
          {title}
        </Text>
        <Button
          className={cn(styles.createButton, createButtonClassName)}
          icon={createButtonIcon}
          iconPosition={createButtonIconPosition}
          iconSize={createButtonIconSize}
          onClick={onCreateButtonClick}
          {...restCreateButtonProps}
        >
          {content}
        </Button>
      </div>
      <div className={wrapperClassName}>
        <CommonListTable columns={columnsConfig} {...tableProps} />
      </div>
      {isShowPagination && (
        <Pagination
          className={cn(styles.pagination, paginationProps.className)}
          {...paginationProps}
        />
      )}
    </div>
  );
};
