import i18n from 'i18next';
import { FileRejection } from 'react-dropzone';

export const getUploadImageModalErrorMessage = (
  fileRejections: readonly FileRejection[],
  maxFiles: number,
): string => {
  if (fileRejections.length === 0) {
    return '';
  }

  if (fileRejections[0].errors[0].code === 'too-many-files') {
    return `${i18n.t('shared.ImageUploadModal.helpers.maxFiles')} ${maxFiles}.`;
  }

  if (fileRejections[0].errors[0].code === 'file-too-large') {
    return i18n.t('shared.ImageUploadModal.helpers.maxSizeFile', {
      mark: '.',
    });
  }

  if (fileRejections[0].errors[0].code === 'file-invalid-type') {
    return i18n.t('shared.ImageUploadModal.helpers.notValid');
  }

  return '';
};
