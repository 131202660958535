import cn from 'classnames';
import { ReactNode } from 'react';
import { Button, TButtonProps } from 'shared/components/Button/Button';
import { Divider } from 'shared/components/Divider';

import styles from './Actions.module.css';

type TProps = {
  buttonProps: TButtonProps;
  title: ReactNode;
  isLast: boolean;
};

export const ActionItem = ({
  buttonProps: {
    className,
    iconPosition = 'before',
    kind = 'basic',
    ...restButtonProps
  },

  title,
  isLast,
}: TProps) => {
  return (
    <>
      <Button
        {...restButtonProps}
        className={cn(styles.actionButton, className)}
        iconPosition={iconPosition}
        kind={kind}
      >
        {title}
      </Button>
      {!isLast && <Divider />}
    </>
  );
};
