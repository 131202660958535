import {
  mapCreatePlayerCareerDTO,
  TMapCreatePlayerDTOProps,
} from 'entities/playerCareers/mapper/mapCreatePlayerCareerDTO';
import { TPlayerEditCareerDTO } from 'entities/playerCareers/types';
import { TEntityId } from 'shared/types/common';

export const mapEditPlayerCareerDTO = async (
  createPlayerCareerDTOProps: TMapCreatePlayerDTOProps,
  careerId: TEntityId,
): Promise<TPlayerEditCareerDTO> => {
  const createPlayerCareerDTO = await mapCreatePlayerCareerDTO(
    createPlayerCareerDTOProps,
  );

  return {
    careerId,
    ...createPlayerCareerDTO,
  };
};
