import { ToolsListButton } from 'pages/stories/edit/EditStory/components/ToolsList/ToolsListButton';
import { getStoryTools } from 'pages/stories/edit/EditStory/constants';

import styles from './ToolsList.module.css';

export const ToolsList = () => {
  return (
    <div className={styles.root}>
      {getStoryTools().map((tool) => (
        <ToolsListButton
          key={tool.kind}
          icon={tool.icon}
          kind={tool.kind}
          limit={tool.limit}
          title={tool.title}
        />
      ))}
    </div>
  );
};
