import { FieldValues, useController } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { Select } from 'shared/components/Form/Select/Select';
import { TFormSelectProps } from 'shared/components/Form/Select/types';

export const FormSelect = <T extends FieldValues>({
  label,
  className,
  fieldName,
  control,
  rules,
  tip,
  required,
  disabled,
  labelClassName,
  ...props
}: TFormSelectProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: fieldName,
    rules,
  });

  return (
    <Field
      className={className}
      disabled={disabled}
      label={label}
      labelClassName={labelClassName}
      required={required}
      tip={tip}
    >
      <Select name={fieldName} value={value} onChange={onChange} {...props} />
    </Field>
  );
};
