import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import {
  TAchievementModalProps,
  TAchievementModalValue,
} from 'feature/achievementModal/types';
import { achievementModalValidationSchema } from 'feature/achievementModal/validationSchema';
import { useForm } from 'shared/hooks/useReactHookForm';
const ACHIEVEMENT_MODAL_WIDTH = 542;
export const useAchievementModal = ({
  initialValue,
  actionSubmit,
  closeModal,
  isLoading,
  isOpened,
  title,
}: TAchievementModalProps) => {
  const { locales } = useLocales();
  const {
    control,
    submit,
    formState: { isValid, isDirty },
    reset,
  } = useForm<TAchievementModalValue>({
    defaultValues: initialValue,
    mode: 'onChange',
    resolver: zodResolver(achievementModalValidationSchema(locales)),
    actionSubmit,
  });
  const onCloseModal = () => {
    closeModal();
    reset();
  };
  return {
    locales,
    control,
    actionModalProps: {
      onClose: onCloseModal,
      opened: isOpened,
      title,
      width: ACHIEVEMENT_MODAL_WIDTH,
      confirmButtonProps: {
        isDisabled: !isValid || !isDirty,
        onClick: submit,
        isLoading,
      },
    },
  };
};
