import cn from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './TBody.module.css';

export type TTableBodyProps = HTMLAttributes<HTMLDivElement>;

export const TBody = ({ children, className, ...other }: TTableBodyProps) => (
  <div {...other} className={cn(styles.tableBody, className)}>
    {children}
  </div>
);
