import { queryClient } from 'app/Providers';
import { useLocales } from 'entities/config/lib/useLocales';
import { opponentPlayersQueryKeys } from 'entities/opponentPlayers/consts';
import { useDeleteOpponentPlayerMutation } from 'entities/opponentPlayers/mutations';
import { TOpponentPlayer } from 'entities/opponentPlayers/types';
import { OpponentPlayerModal } from 'feature/opponentPlayerModal/OpponentPlayerModal';
import { useEditOpponentPlayer } from 'pages/opponentTeam/teamPage/hooks/useEditOpponentPlayer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
type TProps = {
  opponentPlayer: TOpponentPlayer;
};
export const OpponentPlayerCard = ({ opponentPlayer }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { mutateAsync: deleteOpponentPlayer, isLoading } =
    useDeleteOpponentPlayerMutation();
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const {
    editOpponentPlayer,
    isLoading: isLoadingEditOpponentPlayer,
    openEditModal,
    closeEditModal,
    openedEditModal,
    initialValues,
  } = useEditOpponentPlayer(opponentPlayer.id, teamId ?? '');
  const { openConfirmModal, closeConfirmModal, openedConfirmModal } =
    useModal('Confirm');
  const onDeleteHandler = () => {
    deleteOpponentPlayer(opponentPlayer.id, {
      onError: () => notify(t('content.validation.notifyDeletePlayerOpponent')),
      onSuccess: () => {
        queryClient.resetQueries([opponentPlayersQueryKeys.opponentPlayers]);
      },
    });
  };
  return (
    <>
      <Card
        key={opponentPlayer.id}
        type={ECardType.Opponent}
        entity={{
          name: opponentPlayer.translations[defaultLocale].firstName,
          lastName: opponentPlayer.translations[defaultLocale].lastName,
          picture: opponentPlayer?.picture?.x3 ?? '',
          number: opponentPlayer.number,
        }}
        onDelete={openConfirmModal}
        onEdit={() => openEditModal(opponentPlayer)}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpened={openedConfirmModal}
        description={t('content.player.warningDelete', {
          mark: '.',
        })}
        title={t('content.player.notifyDelete', {
          mark: '?',
        })}
        onClose={closeConfirmModal}
        onConfirm={onDeleteHandler}
      />
      <OpponentPlayerModal
        actionSubmit={editOpponentPlayer}
        closeModal={closeEditModal}
        initialValues={initialValues}
        isLoading={isLoadingEditOpponentPlayer}
        isOpened={openedEditModal}
        title={t('content.opponentTeams.editPlayer')}
      />
    </>
  );
};
