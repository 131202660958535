import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { CommonSharingLink } from 'feature/rightPanel/ui/CommonSharingLink/CommonSharingLink';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

export const PhotoGallerySharingLink = (commonProps: TDefaultRightPanelProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TPhotoGalleryFields>();
  const { fieldState } = useController({
    control,
    name: 'sharingLink',
  });

  return (
    <CommonSharingLink
      control={control}
      fieldName="sharingLink"
      isError={fieldState.invalid}
      title={t('content.photoGallery.rightPanel.sharingLink.title')}
      {...commonProps}
    />
  );
};
