import { mapApiVideoPostToVideoPost } from 'entities/video/mapper/mapApiVideoPostToVideoPost';
import { TAPIVideoPost } from 'entities/video/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TContentLang } from 'shared/types/common';

export const mapVideoPostsResponse = (
  response: TResponseData<TAPIVideoPost[], TPaginatedMeta>,
  defaultLocale: TContentLang,
) => {
  return {
    ...response,
    data: response.data.map((videoPost) =>
      mapApiVideoPostToVideoPost(videoPost, defaultLocale),
    ),
  };
};
