import { TStoryElementData, TStoryToolKind } from 'entities/stories/types';
import i18n from 'i18next';
import { ElementForm } from 'pages/stories/edit/EditStory/components/forms/ElementForm';
import { LayersPanel } from 'pages/stories/edit/EditStory/components/LayersPanel/LayersPanel';
import { ToolsList } from 'pages/stories/edit/EditStory/components/ToolsList';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'shared/components/Tabs';

import styles from './ToolsPanel.module.css';

const getElementNameMap = (): { [key in TStoryToolKind]: string } => ({
  text: i18n.t('content.story.text'),
  button: i18n.t('content.story.button'),
  feedback: i18n.t('content.story.feedback'),
  countdown: i18n.t('content.story.countdown'),
  voting: i18n.t('content.story.voting'),
  copy: i18n.t('content.story.copy'),
  swipeUp: i18n.t('content.story.swipeUp'),
  quiz: i18n.t('content.story.quiz'),
});

export const ToolsPanel = () => {
  const { t } = useTranslation();
  const { selectedElement } = useStoryEditorContext();
  const [selectedTab, setSelectedTab] = useState('tools');
  const dispatch = useStoryEditorDispatchContext();

  useLayoutEffect(() => {
    if (selectedElement && selectedTab === 'layers') {
      return;
    }
    if (selectedElement) {
      return setSelectedTab('element');
    }

    if (!selectedElement && selectedTab === 'element') {
      return setSelectedTab('tools');
    }

    if (!selectedElement && selectedTab === 'layers') {
      return;
    }
  }, [selectedElement]);

  const handleElementSelect = (storyElement: TStoryElementData) => {
    dispatch({ type: 'selectStoryElement', payload: storyElement });
    setSelectedTab('element');
  };

  return (
    <div className={styles.root}>
      <Tabs className={styles.tabs} selected={selectedTab} onSelect={setSelectedTab}>
        <Tabs.Tab label={t('content.story.layers')} value="layers">
          <LayersPanel onElementSelect={handleElementSelect} />
        </Tabs.Tab>
        {selectedElement ? (
          <Tabs.Tab
            label={getElementNameMap()[selectedElement.kind]}
            value="element"
          >
            <ElementForm selectedElement={selectedElement} />
          </Tabs.Tab>
        ) : (
          <Tabs.Tab label={t('content.story.contentPanel')} value="tools">
            <ToolsList />
          </Tabs.Tab>
        )}
      </Tabs>
    </div>
  );
};
