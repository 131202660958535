import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { useLocales } from 'entities/config/lib/useLocales';
import { TMatch } from 'entities/matches/types';
import { useFetchOpponentTeamQuery } from 'entities/opponentTeams/queries';
import { getEndSeasonYear, getStartSeasonYear } from 'entities/seasons/helpers';
import { useFetchSeasonQuery } from 'entities/seasons/queries';
import { TSeason } from 'entities/seasons/types';
import { useFetchTeamQuery } from 'entities/teams/queries';
import { useFetchTournamentQuery } from 'entities/tournaments/queries';
import { getMatchTeamsTitle } from 'feature/rightPanel/getMatchTeamsTitle';
import { useMemo } from 'react';
import { Day, DayProps } from 'react-day-picker';
import { Icon } from 'shared/components/Icon/Icon';
import { Image } from 'shared/components/Image/Image';
import { Loader } from 'shared/components/Loader/Loader';
import { Text } from 'shared/components/Text/Text';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

import styles from './MatchDatePickerDay.module.css';

const formatSeason = (season: TSeason) => {
  const startYear = getStartSeasonYear(season);
  const endYear = getEndSeasonYear(season);
  return `${toString(startYear).slice(2)}/${toString(endYear).slice(2)}`;
};

export const MatchDatePickerDay =
  (
    matches: TMatch[],
    onDateChange: (match: TMatch, date: Date) => void,
    selectedMatchId?: TEntityId,
  ) =>
  (props: DayProps) => {
    const { defaultLocale } = useLocales();

    const matchInThisDay = useMemo(() => {
      const currentDay = format(props.date, 'yyyy-MM-dd');
      return matches?.find((m) => {
        const currentMatchDate = format(parseISO(m.startAt), 'yyyy-MM-dd');

        return currentDay === currentMatchDate;
      });
    }, [matches, props.date]);

    const { data: season, isLoading: isSeasonLoading } = useFetchSeasonQuery(
      matchInThisDay?.season ?? '',
    );
    const { data: tournament, isLoading: isTournamentLoading } =
      useFetchTournamentQuery(matchInThisDay?.tournament ?? '');

    const { data: team, isLoading: isTeamLoading } = useFetchTeamQuery(
      matchInThisDay?.team ?? '',
    );

    const { data: opponentTeam, isLoading: isOpponentTeamLoading } =
      useFetchOpponentTeamQuery({
        id: matchInThisDay?.opponentTeam ?? '',
      });

    if (
      matchInThisDay &&
      (isOpponentTeamLoading ||
        isTeamLoading ||
        isSeasonLoading ||
        isTournamentLoading)
    ) {
      return <Loader size="xs" />;
    }

    if (matchInThisDay && tournament && season && team && opponentTeam) {
      const formattedSeason = formatSeason(season);

      return (
        <Tooltip
          contentClassName={styles.tooltip}
          tip={
            <div className={styles.match}>
              <Text className={styles.title} size={14} weight={'SemiBold'}>
                {tournament.translations?.[defaultLocale]?.name} {formattedSeason}
              </Text>
              <Text className={styles.teams}>
                {getMatchTeamsTitle({
                  match: matchInThisDay,
                  team,
                  opponentTeam,
                  defaultLocale,
                })}
              </Text>
            </div>
          }
        >
          <div
            className={cn(styles.matchIconWrapper, {
              [styles.selected]: matchInThisDay.id === selectedMatchId,
            })}
            onClick={() => onDateChange(matchInThisDay, props.date)}
          >
            <Image
              alt={`team ${opponentTeam.translations?.[defaultLocale].name}`}
              className={styles.icon}
              src={opponentTeam.picture.x3}
              DefaultComponent={
                <Icon
                  className={cn(styles.icon, styles.teamPlaceholder)}
                  customSize
                  kind="soccer-team-placeholder"
                />
              }
            />
          </div>
        </Tooltip>
      );
    }

    return <Day {...props} />;
  };
