import { EditorElementTypeEnum, TEditor } from 'shared/components/Editor/types';
import { editorBulletedListController } from 'shared/components/Editor/utils/bulletedList/editorBulletedListController';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';

export const useWorkspacePanelBulletedListActions = (editor: TEditor) => {
  const toggleBulletedList = () => {
    editorBulletedListController.toggleBulletedList(editor);
  };

  const isActiveBulletedList = () => {
    return editorController.isActiveElement(
      editor,
      EditorElementTypeEnum.BulletedList,
    );
  };

  return {
    toggleBulletedList,
    isActiveBulletedList,
  };
};
