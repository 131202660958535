import { parseISO } from 'date-fns';
import { TVideoPost } from 'entities/video/types';
import { UnpublishConfirmationModal } from 'feature/video/ui/modals/UnpublishConfirmationModal';
import { useActiveConfirmationModal } from 'pages/video/VideoPage/videoActions/hooks/useActiveConfirmationModal';
import { useDeleteVideo } from 'pages/video/VideoPage/videoActions/hooks/useDeleteVideo';
import { usePublishVideo } from 'pages/video/VideoPage/videoActions/hooks/usePublishVideo';
import { useUnpublishVideo } from 'pages/video/VideoPage/videoActions/hooks/useUnpublishVideo';
import { useVideoPostActions } from 'pages/video/VideoPage/videoActions/hooks/useVideoPostActions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { TTableActions } from 'widgets/elementsList/types';

export const VideoPostActions = ({
  item: videoPost,
  isActive,
  onIconClick,
}: TTableActions<TVideoPost>) => {
  const { t } = useTranslation();
  const {
    activeConfirmationModal,
    setActiveConfirmationModal,
    closeConfirmationModal,
  } = useActiveConfirmationModal();
  const { handleConfirmUnpublish, isUnpublishing } = useUnpublishVideo(videoPost);
  const { changeVideoPublishDate, isPublishing } = usePublishVideo({
    videoPost,
    closeConfirmationModal,
  });

  const { isVideoDeleting, deleteVideo } = useDeleteVideo({
    videoPost,
    closeConfirmationModal,
  });

  const { actions } = useVideoPostActions(videoPost, setActiveConfirmationModal);
  const { id, published } = videoPost;
  const isLoadingAction = isVideoDeleting || isUnpublishing || isPublishing;

  const onConfirmUnpublish = useCallback(async () => {
    closeConfirmationModal();
    await handleConfirmUnpublish();
  }, []);

  return (
    <>
      <Actions
        actions={actions}
        isLoading={isLoadingAction}
        isOpen={isActive}
        itemId={id}
        onIconClick={onIconClick}
      />
      <DatePicker
        date={(videoPost && published && parseISO(published)) || undefined}
        isOpened={activeConfirmationModal === 'changePublishDate'}
        onClose={closeConfirmationModal}
        onDateChange={changeVideoPublishDate}
      />
      <ConfirmationModal
        description={t('content.video.publishNow', { mark: '?' })}
        isOpened={activeConfirmationModal === 'confirmPublishNow'}
        title={t('content.confirm')}
        onClose={closeConfirmationModal}
        onConfirm={() => changeVideoPublishDate(new Date())}
      />
      <ConfirmationModal
        description={t('shared.warningDelete')}
        isOpened={activeConfirmationModal === 'confirmDelete'}
        title={t('shared.deleteTitle')}
        onClose={closeConfirmationModal}
        onConfirm={deleteVideo}
      />
      {published && (
        <UnpublishConfirmationModal
          isOpened={activeConfirmationModal === 'confirmUnpublish'}
          onClose={closeConfirmationModal}
          onConfirm={onConfirmUnpublish}
        />
      )}
    </>
  );
};
