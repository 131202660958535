import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';
import { PhotoGalleryFormHeader } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormHeader/PhotoGalleryFormHeader';
import { PhotoGalleryFormWorkspace } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormWorkspace/PhotoGalleryFormWorkspace';
import { PhotoGalleryRightPanel } from 'widgets/photoGalleryForm/ui/PhotoGalleryRightPanel/PhotoGalleryRightPanel';
import { photoGalleryFormValidationSchema } from 'widgets/photoGalleryForm/validationSchema';

import styles from './PhotoGalleryForm.module.css';

type TProps = {
  values?: TPhotoGalleryFields;
};

export const PhotoGalleryForm = ({ values }: TProps) => {
  const { config } = useConfig();
  const methods = useForm<TPhotoGalleryFields>({
    mode: 'onChange',
    values,
    resolver: zodResolver(photoGalleryFormValidationSchema(config)),
  });

  return (
    <FormProvider {...methods}>
      <form className={styles.root}>
        <PhotoGalleryFormHeader />
        <div className={styles.content}>
          <div className={styles.editWrapper}>
            <PhotoGalleryFormWorkspace />
          </div>
          <PhotoGalleryRightPanel />
        </div>
      </form>
    </FormProvider>
  );
};
