import cn from 'classnames';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { CardInformation } from 'shared/components/Card/CardInformationPart/CardInformation';
import { TCardProps } from 'shared/components/Card/types';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';

import styles from './Card.module.css';

export const CARD_WIDTH = 330;

export const Card = ({ onEdit, onClick, onDelete, ...props }: TCardProps) => {
  const [imageError, setImageError] = useState(false);

  const onDeleteHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete],
  );

  const onEditHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onEdit();
    },
    [onEdit],
  );

  const imageNotFound = useMemo(() => {
    if (
      props.type === 'player' ||
      props.type === 'manager' ||
      props.type === 'opponent'
    ) {
      return <Icon className={styles.imageNotFoundPerson} kind="not-found-person" />;
    }
    return <Icon className={styles.imageNotFound} kind="not-found-team" />;
  }, [props.type]);

  return (
    <div
      className={styles.cardWrapper}
      style={{ width: `${CARD_WIDTH}px` }}
      onClick={onClick}
    >
      <div
        className={cn(styles.imageBlock, {
          [styles.imageBlockTeam]: props.type === 'team',
        })}
      >
        {props.entity.picture && !imageError ? (
          <div
            className={cn(styles.imageContainer, {
              [styles.imageContainerTeam]: props.type === 'team',
            })}
          >
            <img
              alt={props.entity.name}
              className={styles.image}
              src={props.entity.picture}
              onError={() => setImageError(true)}
            />
          </div>
        ) : (
          imageNotFound
        )}
        <IconButton
          className={cn(styles.button, styles.editButton)}
          icon="edit-02"
          iconSize="s"
          onClick={onEditHandler}
        />
        <IconButton
          className={styles.button}
          icon="trash"
          iconSize="s"
          onClick={onDeleteHandler}
        />
      </div>
      <CardInformation {...props} />
    </div>
  );
};
