import i18n from 'i18next';
import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

export type TManagerNavigationLink = {
  name: string;
  route: string;
  disabled?: boolean;
};

export const getManagerNavigationLinks = (
  teamId: TEntityId = '',
  managerId: TEntityId = '',
): TManagerNavigationLink[] => [
  {
    name: i18n.t('content.baseData'),
    route: managerId
      ? routes.editManager.as(teamId, managerId)
      : routes.createManager.as(teamId),
  },
  {
    name: i18n.t('content.linkToSocial'),
    route: routes.managerSocialNetworks.as(teamId, managerId),
    disabled: !toBoolean(managerId),
  },
  {
    name: i18n.t('content.career'),
    route: routes.managerCareers.as(teamId, managerId),
    disabled: !toBoolean(managerId),
  },
  {
    name: i18n.t('content.teamAchievements'),
    route: routes.managerTeamAchievements.as(teamId, managerId),
    disabled: !toBoolean(managerId),
  },
];
