import { EditorElementTypeEnum, TEditor } from 'shared/components/Editor/types';
import { editorBulletedListController } from 'shared/components/Editor/utils/bulletedList/editorBulletedListController';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';

export const editorWithBulletedListElement = (
  editor: TEditor,
  disabledStructureChange: boolean,
) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (unit) => {
    if (editorController.isEmptySelection(editor) && !disabledStructureChange) {
      const listElement = editorController.getElement(
        editor,
        EditorElementTypeEnum.BulletedList,
      );

      if (listElement && listElement.children.length === 1) {
        editorBulletedListController.toggleBulletedList(editor);
      } else {
        deleteBackward(unit);
      }
    } else {
      deleteBackward(unit);
    }
  };

  return editor;
};
