import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import {
  TManagerCareerModalFields,
  TManagerCareerModalProps,
} from 'feature/managerCareerModal/types';
import { managerCareerModalValidationSchema } from 'feature/managerCareerModal/validationSchema';
import { useMemo } from 'react';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';

export const useManagerCareerModal = ({
  actionSubmit,
  closeModal,
  initialValues,
  isOpened,
  title,
  isLoading,
}: TManagerCareerModalProps) => {
  const { locales } = useLocales();

  const defaultValues = useMemo(
    () => ({
      season: '',
      teamPicture: undefined,
      translations: reduceTranslationObject(locales, () => ({
        teamName: '',
        jobTitle: '',
      })),
    }),
    [locales],
  );

  const {
    control,
    submit,
    formState: { isValid, isDirty },
    reset,
  } = useForm<TManagerCareerModalFields>({
    values: initialValues ?? defaultValues,
    mode: 'onChange',
    resolver: zodResolver(managerCareerModalValidationSchema(locales)),
    actionSubmit,
  });

  const handleClose = () => {
    reset();
    closeModal();
  };

  return {
    actionModalProps: {
      opened: isOpened,
      title,
      width: 542,
      confirmButtonProps: {
        isDisabled: !isValid || !isDirty,
        onClick: submit,
        isLoading,
      },
      onClose: handleClose,
    },
    locales,
    control,
  };
};
