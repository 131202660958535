import { embedLinkFactory } from 'shared/components/VideoPlayer/components/IframePlayerElement/embedLinkFactory';
import {
  allowedVideoFormats,
  secureProtocol,
  videoFormatRegex,
  w3,
} from 'shared/constants';
import { toBoolean } from 'shared/lib/toBoolean';

export const isValidEmbedVideoLink = (videoLink: string, hosting: string) => {
  return toBoolean(embedLinkFactory?.[hosting]?.(videoLink));
};

export const isSecuredLink = (videoLink: string) =>
  videoLink.includes(secureProtocol);

export const isAllowedVideoHosting = (
  videoLink: string,
  whitelistVideoHostings?: string[],
) =>
  whitelistVideoHostings?.find((hosting) =>
    videoLink.replace(w3, '').includes(hosting),
  );

export const isVideoFileLink = (videoLink: string) =>
  videoFormatRegex.test(videoLink);

export const isAllowedVideoFormatLink = (videoLink: string) =>
  allowedVideoFormats.some((format) => videoLink.includes(format));

export const isValidVideoLink = (
  videoLink: string = '',
  whitelistVideoHostings?: string[],
) => {
  const attachedVideoHosting = isAllowedVideoHosting(
    videoLink,
    whitelistVideoHostings,
  );
  const isSecured = isSecuredLink(videoLink);
  const isVideoFile = isVideoFileLink(videoLink);
  const isAllowedVideoFormat = isAllowedVideoFormatLink(videoLink);
  const isValidEmbedLink = attachedVideoHosting
    ? isValidEmbedVideoLink(videoLink, attachedVideoHosting)
    : false;

  const isValidLink =
    isSecured &&
    toBoolean(attachedVideoHosting) &&
    (isVideoFile ? isAllowedVideoFormat : isValidEmbedLink);

  return {
    isValidLink,
    attachedVideoHosting,
    isVideoFile,
    isAllowedVideoFormat,
  };
};
