import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createPlayerTeamAchievement,
  deletePlayerTeamAchievement,
  editPlayerTeamAchievement,
} from 'entities/playerTeamAchievements/api';
import { playerTeamAchievementsQueryKeys } from 'entities/playerTeamAchievements/consts';

export const useDeletePlayerTeamAchievementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePlayerTeamAchievement,
    onSuccess: () =>
      queryClient.resetQueries([
        playerTeamAchievementsQueryKeys.playerTeamAchievements,
      ]),
  });
};

export const useCreatePlayerTeamAchievementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPlayerTeamAchievement,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        playerTeamAchievementsQueryKeys.playerTeamAchievements,
      ]);
    },
  });
};

export const useEditPlayerTeamAchievementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editPlayerTeamAchievement,
    onSuccess: () =>
      queryClient.resetQueries([
        playerTeamAchievementsQueryKeys.playerTeamAchievements,
      ]),
  });
};
