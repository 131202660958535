import { TEntityId } from 'shared/types/common';

export const videoQueryKeys = {
  videoPosts: 'videoPosts',
  videoPostForEdit: 'videoPostForEdit',
};

const videoPostsBaseUrl = 'video_posts';

export const videoApiKeys = {
  getVideoPosts: videoPostsBaseUrl,
  createVideoPost: videoPostsBaseUrl,
  getVideoPost: (videoPostId: TEntityId) => `${videoPostsBaseUrl}/${videoPostId}`,
  editVideoPost: (videoPostId: TEntityId) => `${videoPostsBaseUrl}/${videoPostId}`,
  deleteVideoPost: (videoPostId: TEntityId) => `${videoPostsBaseUrl}/${videoPostId}`,
};
