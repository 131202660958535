import { indexToLetterMap } from 'pages/stories/edit/EditStory/components/elements/constants';
import { TElementProps } from 'pages/stories/edit/EditStory/types';

import styles from './QuizElement.module.css';

export const QuizElement = ({ settings }: TElementProps<'quiz'>) => {
  const { title, fill, background, answers, titleDisabled } = settings;

  return (
    <div className={styles.root}>
      {!titleDisabled && (
        <div
          className={styles.title}
          style={{ backgroundColor: background, color: fill }}
        >
          <span>{title}</span>
        </div>
      )}
      <div className={styles.answers}>
        {answers.map((answer, index) => (
          <div key={index} className={styles.answer}>
            <div>{indexToLetterMap[index]}</div>
            <div>{answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
