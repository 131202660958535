import { TEntityId } from 'shared/types/common';

export const seasonsQueryKeys = {
  seasons: 'seasons',
  season: (seasonId: TEntityId) => `seasons/${seasonId}`,
};

const seasonsBaseUrl = 'seasons';

export const seasonsApiKeys = {
  getSeasons: seasonsBaseUrl,
  getSeason: (seasonId: TEntityId) => `${seasonsBaseUrl}/${seasonId}`,
};
