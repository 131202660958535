import { UseQueryOptions } from '@tanstack/react-query';
import { fetchStadiums } from 'entities/stadiums/api';
import { stadiumsQueryKeys } from 'entities/stadiums/consts';
import { TStadium } from 'entities/stadiums/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export const useFetchStadiumsQuery = (
  defaultPagination?: TPaginatedParameters,
  options: UseQueryOptions<TResponseData<TStadium[], TPaginatedMeta>> = {},
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [stadiumsQueryKeys.stadiums, pagination],
      queryFn: () => fetchStadiums({ params: pagination }),
      ...options,
    }),
    defaultPagination,
  );
