import { logout } from 'feature/auth/model/logout';
import { AuthRedirect } from 'feature/auth/ui/AuthRedirect/AuthRedirect';
import { AuthPage } from 'pages/auth/AuthPage/AuthPage';
import { redirect, RouteObject } from 'react-router-dom';
import { getTokenState } from 'shared/api/token';
import { routes } from 'shared/routes';
import { RootLayout } from 'shared/ui/layouts/rootLayout/RootLayout';

const authLoader = () => {
  const { token } = getTokenState();

  if (token) {
    return redirect(routes.root);
  }

  return null;
};

export const AuthRoute = {
  element: <RootLayout />,
  errorElement: <AuthRedirect />,
  children: [
    { path: routes.auth, element: <AuthPage />, loader: authLoader },
    {
      path: routes.logout,
      loader: logout,
    },
  ],
} satisfies RouteObject;
