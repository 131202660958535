import { createContext, useContext } from 'react';
import { useTooltip } from 'shared/components/Tooltip/useTooltip';

type TContextType = ReturnType<typeof useTooltip> | null;
export const TooltipContext = createContext<TContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};
