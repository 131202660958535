import { getYear } from 'date-fns';
import { range } from 'lodash';
import { TODAY } from 'shared/constants';

export const getTenYearsFromNow = () => {
  const futureYearsCount = 10;
  const currentYear = getYear(new Date());
  return range(currentYear, currentYear + futureYearsCount);
};

export const getGMTOffset = () => {
  const dateString = TODAY.toString();
  const gmtIndex = dateString.indexOf('GMT');
  return dateString.substring(gmtIndex, gmtIndex + 8);
};
