import { getSuccessStoryPublicationNotify } from 'entities/stories/helpers';
import { useUpdateStoryGroupMutation } from 'entities/stories/mutations';
import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import { getRenderActions } from 'feature/workspacePanel/utils';
import isEmpty from 'lodash/isEmpty';
import { SaveStoryModal } from 'pages/stories/edit/EditStory/components/SaveStoryModal/SaveStoryModal';
import { ModalContext } from 'pages/stories/edit/EditStory/context';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { useForm } from 'shared/hooks/useReactHookForm';
import { routes } from 'shared/routes';
import { TContentLang } from 'shared/types/common';

import { useLocalesControl } from './hooks';

export type TFormValues = {
  publicationTime?: Date;
};

type TProps = {
  selectedStoryGroupId: string;
  selectedLocale: TContentLang;
  createdLocales: TContentLang[];
};

export const StoryWorkspacePanel = ({
  selectedStoryGroupId,
  selectedLocale,
  createdLocales,
}: TProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { elementsWithErrors } = useStoryEditorContext();
  const { openedModal, openModal } = useContext(ModalContext);
  const { mutateAsync: publishStoryGroup } = useUpdateStoryGroupMutation();
  const {
    createdLocales: createdLocalesControl,
    onLocaleDelete,
    onLocaleSelect,
    onLocaleAdd,
  } = useLocalesControl();

  const [isClosing, setIsClosing] = useState(false);
  const formMethods = useForm<TFormValues>({
    mode: 'onChange',
  });
  const dispatch = useStoryEditorDispatchContext();

  const handleBackButtonClick = () => {
    setIsClosing(true);
  };

  const handleBackButtonClose = () => {
    setIsClosing(false);
  };

  const handleSaveStory = () => {
    openModal?.('saveStory');
  };

  const handleCloseModal = () => {
    openModal?.(null);
  };

  const handlePublish = (date?: Date) => {
    if (date) {
      publishStoryGroup({
        id: selectedStoryGroupId,
        availableLocales: createdLocales,
        published: date.toString(),
      }).then((result) => {
        getSuccessStoryPublicationNotify(result);
        navigate(routes.stories);
      });
    }
    handleCloseModal();
  };

  const renderActions = getRenderActions({
    publishButtonProps: {
      isDisabled: !isEmpty(elementsWithErrors),
      onClick: handleSaveStory,
      isValid: true,
      children: t('shared.confirm'),
    },
    onClickPreviewSelect: (previewSize) => {
      dispatch({
        type: 'selectPreviewSize',
        payload: { width: previewSize.width, height: previewSize.height },
      });
    },
  });

  return (
    <FormProvider {...formMethods}>
      <WorkspacePanel
        renderActions={renderActions}
        backButtonProps={{
          onClick: handleBackButtonClick,
        }}
        localesControlProps={{
          createdLocales: createdLocalesControl,
          selectedLocale: selectedLocale,
          onLocaleAdd: onLocaleAdd,
          onLocaleDelete: onLocaleDelete,
          onLocaleSelect: onLocaleSelect,
        }}
      />
      <SaveStoryModal
        createdLocales={createdLocales}
        isOpened={openedModal === 'saveStory'}
        selectedStoryGroupId={selectedStoryGroupId}
        onClose={handleCloseModal}
        onPublishNow={handlePublish}
      />
      <ConfirmationModal
        isOpened={isClosing}
        description={t('content.story.storyHeaderPanel.modalDescription', {
          mark: '.',
        })}
        title={t('shared.backToRoot.confirmationModal.titleWithMark', {
          mark: '?',
        })}
        onClose={handleBackButtonClose}
        onConfirm={() => navigate(routes.stories)}
      />
      <DatePicker
        isOpened={openedModal === 'datePicker'}
        onClose={handleSaveStory}
        onDateChange={handlePublish}
      />
    </FormProvider>
  );
};
