import { Icon } from 'shared/components/Icon/Icon';

import styles from './PhotoGalleryFormImageCard.module.css';

export const PhotoGalleryFormImageCardPlug = () => {
  return (
    <div className={styles.plugBackground}>
      <Icon className={styles.plugIcon} customSize kind="image-03" />
    </div>
  );
};
