import i18n from 'i18next';
import { getTextMaxLengthMessage } from 'pages/stories/edit/EditStory/components/forms/utils';
import { getTextMinLengthMessage, TEXT_MIN_LENGTH, URL_REGEXP } from 'shared/consts';
import z from 'zod';

import { BUTTON_LINK_MAX_LENGTH, BUTTON_TITLE_MAX_LENGTH } from './constants';

export const buttonElementValidationSchema = () =>
  z.object({
    title: z
      .string()
      .min(TEXT_MIN_LENGTH, { message: getTextMinLengthMessage() })
      .max(
        BUTTON_TITLE_MAX_LENGTH,
        getTextMaxLengthMessage(BUTTON_TITLE_MAX_LENGTH, 'title'),
      ),
    link: z
      .string()
      .min(TEXT_MIN_LENGTH, { message: getTextMinLengthMessage() })
      .url(i18n.t('content.story.validationUrl'))
      .startsWith('https://', { message: i18n.t('content.story.safeUrl') })
      .regex(URL_REGEXP, { message: i18n.t('content.story.validationUrl') })
      .max(
        BUTTON_LINK_MAX_LENGTH,
        getTextMaxLengthMessage(BUTTON_LINK_MAX_LENGTH, 'link'),
      ),
    fontStyle: z.string(),
    fill: z.string(),
    fontFamily: z.string(),
    size: z.string(),
    background: z.string(),
  });
