import { EditorElementTypeEnum, TEditor } from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { Transforms } from 'slate';

export const editorBulletedListController = {
  toggleBulletedList(editor: TEditor) {
    const isActive = editorController.isActiveElement(
      editor,
      EditorElementTypeEnum.BulletedList,
    );

    editorController.unwrapBlockElements(editor);

    if (isActive) {
      Transforms.setNodes(editor, {
        type: EditorElementTypeEnum.Paragraph,
      });
    } else {
      Transforms.setNodes(editor, {
        type: EditorElementTypeEnum.ListItem,
      });
      Transforms.wrapNodes(editor, {
        type: EditorElementTypeEnum.BulletedList,
        translations: {},
        children: [],
      });
    }
  },
};
