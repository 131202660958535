import { AddNewsRoute } from 'pages/news/add';
import { EditNewsRoute } from 'pages/news/edit';
import { NewsPage } from 'pages/news/NewsPage/NewsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const NewsRoutes: Record<string, TProtectedRoute> = {
  List: {
    path: routes.news,
    element: <NewsPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Add: AddNewsRoute,
  Edit: EditNewsRoute,
};
