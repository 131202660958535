import { TAPIVideoPost } from 'entities/video/types';
import { toString } from 'shared/lib/toString';
import { TContentLang, TPartialTranslations } from 'shared/types/common';
import { mapImageToEdit } from 'shared/utils/image';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const mapAPIVideoPostToVideoPostFields = async (
  videoPost: TAPIVideoPost,
  defaultLocale: TContentLang,
): Promise<TVideoPostFields> => {
  const {
    id,
    match,
    review,
    labels,
    categories,
    availableLocales,
    players,
    url,
    managers,
    tournaments,
    teams,
    translations,
    published,
    pictures,
    importance,
    seasons,
  } = videoPost;

  return {
    id,
    locale: {
      created: availableLocales,
      selected: defaultLocale,
    },
    videoLink: url,
    preview: await mapImageToEdit(pictures.base.x3),
    labels: labels.map(({ id }) => toString(id)),
    categories: categories.map(({ id }) => toString(id)),
    tournament: tournaments.length ? toString(tournaments[0].id) : undefined,
    season: seasons.length ? toString(seasons[0].id) : undefined,
    players: players.map(({ id }) => toString(id)),
    managers: managers.map(({ id }) => toString(id)),
    teams: teams?.map(({ id }) => toString(id)),
    important: importance > 0,
    slider: pictures.slider?.x3
      ? await mapImageToEdit(pictures.slider?.x3)
      : undefined,
    review,
    match: match?.id ? toString(match?.id) : undefined,
    publishDate: published ? new Date(published) : undefined,
    translations: availableLocales?.reduce<TPartialTranslations<{ title: string }>>(
      (acc, locale) => {
        acc[locale] = {
          title: translations[locale].name,
        };
        return acc;
      },
      {},
    ),
  };
};
