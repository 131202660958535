import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './RootLayout.module.css';

type TProps = {
  navigation?: ReactElement;
};

export const RootLayout = ({ navigation }: TProps) => {
  if (navigation) {
    return (
      <div className={styles.root}>
        {navigation}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Outlet />
    </div>
  );
};
