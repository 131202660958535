import { useController, useFormContext } from 'react-hook-form';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const useIsPublishVideo = () => {
  const { control } = useFormContext<TVideoPostFields>();

  const { field } = useController({
    control,
    name: 'publishDate',
  });

  if (!field.value) {
    return false;
  }

  return field.value.valueOf() < Date.now();
};
