import { useEffect } from 'react';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

type TUseRightPanelPreview = {
  file?: File;
  onChange: (value: string) => void;
};

export const useRightPanelPreview = ({ file, onChange }: TUseRightPanelPreview) => {
  useEffect(() => {
    if (!file) {
      onChange('');
    } else {
      getBase64FromFile(file).then((data) => {
        onChange(data);
      });
    }
  }, [file]);
};
