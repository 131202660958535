import { parseISO } from 'date-fns';
import { TOpponentPlayer } from 'entities/opponentPlayers/types';
import { TOpponentPlayerModalFields } from 'feature/opponentPlayerModal/types';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
export const mapOpponentPlayerToFields = async (
  opponentPlayer: TOpponentPlayer,
): Promise<TOpponentPlayerModalFields> => {
  return {
    discardedAt: opponentPlayer.discardedAt
      ? parseISO(opponentPlayer.discardedAt)
      : undefined,
    discarded: opponentPlayer.discardedAt ? 'true' : 'false',
    picture: opponentPlayer.picture?.x3
      ? await getFileFromUrl(opponentPlayer.picture.x3)
      : undefined,
    number: opponentPlayer.number,
    countryId: opponentPlayer.country,
    nickname: opponentPlayer.nickname ?? '',
    translations: opponentPlayer.translations,
  };
};
