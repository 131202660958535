import { managersApiKeys } from 'entities/managers/api/consts';
import {
  TCreateManagerDTO,
  TEditManagerDTO,
  TManager,
} from 'entities/managers/model/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchManagers = ({ params }: TFetchParams) =>
  get<TManager[], TPaginatedMeta>(managersApiKeys.fetchManagers, {
    params,
  }).then((response) => response.data);

export const fetchManager = (id: TEntityId) =>
  get<TManager>(managersApiKeys.fetchManager(id)).then(
    (response) => response.data.data,
  );

export const deleteManager = (managerId: TEntityId) =>
  del(managersApiKeys.deleteManager(managerId));

export const createManager = (data: TCreateManagerDTO) =>
  post<TManager>(managersApiKeys.createManager, data).then(
    (response) => response.data.data,
  );

export const editManager = ({ managerId, ...data }: TEditManagerDTO) =>
  patch<TManager>(managersApiKeys.editManager(managerId), data).then(
    (response) => response.data.data,
  );
