import cn from 'classnames';
import {
  IconButton,
  TIconButtonProps,
} from 'shared/components/IconButton/IconButton';
import { UIKitSizes } from 'shared/types/common';

import styles from './DefaultIconButton.module.css';

type TProps = TIconButtonProps & {
  kind?: 'primary' | 'secondary' | 'outlined' | 'default';
  variant?: 'default' | 'circle';
  size?: UIKitSizes;
};

export const DefaultIconButton = ({
  onClick,
  icon,
  size = UIKitSizes.m,
  variant = 'default',
  kind = 'default',
}: TProps) => {
  return (
    <div
      className={cn(styles.container, styles[size], styles[variant], styles[kind])}
      tabIndex={0}
    >
      <IconButton icon={icon} iconSize={size} onClick={onClick} />
    </div>
  );
};
