import { TEditor } from 'shared/components/Editor/types';
import { WorkspaceContext } from 'shared/components/Workspace/WorkspaceContext/WorkspaceContext';
import { useWorkspaceImageContext } from 'shared/components/Workspace/WorkspaceContext/workspaceImageContext';
import { useWorkspaceSliderContext } from 'shared/components/Workspace/WorkspaceContext/workspaceSliderContext';
import { TWithChildren } from 'shared/types/common';

import { useWorkspaceVideoContext } from './useWorkspaceVideoContext';

type TProps = {
  editor: TEditor;
} & TWithChildren;

export const WorkspaceProvider = ({ children, editor }: TProps) => {
  const image = useWorkspaceImageContext(editor);
  const slider = useWorkspaceSliderContext(editor);
  const video = useWorkspaceVideoContext(editor);

  return (
    <WorkspaceContext.Provider
      value={{
        image,
        slider,
        video,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
