import { TManager } from 'entities/managers/model/types';
import { TPlayer } from 'entities/players/model/types';
import { TTeam } from 'entities/teams/types';
import { TCommonOptionType, TSelectOption } from 'feature/rightPanel/types';
import i18n from 'i18next';
import { declination } from 'shared/lib/declination';
import { toString } from 'shared/lib/toString';
import { TContentLang, TTranslationsObject } from 'shared/types/common';

type TPlaceholderArgs = {
  count?: number;
  singular: string;
  few: string;
  many: string;
  defaultPlaceholder: string;
  ruWordGender?: 'male' | 'female';
};

export const generatePlaceholder = (args: TPlaceholderArgs): string => {
  const { count, singular, few, many, defaultPlaceholder, ruWordGender } = args;
  const choose =
    ruWordGender === 'female'
      ? i18n.t('content.chooseFeminized')
      : i18n.t('content.choose');

  return count
    ? declination(count, [
        `${choose} ${count} ${singular}`,
        `${i18n.t('content.chooseOtherEnding')} ${count} ${few}`,
        `${i18n.t('content.chooseOtherEnding')} ${count} ${many}`,
      ])
    : defaultPlaceholder;
};

type TNamedTranslations = TTranslationsObject<{
  firstName: string;
  lastName: string;
}>;

const generateName = <T extends TPlayer | TManager>(
  item: T & TNamedTranslations,
  locale: TContentLang,
) => {
  return `${item.translations?.[locale]?.firstName ?? ''} ${item.translations?.[locale]?.lastName ?? ''}`;
};

const getTranslationsName = <T extends TTeam>(item: T, locale: TContentLang) => {
  return `${item.translations?.[locale]?.name ?? ''} ${item.translations?.[locale]?.name ?? ''}`;
};

export const generateOptionsArray = <T extends TCommonOptionType>(
  defaultLocale: TContentLang,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: item.translations?.[defaultLocale]?.name ?? '',
  }));
};

export const generateNameOptionsArray = <T extends TPlayer | TManager>(
  defaultLocale: TContentLang,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: generateName(item, defaultLocale),
  }));
};

export const generateTeamOptionsArray = <T extends TTeam>(
  defaultLocale: TContentLang,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: getTranslationsName(item, defaultLocale),
  }));
};
