import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchTeam, fetchTeams } from 'entities/teams/api';
import { teamsQueryKeys } from 'entities/teams/consts';
import { TTeam } from 'entities/teams/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

export const useFetchTeamsQuery = (
  defaultPagination?: TPaginatedParameters,
  options: UseQueryOptions<TResponseData<TTeam[], TPaginatedMeta>> = {},
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [teamsQueryKeys.teams, pagination],
      queryFn: () => fetchTeams({ params: pagination }),
      ...options,
    }),
    defaultPagination,
  );

export const useFetchTeamQuery = (
  id: TEntityId = '',
  options: UseQueryOptions<TTeam, unknown, TTeam> = {},
) =>
  useQuery({
    queryKey: [teamsQueryKeys.team(id)],
    queryFn: () => fetchTeam(id),
    enabled: toBoolean(id),
    ...options,
  });
