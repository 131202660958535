import {
  TDefaultVideoActions,
  TPostponedVideoActions,
} from 'pages/video/VideoPage/videoActions/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TActionByStatus, TEntityId } from 'shared/types/common';

export const getDefaultVideoActions = (
  deleteAction: (id: TEntityId) => void,
): TDefaultVideoActions => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return {
    edit: {
      title: t('content.edit'),
      icon: 'edit-02',
      action: (id) => {
        navigate(routes.editVideo.as(id));
      },
    },
    delete: {
      title: t('content.delete'),
      icon: 'trash',
      action: deleteAction,
    },
  };
};
const createPublishAction = (action: () => void): TActionByStatus => {
  const { t } = useTranslation();
  return {
    title: t('content.deleteFromPublication'),
    icon: 'no-eyes',
    action,
  };
};

export const getVideoActions = (
  deleteAction: (id: TEntityId) => void,
  publishAction: (() => void) | null,
  postponedActions: TPostponedVideoActions | null,
) => {
  const defaultVideoActions = getDefaultVideoActions(deleteAction);

  if (publishAction) {
    return [
      defaultVideoActions.edit,
      createPublishAction(publishAction),
      defaultVideoActions.delete,
    ];
  }
  if (postponedActions) {
    return [
      postponedActions.publishNow,
      defaultVideoActions.edit,
      postponedActions.changePublishDate,
      defaultVideoActions.delete,
    ];
  }

  return Object.values(defaultVideoActions);
};
