import { NewsCategoriesPage } from 'pages/settings/categories/NewsCategoriesPage/NewsCategoriesPage';
import { PhotoGalleryCategoriesPage } from 'pages/settings/categories/PhotoGalleryCategoriesPage/PhotoGalleryCategoriesPage';
import { VideoCategoriesPage } from 'pages/settings/categories/VideoCategoriesPage/VideoCategoriesPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const SettingsCategoriesRoutes: Record<string, TProtectedRoute> = {
  Video: {
    element: <VideoCategoriesPage />,
    path: routes.categoryVideo,
    roles: DEFAULT_ROUTER_ROLES,
  },
  News: {
    element: <NewsCategoriesPage />,
    path: routes.categoryNews,
    roles: DEFAULT_ROUTER_ROLES,
  },
  PhotoGallery: {
    element: <PhotoGalleryCategoriesPage />,
    path: routes.categoryPhotogallery,
    roles: DEFAULT_ROUTER_ROLES,
  },
};
