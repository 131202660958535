import { TEntityId } from 'shared/types/common';

export const playersQueryKeys = {
  players: 'players',
  player: 'player',
};

const playersBaseUrl = 'players';

export const playersApiKeys = {
  getPlayers: playersBaseUrl,
  createPlayer: playersBaseUrl,
  editPlayer: (playerId: TEntityId) => `${playersBaseUrl}/${playerId}`,
  getPlayer: (playerId: TEntityId) => `${playersBaseUrl}/${playerId}`,
  deletePlayer: (playerId: TEntityId) => `${playersBaseUrl}/${playerId}`,
};
