import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Badge } from 'shared/components/Badge/Badge';
import { Field } from 'shared/components/Form/Field/Field';
import formCommonStyles from 'shared/components/Form/FormCommonStyles.module.css';
import { TFormInputProps } from 'shared/components/Form/types';
import { Icon } from 'shared/components/Icon/Icon';
import {
  ImageUploadModal,
  TImageUploadModalProps,
} from 'shared/components/ImageUploadModal/ImageUploadModal';
import { TImageUploadModalCroppingProps } from 'shared/components/ImageUploadModal/types';
import { Text } from 'shared/components/Text/Text';

import styles from './ImageInput.module.css';

type TProps = {
  label: string;
  className?: string;
  modalTitle: string;
  labelClassName?: string;
} & TImageUploadModalCroppingProps &
  Pick<TImageUploadModalProps, 'hint'>;

const croppedString = (str: string, length: number): string => {
  if (str.length < length) {
    return str;
  }

  return str.slice(0, length) + '...';
};

export const ImageInput = <T extends FieldValues>({
  icon,
  label,
  rules,
  control,
  required,
  className,
  fieldName,
  size = 'l',
  showErrors,
  modalTitle,
  placeholder = 'load file',
  tip,
  inputClassName,
  hint,
  labelClassName,
  ...uploadImageProps
}: Omit<TFormInputProps<T>, 'type' | 'maxLength'> & TProps) => {
  return (
    <Controller
      key={fieldName}
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field, fieldState }) => {
        const [isShowLoadFileModal, changeIsShowLoadFileModal] = useState(false);
        const [filePreview, setFilePreview] = useState<string>('');
        const croppedFileName = croppedString(field.value?.name ?? '', 24);

        const cName = cn(
          formCommonStyles.formControl,
          formCommonStyles[size],
          fieldState.invalid ? formCommonStyles.invalid : undefined,
          styles.button,
          inputClassName,
        );

        useEffect(() => {
          if (field.value) {
            const url = URL.createObjectURL(field.value);
            URL.revokeObjectURL(filePreview ?? '');
            setFilePreview(url);
          } else {
            setFilePreview('');
          }
        }, [field.value]);

        const openFileLoaderModal = () => changeIsShowLoadFileModal(true);

        const deleteFileHandler = () => {
          if (!filePreview) {
            return;
          }
          field.onChange(null);
          URL.revokeObjectURL(filePreview);
        };

        const loadFile = (files: File[]) => {
          const file = files[0];
          if (!file) {
            return;
          }
          field.onChange(file);
          changeIsShowLoadFileModal(false);
        };

        return (
          <>
            <Field
              className={cn(styles.field, className)}
              error={fieldState.error}
              label={label}
              labelClassName={labelClassName}
              required={required}
              showErrors={showErrors}
              tip={tip}
            >
              {filePreview ? (
                <div className={cn(cName, styles.withFile)}>
                  <Badge
                    content={croppedFileName}
                    imageUrl={filePreview}
                    size="m"
                    type="white"
                    onClose={deleteFileHandler}
                  />
                </div>
              ) : (
                <button
                  className={cName}
                  type="button"
                  onClick={openFileLoaderModal}
                >
                  {icon && <Icon className={styles.icon} kind={icon} size="s" />}
                  <Text className={styles.placeholder} size={12}>
                    {placeholder}
                  </Text>
                </button>
              )}
            </Field>
            <ImageUploadModal
              {...uploadImageProps}
              hint={hint}
              opened={isShowLoadFileModal}
              title={modalTitle}
              onConfirm={loadFile}
              onClose={() =>
                changeIsShowLoadFileModal && changeIsShowLoadFileModal(false)
              }
            />
          </>
        );
      }}
    />
  );
};
