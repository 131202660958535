import { TLabel } from 'entities/labels/types';
import { TVideoCategory } from 'entities/videoCategories/types';
import {
  TContentLang,
  TEntityId,
  TImageContent,
  TTranslations,
} from 'shared/types/common';

export enum EVideoPostStatus {
  Published = 'published',
  Draft = 'draft',
  Postponed = 'postponed',
}

type TEntitiesArray = { id: TEntityId }[];

export type TAPIVideoPost = {
  id: number;
  availableLocales: TContentLang[];
  url: string;
  importance: number;
  review: boolean;
  published: null | string;
  createdAt: string;
  pictures: {
    base: TImageContent;
    slider?: TImageContent;
  };
  translations: TVideoTranslations;
  match: { id: TEntityId } | null;
  categories: TVideoCategory[];
  labels: TLabel[];
  tournaments: TEntitiesArray;
  seasons: TEntitiesArray;
  teams: TEntitiesArray;
  players: TEntitiesArray;
  managers: TEntitiesArray;
};

export type TVideoPost = {
  title: string;
  status: EVideoPostStatus;
} & TAPIVideoPost;

export type TVideoTranslations = TTranslations<{
  name: string;
  sharing_link?: string;
}>;

type TVideoPostBody = {
  importance: number;
  review: boolean;
  availableLocales: string[];
  translations: TVideoTranslations;
  matchId: number | null;
  relationships: {
    categories?: number[];
    tournaments?: number[];
    seasons?: number[];
    teams?: number[];
    labels?: number[];
    players?: number[];
    managers?: number[];
  };
  pictures: {
    baseImage: {
      filename: string;
      data: string;
    };
    sliderImage?: {
      filename: string;
      data: string;
    };
  };
  url: string;
  published?: string;
};

export type TVideoPostDTO = {
  videoPost: TVideoPostBody;
};

export type TEditVideoPostDTO = {
  videoPost: TVideoPostBody & { id: TEntityId };
};
