import { TEditor } from 'shared/components/Editor/types';
import { createEditorParagraphElement } from 'shared/components/Editor/utils/paragraph/createEditorParagraphElement';
import { TContentLang } from 'shared/types/common';
import { Transforms } from 'slate';

export const editorParagraphController = {
  insertParagraph(editor: TEditor, text: string, defaultLocale: TContentLang) {
    const paragraph = createEditorParagraphElement(text, defaultLocale);
    Transforms.insertNodes(editor, paragraph);
  },
};
