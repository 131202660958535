import { tournamentApiKeys } from 'entities/tournaments/consts';
import {
  TFetchTournamentsParams,
  TTournament,
  TTournamentWith,
} from 'entities/tournaments/types';
import { get } from 'shared/api/methods';
import { TEntityId } from 'shared/types/common';

export const fetchTournaments = <T extends TFetchTournamentsParams>({
  include,
}: T) =>
  get<TTournamentWith<T['include']>[]>(tournamentApiKeys.getTournaments, {
    params: include ? { include } : {},
  }).then((response) => response.data.data);

export const fetchTournament = (id: TEntityId) =>
  get<TTournament>(tournamentApiKeys.getTournament(id)).then(
    (response) => response.data.data,
  );
