import { useDeletePhotoGallery } from 'pages/photogallery/PhotoGalleryPage/hooks/useDeletePhotoGallery';
import { useCallback, useState } from 'react';
import { TEntityId } from 'shared/types/common';

type TProps = {
  closeActiveItemId: () => void;
};

export const usePhotoGalleryActions = ({ closeActiveItemId }: TProps) => {
  const { deletePhotoGallery, isDeletingPhotoGallery } = useDeletePhotoGallery();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const deletePhotoGalleryHandler = () => {
    setShowConfirmModal(true);
  };

  const onConfirm = useCallback(
    async (photoGalleryId: TEntityId) => {
      if (photoGalleryId) {
        deletePhotoGallery(photoGalleryId);
        setShowConfirmModal(false);
        closeActiveItemId();
      }
    },
    [deletePhotoGallery],
  );

  return {
    showConfirmModal,
    setShowConfirmModal,
    deletePhotoGalleryHandler,
    onConfirm,
    isLoading: isDeletingPhotoGallery,
  };
};
