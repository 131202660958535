import { MouseEventHandler, ReactNode } from 'react';
import { Button } from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';

import styles from './EmptyListPage.module.css';

type TEmptyListPageProps = {
  message: string;
  createButtonContent: ReactNode;
  onCreateButtonClick?: MouseEventHandler<HTMLButtonElement>;
};

export const EmptyListPage = ({
  message,
  createButtonContent,
  onCreateButtonClick,
}: TEmptyListPageProps) => {
  return (
    <div className={styles.root}>
      <Text size={24} weight="SemiBold">
        {message}
      </Text>
      <Button icon="plus" onClick={onCreateButtonClick}>
        {createButtonContent}
      </Button>
    </div>
  );
};
