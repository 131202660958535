import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { AchievementModal } from 'feature/achievementModal/AchievementModal';
import { usePlayerAchievementsActions } from 'pages/player/achievements/PlayerAchievementsPage/hooks/usePlayerAchievementsActions';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';
export const PlayerAchievementsActions = (
  props: TTableActions<TPlayerAchievement>,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    usePlayerAchievementsActions(props);
  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <AchievementModal {...editModalProps} />}
    </>
  );
};
