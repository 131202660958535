import { CommonInformation } from 'shared/components/Card/CardInformationPart/CommonInformation';
import { ECardType, TCardInformation } from 'shared/components/Card/types';

import styles from './Information.module.css';

export const ManagerInformation = ({ type, entity }: TCardInformation) => {
  if (type !== ECardType.Manager) {
    return null;
  }

  const { lastName, position, name } = entity;

  return (
    <div className={styles.wrapper}>
      <CommonInformation lastName={lastName} name={name} position={position} />
    </div>
  );
};
