import { useLocales } from 'entities/config/lib/useLocales';
import { TPhotoGalleryCategory } from 'entities/photoGalleryCategories/types';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './PhotoGalleryCategoriesPage.module.css';

export const usePhotoGalleryCategoriesColumnsConfig: TConfigCreator<
  TPhotoGalleryCategory
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.names'),
      cellClassName: styles.publicationNameColumn,
      render: (item) => (
        <Text className={styles.text}>
          {item?.translations[defaultLocale]?.name}
        </Text>
      ),
    },
  ];
};
