import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters, usePagination } from 'shared/hooks/usePagination';
import { toNumber } from 'shared/lib/toNumber';

export const usePaginationQuery = <
  TQueryFnData extends TResponseData<unknown[], TPaginatedMeta>,
  TError,
  TData extends TResponseData<unknown[], TPaginatedMeta> = TQueryFnData,
>(
  callback: (
    pagination: Record<string, string>,
  ) => UseQueryOptions<TQueryFnData, TError, TData>,
  defaultPagination?: TPaginatedParameters,
) => {
  const { pagination, changePage, changePageSize } =
    usePagination(defaultPagination);

  const options = callback(pagination);

  // TODO: Необходимо исследовать, как лучше настроить пагинацию страницы с тем условием, что она может динамически меняться [CF-620]
  const isValidPaginationQueries =
    toNumber(pagination['page[number]']) > 0 &&
    toNumber(pagination['page[size]']) > 0;

  const { data, ...queryResponse } = useQuery({
    ...options,
    enabled: options.enabled && isValidPaginationQueries,
  });

  useEffect(() => {
    const isEmptyData = data && data.data.length === 0;
    const isHaveEntities = data && data.meta.total > 0;

    if (isEmptyData && isHaveEntities) {
      changePage(toNumber(pagination['page[number]']) - 1);
    }
  }, [data]);

  return { ...queryResponse, data, changePage, changePageSize };
};
