import cn from 'classnames';
import { createContext } from 'react';
import { ClassNamesConfig, GroupBase, ThemeConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { Control } from 'shared/components/Form/NewSelect/components/Control';
import { DropdownIndicator } from 'shared/components/Form/NewSelect/components/DropdownIndicator';
import { Spinner as LoadingIndicator } from 'shared/components/Form/NewSelect/components/Loader';
import { MenuList } from 'shared/components/Form/NewSelect/components/MenuList';
import { Option } from 'shared/components/Form/NewSelect/components/Option';
import { Placeholder } from 'shared/components/Form/NewSelect/components/Placeholder';
import { ValueContainer } from 'shared/components/Form/NewSelect/components/ValueContainer';
import styles from 'shared/components/Form/NewSelect/NewSelect.module.css';
import {
  TOption,
  TSelectContext,
  TSelectSize,
  TSelectType,
} from 'shared/components/Form/NewSelect/types';

type TGetClassNames = {
  size: TSelectSize;
  type: TSelectType;
  isLoading?: boolean;
  isError?: boolean;
};
export const getClassNames = ({
  size,
  type,
  isLoading,
  isError,
}: TGetClassNames): ClassNamesConfig<unknown, boolean, GroupBase<unknown>> => ({
  container: (props) =>
    cn(styles.container, styles[size], styles[type], {
      [styles.hasValue]: props.hasValue,
      [styles.disabled]: props.isDisabled,
      [styles.loading]: isLoading,
      [styles.error]: isError,
    }),
  control: (props) =>
    cn(styles.control, styles[size], styles[type], {
      [styles.menuIsOpen]: props.menuIsOpen,
      [styles.hasValue]: props.hasValue,
      [styles.focus]: props.isFocused,
      [styles.error]: isError,
    }),
  valueContainer: (props) =>
    cn(styles.valueContainer, styles[size], styles[type], {
      [styles.hasValue]: props.hasValue,
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
    }),
  indicatorsContainer: () => cn(styles.indicatorsContainer, styles[size]),
  placeholder: (props) =>
    cn(styles.placeholder, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
      [styles.loading]: props.selectProps.isLoading,
    }),
  input: (props) =>
    cn(styles.input, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
      [styles.loading]: props.selectProps.isLoading,
    }),
  singleValue: (props) =>
    cn(styles.singleValue, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
      [styles.loading]: props.selectProps.isLoading,
    }),
  loadingIndicator: () => cn(styles.loader, styles[size]),
  dropdownIndicator: (props) =>
    cn(styles.dropdownIndicator, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
    }),
  clearIndicator: () => styles.clearIndicator,
  multiValue: () => styles.multiValue,
  menu: () => styles.menu,
});

export const components: Partial<
  SelectComponents<TOption, boolean, GroupBase<TOption>>
> = {
  LoadingIndicator,
  Option,
  DropdownIndicator,
  IndicatorSeparator: null,
  Control,
  Placeholder,
  MenuList,
  ValueContainer,
};

export const controlHeights: Record<TSelectSize, number> = {
  large: 38,
  medium: 34,
  small: 30,
};

export const getTheme = (size: TSelectSize, type: TSelectType): ThemeConfig => ({
  colors: {
    primary: 'var(--green-400)',
    primary75: 'var(--green-400)',
    primary50: 'var(--green-400)',
    primary25: 'var(--green-100)',
    danger: 'var(--red-100)',
    dangerLight: 'var(--red-50)',
    neutral0: 'var(--gray-50)',
    neutral5: 'var(--gray-150)',
    neutral10: 'var(--gray-150)',
    neutral20: 'var(--gray-150)',
    neutral30: 'var(--gray-250)',
    neutral40: 'var(--gray-300)',
    neutral50: 'var(--gray-400)',
    neutral60: 'var(--gray-400)',
    neutral70: 'var(--gray-450)',
    neutral80: 'var(--gray-500)',
    neutral90: 'var(--gray-450)',
  },
  borderRadius: type === 'underline' || type === 'ghost' ? 0 : 8,
  spacing: {
    baseUnit: 10,
    controlHeight: controlHeights[size],
    menuGutter: 8,
  },
});

export const SelectContext = createContext<TSelectContext>({
  size: 'medium',
});
