import { TOpponentTeam } from 'entities/opponentTeams/types';
import { TOpponentTeamModalFields } from 'feature/opponentTeamModal/OpponentTeamModal';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toString } from 'shared/lib/toString';
import { TContentLang } from 'shared/types/common';

export const mapOpponentTeamToTeamModalFields = async (
  team: TOpponentTeam,
  locales: TContentLang[],
): Promise<TOpponentTeamModalFields> => ({
  countryId: toString(team.country),
  name: reduceTranslationObject(locales, (locale) => team.translations[locale].name),
  clubName: reduceTranslationObject(
    locales,
    (locale) => team.translations[locale].clubName,
  ),
  picture:
    team.picture && team.picture.x3
      ? await getFileFromUrl(team.picture.x3)
      : undefined,
});
