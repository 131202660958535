import { fetchSocialNetworks } from 'entities/socialNetworks/api';
import { socialNetworksQueryKeys } from 'entities/socialNetworks/consts';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export const useFetchSocialNetworksQuery = () =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [socialNetworksQueryKeys.socialNetworks, pagination],
      queryFn: () => fetchSocialNetworks({ params: pagination }),
      cacheTime: 0,
    }),
    { pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE },
  );
