import { AchievementModal } from 'feature/achievementModal/AchievementModal';
import { ManagerLayout } from 'feature/managerLayout/ui/ManagerLayout/ManagerLayout';
import { useManagerTeamAchievementsColumnsConfig } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/columnsConfig';
import { useCreateManagerTeamAchievement } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/hooks/useCreateManagerTeamAchievement';
import { useManagerTeamAchievements } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/hooks/useManagerTeamAchievements';
import { ManagerTeamAchievementsActions } from 'pages/manager/teamAchievements/ManagerTeamAchievementsPage/ManagerTeamAchievementsActions/ManagerTeamAchievementsActions';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './ManagerTeamAchievementsPage.module.css';
import tableStyles from './ManagerTeamAchievementsTable.module.css';

export const ManagerTeamAchievementsPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    managerTeamAchievements = [],
    page,
    total,
    handleConfirmButtonClick,
  } = useManagerTeamAchievements();
  const { createTeamAchievementModalProps, openCreateAchievementModal } =
    useCreateManagerTeamAchievement();

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonList
        className={styles.wrapper}
        configCreator={useManagerTeamAchievementsColumnsConfig}
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.teamAchievements')}
        createButtonProps={{
          content: t('content.createAchievement'),
          onClick: openCreateAchievementModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(managerTeamAchievements, page),
          totalItems: calculateTotalItems(managerTeamAchievements, total),
          onPageChange: changePage,
        }}
        tableProps={{
          wrapperClassName: tableStyles.root,
          data: managerTeamAchievements,
          headRowClassName: tableStyles.headRow,
          rowClassName: tableStyles.rowGridTemplate,
          ActionComponent: ManagerTeamAchievementsActions,
        }}
      />
      {createTeamAchievementModalProps.isOpened && (
        <AchievementModal {...createTeamAchievementModalProps} />
      )}
    </ManagerLayout>
  );
};
