import { useRightPanel } from 'feature/rightPanel/hooks/useRightPanel';
import { RightPanel } from 'feature/rightPanel/ui/RightPanel';
import { useController, useFormContext } from 'react-hook-form';
import { toBoolean } from 'shared/lib/toBoolean';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';
import { PhotoGalleryCategorizations } from 'widgets/photoGalleryForm/ui/PhotoGalleryRightPanel/PhotoGalleryCategorizations/PhotoGalleryCategorizations';
import { PhotoGalleryConnections } from 'widgets/photoGalleryForm/ui/PhotoGalleryRightPanel/PhotoGalleryConnections/PhotoGalleryConnections';
import { PhotoGalleryPreview } from 'widgets/photoGalleryForm/ui/PhotoGalleryRightPanel/PhotoGalleryPreview/PhotoGalleryPreview';
import { PhotoGallerySharingLink } from 'widgets/photoGalleryForm/ui/PhotoGalleryRightPanel/PhotoGallerySharingLink/PhotoGallerySharingLink';

export const PhotoGalleryRightPanel = () => {
  const { control } = useFormContext<TPhotoGalleryFields>();
  const { field } = useController({
    control,
    name: 'preview.file',
  });

  const { Preview, Label, Connections, SharingLink } = useRightPanel({
    hasPreview: toBoolean(field.value),
    preview: {
      Component: PhotoGalleryPreview,
      fieldName: 'preview',
    },
    label: {
      Component: PhotoGalleryCategorizations,
    },
    connections: {
      Component: PhotoGalleryConnections,
    },
    sharingLink: {
      Component: PhotoGallerySharingLink,
    },
  });

  return <RightPanel components={[Preview, Label, Connections, SharingLink]} />;
};
