import cn from 'classnames';
import { TWithChildren } from 'shared/types/common';

import styles from './Centered.module.css';

type TProps = {
  className?: string;
  horizontal?: boolean;
  vertical?: boolean;
  fixed?: boolean;
} & TWithChildren;

export const Centered = ({
  children,
  className,
  fixed = false,
  horizontal = true,
  vertical = true,
}: TProps) => {
  return (
    <div
      className={cn(styles.root, className, {
        [styles.horizontal]: horizontal,
        [styles.vertical]: vertical,
        [styles.fixed]: fixed,
      })}
    >
      {children}
    </div>
  );
};
