import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useValidVideoLink } from 'shared/components/VideoPlayer/helpers';
import { allowedVideoFormats } from 'shared/constants';

import { IframePlayerElement } from './components/IframePlayerElement/IframePlayerElement';
import { VideoPlayerElement } from './components/VideoPlayerElement/VideoPlayerElement';
import { TDefaultVideoPlayerProps } from './types';

type TProps = TDefaultVideoPlayerProps & {
  hosting: string;
};

export const VideoPlayer = ({ src, hosting, width }: TProps) => {
  const { t } = useTranslation();
  const { isVideoFile, isAllowedVideoFormat } = useValidVideoLink(src);
  if (!isVideoFile) {
    return <IframePlayerElement hosting={hosting} src={src} width={width} />;
  }
  if (isAllowedVideoFormat) {
    return <VideoPlayerElement src={src} width={width} />;
  } else {
    notify(
      t('shared.VideoPlayer.notify.message', {
        text: allowedVideoFormats.join(', '),
      }),
      {
        title: t('shared.VideoPlayer.notify.title'),
        type: 'error',
      },
    );
    // TODO: добавить картинку-заглушку для неподдерживаемого формата видео
    return <></>;
  }
};
