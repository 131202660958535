import { TSocialNetworkModalValue } from 'feature/socialNetworkModal/types';
import { useDeletePlayerSocialNetwork } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/hooks/useDeletePlayerSocialNetwork';
import { useEditPlayerSocialNetwork } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/hooks/useEditPlayerSocialNetwork';
import { TPlayerSocialNetworkItem } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';
export const usePlayerSocialNetworksActions = ({
  isActive,
  onIconClick,
  closeActiveItemId,
  item: playerSocialNetwork,
}: TTableActions<TPlayerSocialNetworkItem>) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { id, playerId, socialNetwork, url } = playerSocialNetwork;
  const { isDeletingPlayerSocialNetwork, deletePlayerSocialNetwork } =
    useDeletePlayerSocialNetwork();
  const { isEditingPlayerSocialNetwork, editPlayerSocialNetwork } =
    useEditPlayerSocialNetwork();
  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: t('content.edit'),
        icon: 'edit-02',
        action: () => setIsEditModalOpen(true),
      },
      {
        title: t('content.delete'),
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [setIsEditModalOpen, setIsDeleteModalOpen],
  );
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerSocialNetwork(id);
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmEditing = ({
    url: newUrl,
    socialNetworkId: newSocialNetworkId,
  }: TSocialNetworkModalValue) => {
    editPlayerSocialNetwork(
      {
        id,
        playerId,
        url: newUrl,
        socialNetworkId: newSocialNetworkId,
      },
      handleCloseEditModal,
    );
  };
  return {
    actionsProps: {
      actions,
      itemId: playerSocialNetwork.id,
      isLoading: isDeletingPlayerSocialNetwork,
      isOpen: isActive,
      onIconClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: t('content.deleteWarning'),
      title: t('content.deleteSocial'),
    },
    editModalProps: {
      initialValue: {
        url,
        socialNetworkId: toString(socialNetwork.id),
      },
      title: t('content.editLink'),
      closeModal: handleCloseEditModal,
      actionSubmit: handleConfirmEditing,
      isOpened: isEditModalOpen,
      isLoading: isEditingPlayerSocialNetwork,
    },
  };
};
