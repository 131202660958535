import { useUpdateStoryGroupMutation } from 'entities/stories/mutations';
import { TStoryElementData } from 'entities/stories/types';
import { Box } from 'pages/stories/edit/EditStory/components/Box';
import { FORM_DEBOUNCE_DELAY } from 'pages/stories/edit/EditStory/components/forms/constants';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { TStoryElementUpdater } from 'pages/stories/edit/EditStory/types';
import { memo, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { BlurredImage } from 'shared/components/BlurredImage/BlurredImage';
import { FormInput } from 'shared/components/Form';
import { useEyeDropContext } from 'shared/contexts/eyedropContext';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TContentLang } from 'shared/types/common';

import styles from './WorkZone.module.css';

type TProps = {
  selectedLocale: TContentLang;
  updater: TStoryElementUpdater;
  storyElements: TStoryElementData[];
  storyOutlineRef: RefObject<HTMLDivElement>;
};

export const WorkZone = memo(
  ({ selectedLocale, storyElements, storyOutlineRef, updater }: TProps) => {
    const { t } = useTranslation();
    const dispatch = useStoryEditorDispatchContext();
    const { storyGroup, highlightedElementId, selectedStory, selectedElement } =
      useStoryEditorContext();
    const mutation = useUpdateStoryGroupMutation();

    const { control, watch } = useForm({
      values: storyGroup.translations[selectedLocale] || { name: '' },
    });
    const watchName = watch('name');

    useDebounce(
      () => {
        mutation.mutate({
          id: storyGroup.id,
          availableLocales: storyGroup.availableLocales,
          translations: {
            ...storyGroup.translations,
            [selectedLocale]: { name: watchName },
          },
        });
      },
      FORM_DEBOUNCE_DELAY,
      [watchName],
    );

    const { isEyeDropActive } = useEyeDropContext();

    const handleWorkZoneClick = useCallback(() => {
      if (selectedElement && !isEyeDropActive) {
        dispatch({ type: 'selectStoryElement', payload: null });
      }
    }, [selectedElement, isEyeDropActive]);

    const handleBoxClick = useCallback((element: TStoryElementData) => {
      dispatch({ type: 'selectStoryElement', payload: element });
    }, []);

    return (
      <div className={styles.root}>
        <div className={styles.workZone} onClick={handleWorkZoneClick}>
          <div>
            <FormInput
              className={styles.storyGroupNameInput}
              control={control}
              fieldName="name"
              maxLength={30}
              placeholder={t('content.inputPlaceholder')}
            />
            {selectedStory && (
              <div className={styles.storyOutline} ref={storyOutlineRef}>
                <BlurredImage alt="Story image" imageUrl={selectedStory?.image.x3} />
              </div>
            )}
          </div>
          {storyElements.map((element) => (
            <Box
              key={element.id}
              isHighlighted={element.id === highlightedElementId}
              isSelected={element.id === selectedElement?.id}
              selectedLocale={selectedLocale}
              updater={updater}
              element={
                selectedElement && element.id === selectedElement.id
                  ? selectedElement
                  : element
              }
              onClick={handleBoxClick}
            />
          ))}
        </div>
      </div>
    );
  },
);
