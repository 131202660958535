import { fetchPlayerSocialNetworks } from 'entities/playerSocialNetworks/api';
import { playerSocialNetworksQueryKeys } from 'entities/playerSocialNetworks/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchPlayerSocialNetworks = {
  filter?: Record<string, string>;
};

export const useFetchPlayerSocialNetworksQuery = ({
  filter,
}: TFetchPlayerSocialNetworks) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [
      playerSocialNetworksQueryKeys.playerSocialNetworks,
      pagination,
      filter,
    ],
    queryFn: () =>
      fetchPlayerSocialNetworks({ params: { ...filter, ...pagination } }),
    cacheTime: 0,
  }));
};
