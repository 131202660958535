import { ToastProps, TypeOptions } from 'react-toastify/dist/types';
import { Alert, TAlertVariant } from 'shared/components/Alert/Alert';

type TProps = {
  title?: string;
  message: string;
} & ToastProps;

const alertVariantByToast: { [key in TypeOptions]: TAlertVariant } = {
  default: 'dark',
  info: 'blue',
  success: 'green',
  error: 'red',
  warning: 'yellow',
};

export const Notification = ({ title, message, closeToast, type }: TProps) => (
  <Alert
    icon="alert-triangle"
    message={message}
    title={title}
    variant={alertVariantByToast[type]}
    onClose={closeToast}
  />
);
