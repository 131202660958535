import cn from 'classnames';
import { noop } from 'lodash';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Switch } from 'shared/components/Form/Switch';
import { TInputSwitchField } from 'shared/components/Form/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';
import { toBoolean } from 'shared/lib/toBoolean';
import { TWithChildren } from 'shared/types/common';

import styles from './Field.module.css';

type TLengthProps =
  | {
      inline?: boolean;
      showMaxLength?: boolean;
      maxLength?: number;
      length?: number;
    }
  | {
      inline: true;
      maxLength: void;
      showMaxLength: void;
      length: void;
    }
  | {
      inline?: false;
      showMaxLength?: boolean;
      maxLength?: number;
      length?: number;
    };

type TPropsWithoutLength = {
  inlineInputClassName?: string;
  label?: string;
  className?: string;
  error?: FieldError;
  tip?: string;
  showErrors?: boolean;
  required?: boolean;
  disabled?: boolean;
  switchField?: TInputSwitchField;
  labelClassName?: string;
} & TWithChildren;

type TProps = TPropsWithoutLength & TLengthProps;

export const Field = ({
  className,
  error,
  children,
  label,
  inline,
  inlineInputClassName,
  maxLength,
  length,
  showErrors = true,
  required = false,
  disabled = false,
  showMaxLength = true,
  switchField,
  tip,
  labelClassName,
}: TProps) => {
  const { t } = useTranslation();
  const isHaveError = error?.message && showErrors;

  if (inline) {
    return (
      <div
        className={cn(
          styles.inlineFormField,
          { [styles.disabled]: disabled },
          className,
        )}
      >
        {label && <div className={labelClassName}>{label}</div>}
        <div className={inlineInputClassName}>
          {children}
          {error?.message && <div className={styles.error}>{error.message}</div>}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(styles.formField, { [styles.disabled]: disabled }, className)}
    >
      {label && (
        <div className={styles.label}>
          <div className={cn(styles.labelLeftWrap, labelClassName)}>
            {label}
            {required && (
              <Tooltip tip={t('shared.Form.tip')}>
                <span className={styles.required}>*</span>
              </Tooltip>
            )}
            {toBoolean(tip) && (
              <Tooltip tip={tip}>
                <IconButton
                  className={styles.tooltip}
                  icon="info"
                  iconSize="xs"
                  onClick={noop}
                />
              </Tooltip>
            )}
          </div>
          <div className={styles.labelRightWrap}>
            {showMaxLength && maxLength && (
              <span className={styles.length}>
                {length}/{maxLength}
              </span>
            )}
            {switchField && (
              <div className={styles.switchWrapper}>
                {switchField.switchLabel && (
                  <span className={styles.switchLabel}>
                    {switchField.switchLabel}
                  </span>
                )}
                <Switch
                  checked={switchField.checked}
                  className={styles.switch}
                  onChange={switchField.onSwitch}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {children}
      {isHaveError && <div className={styles.error}>{error.message}</div>}
    </div>
  );
};
