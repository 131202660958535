import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { managersQueryKeys } from 'entities/managers/api/consts';
import { TManager } from 'entities/managers/model/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchManager, fetchManagers } from './api';

export type TFetchManagersParams = {
  filter?: Record<string, string>;
} & TPaginatedParameters;

export const useFetchManagersQuery = (
  { filter, ...defaultPagination }: TFetchManagersParams,
  options: UseQueryOptions<TResponseData<TManager[], TPaginatedMeta>> = {},
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [managersQueryKeys.managers, filter, pagination],
      queryFn: () => fetchManagers({ params: { ...filter, ...pagination } }),
      ...options,
    }),
    defaultPagination,
  );

export const useFetchManagerQuery = (
  id: TEntityId = '',
  options: UseQueryOptions<TManager, unknown, TManager> = {},
) =>
  useQuery({
    queryKey: [managersQueryKeys.manager, id],
    queryFn: () => fetchManager(id),
    enabled: toBoolean(id),
    ...options,
  });
