import { videoApiKeys } from 'entities/video/consts';
import { mapVideoPostsResponse } from 'entities/video/mapper/mapVideoPostsResponse';
import {
  TAPIVideoPost,
  TEditVideoPostDTO,
  TVideoPostDTO,
} from 'entities/video/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TContentLang, TEntityId } from 'shared/types/common';

export const fetchVideoPosts = (
  { params }: TFetchParams,
  defaultLocale: TContentLang,
) => {
  return get<TAPIVideoPost[], TPaginatedMeta>(videoApiKeys.getVideoPosts, {
    params,
  }).then((response) => mapVideoPostsResponse(response.data, defaultLocale));
};

export const fetchVideoPost = (videoPostId: TEntityId) =>
  get<TAPIVideoPost>(videoApiKeys.getVideoPost(videoPostId)).then(
    (response) => response.data.data,
  );

export const createVideoPost = (videoPost: TVideoPostDTO) =>
  post(videoApiKeys.createVideoPost, videoPost);

export const editVideoPost = ({
  videoPost: { id, ...videoPost },
}: TEditVideoPostDTO) => patch(videoApiKeys.editVideoPost(id), videoPost);

export const deleteVideoPost = (videoId: TEntityId) =>
  del(videoApiKeys.deleteVideoPost(videoId));
