import enTranslations from '../locales/en/common.json';
import ruTranslations from '../locales/ru/common.json';

export const resources = {
  en: {
    translation: enTranslations,
  },
  ru: {
    translation: ruTranslations,
  },
};
