import { TEntityId } from 'shared/types/common';

export const playerTeamAchievementsQueryKeys = {
  playerTeamAchievements: 'playerTeamAchievements',
};

const playerTeamAchievementsBaseUrl = 'player/team_achievements';

export const playerTeamAchievementsApiKeys = {
  getPlayerTeamAchievements: playerTeamAchievementsBaseUrl,
  createPlayerTeamAchievement: playerTeamAchievementsBaseUrl,
  deletePlayerTeamAchievement: (teamAchievementId: TEntityId) =>
    `${playerTeamAchievementsBaseUrl}/${teamAchievementId}`,
  editPlayerTeamAchievement: (teamAchievementId: TEntityId) =>
    `${playerTeamAchievementsBaseUrl}/${teamAchievementId}`,
};
