import { TManagerCareer } from 'entities/managerCareers/model/types';
import { useDeleteManagerCareer } from 'pages/manager/careers/ManagerCareersPage/hooks/useDeleteManagerCareer';
import { useEditManagerCareer } from 'pages/manager/careers/ManagerCareersPage/hooks/useEditManagerCareer';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const useManagerCareersActions = ({
  onIconClick,
  closeActiveItemId,
  isActive,
  item: career,
}: TTableActions<TManagerCareer>) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingManagerCareer, deleteManagerCareer } = useDeleteManagerCareer();
  const { openEditModal, editCareerModalProps } = useEditManagerCareer(career);
  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    closeActiveItemId();
  };
  const handleConfirmDeleteCareer = () => {
    handleCloseDeleteModal();
    deleteManagerCareer(career.id);
  };
  return {
    isDeletingManagerCareer,
    isDeleteModalOpen,
    actions,
    isActive,
    itemId: career.id,
    onIconClick,
    editCareerModalProps,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
  };
};
