import { getTextMaxLengthMessage } from 'pages/stories/edit/EditStory/components/forms/utils';
import { getTextMinLengthMessage, TEXT_MIN_LENGTH } from 'shared/consts';
import z from 'zod';

import { QUIZ_QUESTION_TITLE_MAX_LENGTH, QUIZ_TITLE_MAX_LENGTH } from './constants';

export const quizElementValidationSchema = () =>
  z.object({
    title: z
      .string()
      .max(
        QUIZ_TITLE_MAX_LENGTH,
        getTextMaxLengthMessage(QUIZ_TITLE_MAX_LENGTH, 'title'),
      )
      .min(TEXT_MIN_LENGTH, { message: getTextMinLengthMessage() }),
    titleDisabled: z.boolean(),
    correctAnswerIndex: z.number(),
    answers: z.array(
      z
        .object({
          title: z.string(),
          disabled: z.boolean(),
        })
        .superRefine((answer, context) => {
          if (answer.disabled) {
            return;
          }

          if (!answer.title || answer.title.length < TEXT_MIN_LENGTH) {
            context.addIssue({
              code: z.ZodIssueCode.too_small,
              message: getTextMinLengthMessage(),
              path: ['title'],
              minimum: TEXT_MIN_LENGTH,
              type: 'string',
              inclusive: false,
            });
          }

          if (answer.title && answer.title.length > QUIZ_QUESTION_TITLE_MAX_LENGTH) {
            context.addIssue({
              code: z.ZodIssueCode.too_big,
              message: getTextMaxLengthMessage(QUIZ_QUESTION_TITLE_MAX_LENGTH),
              path: ['title'],
              maximum: QUIZ_QUESTION_TITLE_MAX_LENGTH,
              type: 'string',
              inclusive: false,
            });
          }
        }),
    ),
    fill: z.string(),
    background: z.string(),
  });
