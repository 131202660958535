import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { AchievementModal } from 'feature/achievementModal/AchievementModal';
import { usePlayerTeamAchievementsActions } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/hooks/usePlayerTeamAchievementsActions';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';
export const PlayerTeamAchievementsActions = (
  props: TTableActions<TPlayerTeamAchievement>,
) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    usePlayerTeamAchievementsActions(props);
  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <AchievementModal {...editModalProps} />}
    </>
  );
};
