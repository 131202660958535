import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';

import styles from './Story.module.css';

type TProps = {
  index: number;
  isSelected: boolean;
  image: string;
};

export const StoryPreview = ({ isSelected, image, index }: TProps) => {
  const dragZoneClassName = cn(styles.dragZone, {
    [styles.isSelected]: isSelected,
  });
  const indexClassName = cn(styles.index, { [styles.isSelected]: isSelected });
  const innerClassName = cn(styles.inner, { [styles.imageSelected]: isSelected });
  const wrapperClassName = cn(styles.imageWrapper, styles.preview);

  return (
    <div className={wrapperClassName}>
      <img alt="Story image" className={styles.image} loading="lazy" src={image} />
      <div className={dragZoneClassName}>
        <Icon kind="dots-grag" size="s" />
      </div>
      <div className={indexClassName}>{index + 1}</div>
      <div className={innerClassName} />
    </div>
  );
};
