import { MatchType, TMatch } from 'entities/matches/types';
import { TOpponentTeam } from 'entities/opponentTeams/types';
import { TTeam } from 'entities/teams/types';
import { TContentLang } from 'shared/types/common';

type TProps = {
  match: TMatch;
  team: TTeam;
  opponentTeam: TOpponentTeam;
  defaultLocale: TContentLang;
};

export const getMatchTeamsTitle = ({
  opponentTeam,
  match,
  team,
  defaultLocale,
}: TProps) => {
  const teamName = team.translations?.[defaultLocale].name;
  const opponentTeamName = opponentTeam.translations?.[defaultLocale].name;

  if (match.matchType === MatchType.Home) {
    return `${teamName} - ${opponentTeamName}`;
  }

  return `${opponentTeamName} - ${teamName}`;
};
