import { arrayMoveImmutable } from 'array-move';
import {
  useBatchUpdateStoriesMutation,
  useCreateStoryMutation,
  useDeleteStoryMutation,
  useUpdateStoryGroupMutation,
} from 'entities/stories/mutations';
import {
  TBatchUpdateStoriesDTO,
  TStoryData,
  TStoryGroupData,
} from 'entities/stories/types';
import { findIndex, sortBy } from 'lodash';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useCallback, useMemo } from 'react';
import { toNumber } from 'shared/lib/toNumber';

type TProps = {
  stories: TStoryData[];
  selectedStory: TStoryData | null;
  storyGroup: TStoryGroupData;
  lastPosition: number;
};

const batchedStoriesDTO = (
  stories: TStoryData[],
  storyGroupId: number,
): TBatchUpdateStoriesDTO => {
  return stories.map((story, index) => ({
    id: story.id,
    position: index + 1,
    storyGroupId,
  }));
};

export const useStoryGroupEdit = ({
  stories,
  storyGroup,
  selectedStory,
  lastPosition,
}: TProps) => {
  const dispatch = useStoryEditorDispatchContext();
  const { mutate: createStory, isLoading: isStoryCreating } =
    useCreateStoryMutation();
  const batchStoriesMutation = useBatchUpdateStoriesMutation();
  const { mutateAsync: updateStoryGroup, isLoading: isPreviewUpdating } =
    useUpdateStoryGroupMutation();
  const { mutateAsync: deleteStoryGroup, isLoading: isStoryDeleting } =
    useDeleteStoryMutation();

  const sortedStories = useMemo(() => sortBy(stories, ['position']), [stories]);

  const removeStory = () => {
    if (selectedStory) {
      deleteStoryGroup(selectedStory).then(() => {
        const selectedStoryIndex = findIndex(
          sortedStories,
          (story) => story.id === selectedStory.id,
        );
        const storyToSelect =
          selectedStoryIndex === 0
            ? sortedStories[selectedStoryIndex + 1]
            : sortedStories[selectedStoryIndex - 1];
        const newStories = sortedStories.filter(({ id }) => selectedStory.id !== id);

        dispatch({
          type: 'selectStory',
          payload: storyToSelect,
        });

        batchStoriesMutation.mutate(
          batchedStoriesDTO(newStories, toNumber(storyGroup.id)),
        );
      });
    }
  };

  const addStory = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      if (!result) {
        return;
      }
      createStory({
        image: {
          filename: file.name,
          data: result.split(',')[1],
        },
        position: lastPosition + 1,
        storyGroupId: toNumber(storyGroup.id),
      });
    };
  };

  const addPreviewImage = (file: File, onSuccess: () => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const result = reader.result as string;
      if (!result) {
        return;
      }
      await updateStoryGroup({
        id: storyGroup.id,
        availableLocales: storyGroup.availableLocales,
        preview: {
          filename: file.name,
          data: result.split(',')[1],
        },
      });
      onSuccess();
    };
  };

  const selectStory = (storyData: TStoryData) => () => {
    if (storyData.id === selectedStory?.id) {
      return;
    } else {
      dispatch({ type: 'selectStoryElement', payload: null });
      dispatch({ type: 'selectStory', payload: storyData });
    }
  };

  const moveStory = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newOrderedStories = arrayMoveImmutable(
        sortedStories,
        dragIndex,
        hoverIndex,
      );
      batchStoriesMutation.mutate(
        batchedStoriesDTO(newOrderedStories, toNumber(storyGroup.id)),
      );
    },
    [batchStoriesMutation, sortedStories, storyGroup],
  );

  return {
    sortedStories,
    addStory,
    removeStory,
    addPreviewImage,
    moveStory,
    selectStory,
    isStoryCreating,
    isStoryDeleting,
    isPreviewUpdating,
  };
};
