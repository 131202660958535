import { EditRoute } from 'pages/stories/edit';
import { StoriesPage } from 'pages/stories/StoriesPage/StoriesPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const StoriesRoutes: Record<string, TProtectedRoute> = {
  List: {
    path: routes.stories,
    element: <StoriesPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Edit: EditRoute,
};
