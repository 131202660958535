import cn from 'classnames';
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import { Props as SVGProps } from 'react-inlinesvg/src/types';
import { TIconSize, TIconType } from 'shared/components/Icon/types';

import { componentsMap } from './constants';
import styles from './Icon.module.css';

export type TIconProps = {
  kind: TIconType;
  size?: TIconSize;
  customSize?: boolean;
  svgProps?: Omit<SVGProps, 'src'>;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Icon = forwardRef<HTMLSpanElement, TIconProps>(
  ({ className, kind, size = 'l', customSize, svgProps, ...props }, ref) => {
    const Component = componentsMap[kind];

    return (
      <i
        ref={ref}
        className={cn(styles.root, className, {
          [styles[size]]: !customSize,
        })}
        {...props}
      >
        <Component {...svgProps} />
      </i>
    );
  },
);
