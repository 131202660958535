import cn from 'classnames';
import { useRef } from 'react';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { toNumber } from 'shared/lib/toNumber';
import { TWithChildren } from 'shared/types/common';

import styles from './NavCaption.module.css';

type TProps = {
  minDate: Date;
  maxDate: Date;
  goToHandler: (date: Date) => void;
  prev?: Date;
  next?: Date;
  className?: string;
} & TWithChildren;

export const NavCaption = ({
  children,
  minDate,
  maxDate,
  prev,
  next = maxDate,
  className,
  goToHandler,
}: TProps) => {
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);

  const handlePrevClick = () => {
    if (prevRef.current) {
      prevRef.current?.blur();
    }
    prev && goToHandler(prev);
  };

  const handleNextClick = () => {
    if (nextRef.current) {
      nextRef.current?.blur();
    }
    next && goToHandler(next);
  };

  return (
    <div className={cn(styles.navButtons, className)}>
      <IconButton
        icon="chevron-left"
        ref={prevRef}
        disabled={
          toNumber(prev ? prev.valueOf() : Number.MIN_SAFE_INTEGER) <
          minDate.valueOf()
        }
        onClick={handlePrevClick}
      />
      {children}
      <IconButton
        disabled={toNumber(next?.valueOf()) >= maxDate.valueOf()}
        icon="chevron-right"
        ref={nextRef}
        onClick={handleNextClick}
      />
    </div>
  );
};
