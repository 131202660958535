import cn from 'classnames';
import { Button, TButtonProps } from 'shared/components/Button/Button';

import styles from './WorkspacePanelDraftButton.module.css';

export type TWorkspacePanelDraftButtonProps = TButtonProps & { isValid: boolean };

export const WorkspacePanelDraftButton = ({
  children,
  color = 'gray',
  kind = 'outlined',
  isValid,
  ...otherProps
}: TWorkspacePanelDraftButtonProps) => (
  <Button
    color={color}
    kind={kind}
    className={cn({
      [styles.disabled]: !isValid,
    })}
    {...otherProps}
  >
    {children}
  </Button>
);
