import { TMeta } from 'shared/api/types';

type TApiMeta = {
  page?: {
    number: number;
    size: number;
    offset: number;
    total_entries: number;
    total_pages: number;
  };
};

export const deserializeApiMeta = (meta: TApiMeta = {}): TMeta => {
  return {
    ...deserializeMetaPage(meta),
  };
};

function deserializeMetaPage(meta: TApiMeta) {
  const page = meta.page;

  if (!page) {
    return {};
  }

  return {
    total: page.total_entries,
    pageSize: page.size,
    page: page.number,
  };
}
