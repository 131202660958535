import cn from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './TRow.module.css';

export type TTableRowProps = {
  isActive?: boolean;
  hoverable?: boolean;
  padding?: 's' | 'm';
} & HTMLAttributes<HTMLDivElement>;

const paddingStyles = {
  s: styles.paddingS,
  m: styles.paddingM,
};

export const TRow = ({
  isActive,
  hoverable = true,
  children,
  padding = 'm',
  className,
  ...other
}: TTableRowProps) => (
  <div
    {...other}
    className={cn(
      styles.row,
      paddingStyles[padding],
      { [styles.hoverable]: hoverable, [styles.isActive]: isActive },
      className,
    )}
  >
    {children}
  </div>
);
