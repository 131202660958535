import {
  TWorkspacePanelDraftButtonProps,
  WorkspacePanelDraftButton,
} from 'feature/workspacePanel/ui/WorkspacePanelDraftButton/WorkspacePanelDraftButton';
import { WorkspacePanelPreviewButton } from 'feature/workspacePanel/ui/WorkspacePanelPreviewButton/WorkspacePanelPreviewButton';
import {
  TPreviewSizeOption,
  WorkspacePanelPreviewSelect,
} from 'feature/workspacePanel/ui/WorkspacePanelPreviewSelect/WorkspacePanelPreviewSelect';
import {
  TWorkspacePanelPublishButtonProps,
  WorkspacePanelPublishButton,
} from 'feature/workspacePanel/ui/WorkspacePanelPublishButton/WorkspacePanelPublishButton';
import {
  TWorkspacePanelUnpublishButtonProps,
  WorkspacePanelUnpublishButton,
} from 'feature/workspacePanel/ui/WorkspacePanelUnpublishButton/WorkspacePanelUnpublishButton';

export type TRenderActions = {
  onClickPreviewButton?: () => void;
  onClickPreviewSelect?: (option: TPreviewSizeOption) => void;
  draftButtonProps?: TWorkspacePanelDraftButtonProps;
  publishButtonProps?: TWorkspacePanelPublishButtonProps;
  unpublishButtonProps?: TWorkspacePanelUnpublishButtonProps;
};
export const getRenderActions =
  ({
    onClickPreviewButton,
    onClickPreviewSelect,
    draftButtonProps,
    unpublishButtonProps,
    publishButtonProps,
  }: TRenderActions) =>
  () => (
    <>
      {onClickPreviewButton && (
        <WorkspacePanelPreviewButton onClick={onClickPreviewButton} />
      )}
      {onClickPreviewSelect && (
        <WorkspacePanelPreviewSelect onShowPreview={onClickPreviewSelect} />
      )}
      {draftButtonProps && <WorkspacePanelDraftButton {...draftButtonProps} />}
      {unpublishButtonProps && (
        <WorkspacePanelUnpublishButton {...unpublishButtonProps} />
      )}
      {publishButtonProps && <WorkspacePanelPublishButton {...publishButtonProps} />}
    </>
  );
