import { fetchPhotoGalleryImages } from 'entities/photoGalleryImages/api';
import { photoGalleryImagesQueryKeys } from 'entities/photoGalleryImages/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchPhotoGalleriesParams = {
  filter: Record<string, string>;
};

export const useFetchPhotoGalleryImagesQuery = (
  { filter }: TFetchPhotoGalleriesParams,
  defaultPagination?: TPaginatedParameters,
) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [photoGalleryImagesQueryKeys.photoGalleryImages, pagination, filter],
      queryFn: () =>
        fetchPhotoGalleryImages({ params: { ...pagination, ...filter } }),
      cacheTime: 0,
    }),
    defaultPagination,
  );
