import { useFetchOpponentTeamsQuery } from 'entities/opponentTeams/queries';
import { OpponentTeamCard } from 'entities/opponentTeams/ui/OpponentTeamCard/OpponentTeamCard';
import styles from 'pages/opponentTeam/OpponentTeamsPage/OpponentTeamsPage.module.css';
import { OpponentTeamsPageCreateButton } from 'pages/opponentTeam/OpponentTeamsPage/OpponentTeamsPageCreateButton';
import { useCalcItemsCountByParent } from 'pages/team/TeamsPage/useCalcItemsCountByParent';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CARD_WIDTH } from 'shared/components/Card/Card';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { routes } from 'shared/routes';
const PAGE_GAP = 24;
const CREATE_BUTTON_COUNT = 1;
export const OpponentTeamsList = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { dynamicItems, isCalculateItems } = useCalcItemsCountByParent({
    parent: wrapperRef,
    itemWidth: CARD_WIDTH,
    wrapperGap: PAGE_GAP,
  });
  const {
    data: {
      data: teams = [],
      meta: { total = 0, pageSize = 0, page = 1 } = {},
    } = {},
    error,
    isLoading,
    changePage,
  } = useFetchOpponentTeamsQuery(
    {
      pageSize: dynamicItems - CREATE_BUTTON_COUNT,
    },
    {
      cacheTime: 0,
      enabled: isCalculateItems,
    },
  );
  if (error) {
    throw error;
  }
  const isShowPagination = total > 0 && total > pageSize && !isLoading;
  return (
    <>
      <div ref={wrapperRef}>
        {isLoading ? (
          <Centered>
            <Loader showLabel />
          </Centered>
        ) : (
          <div
            className={styles.listWrapper}
            style={{
              gap: `${PAGE_GAP}px`,
            }}
          >
            <OpponentTeamsPageCreateButton />
            {teams?.map((item) => {
              return (
                <OpponentTeamCard
                  key={item.id}
                  team={item}
                  onClick={() => navigate(routes.editOpponentTeam.as(item.id))}
                />
              );
            })}
          </div>
        )}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={pageSize}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </>
  );
};
