import { forwardRef, ReactElement, Ref } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { CustomSelect } from 'shared/components/Form/SimpleSelect/CustomSelect';
import {
  TCustomSimpleSelectProps,
  TFormSimpleSelectProps,
  TRenderInputProps,
} from 'shared/components/Form/types';

const ForwardedCustomSelect = forwardRef(CustomSelect) as <O, V>(
  props: TCustomSimpleSelectProps<O, V> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;

export const SimpleSelect = <T extends FieldValues, O, V>({
  control,
  label,
  fieldName,
  className,
  rules,
  inline,
  inlineInputClassName,
  ...restSelectProps
}: TFormSimpleSelectProps<T, O, V>) => {
  const renderSelect = ({ field, fieldState: { error } }: TRenderInputProps<T>) => (
    <Field
      className={className}
      error={error}
      inline={inline}
      inlineInputClassName={inlineInputClassName}
      label={label}
    >
      <ForwardedCustomSelect {...field} {...restSelectProps} />
    </Field>
  );

  return (
    <Controller
      control={control}
      name={fieldName}
      render={renderSelect}
      rules={rules}
    />
  );
};
