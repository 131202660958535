import { TEntityId } from 'shared/types/common';

const photoGalleryImagesBaseUrl = 'photo_gallery_images';

export const photoGalleryImagesQueryKeys = {
  photoGalleryImages: 'photoGalleryImages',
};

export const photoGalleryImagesApiKeys = {
  getPhotoGalleryImages: photoGalleryImagesBaseUrl,
  deletePhotoGalleryImage: (photoId: TEntityId) =>
    `${photoGalleryImagesBaseUrl}/${photoId}`,
  editPhotoGalleryImage: (photoId: TEntityId) =>
    `${photoGalleryImagesBaseUrl}/${photoId}`,
  uploadPhotoGalleryImage: photoGalleryImagesBaseUrl,
  // getPhotoGallery: (photoGalleryId: TEntityId) =>
  //   `${photoGalleryBaseUrl}/${photoGalleryId}`,
};
