import {
  fontFamilyOptions,
  fontStyleOptions,
} from 'pages/stories/edit/EditStory/components/forms/constants';
import styles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import {
  TFormLabelResolver,
  TFormValueResolver,
} from 'pages/stories/edit/EditStory/components/forms/types';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SimpleSelect } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  labelResolver: TFormLabelResolver;
  valueResolver: TFormValueResolver;
  fontFamilyName: Path<T>;
  fontStyleName: Path<T>;
  withTitle?: boolean;
};
export const FontSettings = <T extends FieldValues>({
  control,
  labelResolver,
  valueResolver,
  fontFamilyName,
  fontStyleName,
  withTitle,
}: TProps<T>) => {
  const { t } = useTranslation();
  return (
    <>
      {withTitle && (
        <Text className={styles.title} size={14} tag="h2">
          {t('content.story.optionFonts')}
        </Text>
      )}
      <SimpleSelect
        control={control}
        fieldName={fontFamilyName}
        iconSize="xs"
        inline
        inlineInputClassName={styles.formField}
        label={t('content.story.fonts')}
        labelResolver={labelResolver}
        menuPlacement="bottom-end"
        menuWidth={116}
        options={fontFamilyOptions}
        placeholder="Select"
        valueResolver={valueResolver}
      />
      <SimpleSelect
        control={control}
        fieldName={fontStyleName}
        iconSize="xs"
        inline
        inlineInputClassName={styles.formField}
        label={t('content.story.typeface')}
        labelResolver={labelResolver}
        menuPlacement="bottom-end"
        menuWidth={116}
        options={fontStyleOptions}
        placeholder="Select"
        valueResolver={valueResolver}
      />
    </>
  );
};
