import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { RenderElementProps } from 'slate-react';

export const EditorListItemElement = (props: RenderElementProps) => {
  if (props.element.type !== EditorElementTypeEnum.ListItem) {
    return null;
  }

  return <li {...props.attributes}>{props.children}</li>;
};
