import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchPhotoGalleryCategoriesQuery } from 'entities/photoGalleryCategories/queries';
import { TFilterComponentProps } from 'feature/filters/types';
import { EntityFilter } from 'feature/filters/ui/EntityFilter/EntityFilter';
import { TEntityFilter } from 'feature/filters/ui/EntityFilter/type';
import { TPhotoGalleryFilter } from 'pages/photogallery/PhotoGalleryPage/types';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';

export const PhotoGalleryCategoryFilter = ({
  setFilter,
  filter,
}: TFilterComponentProps<TPhotoGalleryFilter>) => {
  const { defaultLocale } = useLocales();
  const { data: { data: categories } = {}, isLoading } =
    useFetchPhotoGalleryCategoriesQuery({
      pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    });

  const mappedCategories = categories?.map<TEntityFilter>(
    ({ id, translations }) => ({
      id,
      name: translations[defaultLocale].name,
    }),
  );

  return (
    <EntityFilter
      entities={mappedCategories ?? []}
      filter={filter.categories ?? []}
      isLoading={isLoading}
      onChangeFilter={(categories) => setFilter({ ...filter, categories })}
    />
  );
};
