import { useLocales } from 'entities/config/lib/useLocales';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TContentLang } from 'shared/types/common';

import { TVideoPostFields } from './types';

export const useVideoTranslation = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { setValue, trigger, getValues } = useFormContext<TVideoPostFields>();
  const {
    field: { value: selectedLocale, onChange: changeSelectedLocale },
  } = useController({
    name: 'locale.selected',
  });
  const {
    field: { value: createdLocales, onChange: changeCreatedLocales },
  } = useController({ name: 'locale.created' });

  const isValidContent = () =>
    trigger([`translations.${selectedLocale as TContentLang}.title`, 'videoLink']);

  const handleAddLocale = async (locale: TContentLang) => {
    const isValid = await isValidContent();

    if (!isValid) {
      return;
    }

    const { translations } = getValues();

    changeCreatedLocales([...createdLocales, locale]);
    changeSelectedLocale(locale);
    setValue(`translations.${locale}.title`, translations![defaultLocale]!.title);
    notify(t('content.notifyTranslation'), { type: 'warning' });
  };

  const handleSelectLocale = async (locale: TContentLang) => {
    const isValid = await isValidContent();

    if (!isValid) {
      return;
    }

    changeSelectedLocale(locale);
  };

  const handleDeleteLocale = (locale: TContentLang) => {
    changeSelectedLocale(defaultLocale);
    changeCreatedLocales(createdLocales.filter((l: TContentLang) => l !== locale));
  };

  return {
    selectedLocale,
    createdLocales,
    handleAddLocale,
    handleSelectLocale,
    handleDeleteLocale,
  };
};
