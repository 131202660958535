import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { createNewsPost, deleteNewsPost, editNewsPost } from 'entities/news/api';
import { newsQueryKeys } from 'entities/news/consts';
import {
  editNewsPostDTO,
  TChangedEditNewsPostParagraphs,
} from 'entities/news/mapper/editNewsPostDTO';
import { prepareCreateNewsDTO } from 'entities/news/mapper/prepareCreateNewsDTO';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewsPostFormContext } from 'shared/components/NewsPostForm/context';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { routes } from 'shared/routes';

export const useDeleteNewsPostMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteNewsPost,
    onSuccess: () => queryClient.resetQueries([newsQueryKeys.news]),
  });
};

export const useCreateNewsPostMutation = () => {
  const navigate = useNavigate();
  const { defaultLocale } = useLocales();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: TEditNewsPostFields) =>
      createNewsPost(prepareCreateNewsDTO(data, defaultLocale)),
    onSuccess: () => {
      navigate(routes.news);
      queryClient.resetQueries([newsQueryKeys.news]);
    },
  });
};

export const useEditNewsPostMutation = (
  changedParams: TChangedEditNewsPostParagraphs,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { defaultLocale } = useLocales();
  const { initialValue } = useContext(NewsPostFormContext);

  return useMutation({
    mutationFn: (data: TEditNewsPostFields) =>
      editNewsPost(
        editNewsPostDTO({
          initialValue,
          data,
          defaultLocale,
          ...changedParams,
        }),
      ),
    onSuccess: () => {
      queryClient.resetQueries([newsQueryKeys.news]);
      navigate(routes.news);
    },
  });
};

export const useEditPublicationDateNewsPostMutation = (
  changedParams: TChangedEditNewsPostParagraphs,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { defaultLocale } = useLocales();

  return useMutation({
    mutationFn: (data: TEditNewsPostFields) =>
      editNewsPost(
        editNewsPostDTO({
          initialValue: data,
          data,
          defaultLocale,
          ...changedParams,
        }),
      ),
    onSuccess: () => {
      queryClient.resetQueries([newsQueryKeys.news]);
      navigate(routes.news);
    },
  });
};
