import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import { getRenderActions } from 'feature/workspacePanel/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TButtonProps } from 'shared/components/Button/Button';
import { TIconButtonProps } from 'shared/components/IconButton/IconButton';
import { SecondLayoutNavigation } from 'shared/ui/layouts/secondLayout/SecondLayoutNavigation/SecondLayoutNavigation';
import { TSecondLayoutNavigationLink } from 'shared/ui/layouts/secondLayout/types';

import styles from './SecondLayout.module.css';

type TProps = {
  title: string;
  confirmButtonProps?: TButtonProps;
  backButtonProps: Omit<TIconButtonProps, 'icon' | 'iconSize'>;
  navigationLinks: TSecondLayoutNavigationLink[];
  children: ReactNode;
};

export const SecondLayout = ({
  title,
  backButtonProps,
  confirmButtonProps,
  children,
  navigationLinks,
}: TProps) => {
  const { t } = useTranslation();
  const renderActions = getRenderActions({
    publishButtonProps: {
      children: t('shared.confirm'),
      ...confirmButtonProps,
    },
  });

  return (
    <div className={styles.root}>
      <WorkspacePanel
        renderActions={renderActions}
        title={title}
        backButtonProps={{
          onClick: backButtonProps.onClick,
        }}
      />
      <div className={styles.wrapper}>
        <SecondLayoutNavigation links={navigationLinks} />
        <div className={styles.content}> {children}</div>
      </div>
    </div>
  );
};
