import { arrayMoveImmutable } from 'array-move';
import { useUpdateStoryElementMutation } from 'entities/stories/mutations';
import {
  TStoryElementData,
  TStoryToolKind,
  TTextElementSettings,
} from 'entities/stories/types';
import { orderBy } from 'lodash';
import { EmptyLayers } from 'pages/stories/edit/EditStory/components/LayersPanel/EmptyLayers';
import { LayerItem } from 'pages/stories/edit/EditStory/components/LayersPanel/LayerItem';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryElements } from 'pages/stories/hooks/useStoryElements';
import { TContentLang } from 'shared/types/common';

import styles from './LayersPanel.module.css';

const getElementLayerText = (
  element: TStoryElementData<TStoryToolKind>,
  selectedLocale: TContentLang,
): string | null => {
  if (element.kind === 'text') {
    return (element.translations[selectedLocale].settings as TTextElementSettings)
      .text;
  }

  return null;
};

type TProps = {
  onElementSelect: (storyElement: TStoryElementData<TStoryToolKind>) => void;
};

export const LayersPanel = ({ onElementSelect }: TProps) => {
  const { selectedLocale } = useStoryEditorContext();
  const elementsInWork = useStoryElements();
  const updateElementMutation = useUpdateStoryElementMutation();

  const sortedElements = orderBy(elementsInWork, ['layer'], 'desc');
  const emptyLayers = sortedElements.length === 0;

  const updateLayers = (dragIndex: number, hoverIndex: number) => {
    const updatedArray = arrayMoveImmutable(sortedElements, dragIndex, hoverIndex);
    updatedArray.forEach((element, index) => {
      updateElementMutation.mutate({
        ...element,
        layer: updatedArray.length - 1 - index,
      });
    });
  };

  return (
    <div className={styles.root}>
      {emptyLayers ? (
        <EmptyLayers />
      ) : (
        sortedElements.map((element, index) => (
          <LayerItem
            key={element.id}
            id={element.id}
            index={index}
            kind={element.kind}
            storyElement={element}
            text={getElementLayerText(element, selectedLocale)}
            updateLayers={updateLayers}
            onClick={onElementSelect}
          />
        ))
      )}
    </div>
  );
};
