import { Text } from 'shared/components/Text/Text';

import styles from './SocialNetworkLabel.module.css';

type TProps = {
  label: string;
  image: string;
};

export const SocialNetworkLabel = ({ image, label }: TProps) => (
  <div className={styles.optionLabel}>
    <div className={styles.icon}>
      <img alt={`social network ${label}`} src={image} />
    </div>
    <Text className={styles.text} color="var(--gray-500)" size={12}>
      {label}
    </Text>
  </div>
);
