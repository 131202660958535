import { TEntityId } from 'shared/types/common';

export const newsQueryKeys = {
  news: 'news',
  newsPost: 'newsPost',
  newsPostForEdit: 'newsPostForEdit',
};

const newsPostsBaseUrl = 'news_posts';

export const newsApiKeys = {
  getNews: newsPostsBaseUrl,
  deleteNewsPost: (newsPostId: TEntityId) => `${newsPostsBaseUrl}/${newsPostId}`,
  getNewsPost: (newsPostId: TEntityId) => `${newsPostsBaseUrl}/${newsPostId}`,
  createNewsPost: newsPostsBaseUrl,
  editNewsPost: (newsPostId: TEntityId) => `${newsPostsBaseUrl}/${newsPostId}`,
};
