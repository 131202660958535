import { FocusEvent, forwardRef } from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { EyeDropper } from 'shared/components/ColorPicker/components/EyeDropper';
import { OpacityInput } from 'shared/components/ColorPicker/components/OpacityInput';
import { SavedColors } from 'shared/components/ColorPicker/components/SavedColors';
import { HEX_TRANSPARENCIES } from 'shared/components/ColorPicker/constants';
import { opacityFromHex } from 'shared/components/ColorPicker/utils';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { replaceBetween } from 'shared/utils/string';

import styles from './ColorPicker.module.css';

type TProps = {
  value: string;
  onChange: (value: string) => void;
  onCloseClick: () => void;
  setIsEyeDropActive: (active: boolean) => void;
};

export const ColorPicker = forwardRef<HTMLDivElement, TProps>(
  ({ value, onChange, onCloseClick, setIsEyeDropActive }, ref) => {
    const { t } = useTranslation();

    const handleOpacityInputChange = (opacity: number) => {
      const hexOpacity = HEX_TRANSPARENCIES[opacity];
      const newColor = replaceBetween(value, hexOpacity, 7, 9);
      onChange(newColor);
    };

    const handleHexFocus = (e: FocusEvent) => {
      (e.target as HTMLInputElement).select();
    };

    const opacityValue = opacityFromHex(value.substring(7));

    return (
      <div className={styles.colorPicker} ref={ref}>
        <div className={styles.pickerWrapper}>
          <div className={styles.title}>
            <Text size={14}>{t('shared.ColorPicker.title')}</Text>
            <button onClick={onCloseClick}>
              <Icon kind="x" size="s" />
            </button>
          </div>
          <HexAlphaColorPicker color={value} onChange={onChange} />
          <EyeDropper
            className={styles.eyeDropper}
            setIsEyeDropActive={setIsEyeDropActive}
            onColorPick={onChange}
          />
        </div>
        <div className={styles.colorInputs}>
          <Text className={styles.hexTitle} weight="SemiBold">
            Hex
          </Text>
          <div className={styles.hexInputWrapper}>
            <HexColorInput
              className={styles.hexInput}
              color={value}
              onChange={onChange}
              onFocus={handleHexFocus}
            />
            <Text size={14}>#</Text>
          </div>
          <OpacityInput value={opacityValue} onChange={handleOpacityInputChange} />
        </div>
        <SavedColors selectedColor={value} onColorSelect={onChange} />
      </div>
    );
  },
);

ColorPicker.displayName = 'ColorPicker';
