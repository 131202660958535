import cn from 'classnames';
import { memo } from 'react';
import { Text } from 'shared/components/Text/Text';

import styles from './CountryLabel.module.css';

type TProps = {
  label: string;
  image: string;
  flagSize?: 's' | 'm';
};

export const CountryLabel = memo(({ image, label, flagSize = 's' }: TProps) => (
  <div className={styles.optionLabel}>
    <div className={cn(styles.countryFlag, styles[flagSize])}>
      <img alt={`country ${label}`} src={image} />
    </div>
    <Text className={styles.text} color="var(--gray-500)" size={12}>
      {label}
    </Text>
  </div>
));
