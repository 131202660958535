import { Control, useController } from 'react-hook-form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

export const useGetCreatedLocales = (control: Control<TEditNewsPostFields>) => {
  const {
    field: { value: createdLocales, onChange: changeCreatedLocales },
  } = useController({ control, name: 'locale.created' });

  return { createdLocales, changeCreatedLocales };
};
