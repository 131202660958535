import { useUserStore } from 'entities/user/model/store';
import { TUserRoles } from 'shared/types/user';

export const useCanUser = () => {
  const userRoles = useUserStore((state) => state.user?.roles);

  return (roles: TUserRoles) => {
    return userRoles && roles.some((role) => userRoles.has(role));
  };
};
