import { UIKit } from 'pages/uikit/UIKit';
import { redirect, RouteObject } from 'react-router-dom';
import { isProduction } from 'shared/constants';
import { routes } from 'shared/routes';

export const UIKitRoute: RouteObject = {
  path: routes.uikit,
  element: <UIKit />,
  loader: () => {
    if (isProduction) {
      return redirect(routes.root);
    }

    return null;
  },
};
