import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { TNavigationLink } from 'widgets/navigation/types';

import styles from './NavigationLink.module.css';

type TProps = {
  link: TNavigationLink;
};

export const NavigationLink = ({ link }: TProps) => {
  return (
    <div className={styles.root}>
      <NavLink
        to={link.route}
        className={({ isActive }) =>
          cn(styles.link, { [styles.linkActive]: isActive })
        }
      >
        {link.name}
      </NavLink>
    </div>
  );
};
