import { useLocales } from 'entities/config/lib/useLocales';
import { last } from 'lodash';
import { PreviewImage } from 'pages/stories/edit/EditStory/components/PreviewImage';
import { StoryWrapper } from 'pages/stories/edit/EditStory/components/StoryWrapper/StoryWrapper';
import { ModalContext } from 'pages/stories/edit/EditStory/context';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useStoryGroupEdit } from 'pages/stories/hooks/useStoryGroupEdit';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { CustomDragLayer } from 'shared/components/CustomDragLayer/CustomDragLayer';
import { Divider } from 'shared/components/Divider';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { Loader } from 'shared/components/Loader/Loader';
import { ToolButton } from 'shared/components/ToolButton';
import { getImageSettings } from 'shared/constants';
import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';

import styles from './StoryGroupPanel.module.css';

const MAXIMUM_STORIES = 10;

export const StoryGroupPanel = () => {
  const { t } = useTranslation();
  const { storyGroup, stories, selectedStory, selectedLocale, elementsWithErrors } =
    useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const { openedModal, openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { defaultLocale } = useLocales();

  const {
    sortedStories,
    addStory,
    removeStory,
    moveStory,
    selectStory,
    addPreviewImage,
    isStoryCreating,
    isStoryDeleting,
    isPreviewUpdating,
  } = useStoryGroupEdit({
    stories,
    storyGroup,
    selectedStory,
    lastPosition: last(stories)?.position || 1,
  });

  const canAddImages = useMemo(
    () => stories.length < MAXIMUM_STORIES,
    [stories, selectedLocale],
  );

  const hasErrors = toBoolean(elementsWithErrors && elementsWithErrors?.length > 0);

  const addStoryDisabled =
    !canAddImages ||
    selectedLocale !== defaultLocale ||
    isStoryCreating ||
    hasErrors;

  useEffect(() => {
    if (!selectedStory && sortedStories.length > 0) {
      dispatch({ type: 'selectStory', payload: sortedStories[0] });
    }
  }, [selectedStory, sortedStories]);

  const confirmDelete = useCallback(() => {
    if (stories.length === 1) {
      navigate(routes.stories, { replace: true });
    } else {
      removeStory();
    }
    openModal(null);
  }, [removeStory, stories]);

  const openConfirmModal = useCallback(() => openModal('confirm'), []);

  const handleImageLoad = (files: File[]) => {
    const file = files[0];

    if (openedModal === 'storyGroupPreviewUpload') {
      addPreviewImage(file, () => {
        openModal(null);
      });
    } else if (openedModal === 'addStory') {
      addStory(file);
      openModal(null);
    }
  };

  const handlePreviewSettingsClick = () => {
    if (selectedLocale === defaultLocale) {
      openModal('storyGroupPreviewUpload');
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.overflowWrapper}>
        {storyGroup && storyGroup.preview.x1 && !isPreviewUpdating ? (
          <PreviewImage
            name={storyGroup.translations[selectedLocale].name}
            previewImage={storyGroup.preview.x1}
            selectedLocale={selectedLocale}
            onSettingsClick={handlePreviewSettingsClick}
          />
        ) : isPreviewUpdating ? (
          <Loader className={styles.previewLoader} size="s" />
        ) : (
          <ToolButton
            disabled={selectedLocale !== defaultLocale}
            icon="image-plus"
            title={t('content.story.storyGroupPanel.buttonTitle')}
            onClick={() => openModal('storyGroupPreviewUpload')}
          />
        )}
        <Divider />
        {stories.length > 0 && (
          <div className={styles.storiesList}>
            {sortedStories.map((story, idx) => (
              <StoryWrapper
                key={story.id}
                id={story.id}
                image={story.image.x1}
                index={idx}
                moveStory={moveStory}
                selected={selectedStory?.id === story.id}
                onClick={selectStory(story)}
              />
            ))}
            {isStoryCreating && <Loader className={styles.storyLoader} size="s" />}
            <CustomDragLayer />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <IconButton
          disabled={addStoryDisabled}
          icon="filePlus"
          onClick={() => openModal('addStory')}
        />
        <IconButton
          className={styles.deleteButton}
          icon="trash"
          disabled={
            selectedLocale !== defaultLocale ||
            stories.length === 1 ||
            isStoryDeleting
          }
          onClick={openConfirmModal}
        />
      </div>
      <ConfirmationModal
        isOpened={openedModal === 'confirm'}
        title={t('content.story.deleteView', { mark: '?' })}
        onClose={() => openModal && openModal(null)}
        onConfirm={confirmDelete}
      />
      <ImageUploadModal
        aspectRatio={getImageSettings(t).stories.background.aspectRatio}
        hint={getImageSettings(t).stories.background.hint}
        opened={openedModal === 'addStory'}
        title={t('content.story.storyGroupPanel.imageUploadTitle')}
        withCropping
        onClose={() => openModal && openModal(null)}
        onConfirm={handleImageLoad}
      />
      <ImageUploadModal
        aspectRatio={getImageSettings(t).stories.preview.aspectRatio}
        opened={openedModal === 'storyGroupPreviewUpload'}
        title={t('content.story.storyGroupPanel.optionsPreview')}
        withCropping
        onClose={() => openModal && openModal(null)}
        onConfirm={handleImageLoad}
      />
    </div>
  );
};
