import cn from 'classnames';

import styles from './SavedColors.module.css';

type TProps = {
  isSelected: boolean;
  color: string;
  onClick: (color: string) => void;
};

export const Swatch = ({ color, isSelected, onClick }: TProps) => {
  const handleClick = () => {
    onClick(color);
  };
  return (
    <div
      className={cn(styles.swatch, { [styles.swatchSelected]: isSelected })}
      role="button"
      style={{ backgroundColor: color }}
      onClick={handleClick}
    />
  );
};
