import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { useCallback, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
} from 'react-hook-form';
import { useToggle } from 'react-use';
import { ColorPicker } from 'shared/components/ColorPicker';
import { DROPDOWN_OFFSET } from 'shared/components/constants';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { Field } from 'shared/components/Form/Field/Field';
import { TFormInputProps, TRenderInputProps } from 'shared/components/Form/types';
import { useEyeDropContext } from 'shared/contexts/eyedropContext';
import { useFloat } from 'shared/hooks/useFloat';

import styles from './ColorInput.module.css';

type TProps<T extends FieldValues> = Omit<
  TFormInputProps<T>,
  'type' | 'switchField'
> & {
  showSwitch?: boolean;
};

export const ColorInput = <T extends FieldValues>(props: TProps<T>) => {
  const {
    control,
    fieldName,
    className,
    rules,
    label,
    inline,
    inlineInputClassName,
    inputClassName,
    showSwitch,
  } = props;
  const [showColorInput, setShowColorInput] = useState(true);
  const [isOpen, toggleOpened] = useToggle(false);
  const { isEyeDropActive, setIsEyeDropActive } = useEyeDropContext();
  const switchField = showSwitch
    ? {
        checked: showColorInput,
        onSwitch: () => {
          setShowColorInput((prevState) => !prevState);
        },
      }
    : undefined;

  const onChange = useCallback(
    (isOpen: boolean) => {
      if (isEyeDropActive) {
        return;
      }

      toggleOpened(isOpen);
    },
    [isEyeDropActive, toggleOpened],
  );

  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    placement: 'bottom-end',
    offset: DROPDOWN_OFFSET,
    onChange: onChange,
  });

  const renderColorInput = (field: ControllerRenderProps<T, Path<T>>) => {
    return (
      <>
        <div className={cn(styles.innerWrapper, inputClassName)}>
          <div
            ref={triggerRef}
            {...trigger}
            style={{ backgroundColor: field.value }}
            className={cn(styles.selectedColor, {
              [styles.openedColorPicker]: isOpen,
            })}
          />
          <span>{(field.value as string).toUpperCase()}</span>
        </div>
        {isOpen && (
          <FloatingPortal>
            <Dropdown {...floating}>
              <ColorPicker
                setIsEyeDropActive={setIsEyeDropActive}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                onCloseClick={toggleOpened}
              />
            </Dropdown>
          </FloatingPortal>
        )}
      </>
    );
  };

  const renderInput = (renderProps: TRenderInputProps<T>) => {
    const {
      field,
      fieldState: { error },
    } = renderProps;

    return (
      <Field
        className={className}
        error={error}
        inline={inline}
        inlineInputClassName={inline ? inlineInputClassName : undefined}
        label={label}
        switchField={switchField}
      >
        {renderColorInput(field)}
      </Field>
    );
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={renderInput}
      rules={rules}
    />
  );
};
