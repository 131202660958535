import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchTeamsQuery } from 'entities/teams/queries';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';

import styles from './TeamSelect.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
};

export const TeamSelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
}: TProps<T>) => {
  const { t } = useTranslation();
  const {
    field: { value: selectedTeam },
  } = useController({
    control,
    name: fieldName,
  });

  const { defaultLocale } = useLocales();
  const { data: { data: teams = [] } = {} } = useFetchTeamsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const teamOptions = useMemo(
    () =>
      teams.map<TSelectOption>((team) => ({
        value: toString(team.id),
        label: team.translations[defaultLocale].name,
        size: 's',
      })),
    [teams],
  );

  const teamPlaceholder = useMemo(() => {
    if (selectedTeam && teamOptions.length) {
      return teamOptions.find((team) => team.value === selectedTeam)!.label;
    }

    return t('content.teams.chooseTeam');
  }, [selectedTeam, teamOptions]);

  return (
    <FormSelect
      className={className}
      control={control}
      fieldName={fieldName}
      label={t('content.teams.determinationTeam')}
      multiple={false}
      options={teamOptions}
      optionsContainerClassName={styles.optionsContainer}
      placeholder={teamPlaceholder}
      required
      search={true}
    />
  );
};
