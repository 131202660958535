import { fetchManagerCareers } from 'entities/managerCareers/api/api';
import { managerCareersQueryKeys } from 'entities/managerCareers/api/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchManagerCareersParams = {
  filter?: Record<string, string>;
};

export const useFetchManagerCareersQuery = ({
  filter,
}: TFetchManagerCareersParams) => {
  return usePaginationQuery((pagination) => ({
    queryKey: [managerCareersQueryKeys.managerCareers, pagination, filter],
    queryFn: () => fetchManagerCareers({ params: { ...filter, ...pagination } }),
    cacheTime: 0,
  }));
};
