import { TEntityFilterProps } from 'feature/filters/ui/EntityFilter/EntityFilter';
import { useCallback, useMemo } from 'react';
import { useForm } from 'shared/hooks/useReactHookForm';

type TValues = Record<string, boolean>;

export const useEntityFilterForm = ({
  onChangeFilter,
  entities,
  filter,
}: Omit<TEntityFilterProps, 'isLoading'>) => {
  const actionSubmit = useCallback(
    (values: Record<string, boolean>) => {
      if (!entities) {
        return;
      }

      onChangeFilter(
        entities.filter((entity) => values[entity.id]).map((entity) => entity.id),
      );
    },
    [entities],
  );

  const defaultValues = useMemo(
    () =>
      entities?.reduce<TValues>(
        (acc, { id }) => ({ ...acc, [id]: (filter ?? []).includes(id) }),
        {},
      ),
    [entities, filter],
  );

  return useForm<TValues>({
    actionSubmit,
    defaultValues,
  });
};
