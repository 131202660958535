import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { deleteManager, editManager } from 'entities/managers/api/api';
import { createManager } from 'entities/managers/api/api';
import { managersQueryKeys } from 'entities/managers/api/consts';
import {
  TCreateManagerDTO,
  TEditManagerDTO,
  TManager,
} from 'entities/managers/model/types';

export const useDeleteManagerMutation = () => {
  return useMutation({
    mutationFn: deleteManager,
    onSuccess: () => {
      return queryClient.resetQueries([managersQueryKeys.managers]);
    },
  });
};

export const useCreateManagerMutation = (
  options?: UseMutationOptions<TManager, unknown, TCreateManagerDTO>,
) =>
  useMutation({
    mutationFn: createManager,
    ...options,
  });

export const useEditManagerMutation = (
  options?: UseMutationOptions<TManager, unknown, TEditManagerDTO>,
) =>
  useMutation({
    mutationFn: editManager,
    ...options,
  });
