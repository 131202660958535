import { useFetchPhotoGalleryImagesQuery } from 'entities/photoGalleryImages/queries';
import { useParams } from 'react-router-dom';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { getApiFilters } from 'shared/lib/apiFilters';

export const useGetPhotoGalleryFormImages = () => {
  const { photoGalleryId } = useParams<{ photoGalleryId: string }>();

  return useFetchPhotoGalleryImagesQuery(
    {
      filter: getApiFilters({
        name: 'photo_gallery_id',
        type: 'eq',
        value: photoGalleryId,
      }),
    },
    {
      pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    },
  );
};
