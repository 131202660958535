import { videoCategoriesQueryKeys } from 'entities/videoCategories/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

import { fetchVideoCategories } from './api';

type TVideoFetchParams = TPaginatedParameters & {
  sort?: 'name' | 'importance' | '-name' | '-importance';
};

export const useFetchVideoCategoriesQuery = ({
  sort = 'name',
  ...defaultPagination
}: TVideoFetchParams) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [videoCategoriesQueryKeys.videoCategories, pagination, sort],
      queryFn: () => fetchVideoCategories({ params: { ...pagination, sort } }),
      cacheTime: 0,
    }),
    defaultPagination,
  );
};
