import { useEffect, useState } from 'react';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { TCropper } from 'shared/components/cropper/types';
import {
  ImageUpload,
  TImageUploadProps,
} from 'shared/components/ImageUploadModal/ImageUpload';
import { ImageUploadModalCropper } from 'shared/components/ImageUploadModal/ImageUploadModalCropper';
import { TImageUploadModalCroppingProps } from 'shared/components/ImageUploadModal/types';

import styles from './ImageUploadModal.module.css';

type TCommonProps = {
  opened: boolean;
  onClose: () => void;
  onConfirm: (files: File[]) => void;
  title: string;
};

export type TImageUploadModalProps = TCommonProps &
  TImageUploadModalCroppingProps &
  Pick<TImageUploadProps, 'hint' | 'multiple'>;

// TODO данный компонент можно упростить,
//  заменив в onConfirm files на объект { data: base64string, filename: name },
//  так как бэк принимает изображения только в таком формате и нет необходимости отдавать объект File [CF-322]
export const ImageUploadModal = ({
  opened,
  onClose,
  title,
  onConfirm,
  withCropping,
  aspectRatio,
  multiple = false,
  hint,
  mode,
}: TImageUploadModalProps) => {
  const [cropper, setCropper] = useState<TCropper | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const haveFiles = files.length !== 0;
  const enableCropping = withCropping && !multiple && haveFiles && aspectRatio;

  const reset = () => {
    haveFiles && setFiles([]);
    setIsLoading(false);
  };

  const handleConfirm = () => {
    if (haveFiles) {
      setIsLoading(true);

      if (enableCropping && cropper) {
        const file = files[0];
        cropper.getCroppedCanvas().toBlob((blob) => {
          if (blob) {
            const croppedFile = new File([blob], file.name, { type: file.type });
            onConfirm([croppedFile]);
          }
        }, file.type);
      } else {
        onConfirm(files);
      }
    }
  };

  const onClickCloseButtonHandler = () => {
    if (enableCropping) {
      reset();
      return;
    }

    onCloseHandler();
  };

  const onCloseHandler = () => {
    if (isLoading) {
      return;
    }

    onClose();
  };

  useEffect(() => {
    if (!opened) {
      reset();
    }
  }, [opened]);

  return (
    <ActionModal
      contentClassName={enableCropping ? styles.cropper : styles.uploader}
      opened={opened}
      title={title}
      width={724}
      cancelButtonProps={{
        isDisabled: isLoading,
        onClick: onClickCloseButtonHandler,
      }}
      closeButtonProps={{
        disabled: isLoading,
      }}
      confirmButtonProps={{
        isDisabled: !haveFiles && !isLoading,
        isLoading: isLoading,
        onClick: handleConfirm,
      }}
      onClose={onCloseHandler}
    >
      {enableCropping && aspectRatio ? (
        <ImageUploadModalCropper
          aspectRatio={aspectRatio}
          file={files[0]}
          initCropper={setCropper}
          mode={mode}
        />
      ) : (
        <ImageUpload
          files={files}
          hint={hint}
          multiple={multiple}
          setFiles={setFiles}
        />
      )}
    </ActionModal>
  );
};
