import { TEntityId } from 'shared/types/common';

const newsCategoriesBaseUrl = 'news_categories';

export const newsCategoriesQueryKeys = {
  newsCategories: 'newsCategories',
};

export const newsCategoriesApiKeys = {
  createNewsCategory: newsCategoriesBaseUrl,
  getNewsCategories: newsCategoriesBaseUrl,
  editNewsCategory: (categoryId: TEntityId) =>
    `${newsCategoriesBaseUrl}/${categoryId}`,
  deleteNewsCategory: (categoryId: TEntityId) =>
    `${newsCategoriesBaseUrl}/${categoryId}`,
};
