import { useQuery } from '@tanstack/react-query';
import { useToken } from 'shared/api/token';
import { toBoolean } from 'shared/lib/toBoolean';

import { fetchConfig } from './api';
import { configQueryKeys } from './consts';

export const useFetchConfig = () => {
  const token = useToken();

  return useQuery({
    queryKey: [configQueryKeys.config],
    queryFn: fetchConfig,
    enabled: toBoolean(token),
  });
};
