import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TIconType } from 'shared/components/Icon/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './Alert.module.css';

export type TAlertVariant = 'dark' | 'green' | 'blue' | 'red' | 'yellow';

type TProps = {
  icon?: TIconType;
  className?: string;
  textSize?: 12 | 14;
  variant: TAlertVariant;
  title?: string;
  message: string;
  onClose?: () => void;
};

export const Alert = ({
  variant,
  title,
  textSize = 14,
  message,
  icon,
  onClose,
  className,
}: TProps) => {
  return (
    <div
      className={cn(styles.root, styles[variant], className, {
        [styles.withIcon]: toBoolean(icon),
      })}
    >
      {icon && <Icon kind={icon} size="s" />}
      <div className={styles.info}>
        {title && (
          <Text size={14} weight="SemiBold">
            {title}
          </Text>
        )}
        {message && <Text size={title ? 12 : textSize}>{message}</Text>}
      </div>
      {onClose && (
        <IconButton
          className={styles.closeIcon}
          icon="x"
          iconSize="s"
          onClick={onClose}
        />
      )}
    </div>
  );
};
