import { OpponentTeamsList } from 'pages/opponentTeam/OpponentTeamsPage/OpponentTeamsList';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';

import styles from './OpponentTeamsPage.module.css';
export const OpponentTeamsPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Text size={24} tag="h1" weight="Bold">
        {t('content.opponentTeams')}
      </Text>
      <OpponentTeamsList />
    </div>
  );
};
