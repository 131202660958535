import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  deletePhotoGalleryImage,
  editPhotoGalleryImage,
  uploadPhotoGalleryImage,
} from 'entities/photoGalleryImages/api';
import { photoGalleryImagesQueryKeys } from 'entities/photoGalleryImages/consts';
import {
  TCreatePhotoGalleryImageDTO,
  TEditPhotoGalleryImageDTO,
} from 'entities/photoGalleryImages/types';

export const useDeletePhotoGalleryImageMutation = () => {
  return useMutation({
    mutationFn: deletePhotoGalleryImage,
    onSuccess: () =>
      queryClient.invalidateQueries([
        photoGalleryImagesQueryKeys.photoGalleryImages,
      ]),
  });
};

export const useEditPhotoGalleryImageMutation = () =>
  useMutation({
    mutationFn: (payload: TEditPhotoGalleryImageDTO) =>
      editPhotoGalleryImage(payload),
    onSuccess: () =>
      queryClient.invalidateQueries([
        photoGalleryImagesQueryKeys.photoGalleryImages,
      ]),
  });

export const useCreatePhotoGalleryImageMutation = () =>
  useMutation({
    mutationFn: (payload: TCreatePhotoGalleryImageDTO) =>
      uploadPhotoGalleryImage(payload),
  });
