import { TCreateManagerCareerDTO } from 'entities/managerCareers/model/types';
import { TManagerCareerModalFields } from 'feature/managerCareerModal/types';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang, TEntityId } from 'shared/types/common';
import { mapFileToUpload } from 'shared/utils/image';

type TProps = {
  data: TManagerCareerModalFields;
  locales: TContentLang[];
  managerId: TEntityId;
};

export const mapCreateManagerCareerDTO = async ({
  locales,
  data,
  managerId,
}: TProps): Promise<TCreateManagerCareerDTO> => {
  return {
    season: data.season,
    managerId,
    translations: reduceTranslationObject(locales, (locale) => ({
      teamName: data.translations[locale]?.teamName,
      jobTitle: data.translations[locale]?.jobTitle,
    })),
    teamPicture: await mapFileToUpload(data.teamPicture!),
  };
};
