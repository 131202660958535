import { useRightPanel } from 'feature/rightPanel/hooks/useRightPanel';
import { RightPanel } from 'feature/rightPanel/ui/RightPanel';
import { useController, useFormContext } from 'react-hook-form';
import { AddNewsPostRightPanelConnections } from 'shared/components/NewsPostForm/components/AddNewsPostRightPanel/AddNewsPostRightPanelConnections';
import { AddNewsPostRightPanelImportance } from 'shared/components/NewsPostForm/components/AddNewsPostRightPanel/AddNewsPostRightPanelImportance';
import { AddNewsPostRightPanelLabel } from 'shared/components/NewsPostForm/components/AddNewsPostRightPanel/AddNewsPostRightPanelLabel';
import { AddNewsPostRightPanelPreview } from 'shared/components/NewsPostForm/components/AddNewsPostRightPanel/AddNewsPostRightPanelPreview';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { toBoolean } from 'shared/lib/toBoolean';

export const AddNewsPostRightPanel = () => {
  const { control } = useFormContext<TEditNewsPostFields>();
  const { field } = useController({
    control,
    name: 'preview.file',
  });

  const { Preview, Label, Connections, Importance } = useRightPanel({
    hasPreview: toBoolean(field.value),
    preview: {
      Component: AddNewsPostRightPanelPreview,
      fieldName: 'preview',
    },
    label: {
      Component: AddNewsPostRightPanelLabel,
    },
    connections: {
      Component: AddNewsPostRightPanelConnections,
    },
    importance: {
      Component: AddNewsPostRightPanelImportance,
    },
  });

  return <RightPanel components={[Preview, Label, Connections, Importance]} />;
};
