import { TEntityId } from 'shared/types/common';

export const opponentPlayersQueryKeys = {
  opponentPlayers: 'opponentPlayers',
};

const opponentPlayersBaseUrl = 'opponent_players';

export const opponentPlayersApiKeys = {
  getOpponentPlayers: opponentPlayersBaseUrl,
  deleteOpponentPlayer: (playerId: TEntityId) =>
    `${opponentPlayersBaseUrl}/${playerId}`,
  editOpponentPlayer: (id: TEntityId) => `${opponentPlayersBaseUrl}/${id}`,
  createOpponentPlayer: opponentPlayersBaseUrl,
};
