import { useLocales } from 'entities/config/lib/useLocales';
import { useCreatePhotoGalleryCategoryMutation } from 'entities/photoGalleryCategories/mutations';
import { useFetchPhotoGalleryCategoriesQuery } from 'entities/photoGalleryCategories/queries';
import { usePhotoGalleryCategoriesColumnsConfig } from 'pages/settings/categories/PhotoGalleryCategoriesPage/columnsConfig';
import { PhotoGalleryCategoriesActions } from 'pages/settings/categories/PhotoGalleryCategoriesPage/PhotoGalleryCategoriesActions';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './PhotoGalleryCategoriesPage.module.css';

export const PhotoGalleryCategoriesPage = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const createPhotoGalleryCategories = useCreatePhotoGalleryCategoryMutation();
  const {
    data: { data: categories = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchPhotoGalleryCategoriesQuery();
  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);
  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);
  const createPhotoGalleryCategory = (translations: TTranslations<string>) => {
    createPhotoGalleryCategories.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: translations[locale],
      })),
    });
    closeModal();
  };
  return (
    <div className={styles.root}>
      <CommonList
        configCreator={usePhotoGalleryCategoriesColumnsConfig}
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.categories')}
        createButtonProps={{
          content: t('shared.create'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: categories,
          rowClassName: styles.rowGridTemplate,
          ActionComponent: PhotoGalleryCategoriesActions,
        }}
      />
      <LocalizationModal
        actionSubmit={createPhotoGalleryCategory}
        closeModal={closeModal}
        isOpened={showCreateModal}
        subtitle={t('content.settings.nameCategory')}
        title={t('content.settings.creatingCategory')}
        inputProps={{
          maxLength: 255,
          placeholder: t('content.inputPlaceholder'),
          required: true,
          size: 's',
        }}
      />
    </div>
  );
};
