import { useQueryClient } from '@tanstack/react-query';
import { photoGalleryQueryKeys } from 'entities/photoGallery/consts';
import { useDeletePhotoGalleryMutation } from 'entities/photoGallery/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePhotoGallery = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: deletePhotoGalleryMutation, isLoading } =
    useDeletePhotoGalleryMutation();

  const deletePhotoGallery = async (photoGalleryId: TEntityId) =>
    deletePhotoGalleryMutation(photoGalleryId, {
      onError: () =>
        notify(t('content.photoGallery.errorDeleteImage'), { type: 'error' }),
      onSuccess: () =>
        queryClient.resetQueries([photoGalleryQueryKeys.photoGallery]),
    });

  return {
    isDeletingPhotoGallery: isLoading,
    deletePhotoGallery,
  };
};
