export const stadiumsQueryKeys = {
  stadiums: 'stadiums',
};

const stadiumsBaseUrl = 'stadiums';

export const stadiumsApiKeys = {
  getStadiums: stadiumsBaseUrl,
  createStadium: stadiumsBaseUrl,
};
