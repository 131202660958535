import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateLabelsMutation } from 'entities/labels/mutations';
import { useFetchLabelsQuery } from 'entities/labels/queries';
import { useLabelsColumnsConfig } from 'pages/settings/labels/LabelsPage/columnsConfig';
import { LabelActions } from 'pages/settings/labels/LabelsPage/LabelActions';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './LabelsPage.module.css';

export const LabelsPage = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const {
    data: { data: labels = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchLabelsQuery();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const createLabels = useCreateLabelsMutation();
  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);
  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);
  const createLabel = (data: TTranslations<string>) => {
    createLabels.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: data[locale],
      })),
    });
    closeModal();
  };
  return (
    <div className={styles.root}>
      <CommonList
        configCreator={useLabelsColumnsConfig}
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.labelList')}
        createButtonProps={{
          content: t('shared.create'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: labels,
          rowClassName: styles.rowGridTemplate,
          ActionComponent: LabelActions,
        }}
      />
      <LocalizationModal
        actionSubmit={createLabel}
        closeModal={closeModal}
        isOpened={showCreateModal}
        subtitle={t('content.settings.labelName')}
        title={t('content.settings.creatingLabel')}
        inputProps={{
          maxLength: 255,
          placeholder: t('content.inputPlaceholder'),
          required: true,
          size: 's',
        }}
      />
    </div>
  );
};
