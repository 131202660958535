import { PlayerLayout } from 'feature/playerLayout/ui/PlayerLayout/PlayerLayout';
import { useEditPlayer } from 'pages/player/edit/EditPlayerPage/EditPlayerPage/useEditPlayer';
import { useLoadPlayerForEdit } from 'pages/player/edit/EditPlayerPage/EditPlayerPage/useLoadPlayerForEdit';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { PlayerForm } from 'widgets/playerForm/ui/PlayerForm/PlayerForm';
import { usePlayerForm } from 'widgets/playerForm/ui/PlayerForm/usePlayerForm';

export const EditPlayerPage = () => {
  const { isLoading: isLoadingPlayer, playerFormFields } = useLoadPlayerForEdit();
  const { editPlayer, isLoading: isLoadingEditPlayer } = useEditPlayer();

  const playerForm = usePlayerForm({
    initialValues: playerFormFields,
  });

  const editPlayerHandler = () => {
    editPlayer(playerForm.getValues());
  };

  return (
    <PlayerLayout
      confirmButtonProps={{
        isDisabled: !playerForm.formState.isValid,
        onClick: editPlayerHandler,
        isLoading: isLoadingEditPlayer,
      }}
    >
      {isLoadingPlayer ? (
        <Centered>
          <Loader />
        </Centered>
      ) : (
        <PlayerForm control={playerForm.control} />
      )}
    </PlayerLayout>
  );
};
