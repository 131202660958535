import { useQueryClient } from '@tanstack/react-query';
import { configQueryKeys } from 'entities/config/model/consts';
import { startSession } from 'entities/session/model/api';
import { TSessionCredentials } from 'entities/session/model/types';
import { userQueryKeys } from 'entities/user/model/consts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTokenStore } from 'shared/api/token';

import { authErrorHandler } from '../lib/authErrorHandler';

export const useAuthorize = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const setToken = useTokenStore((state) => state.setToken);

  const authorize = async (credentials: TSessionCredentials) => {
    try {
      setIsLoading(true);
      const response = await startSession(credentials).catch((reason) =>
        authErrorHandler(reason, t),
      );
      // TODO: Resolve ignore by improving generics in axios config [CF-200]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const token = response.token;

      setToken(token);
      queryClient.resetQueries([userQueryKeys.user]);
      queryClient.resetQueries([configQueryKeys.config]);
    } finally {
      setIsLoading(false);
    }
  };

  return { authorize, isLoading };
};
