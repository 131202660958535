import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';

import styles from './PhotoGalleryFormEmpty.module.css';

type TProps = {
  onClick: VoidFunction;
};

export const PhotoGalleryFormEmpty = ({ onClick }: TProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Icon
        className={styles.icon}
        customSize
        kind="file-attachment-04"
        svgProps={{
          height: 64,
          width: 64,
          color: 'var(--green-400)',
        }}
      />
      <div className={styles.text}>
        <Button className={styles.button} kind="ghost" onClick={onClick}>
          <Text size={14} weight="SemiBold">
            {t('content.photoGallery.emptyForm.selectLabel')}
          </Text>
        </Button>
        <Text className={styles.mainText} size={14}>
          {t('content.photoGallery.emptyForm.toDownloadLabel')}
        </Text>
      </div>
      <Text className={styles.hintText}>
        {t('content.photoGallery.emptyForm.recommendsRequirements')}
      </Text>
    </>
  );
};
