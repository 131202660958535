import styles from 'shared/components/VideoPlayer/PlayerStyles.module.css';
import { TDefaultVideoPlayerProps } from 'shared/components/VideoPlayer/types';

import { embedLinkFactory } from './embedLinkFactory';

type TProps = TDefaultVideoPlayerProps & {
  hosting: string;
};

export const IframePlayerElement = ({ src, hosting }: TProps) => {
  const link = embedLinkFactory[hosting](src);

  if (!link) {
    return null;
  }

  return (
    <iframe
      allowFullScreen
      className={styles.root}
      sandbox="allow-scripts allow-same-origin"
      src={link}
    />
  );
};
