import { newsApiKeys } from 'entities/news/consts';
import { mapNewsPostsResponse } from 'entities/news/mapper/mapNewsPostsResponse';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TContentLang, TEntityId } from 'shared/types/common';

import {
  TAPINewsPost,
  TCreateNewsPostDTO,
  TDeleteNewsPostParams,
  TEditNewsPostDTO,
} from './types';

export const fetchNews = ({ params }: TFetchParams, defaultLocale: TContentLang) =>
  get<TAPINewsPost[], TPaginatedMeta>(newsApiKeys.getNews, {
    params,
  }).then((response) => mapNewsPostsResponse(response.data, defaultLocale));

export const fetchNewsPost = (newsPostId: TEntityId) =>
  get<TAPINewsPost>(newsApiKeys.getNewsPost(newsPostId)).then(
    (response) => response.data.data,
  );

export const createNewsPost = (newsPostData: TCreateNewsPostDTO) =>
  post(newsApiKeys.createNewsPost, newsPostData);

export const editNewsPost = ({ id, ...newsPostData }: TEditNewsPostDTO) =>
  patch(newsApiKeys.editNewsPost(id), newsPostData);

export const deleteNewsPost = ({ newsPostId }: TDeleteNewsPostParams) =>
  del(newsApiKeys.deleteNewsPost(newsPostId));
