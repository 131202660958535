import cn from 'classnames';
import React from 'react';
import { IconButton } from 'shared/components/IconButton/IconButton';

import styles from './PhotoGalleryFormUploadButton.module.css';

type TProps = {
  openedUploadModal: boolean;
  count: number;
  onClick: VoidFunction;
};

export const PhotoGalleryFormUploadButton = ({
  openedUploadModal,
  count,
  onClick,
}: TProps) => {
  return (
    <IconButton
      disabled={count >= 100}
      icon="plus"
      className={cn(styles.root, {
        [styles.uploading]: openedUploadModal,
      })}
      iconProps={{
        customSize: true,
        color: 'var(--gray-50)',
        svgProps: {
          width: 28,
          height: 28,
        },
      }}
      onClick={onClick}
    />
  );
};
