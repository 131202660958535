import { prepareCreateNewsDTO } from 'entities/news/mapper/prepareCreateNewsDTO';
import { prepareCreateNewsPostParagraphsDTO } from 'entities/news/mapper/prepareCreateNewsPostParagraphsDTO';
import {
  ParagraphTypeEnum,
  TCreateNewsPostParagraphDTO,
  TEditNewsPostDTO,
  TEditNewsPostParagraphDTO,
} from 'entities/news/types';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { TContentLang, TEntityId } from 'shared/types/common';

type TProps = {
  initialValue: TEditNewsPostFields;
  data: TEditNewsPostFields;
  defaultLocale: TContentLang;
};

export const editNewsPostDTO = ({
  data,
  defaultLocale,
  initialValue,
  ...changedParams
}: TProps & TChangedEditNewsPostParagraphs): TEditNewsPostDTO => {
  const { paragraphs, ...mappedData } = prepareCreateNewsDTO(data, defaultLocale);

  const initialParagraphs = prepareCreateNewsPostParagraphsDTO(
    initialValue,
    defaultLocale,
  );

  return {
    id: data.id as TEntityId,
    ...mappedData,
    paragraphs: addDeletedParagraphs(
      initialParagraphs,
      filterDirtyParagraphs({
        paragraphs,
        ...changedParams,
      }),
    ),
  };
};

const addDeletedParagraphs = (
  initialParagraphs: TCreateNewsPostParagraphDTO[],
  changedParagraphs: TCreateNewsPostParagraphDTO[],
): TEditNewsPostParagraphDTO[] => {
  const changedParagraphIds = changedParagraphs.map((p) => p.id);
  const deletedParagraphs: TEditNewsPostParagraphDTO[] = initialParagraphs
    .filter(
      (p) =>
        !changedParagraphIds.includes(p.id) &&
        p.type !== ParagraphTypeEnum.Title &&
        p.type !== ParagraphTypeEnum.MainImage,
    )
    .map((p) => ({
      ...p,
      deleted: true,
    }));

  return [...changedParagraphs, ...deletedParagraphs];
};

export type TChangedEditNewsPostParagraphs = {
  isChangeMainImage: boolean;
  isChangeTitle: boolean;
};

const filterDirtyParagraphs = ({
  paragraphs,
  isChangeTitle,
  isChangeMainImage,
}: {
  paragraphs: TCreateNewsPostParagraphDTO[];
} & TChangedEditNewsPostParagraphs) =>
  paragraphs.filter((p) => {
    if (!isChangeMainImage && p.type === ParagraphTypeEnum.MainImage) {
      return false;
    }

    if (!isChangeTitle && p.type === ParagraphTypeEnum.Title) {
      return false;
    }

    return true;
  });
