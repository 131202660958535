import { getVideoPostStatus } from 'entities/video/helpers';
import { TAPIVideoPost, TVideoPost } from 'entities/video/types';
import { TContentLang } from 'shared/types/common';

export const mapApiVideoPostToVideoPost = (
  apiVideoPost: TAPIVideoPost,
  defaultLocale: TContentLang,
): TVideoPost => ({
  ...apiVideoPost,
  status: getVideoPostStatus(apiVideoPost.published ?? ''),
  title: apiVideoPost.translations[defaultLocale]?.name ?? defaultLocale,
});
