import { AchievementModal } from 'feature/achievementModal/AchievementModal';
import { PlayerLayout } from 'feature/playerLayout/ui/PlayerLayout/PlayerLayout';
import { usePlayerAchievementsColumnsConfig } from 'pages/player/achievements/PlayerAchievementsPage/columnsConfig';
import { useCreatePlayerAchievement } from 'pages/player/achievements/PlayerAchievementsPage/hooks/useCreatePlayerAchievement';
import { usePlayerAchievements } from 'pages/player/achievements/PlayerAchievementsPage/hooks/usePlayerAchievements';
import { PlayerAchievementsActions } from 'pages/player/achievements/PlayerAchievementsPage/PlayerAchievementsActions/PlayerAchievementsActions';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { useTranslation } from 'react-i18next';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './PlayerAchievementsPage.module.css';
import tableStyles from './PlayerAchievementsTable.module.css';

export const PlayerAchievementsPage = () => {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    changePage,
    playerAchievements,
    page,
    total,
    handleConfirmButtonClick,
  } = usePlayerAchievements();

  const { createAchievementModalProps, openCreateAchievementModal } =
    useCreatePlayerAchievement();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonList
        className={styles.wrapper}
        configCreator={usePlayerAchievementsColumnsConfig}
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.player.personalAchievement')}
        createButtonProps={{
          content: t('content.createAchievement'),
          onClick: openCreateAchievementModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(playerAchievements, page),
          totalItems: calculateTotalItems(playerAchievements, total),
          onPageChange: changePage,
        }}
        tableProps={{
          wrapperClassName: tableStyles.root,
          data: playerAchievements,
          headRowClassName: tableStyles.headRow,
          rowClassName: tableStyles.rowGridTemplate,
          ActionComponent: PlayerAchievementsActions,
        }}
      />
      {createAchievementModalProps.isOpened && (
        <AchievementModal {...createAchievementModalProps} />
      )}
    </PlayerLayout>
  );
};
