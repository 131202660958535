import { TFunction } from 'i18next';
import { defaultErrorHandler } from 'shared/api/errors';
import { TAPIError } from 'shared/api/types';
import { notify } from 'shared/components/Notification';

export const authErrorHandler = (reason: TAPIError, t: TFunction) => {
  // TODO: This case only for QA acceptance. Error handling should be improved: [WEB-1011]
  if (reason.response?.status === 401) {
    notify(t('content.checkInputData'), {
      type: 'error',
      title: t('shared.Form.validations.defaultLoginMessage'),
    });
  } else {
    defaultErrorHandler(reason);
  }
};
