import { useRef } from 'react';
import { EditorSlider } from 'shared/components/Editor/elements/EditorSliderElement/EditorSlider/EditorSlider';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { TEditorDisableStructureChange } from 'shared/components/Editor/utils/structureChange/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { Swiper } from 'swiper/types';

import styles from './EditorSliderElement.module.css';

export type TEditorSliderElementProps = {
  changeSlider: (path: number[]) => void;
  cropSliderImage: (path: number[], index: number) => void;
};

export const EditorSliderElement = ({
  attributes,
  element,
  changeSlider,
  disabledStructureChange,
  cropSliderImage,
  children,
}: RenderElementProps &
  TEditorSliderElementProps &
  TEditorDisableStructureChange) => {
  const editor = useSlateStatic();
  const swiperRef = useRef<Swiper>();

  if (element.type !== EditorElementTypeEnum.Slider) {
    return null;
  }

  const deleteSliderHandler = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  };

  const changeSliderHandler = () => {
    const path = ReactEditor.findPath(editor, element);
    changeSlider(path);
  };

  const cropImageHandler = () => {
    const path = ReactEditor.findPath(editor, element);
    cropSliderImage(path, swiperRef.current!.activeIndex);
  };

  return (
    <div {...attributes}>
      {children}
      <div className={styles.sliderWrapper} contentEditable={false}>
        <EditorSlider
          initSwiper={(swiper) => (swiperRef.current = swiper)}
          nodes={element.files.map(({ url }) => (
            <img key={url} alt="" className={styles.image} src={url} />
          ))}
        />
        <div className={styles.buttonWrapper}>
          <IconButton
            className={styles.button}
            icon="edit-02"
            iconSize="s"
            onClick={changeSliderHandler}
          />
          <IconButton
            className={styles.button}
            icon="crop-01"
            iconSize="s"
            onClick={cropImageHandler}
          />
          {!disabledStructureChange && (
            <IconButton
              className={styles.button}
              icon="trash"
              iconSize="s"
              onClick={deleteSliderHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};
