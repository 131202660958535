import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { DatePickerTime } from 'shared/components/DatePicker/components/DatePickerTime/DatePickerTime';

import styles from './Footer.module.css';

type TProps = {
  date?: Date;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (date: Date) => void;
  onTimeChange: (date: Date) => void;
  isShowTimePicker: boolean;
};

export const Footer = ({
  date,
  onCancel,
  onTimeChange,
  onConfirm,
  isShowTimePicker,
}: TProps) => {
  const { t } = useTranslation();
  const handleConfirm = () => {
    if (onConfirm && date) {
      onConfirm(date);
    }
  };

  return (
    <div className={styles.container}>
      {isShowTimePicker && (
        <DatePickerTime date={date} onTimeChange={onTimeChange} />
      )}
      <div className={styles.buttons}>
        <Button color="gray" kind="outlined" onClick={onCancel}>
          {t('shared.cancel')}
        </Button>
        <Button isDisabled={!date} onClick={handleConfirm}>
          {t('shared.confirm')}
        </Button>
      </div>
    </div>
  );
};
