import { useMergeRefs } from '@floating-ui/react';
import { cloneElement, forwardRef, HTMLProps, ReactElement } from 'react';
import { useTooltipContext } from 'shared/components/Tooltip/TooltipContext';

export const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { children: ReactElement }
>(({ children, ...props }, propRef) => {
  const context = useTooltipContext();
  // eslint-disable-next-line
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  return cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed',
    }),
  );
});
