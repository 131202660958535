import Select, { GroupBase } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { TSelectProps } from 'shared/components/Form/NewSelect/types';
import { toBoolean } from 'shared/lib/toBoolean';

import { components, getClassNames, getTheme, SelectContext } from './configs';
import styles from './NewSelect.module.css';

export const NewSelect = ({
  type = 'basic',
  size = 'medium',
  isLoading,
  error,
  isMulti,
  getMultiValueDescription,
  ...reactSelectProps
}: TSelectProps) => {
  return (
    <SelectContext.Provider value={{ getMultiValueDescription, size }}>
      <Select
        controlShouldRenderValue={!isMulti}
        isLoading={isLoading}
        isMulti={isMulti}
        theme={getTheme(size, type)}
        classNames={getClassNames({
          size,
          type,
          isLoading,
          isError: toBoolean(error),
        })}
        components={
          components as SelectComponents<unknown, boolean, GroupBase<unknown>>
        }
        {...reactSelectProps}
      />
      {error && <span className={styles.errorText}>{error}</span>}
    </SelectContext.Provider>
  );
};
