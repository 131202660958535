import { managerTeamAchievementsApiKeys } from 'entities/managerTeamAchievements/consts';
import {
  TCreateManagerTeamAchievementDTO,
  TEditManagerTeamAchievementDTO,
  TManagerTeamAchievement,
} from 'entities/managerTeamAchievements/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';
export const fetchManagerTeamAchievements = ({ params }: TFetchParams) =>
  get<TManagerTeamAchievement[], TPaginatedMeta>(
    managerTeamAchievementsApiKeys.getManagerTeamAchievements,
    {
      params,
    },
  ).then((response) => response.data);

export const deleteManagerTeamAchievement = (teamAchievementId: TEntityId) =>
  del(
    managerTeamAchievementsApiKeys.deleteManagerTeamAchievement(teamAchievementId),
  );

export const createManagerTeamAchievement = (
  teamAchievement: TCreateManagerTeamAchievementDTO,
) =>
  post(managerTeamAchievementsApiKeys.createManagerTeamAchievement, {
    teamAchievement,
  }).then((response) => response.data.data);

export const editManagerTeamAchievement = ({
  id,
  ...editTeamAchievementDTO
}: TEditManagerTeamAchievementDTO) =>
  patch(
    managerTeamAchievementsApiKeys.editManagerTeamAchievement(id),
    editTeamAchievementDTO,
  ).then((response) => response.data.data);
