import { useLocales } from 'entities/config/lib/useLocales';
import { TPlayerCareer } from 'entities/playerCareers/types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './PlayerCareersPage.module.css';

export const usePlayerCareersColumnsConfig: TConfigCreator<TPlayerCareer> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  return [
    {
      title: t('content.club'),
      render: (item) => (
        <>
          <div className={styles.previewWrapper}>
            {item.teamPicture ? (
              <img
                alt={`${item?.translations[defaultLocale]?.teamName} preview`}
                src={item.teamPicture.x1}
              />
            ) : (
              <Icon kind="picture" size="s" />
            )}
          </div>
          <Text className={styles.text}>
            {item?.translations[defaultLocale]?.teamName}
          </Text>
        </>
      ),
    },
    {
      title: t('content.season'),
      render: (item) => <Text className={styles.season}>{item.season}</Text>,
    },
    {
      title: t('content.player.championshipGames'),
      render: (item) => <Text>{item.championshipCount}</Text>,
    },
    {
      title: t('content.player.cupGames'),
      render: (item) => <Text>{item.cupsCount}</Text>,
    },
    {
      title: t('content.player.euroCupGames'),
      render: (item) => <Text>{item.eurocupsCount}</Text>,
    },
    {
      title: t('content.player.games'),
      render: (item) => <Text>{item.gamesCount || '-'}</Text>,
    },
    {
      title: 'ID',
      render: (item) => <Text>{item.id}</Text>,
    },
  ];
};
