import { OpponentTeamsPage } from 'pages/opponentTeam/OpponentTeamsPage/OpponentTeamsPage';
import { OpponentTeamRoute } from 'pages/opponentTeam/teamPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';
export const OpponentTeamsRoutes = {
  List: {
    path: routes.opponentTeams,
    element: <OpponentTeamsPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Page: OpponentTeamRoute,
} satisfies Record<string, TProtectedRoute>;
