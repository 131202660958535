import styles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';

type TProps = {
  disabled?: boolean;
};

export const BackButton = ({ disabled }: TProps) => {
  const { t } = useTranslation();
  const dispatch = useStoryEditorDispatchContext();
  const handleCancel: MouseEventHandler = () => {
    dispatch({ type: 'selectStoryElement', payload: null });
  };

  return (
    <div className={styles.backButton}>
      <Button isDisabled={disabled} kind="outlined" size="l" onClick={handleCancel}>
        {t('content.back')}
      </Button>
    </div>
  );
};
