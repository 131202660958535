import { useTranslation } from 'react-i18next';
import { Badge } from 'shared/components/Badge/Badge';
import { Centered } from 'shared/components/Centered/Centered';
import { PHOTO_GALLERY_DROPZONE_MAX_FILES_COUNT } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormDropzone/constants';

import styles from './PhotoGalleryFormCount.module.css';

type TProps = {
  count: number;
};

export const PhotoGalleryFormCount = ({ count }: TProps) => {
  const { t } = useTranslation();
  return (
    <Centered className={styles.count} fixed vertical={false}>
      <Badge
        color="dark"
        shape="pilled"
        size="l"
        type="solid"
        content={t('content.photoGallery.countImages', {
          count,
          maxFiles: PHOTO_GALLERY_DROPZONE_MAX_FILES_COUNT,
        })}
      />
    </Centered>
  );
};
