import { TEditVideoPostDTO } from 'entities/video/types';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { convertIdToNumberArray, prepareEntitiesIds } from 'shared/utils/entityIds';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const prepareVideoPostDTO = (
  formData: TVideoPostFields,
): TEditVideoPostDTO => {
  return {
    videoPost: {
      id: formData.id,
      importance: toNumber(formData.important),
      availableLocales: formData.locale.created,
      review: formData.review ?? false,
      matchId: formData.match ? toNumber(formData.match) : null,
      translations: reduceTranslationObject(formData.locale.created, (locale) => ({
        name: formData.translations[locale]?.title ?? '',
      })),
      relationships: {
        categories: prepareEntitiesIds(formData.categories),
        labels: prepareEntitiesIds(formData.labels),
        players: prepareEntitiesIds(formData.players),
        managers: prepareEntitiesIds(formData.managers),
        tournaments: formData.tournament
          ? convertIdToNumberArray(formData.tournament)
          : undefined,
        teams: prepareEntitiesIds(formData.teams),
        seasons: formData.season
          ? convertIdToNumberArray(formData.season)
          : undefined,
      },
      pictures: {
        baseImage: {
          filename: formData.preview.file?.name ?? 'base_image',
          data: clearMetaDataFromBase64Url(formData.preview.data),
        },
        sliderImage:
          formData.slider?.data && formData.important
            ? {
                filename: formData.slider.file?.name ?? 'slider_image',
                data: clearMetaDataFromBase64Url(formData.slider.data),
              }
            : undefined,
      },
      url: formData.videoLink,
      published: formData.publishDate ? formData.publishDate.toISOString() : '',
    },
  };
};
