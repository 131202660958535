import cn from 'classnames';
import { TEntityFilter } from 'feature/filters/ui/EntityFilter/type';
import { useEntityFilterForm } from 'feature/filters/ui/EntityFilter/useEntityFilterForm';
import styles from 'feature/filters/ui/Filter.module.css';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Checkbox } from 'shared/components/Form';
import { Loader } from 'shared/components/Loader/Loader';
import { Text } from 'shared/components/Text/Text';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

export type TEntityFilterProps = {
  onChangeFilter: (entities: TEntityId[]) => void;
  filter: TEntityId[];
  entities: TEntityFilter[];
  isLoading?: boolean;
};

export const EntityFilter = ({
  entities,
  isLoading,
  onChangeFilter,
  filter,
}: TEntityFilterProps) => {
  const { t } = useTranslation();

  const { control, submit } = useEntityFilterForm({
    entities,
    filter,
    onChangeFilter,
  });

  return (
    <form
      className={cn(styles.container, {
        [styles.loading]: isLoading,
      })}
      onSubmit={submit}
    >
      <div
        className={cn(styles.entitiesWrapper, {
          [styles.isLoading]: isLoading,
        })}
      >
        {isLoading ? (
          <Loader showLabel size="s" />
        ) : (
          entities!.map(({ id, name }) => (
            <Checkbox
              key={id}
              className={styles.checkbox}
              control={control}
              fieldName={toString(id)}
              labelClassName={styles.labelContainer}
              checkboxLabel={
                <div className={styles.label}>
                  <Text className={styles.labelText} size={12}>
                    {name}
                  </Text>
                </div>
              }
            />
          ))
        )}
      </div>
      <Button htmlType="submit" isDisabled={isLoading} onClick={submit}>
        {t('shared.confirm')}
      </Button>
    </form>
  );
};
