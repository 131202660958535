import { TEntityId } from 'shared/types/common';

const playerCareersBaseUrl = 'player/careers';

export const playerCareersQueryKeys = {
  playerCareers: 'playerCareers',
};

export const playerCareersApiKeys = {
  getPlayerCareers: playerCareersBaseUrl,
  createPlayerCareer: playerCareersBaseUrl,
  deleteCareer: (careerId: TEntityId) => `${playerCareersBaseUrl}/${careerId}`,
  editCareer: (careerId: TEntityId) => `${playerCareersBaseUrl}/${careerId}`,
};
