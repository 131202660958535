import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import { TOption } from 'shared/components/Form/NewSelect/types';
import { Icon } from 'shared/components/Icon/Icon';

export const DropdownIndicator = (props: DropdownIndicatorProps<TOption>) => {
  const { selectProps, innerProps, getClassNames } = props;
  const { isLoading, menuIsOpen } = selectProps;

  if (isLoading) {
    return null;
  }

  return (
    <div className={getClassNames('dropdownIndicator', props)} {...innerProps}>
      <Icon kind={menuIsOpen ? 'chevron-up' : 'chevron-down'} size="s" />
    </div>
  );
};
