import commonElementFormStyles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import {
  TQuizAnswersArray,
  TQuizControl,
} from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TChangeRadioButtonHandler } from 'shared/components/Form/RadioButton/types';
import { Text } from 'shared/components/Text/Text';

import { AnswerRadioButton } from './AnswerRadioButton';

type TProps = {
  control: TQuizControl;
  fields: TQuizAnswersArray;
  onChange: TChangeRadioButtonHandler;
};

export const AnswerRadioButtons = memo(({ control, fields, onChange }: TProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Text
        className={commonElementFormStyles.subtitle}
        size={14}
        tag="h2"
        weight="Regular"
      >
        {t('content.story.correctAnswer')}
      </Text>
      {fields.map((field, index) => (
        <AnswerRadioButton
          key={field.id}
          answerField={field}
          control={control}
          index={index}
          onChange={onChange}
        />
      ))}
    </>
  );
});
