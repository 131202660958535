import { ManagerCareersPage } from 'pages/manager/careers/ManagerCareersPage/ManagerCareersPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const CareersRoute: TProtectedRoute = {
  path: routes.managerCareers.url,
  element: <ManagerCareersPage />,
  roles: DEFAULT_ROUTER_ROLES,
};
